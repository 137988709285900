import React, { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Input, Button, Typography, Row } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import bkgImage from 'assets/images/background-login.jpg';

import useAuth from 'stores/useAuth';

const Login = () => {
  let history = useHistory();
  const login = useAuth(useCallback(({ login }) => login, []));
  const isLoggingIn = useAuth(
    useCallback(({ isLoggingIn }) => isLoggingIn, [])
  );

  const { search } = useLocation();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const onFinish = values => {
    const callback = () => history.push(searchParams?.redirect || '/');

    return login(values, callback);
  };

  return (
    <div
      style={{ backgroundImage: `url(${bkgImage})` }}
      className="unauthorized-wrapper"
    >
      <div className="unauthorized-container">
        <div className="unauthorized-container__form-wrapper">
          <Row justify="center">
            <Typography.Title
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              Đăng nhập
            </Typography.Title>
          </Row>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={[{ required: true, message: 'Nhập tên đăng nhập!' }]}
            >
              <Input prefix={<UserOutlined />} />
            </Form.Item>

            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>

            <div style={{ textAlign: 'end', marginBottom: '0.5em' }}>
              <Button disabled type="link">
                <Link to="/">Quên mật khẩu?</Link>
              </Button>
            </div>

            <Form.Item>
              <Button
                loading={isLoggingIn}
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
