import React, { useCallback } from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import moment from 'moment';
import { CONTRACT_STATUS_VALUE, CONTRACT_TYPE_VALUE } from 'appConstants';
import Button from 'components/Button';
import LiquidateFormModal from './LiquidateFormModal';

const ChangeStatusButton = ({ contract }) => {
  const { status, id, finishDate, canLiquidate, contractType } = contract;
  const { changeContractStatus } = useDashboard(
    useCallback(
      ({ changeContractStatus }) => ({
        changeContractStatus
      }),
      []
    ),
    shallow
  );
  const shouldLiquidate =
    canLiquidate &&
    (status === CONTRACT_STATUS_VALUE.COMPOSE ||
      status === CONTRACT_STATUS_VALUE.EXPIRED);
  const shouldExpire =
    moment(finishDate).diff(moment()) < 0 &&
    (status === CONTRACT_STATUS_VALUE.COMPOSE ||
      status === CONTRACT_STATUS_VALUE.LIQUIDATED);
  const requiredLiquidationFile =
    contractType !== CONTRACT_TYPE_VALUE.QUAY &&
    contractType !== CONTRACT_TYPE_VALUE.CHUP &&
    contractType !== CONTRACT_TYPE_VALUE.THUE &&
    contractType !== CONTRACT_TYPE_VALUE.HOTR;

  return (
    <Space direction="vertical">
      {shouldLiquidate &&
        (requiredLiquidationFile ? (
          <LiquidateFormModal contractId={contract.id} contract={contract}>
            <Button danger>Thanh lý</Button>
          </LiquidateFormModal>
        ) : (
          <Popconfirm
            title={
              <Typography.Text>
                Chuyển trạng thái hợp đồng sang{' '}
                <Typography.Text strong>Thanh Lý?</Typography.Text>
              </Typography.Text>
            }
            onConfirm={() =>
              changeContractStatus(id, CONTRACT_STATUS_VALUE.LIQUIDATED)
            }
            okText="Đồng ý"
            cancelText="Huỷ bỏ"
          >
            <Button danger>Thanh Lý</Button>
          </Popconfirm>
        ))}
      {shouldExpire && (
        <Popconfirm
          title={
            <Typography.Text>
              Chuyển trạng thái hợp đồng sang{' '}
              <Typography.Text strong>Hết Hạn?</Typography.Text>
            </Typography.Text>
          }
          onConfirm={() =>
            changeContractStatus(id, CONTRACT_STATUS_VALUE.EXPIRED)
          }
          okText="Đồng ý"
          cancelText="Huỷ bỏ"
        >
          <Button style={{ background: 'gray', border: 'none' }}>
            Hết hạn
          </Button>
        </Popconfirm>
      )}
    </Space>
  );
};

export default ChangeStatusButton;
