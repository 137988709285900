import { Button, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';

import ReupSongAutoComplete from './AutoComplete/ReupSongAutoComplete';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from 'utils/other';

const ReupSongSelect = ({ form, songs, onChange = f => f }) => {
  const [items, setItems] = useState([]);

  const getListStyle = useCallback(
    isDraggingOver => ({
      background: isDraggingOver ? 'lightgrey' : ''
    }),
    []
  );

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const rItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      setItems(rItems);
      form.setFieldsValue({ songIds: rItems.map(({ value }) => value) });
    },
    [form, items]
  );

  const onRemove = useCallback(
    v => {
      const rItems = items.filter(({ value: _v }) => _v !== v);
      setItems(rItems);
      form.setFieldsValue({ songIds: rItems.map(({ value }) => value) });
    },
    [form, items]
  );

  useEffect(() => {
    if (songs && songs.length > 0) {
      setItems(songs.map(({ id, title }) => ({ value: id, label: title })));
    }
  }, [songs]);

  return (
    <>
      <Form.Item
        name="songIds"
        label="Bài hát"
        className="reup-song-select"
        rules={[{ required: true, message: 'Chọn bài hát!' }]}
      >
        <ReupSongAutoComplete
          mode="multiple"
          reupSong={songs}
          onChange={(_, v) => {
            setItems(
              v.map(({ value, children }) => ({ value, label: children }))
            );

            onChange(_, v);
          }}
        />
      </Form.Item>

      {!!items.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="reup-dnd-container"
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.value}
                    draggableId={item.value}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="reup-dnd-item"
                      >
                        <div className="reup-dnd-item-main">
                          Bài {index + 1}: {item.label}
                        </div>

                        <div className="reup-dnd-item-side">
                          <Button
                            onClick={() => onRemove(item.value)}
                            danger
                            size="small"
                            type="primary"
                            icon={<DeleteOutlined />}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default ReupSongSelect;
