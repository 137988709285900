import AutoComplete from 'components/AutoComplete';
import React, { useCallback } from 'react';
import { useSongRelease } from 'stores/useSongRelease';
import { useSong } from 'stores/useSong';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';
import { extractPartnersFromSong, getSongWithSingerLabel } from 'utils/other';
import { useService } from 'stores/useService';

const SongReleaseAutoComplete = props => {
  const { songRelease, setCurrentSong } = useSongRelease(
    useCallback(
      ({ songRelease, setCurrentSong }) => ({
        songRelease,
        setCurrentSong
      }),
      []
    ),
    shallow
  );
  const { getSong } = useSong(
    useCallback(
      ({ getSong }) => ({
        getSong
      }),
      []
    ),
    shallow
  );

  const { searchForSong } = useService(
    useCallback(
      ({ searchForSong }) => ({
        searchForSong
      }),
      []
    ),
    shallow
  );

  const song = get(songRelease, 'song', null);
  const defaultOption = song ? { value: song.id, label: song.songName } : null;

  const handleSelect = async id => {
    const song = await getSong(id);

    const { songName, partnerSong, songType, songTypeName } = song;
    const [musicians, singers] = extractPartnersFromSong(partnerSong, true);

    const musician = musicians.map(({ label }) => label).join(', ');
    const singer = singers.map(({ label }) => label).join(', ');
    const songParser = {
      label: songName + ' - ' + singer,
      songName,
      value: id,
      singer,
      musician,
      songType,
      songTypeName
    };
    setCurrentSong(songParser);
  };

  return (
    <AutoComplete
      onSelect={handleSelect}
      defaultOption={defaultOption}
      placeHolder="Tìm bài hát"
      handleSearch={searchValue => searchForSong(searchValue, true)}
      labelKey="songName"
      valueKey="id"
      style={{ width: '100%' }}
      getLabel={getSongWithSingerLabel}
      {...props}
    />
  );
};

export default SongReleaseAutoComplete;
