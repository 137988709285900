import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import React, { useCallback } from 'react';
import { useSongPublication } from 'stores/useSongPublication';
import shallow from 'zustand/shallow';

const SongPublicationDeleteButton = ({ item }) => {
  const { deleteSongPublication } = useSongPublication(
    useCallback(
      ({ deleteSongPublication }) => ({
        deleteSongPublication
      }),
      []
    ),
    shallow
  );

  return (
    <DeleteConfirmPopup
      title={`Xoá ${item.code}?`}
      onDelete={() => deleteSongPublication(item.id)}
    >
      <Button type="danger" icon={<DeleteOutlined />} />
    </DeleteConfirmPopup>
  );
};

export default SongPublicationDeleteButton;
