import { Button, Tooltip } from 'antd';
import React from 'react';
import { ExportOutlined } from '@ant-design/icons';

const ExportButton = React.forwardRef(
  (
    {
      onExport,
      onExportByIds,
      selectedItems,
      callback,
      subject,
      showTooltip = true,
      tooltip,
      isSelected = true,
      ...rest
    },
    ref
  ) => {
    const handleExportByIds = async () => {
      try {
        await onExportByIds(selectedItems);
        callback();
      } catch (error) {
        callback();
      }
    };

    return showTooltip ? (
      <Tooltip
        title={
          tooltip
            ? tooltip
            : isSelected && !!selectedItems?.length
            ? `Export ${selectedItems?.length} ${subject} đã chọn`
            : `Export ${subject} của trang này`
        }
      >
        <Button
          ref={ref}
          type="primary"
          onClick={() =>
            !!selectedItems?.length ? handleExportByIds() : onExport()
          }
          icon={<ExportOutlined />}
          style={{ background: 'green', border: 'none' }}
          {...rest}
        >
          Export
        </Button>
      </Tooltip>
    ) : (
      <Button
        ref={ref}
        type="primary"
        onClick={() => onExport()}
        icon={<ExportOutlined />}
        style={{ background: 'green', border: 'none' }}
        {...rest}
      >
        Export
      </Button>
    );
  }
);

export default ExportButton;
