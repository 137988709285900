import AutoComplete from 'components/AutoComplete/AutoComplete';
import { useCallback } from 'react';
import { useService } from 'stores/useService';
import shallow from 'zustand/shallow';

const ContractAutocomplete = ({
  defaultOption,
  placeholder = 'Tìm hợp đồng',
  ...props
}) => {
  const { searchForContract } = useService(
    useCallback(
      ({ searchForContract }) => ({
        searchForContract
      }),
      []
    ),
    shallow
  );

  return (
    <AutoComplete
      placeholder={placeholder}
      handleSearch={searchForContract}
      defaultOption={defaultOption}
      labelKey="contractCode"
      valueKey="id"
      {...props}
    />
  );
};

export default ContractAutocomplete;
