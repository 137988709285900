import { AuditOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Music } from 'components/Icons';
import React, { useCallback, useEffect } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import CardStatic from './CardStatic';

const Statistics = () => {
  const { statistics, getStatistics } = useDashboard(
    useCallback(
      ({ statistics, getStatistics }) => ({
        statistics,
        getStatistics
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  return (
    <Row justify="space-between" gutter={16} className="mb-md">
      <Col flex={1}>
        <CardStatic
          title="Hợp đồng"
          Icon={AuditOutlined}
          total={statistics?.contract?.total}
          color="#1e3d73"
        />
      </Col>
      <Col flex={1}>
        <CardStatic
          title="Bài hát"
          Icon={Music}
          total={statistics?.song?.total}
          color="#ffbf43"
        />
      </Col>
      <Col flex={1}>
        <CardStatic
          title="Đối tác"
          Icon={TeamOutlined}
          total={statistics?.partner?.total}
          color="#1ba463"
        />
      </Col>
      <Col flex={1}>
        <CardStatic
          title="Nhân viên"
          Icon={UserOutlined}
          total={statistics?.user?.total}
          color="#fe517e"
        />
      </Col>
    </Row>
  );
};

export default Statistics;
