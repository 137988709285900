import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  SaveOutlined
} from '@ant-design/icons';
import {
  Button as AntdButton,
  Col,
  Form,
  notification,
  Row,
  Select as AntdSelect,
  Space,
  Tooltip,
  Typography
} from 'antd';
import { REVENUE_SHARING_PERTAGE } from 'appConstants';
import Button from 'components/Button';
import Select from 'components/Select';
import Tag from 'components/Tag';
import { useCallback, useEffect, useState } from 'react';
import { useContract } from 'stores/useContract';
import { useService } from 'stores/useService';
import { generatePertage } from 'utils/common';
import shallow from 'zustand/shallow';

const { Option } = AntdSelect;

const pertages = generatePertage();
const pertageDefault = {
  groupA: 50,
  groupBCopyRight: 10,
  groupBRelatedRight: 40
};

const RevenueSharing = () => {
  const [form] = Form.useForm();
  const [autoFillType, setAutoFillType] = useState(true);
  const [typeOfBusiness, setTypeOfBusiness] = useState([]);
  const { contract, updateContractRevenue } = useContract(
    useCallback(
      ({ contract, updateContractRevenue }) => ({
        contract,
        updateContractRevenue
      }),
      []
    ),
    shallow
  );

  const { getBusinessAreas } = useService(
    useCallback(
      ({ getBusinessAreas }) => ({
        getBusinessAreas
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    (async () => {
      const data = await getBusinessAreas();
      if (data) {
        const types = data.map(({ id, name, code }) => ({
          value: id,
          label: name,
          code: code
        }));
        setTypeOfBusiness(types);
      }
    })();
  }, [getBusinessAreas]);

  const generatePertageDefault = useCallback(
    businessType => {
      const { contractType } = contract;
      const getPertageItem = REVENUE_SHARING_PERTAGE.find(
        item => item.contractType === contractType
      );
      // Check contract type with special pertage by business type
      if (
        getPertageItem.specialPertage &&
        getPertageItem.specialPertage.businessType.includes(businessType)
      )
        return getPertageItem.specialPertage.pertage;
      return getPertageItem.pertage;
    },
    [contract]
  );

  // function convert all pertage to default or allInCompany
  const generatePertageByType = useCallback(
    generateType => {
      const revenue = form.getFieldValue('revenue');
      if (generateType === 'default') {
        const revenueParser = revenue.map(item => ({
          ...item,
          code: typeOfBusiness.find(type => type.value === item.businessTypeId)
            ?.code
        }));
        const generateRevenue = revenueParser.map(type => ({
          businessTypeId: type.businessTypeId,
          ...generatePertageDefault(type?.code)
        }));
        form.setFieldsValue({
          revenue: generateRevenue
        });
        notification.info({
          message: 'Đã chuyển tất cả tỉ lệ về mặc định'
        });
      } else if (generateType === 'allInCompany') {
        // case owned company
        const generateRevenue = revenue.map(type => ({
          businessTypeId: type.businessTypeId,
          groupA: 100,
          groupBCopyRight: 0,
          groupBRelatedRight: 0
        }));
        form.setFieldsValue({
          revenue: generateRevenue
        });
        notification.info({
          message: 'Đã chuyển tất cả tỉ lệ về công ty sở hữu'
        });
      }
    },
    [form, generatePertageDefault, typeOfBusiness]
  );

  useEffect(() => {}, [contract]);

  useEffect(() => {
    if (contract && autoFillType && !!typeOfBusiness.length) {
      const { revenueSharing } = contract;
      const typesRemain = typeOfBusiness.filter(
        t => !revenueSharing.map(r => r.businessTypeId).includes(t.value)
      );
      const generateRevenue = typesRemain.map(type => ({
        businessTypeId: type.value,
        ...generatePertageDefault(type?.code)
      }));
      form.setFieldsValue({
        revenue: !!revenueSharing.length ? revenueSharing : generateRevenue
      });
      setAutoFillType(false);
    }
  }, [contract, form, typeOfBusiness, autoFillType, generatePertageDefault]);

  const handleAddRevenue = add => {
    if (form.getFieldValue('revenue')?.length >= typeOfBusiness.length) {
      notification.error({
        message: 'Đã đủ loại lĩnh vực kinh doanh'
      });
      return;
    }
    add();
  };
  const onFinish = () => {
    const revenue = form.getFieldValue('revenue');
    const isVerify = revenue.every(
      ({ groupA, groupBCopyRight, groupBRelatedRight }) => {
        const total = groupA + groupBCopyRight + groupBRelatedRight;
        if (total === 100) {
          return true;
        }
        return notification.error({
          message: 'Vui lòng kiểm tra tỉ lệ'
        });
      }
    );
    if (isVerify) {
      updateContractRevenue(contract.id, revenue);
    }
  };

  const handleChangeRate = (e, index, name) => {
    const data = form.getFieldValue('revenue');
    const record = data[index];
    const { groupBRelatedRight, groupBCopyRight } = record;
    const remainRate =
      100 -
      (name === 'groupBCopyRight'
        ? groupBRelatedRight + e
        : groupBCopyRight + e);
    const newData = data.map((d, i) => {
      return i === index
        ? {
            ...d,
            groupA: remainRate >= 0 ? remainRate : 0
          }
        : d;
    });
    form.setFieldsValue({ revenue: newData });

    return e;
  };

  return (
    <div>
      <Form
        name="revenue-form"
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <Form.List
          name="revenue"
          rules={[
            {
              validator: async (_, revenue) => {
                if (!revenue || revenue.length < 1) {
                  return Promise.reject(
                    new Error('Cần tối thiểu 1 lĩnh vực kinh doanh')
                  );
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <Row justify="space-between" className="mb-md">
                <Space>
                  <Typography.Text strong>Tiện ích:</Typography.Text>
                  <Tooltip title="Chuyển tất cả tỉ lệ về mặc định">
                    <AntdButton
                      icon={<PercentageOutlined />}
                      onClick={() => generatePertageByType('default')}
                    >
                      Mặc định
                    </AntdButton>
                  </Tooltip>
                  <Tooltip title="Chuyển tất cả tỉ lệ về bên A 100%">
                    <AntdButton
                      icon={<HomeOutlined />}
                      onClick={() => generatePertageByType('allInCompany')}
                    >
                      Công ty sở hữu
                    </AntdButton>
                  </Tooltip>
                </Space>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => handleAddRevenue(add)}
                  type="link"
                >
                  Thêm lĩnh vực kinh doanh
                </Button>
              </Row>
              <Row gutter={16} style={{ textAlign: 'left' }}>
                <Col span={6}>
                  <Typography.Text strong>
                    Loại lĩnh vực kinh doanh:
                  </Typography.Text>
                </Col>
                <Col span={5}>
                  <Typography.Text strong>Bên A:</Typography.Text>
                </Col>
                <Col span={5}>
                  <Typography.Text strong>
                    Bên B Quyền liên quan:
                  </Typography.Text>
                </Col>
                <Col span={5}>
                  <Typography.Text strong>Bên B Quyền tác giả:</Typography.Text>
                </Col>
              </Row>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row gutter={16} align="middle" key={key}>
                  <Col span={6}>
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => {
                        const businessTypes = getFieldValue('revenue').map(
                          field => field?.businessTypeId
                        );
                        return (
                          <Form.Item
                            {...restField}
                            name={[name, 'businessTypeId']}
                            fieldKey={[fieldKey, 'businessTypeId']}
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng chọn lĩnh vực'
                              }
                            ]}
                            noStyle
                          >
                            <AntdSelect>
                              {typeOfBusiness.map(field => (
                                <Option
                                  key={field.value}
                                  value={field.value}
                                  disabled={businessTypes.includes(field.value)}
                                >
                                  {field.label}
                                </Option>
                              ))}
                            </AntdSelect>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...restField}
                      name={[name, 'groupA']}
                      fieldKey={[fieldKey, 'groupA']}
                      initialValue={pertageDefault.groupA}
                    >
                      <Select
                        options={pertages}
                        style={{ width: '100%' }}
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...restField}
                      name={[name, 'groupBRelatedRight']}
                      fieldKey={[fieldKey, 'groupBRelatedRight']}
                      initialValue={pertageDefault.groupBRelatedRight}
                      getValueFromEvent={e =>
                        handleChangeRate(e, index, 'groupBRelatedRight')
                      }
                    >
                      <Select
                        options={pertages}
                        style={{ width: '100%' }}
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...restField}
                      name={[name, 'groupBCopyRight']}
                      fieldKey={[fieldKey, 'groupBCopyRight']}
                      initialValue={pertageDefault.groupBCopyRight}
                      getValueFromEvent={e =>
                        handleChangeRate(e, index, 'groupBCopyRight')
                      }
                    >
                      <Select options={pertages} showSearch />
                    </Form.Item>
                  </Col>

                  <Col flex={'auto'}>
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => {
                        const record = getFieldValue('revenue')[index];
                        const { groupA, groupBRelatedRight, groupBCopyRight } =
                          record ? record : 0;

                        const isVerify =
                          groupA + groupBRelatedRight + groupBCopyRight === 100;
                        return (
                          <Form.Item
                            {...restField}
                            name={[name, 'Status']}
                            fieldKey={[fieldKey, 'Status']}
                            noStyle
                          >
                            <Space size={16}>
                              {isVerify ? (
                                <CheckOutlined style={{ color: 'green' }} />
                              ) : (
                                <CloseOutlined style={{ color: 'red' }} />
                              )}
                              <Button
                                size="small"
                                danger
                                ghost
                                onClick={() => remove(name)}
                                icon={<DeleteOutlined />}
                              ></Button>
                            </Space>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row justify="end">
                <Form.ErrorList errors={errors} />
              </Row>
              <Row justify="end">
                <Space>
                  {!contract?.revenueSharing.length && (
                    <Tag icon={<InfoCircleOutlined />} color="warning">
                      Chưa có lĩnh vực kinh doanh.
                    </Tag>
                  )}

                  <Button icon={<SaveOutlined />} htmlType="submit">
                    Lưu
                  </Button>
                </Space>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default RevenueSharing;
