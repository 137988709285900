import React, { useCallback, useEffect } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import DashboardTable from '../DashboardTable';
import { columns } from './columns';

const LatestSong = () => {
  const { latestSongs, getLatestSongs } = useDashboard(
    useCallback(
      ({ latestSongs, getLatestSongs }) => ({
        latestSongs,
        getLatestSongs
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getLatestSongs();
  }, [getLatestSongs]);

  return (
    <DashboardTable
      data={latestSongs?.data?.map(song => ({ ...song, key: song.id }))}
      columns={columns}
      title="Bài hát mới"
      total={latestSongs?.total}
    />
  );
};

export default LatestSong;
