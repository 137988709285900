import { Typography, Collapse, Space, Avatar } from 'antd';
import React from 'react';
import ContractInfoContent from './ContractInfoContent';

const { Panel } = Collapse;

const ChildContracts = ({ childContracts }) => {
  return (
    <div>
      <Typography.Text strong>Hợp đồng con:</Typography.Text>
      <Collapse>
        {childContracts.map((contract, index) => (
          <Panel
            key={contract.id}
            header={
              <Space>
                <Avatar
                  style={{ backgroundColor: 'red', verticalAlign: 'middle' }}
                  size="small"
                >
                  {index + 1}
                </Avatar>
                <Typography.Text strong>
                  {contract.contractCode}
                </Typography.Text>
              </Space>
            }
          >
            <ContractInfoContent contract={contract} />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ChildContracts;
