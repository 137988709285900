import {
  ApiOutlined,
  CaretRightOutlined,
  FundProjectionScreenOutlined,
  SettingOutlined
} from '@ant-design/icons';
import {
  Affix,
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Space,
  Tabs,
  Typography,
  Spin
} from 'antd';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import Role from 'components/Icons/Role';
import { CommonContainer } from 'components/StyledComponents';
import React, { useCallback, useEffect, useState } from 'react';
import { useSysRole } from 'stores/useSysRole';
import shallow from 'zustand/shallow';
import { generateKeyPanel } from './utils';

const { Panel } = Collapse;
const { TabPane } = Tabs;

// TODO: split component
const SysRole = () => {
  const [groupData, setGroupData] = useState();
  const [activeKey, setActiveKey] = useState();
  const [isAddingRole, setIsAddingRole] = useState(false);
  const [form] = Form.useForm();
  const [infoForm] = Form.useForm();
  const [prevKeys, setPrevKeys] = useState([]);
  const [prevValues, setPrevValues] = useState(null);
  // const [currentRole, setCurrentRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    getSysPermission,
    sysPermission,
    getSysRole,
    sysRole,
    deleteSysRole,
    updateSysRolePermission,
    updateSysRole,
    addNewRoleLocal,
    createSysRole,
    cancelNewRoleLocal,
    setCurrentRole,
    currentRole
  } = useSysRole(
    useCallback(
      ({
        getSysPermission,
        sysPermission,
        getSysRole,
        sysRole,
        deleteSysRole,
        updateSysRolePermission,
        updateSysRole,
        addNewRoleLocal,
        createSysRole,
        cancelNewRoleLocal,
        setCurrentRole,
        currentRole
      }) => ({
        getSysPermission,
        sysPermission,
        getSysRole,
        sysRole,
        deleteSysRole,
        updateSysRolePermission,
        updateSysRole,
        addNewRoleLocal,
        createSysRole,
        cancelNewRoleLocal,
        setCurrentRole,
        currentRole
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getSysPermission();
    getSysRole();
  }, [getSysPermission, getSysRole]);

  function groupBy(arr, prop) {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  }

  useEffect(() => {
    if (!!sysPermission.length && !!sysRole.length) {
      const listKeyExtension = ['ByOwner', 'ByAuthorized'];
      let extensions = [];
      sysPermission.forEach(permission => {
        const isExtension = listKeyExtension.some(key =>
          permission.key.includes(key)
        );

        if (isExtension) {
          const parentKey = permission.key.replace(
            /.ByOwner|.ByAuthorized/g,
            ''
          );
          const extension = { ...permission, parentKey };
          extensions.push(extension);
        }
      });

      const newArr = sysPermission.filter(
        permission =>
          !extensions.some(extension => extension.key === permission.key)
      );

      const parserData = newArr.map(permission => {
        const findPermission = extensions.filter(
          extension => extension.parentKey === permission.key
        );
        return !!findPermission.length
          ? { ...permission, extensions: findPermission }
          : permission;
      });

      const groupData = groupBy(parserData, 'group');

      setGroupData(groupData);
    }
  }, [sysPermission, sysRole]);

  // set form data from Role
  const setFormRole = useCallback(
    role => {
      if (groupData) {
        const thisRolePermission = role.sysPermissionRole.map(
          s => s.permissionId
        );
        const permissionsCheckAll = groupData.filter(group =>
          group.every(permission => thisRolePermission.includes(permission.id))
        );
        const keysCheckAll = permissionsCheckAll.map(d => d[0].group);
        setPrevKeys(keysCheckAll);
        setPrevValues(thisRolePermission);

        form.setFieldsValue({
          sysPermission: [...thisRolePermission, ...keysCheckAll]
        });

        infoForm.setFieldsValue({
          name: role.name,
          title: role.title
        });
      }
    },
    [form, infoForm, groupData]
  );

  useEffect(() => {
    if (currentRole && groupData && !isAddingRole) {
      setFormRole(currentRole);
    }
  }, [currentRole, setFormRole, groupData, isAddingRole]);

  const onUpdateRole = async id => {
    try {
      const values = await infoForm.validateFields();
      updateSysRole({ id, ...values });
    } catch (errorInfo) {}
  };

  const updateRolePermission = id => {
    updateSysRolePermission({
      id,
      permissionIds: form
        .getFieldValue('sysPermission')
        .filter(permission => typeof permission !== 'string')
    });
  };

  const onChangeTab = key => {
    setActiveKey(key);
    const role = sysRole.find(role => role.id.toString() === key);
    setCurrentRole(role);
  };

  const onDeleteRole = id => {
    deleteSysRole(id, lastKey => {
      setActiveKey(lastKey);
      const role = sysRole.find(role => role.id.toString() === lastKey);
      setCurrentRole(role);
    });
  };

  const addRoleLocal = () => {
    addNewRoleLocal();
    setActiveKey('0');
    setIsAddingRole(true);
    form.setFieldsValue({ sysPermission: [] });
    infoForm.setFieldsValue({ name: '', title: '' });
  };

  const onCreateSysRole = () => {
    setLoading(true);
    createSysRole(
      { ...infoForm.getFieldValue() },
      role => {
        setActiveKey(role.id.toString());
        setCurrentRole(role);
        setLoading(false);
      },
      () => {
        onCancelNewRole();
        setLoading(false);
      }
    );
    setIsAddingRole(false);
  };

  const onCancelNewRole = () => {
    cancelNewRoleLocal();
    setIsAddingRole(false);
    setActiveKey(sysRole[sysRole.length - 2].id.toString());

    const role = sysRole[sysRole.length - 2];
    setCurrentRole(role);
  };

  // Handle change checkbox value
  const onValuesChange = changedValues => {
    const keys = changedValues.sysPermission.filter(
      permission => typeof permission === 'string'
    );
    const values = changedValues.sysPermission.filter(
      permission => typeof permission !== 'string'
    );

    const keyChange = keys
      .filter(key => !prevKeys.includes(key))
      .concat(prevKeys.filter(key => !keys.includes(key)))
      .shift();
    // Check checkbox checkAll (value by Key)
    if (keyChange) {
      const isChangeKeyChecked = keys.length > prevKeys.length;
      const thisPermission = sysPermission
        .filter(permission => permission.group === keyChange)
        .map(permission => permission.id);
      if (isChangeKeyChecked) {
        // checkAll checked
        const newPermission = [
          ...new Set([...thisPermission, ...values, ...keys])
        ];
        setPrevKeys(keys);
        setPrevValues([...thisPermission, ...values]);
        form.setFieldsValue({
          sysPermission: newPermission
        });
      } else {
        // checkAll unchecked
        const removePermission = values.filter(function (el) {
          return !thisPermission.includes(el);
        });
        setPrevKeys(keys);
        setPrevValues(removePermission);
        form.setFieldsValue({
          sysPermission: [
            ...removePermission,
            ...keys.filter(key => key !== keyChange)
          ]
        });
      }
    } else {
      // Check checkbox value (value by id)
      setPrevValues(values);
      const valueChange = values
        .filter(value => !prevValues.includes(value))
        .concat(prevValues.filter(value => !values.includes(value)))
        .shift();
      const recordChange = sysPermission.find(
        permission => permission.id === valueChange
      );
      const groupChange = sysPermission.filter(
        permission => permission.group === recordChange.group
      );
      const keyChange = groupChange[0].group;
      const isShouldCheck = groupChange.every(permission =>
        values.includes(permission.id)
      );
      if (isShouldCheck) {
        setPrevKeys(keys => [...keys, keyChange]);
        form.setFieldsValue({
          sysPermission: [...changedValues.sysPermission, keyChange]
        });
      } else {
        setPrevKeys(keys => keys.filter(key => key !== keyChange));
        form.setFieldsValue({
          sysPermission: changedValues.sysPermission.filter(
            permission => permission !== keyChange
          )
        });
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Typography.Title>Quản lý quyền hạn</Typography.Title>
      {groupData && !!sysRole.length ? (
        <CommonContainer>
          <Tabs
            onChange={onChangeTab}
            tabBarExtraContent={{
              right: (
                <Button
                  type="primary"
                  ghost
                  onClick={addRoleLocal}
                  disabled={isAddingRole}
                >
                  Thêm vai trò
                </Button>
              )
            }}
            activeKey={activeKey}
          >
            {sysRole.map(role => (
              <TabPane tab={role.title} key={role.id} disabled={isAddingRole}>
                <Row gutter={16}>
                  <Col flex="auto">
                    <Form
                      form={form}
                      name="sys-permission"
                      onValuesChange={onValuesChange}
                    >
                      <Form.Item name="sysPermission">
                        <Checkbox.Group className="role-group">
                          <Row style={{ padding: 16 }}>
                            <Col span={12}>
                              <Space size="small">
                                <ApiOutlined style={{ fontSize: 16 }} />
                                <Typography.Text strong>
                                  Quyền Thao Tác
                                </Typography.Text>
                              </Space>
                            </Col>
                            <Col span={12}>
                              <Row justify="space-between">
                                <Space size="small">
                                  <FundProjectionScreenOutlined
                                    style={{ fontSize: 16 }}
                                  />
                                  <Typography.Text strong>
                                    Quyền Màn Hình
                                  </Typography.Text>
                                </Space>
                                <Button
                                  type="primary"
                                  onClick={() => updateRolePermission(role.id)}
                                  disabled={isAddingRole}
                                >
                                  Cập nhật quyền
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                          <Collapse
                            defaultActiveKey={generateKeyPanel(groupData)}
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}
                          >
                            {groupData.map((data, index) => (
                              <Panel
                                header={
                                  <Checkbox
                                    onClick={e => e.stopPropagation()}
                                    value={`${data[0].group}`}
                                    disabled={isAddingRole}
                                  >
                                    {data[0].groupName}
                                  </Checkbox>
                                }
                                key={index + 1}
                                extra={<SettingOutlined />}
                              >
                                <Row>
                                  <Col span={12}>
                                    <div className="role-item">
                                      {data
                                        .filter(
                                          permission =>
                                            permission.type === 'action'
                                        )
                                        .map(permission => (
                                          <>
                                            <Checkbox
                                              key={permission.id}
                                              value={permission.id}
                                              style={{
                                                lineHeight: '32px'
                                              }}
                                              disabled={isAddingRole}
                                            >
                                              <Typography.Text>
                                                {permission.name}{' '}
                                                {permission.extensions && (
                                                  <Typography.Text type="warning">
                                                    (* Quyền mở rộng sẽ được ưu
                                                    tiên)
                                                  </Typography.Text>
                                                )}
                                              </Typography.Text>
                                            </Checkbox>
                                            {/* Permission extensions */}
                                            {permission.extensions && (
                                              <Space
                                                style={{ marginLeft: 24 }}
                                                direction="vertical"
                                                size={0}
                                              >
                                                {permission.extensions.map(
                                                  extension => (
                                                    <Checkbox
                                                      key={extension.id}
                                                      value={extension.id}
                                                      style={{
                                                        lineHeight: '32px'
                                                      }}
                                                      disabled={isAddingRole}
                                                    >
                                                      <Typography.Text>
                                                        {extension.name}
                                                      </Typography.Text>
                                                    </Checkbox>
                                                  )
                                                )}
                                              </Space>
                                            )}
                                          </>
                                        ))}
                                    </div>
                                  </Col>
                                  <Col span={12}>
                                    <div className="role-item">
                                      {data
                                        .filter(
                                          permission =>
                                            permission.type === 'views'
                                        )
                                        .map(d => (
                                          <Checkbox
                                            key={d.id}
                                            value={d.id}
                                            style={{
                                              lineHeight: '32px'
                                            }}
                                            disabled={isAddingRole}
                                          >
                                            <Space
                                              direction="vertical"
                                              size={0}
                                            >
                                              <Typography.Text>
                                                {d.name}
                                              </Typography.Text>
                                              <Typography.Text type="secondary">
                                                {d.value}
                                              </Typography.Text>
                                            </Space>
                                          </Checkbox>
                                        ))}
                                    </div>
                                  </Col>
                                </Row>
                              </Panel>
                            ))}
                          </Collapse>
                        </Checkbox.Group>
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col>
                    <Affix>
                      <div>
                        <CommonContainer>
                          <Row justify="center" gutter={8} align="middle">
                            <Role style={{ fontSize: 18 }} />
                            <Typography.Text strong>
                              {role.title}
                            </Typography.Text>
                          </Row>
                          <Form
                            form={infoForm}
                            layout="vertical"
                            initialValues={{
                              name: role.name,
                              title: role.title
                            }}
                          >
                            <Form.Item
                              label="Name"
                              name="name"
                              rules={[{ required: true, message: 'Nhập tên!' }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Title"
                              name="title"
                              rules={[
                                { required: true, message: 'Nhập chức vụ!' }
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Row gutter={8}>
                              {isAddingRole && (
                                <Col>
                                  <Button onClick={onCancelNewRole}>Huỷ</Button>
                                </Col>
                              )}

                              <Col>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    isAddingRole
                                      ? onCreateSysRole()
                                      : onUpdateRole(role.id)
                                  }
                                >
                                  {isAddingRole ? 'Tạo' : 'Lưu'}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </CommonContainer>
                        <DeleteConfirmPopup
                          title={
                            <Typography.Text>
                              Xoá vai trò{' '}
                              <Typography.Text strong>
                                {role.title}
                              </Typography.Text>
                              ?
                            </Typography.Text>
                          }
                          onDelete={() => onDeleteRole(role.id)}
                          disabled={isAddingRole}
                        >
                          <Button
                            type="default"
                            disabled={isAddingRole}
                            block
                            danger
                            style={{ marginTop: 16 }}
                          >
                            Xoá vai trò
                          </Button>
                        </DeleteConfirmPopup>
                      </div>
                    </Affix>
                  </Col>
                </Row>
              </TabPane>
            ))}
            )
          </Tabs>
        </CommonContainer>
      ) : null}
    </Spin>
  );
};

export default SysRole;
