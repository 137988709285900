import { message, notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';
import create from 'zustand';

export const usePublisherStatistic = create((set, get) => ({
  isStatisticLoading: false,
  publisherStatistics: [],
  publisher: null,
  totalPage: 1,
  total: 0,
  params: {},
  setParams: params => set({ params }),
  editingKey: '',
  setEditingKey: editingKey => set({ editingKey }),
  tableData: [],
  setTableData: tableData => set({ tableData }),
  setFilter: (key, value) =>
    set({ filter: { ...get().filter, [key]: value }, page: 1 }),

  //* ***************************** */
  //* NOTE: get publisher by id */
  //* ***************************** */
  getPublisher: async id => {
    try {
      const { data } = await API.get(`/publisher/${id}`);

      if (data) {
        set({ publisher: data });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ***************************** */
  //* NOTE: get publisher statistic */
  //* ***************************** */
  getPublisherStatistics: async params => {
    set({ isStatisticLoading: true });
    try {
      const {
        data: { data, total }
      } = await API.get(`/publisher/${params.id}/statistics`, {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });
      set({ isStatisticLoading: false });

      if (data) {
        set({ publisherStatistics: data, total });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isStatisticLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************ */
  //* NOTE: create publishers statistic  */
  //* ************************ */

  createPublisherStatistic: async params => {
    set({ isStatisticLoading: true });
    try {
      const { data } = await API.post(
        `/publisher/${params.id}/statistics`,
        params
      );

      set({ isStatisticLoading: false });

      if (data) {
        set(({ publisherStatistics }) => ({
          publisherStatistics: [data, ...publisherStatistics],
          isAddingPublisher: false
        }));

        notification.success({
          message: 'Tạo thống kê thành công!'
        });
        return data;
      }
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isStatisticLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************************** */
  //* NOTE: update publisher statistic */
  //* ******************************** */

  updatePublisherStatistic: async params => {
    set({ isStatisticLoading: true });

    try {
      const { data } = await API.put(
        `/publisher/${params.id}/statistics/${params.statisticreportid}`,
        params
      );

      set({ isStatisticLoading: false });

      if (data) {
        const newData = get().publisherStatistics.map(_statistic =>
          _statistic.id === data.id
            ? { ...data, createDate: _statistic.createDate }
            : _statistic
        );
        set({ publisherStatistics: newData });

        notification.success({
          message: 'Cập nhật thống kê thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isStatisticLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************/
  //* NOTE: craw statistic  */
  //* ********************* */
  getChannelInfo: async params => {
    set({ isStatisticLoading: true });

    try {
      const { data } = await API.get(`/publisher/channel-info`, { params });
      set({ isStatisticLoading: false });

      if (data) {
        message.success('Đã đồng bộ dữ liệu');
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isStatisticLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************ */
  //* NOTE: create publishers statistic  */
  //* ************************ */

  deletePublisherStatistic: async params => {
    set({ isStatisticLoading: true });
    try {
      const { data } = await API.delete(`/publisher/${params.id}/statistics`, {
        data: params
      });

      set({ isStatisticLoading: false });

      if (data) {
        get().getPublisherStatistics({ id: data.publisherId });

        notification.success({
          message: 'Xoá thống kê thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isStatisticLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
