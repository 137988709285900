import { Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import 'moment/locale/vi';

const TimeLine = ({ createDate, updateDate, publicTime }) => {
  return (
    <Space direction="vertical">
      <Typography.Text>
        <Typography.Text strong> Khởi tạo: </Typography.Text>
        {createDate && moment(createDate).format('DD/MM/YYYY hh:mm')}
      </Typography.Text>
      <Typography.Text>
        <Typography.Text strong>Cập nhật: </Typography.Text>
        {updateDate && moment(updateDate).format('DD/MM/YYYY hh:mm')}
      </Typography.Text>
      {publicTime && (
        <Typography.Text>
          <Typography.Text strong>Phát hành: </Typography.Text>
          {publicTime && moment(publicTime).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>
      )}

      {/* <Typography.Text>{moment(createdDate).fromNow()}</Typography.Text> */}
    </Space>
  );
};

export default TimeLine;
