import { Space, Typography } from 'antd';
import ContractStatusTag from 'containers/ContractStatusTag';
import ContractStatus from 'pages/Contract/components/ContractStatus';
import { Link } from 'react-router-dom';

const { Text } = Typography;
export const columns = [
  {
    title: 'Mã hợp đồng',
    dataIndex: 'contractCode',
    render: (contractCode, { status, id }) => (
      <Space direction="vertical">
        <Link to={`/chi-tiet-hop-dong/${id}/soan-hop-dong`}>
          <Typography.Text strong>#{contractCode}</Typography.Text>
        </Link>
        <ContractStatusTag value={status} />
      </Space>
    )
  },
  {
    title: 'Đối tác',
    dataIndex: 'partner',
    width: '150px',
    render: partner =>
      partner ? (
        <Text>{partner?.artistName || partner?.fullName}</Text>
      ) : (
        <Text type="danger">Chưa có</Text>
      )
  },
  {
    title: 'Thời gian hiệu lực',
    dataIndex: 'effectiveTime',
    width: '150px',
    render: (_, contract) => <ContractStatus contract={contract} />
  }
  // {
  //   title: 'Liên quan',
  //   dataIndex: 'parentContract',
  //   width: '150px',
  //   render: (parentContract, { appendix }) => (
  //     <Space direction="vertical" size="middle">
  //       {parentContract && (
  //         <Space direction="vertical" size={0}>
  //           <span>Hợp đồng cha:</span>
  //           <Link to={`/chi-tiet-hop-dong/${parentContract.id}/soan-hop-dong`}>
  //             <Text strong>
  //               #{parentContract.contractCode} <EditOutlined />
  //             </Text>
  //           </Link>
  //         </Space>
  //       )}
  //       {!!appendix.length && (
  //         <Text type="success">
  //           <Text type="success" strong>
  //             {appendix.length}
  //           </Text>{' '}
  //           phụ lục
  //         </Text>
  //       )}
  //     </Space>
  //   )
  // }
];
