import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import { useProvider } from 'stores/useProvider';

const ProviderDeleteButton = ({ item }) => {
  const { deleteProvider } = useProvider(
    useCallback(
      ({ deleteProvider }) => ({
        deleteProvider
      }),
      []
    ),
    shallow
  );

  return (
    <DeleteConfirmPopup
      title={`Xoá '${item.name}'?`}
      onDelete={() => deleteProvider(item.id)}
    >
      <Button type="danger" icon={<DeleteOutlined />} />
    </DeleteConfirmPopup>
  );
};

export default ProviderDeleteButton;
