import { Col, Form, Row } from 'antd';
import Input from 'components/Input';
import React from 'react';
import TagMail from './TagMail';

const GroupMailFormModalContent = ({ form, defaultMails }) => {
  return (
    <Form form={form} layout="vertical">
      <Row gutter={32}>
        <Col span={24}>
          <Form.Item
            name="title"
            label="Tên danh sách"
            rules={[{ required: true, message: 'Nhập tên danh sách!' }]}
          >
            <Input />
          </Form.Item>{' '}
        </Col>{' '}
        <Col span={24}>
          <Form.Item
            name="mails"
            label="Danh sách"
            rules={[{ required: true, message: 'Nhập danh sách mail!' }]}
          >
            <TagMail defaultMails={defaultMails} form={form} />{' '}
          </Form.Item>{' '}
        </Col>{' '}
      </Row>{' '}
    </Form>
  );
};

export default GroupMailFormModalContent;
