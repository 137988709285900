import React from 'react';
import PropTypes from 'prop-types';
import { DATE_FORMAT } from 'appConstants';

import { SwapRightOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const NaviRangePicker = ({ placeHolder, onChange, ...rest }) => {
  const separator = (
    <div className="separator-picker">
      <span>đến</span>
      <SwapRightOutlined />
    </div>
  );

  return (
    <RangePicker
      placeholder={placeHolder}
      onChange={onChange}
      format={DATE_FORMAT}
      separator={separator}
      className="navi-range-picker"
      {...rest}
    />
  );
};

NaviRangePicker.propTypes = {
  placeHolder: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

NaviRangePicker.defaultProps = {
  placeHolder: ['ngày', 'ngày']
};

export default NaviRangePicker;
