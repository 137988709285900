import React, {
  useCallback
  // useEffect,
  // useMemo
  // , useState
} from 'react';
import {
  Col,
  Empty,
  Row,
  // , Tree
  Typography
} from 'antd';
// import { CarryOutOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';

import MetaCard from 'components/MetaCard';

// import { groupSubscribe } from 'pages/DepartmentDetail/utils';
import { useCurrentUser } from 'stores/useCurrentUser';
// import { useDepartmentDetail } from 'stores/useDepartmentDetail';
// import Checkbox from 'antd/lib/checkbox/Checkbox';

const DepartmentInfo = () => {
  // const [department, setDepartment] = useState(null);

  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  // const { fetchDepartment } = useDepartmentDetail(
  //   useCallback(
  //     ({ fetchDepartment }) => ({
  //       fetchDepartment
  //     }),
  //     []
  //   ),
  //   shallow
  // );

  // const getDepartmentInfo = useCallback(
  //   async departmentId => {
  //     const res = await fetchDepartment(departmentId);
  //     setDepartment(res);
  //   },
  //   [fetchDepartment]
  // );

  // useEffect(() => {
  //   if (currentUser && currentUser?.departmentId) {
  //     getDepartmentInfo(currentUser?.departmentId);
  //   }
  // }, [currentUser, getDepartmentInfo]);

  // const treeData = useMemo(() => {
  //   const subscribe = groupSubscribe(department?.subscribe || []);

  //   return subscribe.map(({ key, value }, index) => ({
  //     title: key.title,
  //     key: key.code + index,
  //     icon: <CarryOutOutlined />,
  //     children:
  //       key.code === 'changeStatusAppendix'
  //         ? value.map(({ label, receiveStatus }, index) => ({
  //             title: label,
  //             key: receiveStatus + index
  //           }))
  //         : []
  //   }));
  // }, [department?.subscribe]);

  return currentUser?.department ? (
    <>
      <Typography.Text strong>
        Tên phòng ban: {currentUser?.department?.name}
      </Typography.Text>
      {/* <MetaCard title="Thông báo đang theo dõi" style={{ marginTop: '24px' }}>
        <Tree
          showLine={{ showLeafIcon: false }}
          defaultExpandAll
          treeData={treeData}
        />
      </MetaCard>
      <MetaCard title="Quyền truy cập màn hình" style={{ marginTop: '24px' }}>
        <Row>
          {(department?.access || []).map(({ layoutDetail }, index) => (
            <Col key={layoutDetail?.code + index} span={6}>
              <Row>
                <Col span={12}>
                  <Typography.Paragraph>
                    {layoutDetail?.title}:
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>{<Checkbox disabled checked={true} />}</Col>
              </Row>
            </Col>
          ))}
        </Row>
      </MetaCard> */}

      <MetaCard title="Chi tiết phòng ban" style={{ marginTop: '24px' }}>
        <Row align="middle" justify="center">
          <Col>
            <Empty
              description="Đang hoàn thiện"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </Col>
        </Row>
      </MetaCard>
    </>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};

export default DepartmentInfo;
