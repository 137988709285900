import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  MONTH_FORMAT,
  TIME_FORMAT
} from 'appConstants.js';

export const formatDate = date => moment(date).format(DATE_FORMAT);
export const formatTime = date => moment(date, TIME_FORMAT).format(TIME_FORMAT);
export const formatMonth = date => moment(date).format(MONTH_FORMAT);
export const formatDateTime = date => moment(date).format(DATE_TIME_FORMAT);
