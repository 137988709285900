import React, { useCallback } from 'react';

import shallow from 'zustand/shallow';

import { useUser } from 'stores/useUser';

import AutoComplete from 'components/AutoComplete';

const DepartmentAutoComplete = props => {
  const { searchForDepartment, user } = useUser(
    useCallback(
      ({ searchForDepartment, user }) => ({
        searchForDepartment,
        user
      }),
      []
    ),
    shallow
  );

  const defaultOption = user?.department
    ? {
        label: user?.department?.name,
        value: user?.department?.id
      }
    : null;

  return (
    <AutoComplete
      placeHolder="Tìm phòng ban"
      handleSearch={searchForDepartment}
      labelKey="name"
      valueKey="id"
      defaultOption={defaultOption}
      {...props}
    />
  );
};

export default DepartmentAutoComplete;
