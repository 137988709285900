import Button from 'components/Button';
import React, { useCallback } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { DeleteOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';

const SongAddendumDeleteMultiButton = ({ ids, callback }) => {
  const { deleteMultiSongAddendum } = useAddendumDetail(
    useCallback(
      ({ deleteMultiSongAddendum }) => ({
        deleteMultiSongAddendum
      }),
      []
    ),
    shallow
  );

  return !!ids.length ? (
    <Button
      type="danger"
      icon={<DeleteOutlined />}
      onClick={() => {
        deleteMultiSongAddendum(ids);
        callback();
      }}
    >
      {ids?.length} bài hát
    </Button>
  ) : null;
};

export default SongAddendumDeleteMultiButton;
