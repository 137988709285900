import { Tag } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const NaviTag = React.forwardRef(
  ({ children, closable, onClose, ...rest }, ref) => {
    return (
      <Tag
        className="navi-tag"
        closable={closable}
        onClose={onClose}
        ref={ref}
        {...rest}
      >
        {children}
      </Tag>
    );
  }
);

NaviTag.propTypes = {
  onClose: PropTypes.func,
  closable: PropTypes.bool
};

NaviTag.defaultProps = {
  closable: false
};

export default NaviTag;
