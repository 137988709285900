import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Typography } from 'antd';
import Button from 'components/Button';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePartner } from 'stores/usePartner';
import shallow from 'zustand/shallow';
import PartnerAutoComplete from './PartnerAutoComplete';
import PartnerCard from './PartnerCard';
import { useContract } from 'stores/useContract';
import { get } from 'utils/lodash';
import { SaveOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const PartnerInfo = ({ contractId, isUpdateContract }) => {
  const [currPartner, setCurrPartner] = useState(null);
  const [form] = Form.useForm();
  const { getPartner } = usePartner(
    useCallback(
      ({ getPartner }) => ({
        getPartner
      }),
      []
    ),
    shallow
  );

  const { contract, updateContract } = useContract(
    useCallback(
      ({ contract, updateContract }) => ({
        contract,
        updateContract
      }),
      []
    ),
    shallow
  );

  const shouldDisblePartner = useMemo(
    () => !contract?.contractCode.includes('***'),
    [contract]
  );

  useEffect(() => {
    if (contract && isUpdateContract) {
      const { partnerId } = contract;
      form.setFieldsValue({
        partnerId
      });
    }
  }, [form, contract, isUpdateContract]);

  const partner = get(contract, 'partner', null);

  useEffect(() => {
    if (partner) setCurrPartner(partner);
  }, [partner]);

  const handleChange = values => {
    getPartner(values.partnerId).then(res => {
      setCurrPartner(res);
    });
  };

  const onFinish = values => {
    updateContract({
      ...values,
      contractId,
      taxPercent: contract?.taxPercent,
      file: contract?.file
    });
  };

  return (
    <div>
      <Form form={form} onValuesChange={handleChange} onFinish={onFinish}>
        <Row justify="space-between">
          <Typography.Text strong>Đối tác</Typography.Text>
          <Link to="/chi-tiet-doi-tac" target="_blank">
            <Button type="link" icon={<PlusCircleOutlined />}>
              Tạo đối tác mới
            </Button>
          </Link>
        </Row>

        <Form.Item name="partnerId">
          <PartnerAutoComplete
            partner={currPartner}
            disabled={shouldDisblePartner}
          />
        </Form.Item>

        <Row gutter={16} className="mb-md">
          <Col span={24}>
            <PartnerCard partner={currPartner} />
          </Col>
        </Row>
        <Row justify="end">
          <Button
            disabled={shouldDisblePartner}
            icon={<SaveOutlined />}
            htmlType="submit"
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default PartnerInfo;
