import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';
import { generateFileDownload } from 'utils/redirect';

export const useSong = create((set, get) => ({
  isSongsLoading: false,
  params: {},
  setParams: params => set({ params }),
  //* **************** */
  //* NOTE: get songs  */
  //* **************** */
  songs: [],
  totalPage: 1,
  pageSize: PAGE_SIZE,
  total: 0,
  songOfMedley: [],
  setSongOfMedley: songs => set({ songOfMedley: songs }),
  singerFromMedley: [],
  musicianFromMedley: [],
  getSongs: async params => {
    set({ isSongsLoading: true });
    try {
      const {
        data: { data, total }
      } = await API.get(`/song`, {
        params: generateQueryParams({
          // PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });
      set({ isSongsLoading: false });
      if (data) {
        set({
          songs: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isSongsLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: delete songs  */
  //* ******************* */

  deleteSongs: async params => {
    try {
      const { data } = await API.delete('/song', { data: params });

      if (data) {
        get().getSongs();

        return notification.success({
          message: 'Xoá bài hát thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: create songs  */
  //* ******************* */

  createSong: async params => {
    try {
      const { data } = await API.post('/song', params);

      if (data) {
        await get().getSongs();

        notification.success({
          message: 'Tạo bài hát thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************* */
  //* NOTE: get and update song */
  //* ************************* */

  song: null,
  resetSong: () => set({ song: null }),
  isSongLoading: false,
  getSong: async songId => {
    set({ isSongLoading: true });
    try {
      const { data } = await API.get(`/song/${songId}`);
      set({ isSongLoading: false });
      if (data) {
        set({ song: data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isSongLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateSong: async params => {
    try {
      const { data } = await API.put(`/song/${params.id}`, params);

      if (data) {
        get().getSongs();

        notification.success({
          message: 'Cập nhật bài hát thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForPartner: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/partner/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForSongType: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata/search', {
        params: { limit: 5, group: 'musicType', freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                 Get parent song type                                 //
  //--------------------------------------------------------------------------------------//

  getSongTypeParent: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata', {
        params: {
          Page: 0,
          PageSize: 10000,
          'Group[eq]': 'musicType',
          'Code[nctn]': '/'
        }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                         Get song type detail by parent code                          //
  //--------------------------------------------------------------------------------------//

  getSongTypeDetail: async code => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata', {
        params: { Page: 0, PageSize: 10000, 'Code[ctn]': `${code}/` }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getArtistsFromMedley: async params => {
    try {
      let query = '';
      params.forEach(songId => {
        query += `songIds=${songId}&`;
      });

      const { data } = await API.get(`/song/artists-of-medley?${query}`);

      if (data) {
        set({
          singerFromMedley: data.singers,
          musicianFromMedley: data.musicians
        });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getPartnerFromIds: async params => {
    try {
      const {
        data: { data }
      } = await API.get(`/partner/search`, {
        params: generateQueryParams(params)
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                            Export song list from songIds                             //
  //--------------------------------------------------------------------------------------//
  isExportLoading: false,
  exportSongByIds: async ids => {
    set({ isExportLoading: true });
    try {
      const { data } = await API.post('/excel/export-songs/bulk', {
        ids
      });
      set({ isExportLoading: false });
      if (data) {
        generateFileDownload(data?.link);
        return data;
      }
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
