import { Avatar, List } from 'antd';
import {
  AppleOutlined,
  WindowsOutlined,
  QuestionOutlined
} from '@ant-design/icons';
import React, { useCallback, useEffect } from 'react';
import useAuth from 'stores/useAuth';
import shallow from 'zustand/shallow';
import moment from 'moment';
import 'moment/locale/vi';

const genIcon = type => {
  switch (type) {
    case 'Windows':
      return <WindowsOutlined />;

    case 'Mac OS X':
      return <AppleOutlined />;

    default:
      return <QuestionOutlined />;
  }
};

const LoginHistory = () => {
  const { getLoginHistory, loggingHistory } = useAuth(
    useCallback(
      ({ getLoginHistory, loggingHistory }) => ({
        getLoginHistory,
        loggingHistory
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getLoginHistory();
  }, [getLoginHistory]);

  const renderItem = ({
    osName,
    osVersion,
    ipAddress,
    browserName,
    browserMajor,
    createDate
  }) => (
    <List.Item>
      <List.Item.Meta
        style={{ alignItems: 'center' }}
        avatar={<Avatar icon={genIcon(osName)} size={40} />}
        title={osName + ' ' + osVersion + ' · ' + ipAddress}
        description={
          browserName +
          ' ' +
          browserMajor +
          ' · ' +
          moment(createDate).format('DD [tháng] MM [lúc] HH:mm')
        }
      />
    </List.Item>
  );

  return (
    <List
      itemLayout="horizontal"
      dataSource={loggingHistory}
      renderItem={renderItem}
    />
  );
};

export default LoginHistory;
