import React, { useCallback } from 'react';
import { Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { APPENDIX_PROCESS_VALUE } from 'appConstants';
import { getAppendixProcessValue } from 'utils/parser';

const AddendumStatusTag = ({ value, editable }) => {
  const generateColor = useCallback(() => {
    switch (value) {
      case APPENDIX_PROCESS_VALUE.COMPOSE:
        return 'gold';

      case APPENDIX_PROCESS_VALUE.DEAL:
        return 'processing';

      case APPENDIX_PROCESS_VALUE.PAY:
        return 'error';

      case APPENDIX_PROCESS_VALUE.CHECK:
        return 'magenta';

      case APPENDIX_PROCESS_VALUE.COMPLETE:
        return 'success';

      default:
        return '';
    }
  }, [value]);

  return (
    <Tag color={generateColor()}>
      {editable && (
        <>
          <EditOutlined />{' '}
        </>
      )}
      {getAppendixProcessValue(value)}
    </Tag>
  );
};

export default AddendumStatusTag;
