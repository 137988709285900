import AddendumDetail from 'pages/AddendumDetail';
import AppendixReview from 'pages/AppendixReview';
import Contract from 'pages/Contract';
import Completed from 'pages/ContractDetail/Completed';
import ContractNegotiation from 'pages/ContractDetail/ContractNegotiation';
import DraftingContract from 'pages/ContractDetail/DraftingContract';
import InstallmentPayment from 'pages/ContractDetail/InstallmentPayment';
import ProductInspection from 'pages/ContractDetail/ProductInspection';
import ContractHistory from 'pages/ContractHistory';
import CreateAddendum from 'pages/CreateAddendum';
import CurrentUserDetail from 'pages/CurrentUserDetail';
import Dashboard from 'pages/Dashboard';
import Department from 'pages/Department';
import DepartmentDetail from 'pages/DepartmentDetail';
import Login from 'pages/Login';
import MasterData from 'pages/MasterData';
import NotFound from 'pages/NotFound';
import Partner from 'pages/Partner';
import PartnerDetail from 'pages/PartnerDetail';
import Playlist from 'pages/Playlist/Playlist';
import PlaylistDetail from 'pages/PlaylistDetail/PlaylistDetail';
import PublishChannel from 'pages/PublishChannel';
import PublisherStatistic from 'pages/PublisherStatistic';
import Reup from 'pages/Reup/Reup';
import ReupVideoDetail from 'pages/ReupVideoDetail';
// import Role from 'pages/Role';
import Song from 'pages/Song';
import SongPublication from 'pages/SongPublication';
import SongPublicationDetail from 'pages/SongPublicationDetail';
import SongRelease from 'pages/SongRelease';
import SongReleaseDetail from 'pages/SongReleaseDetail';
import SysRole from 'pages/SysRole';
import Unauthorized from 'pages/Unauthorized';
import User from 'pages/User';
import { Redirect } from 'react-router-dom';

export const publicRoutes = [
  {
    path: '/dang-nhap',
    component: Login
  }
  // {
  //   path: '/quen-mat-khau',
  //   component: PasswordRecovery
  // }
];

export const privateRoutes = [
  {
    path: '/',
    component: Dashboard,
    exact: true
  },
  {
    path: '/danh-sach-hop-dong',
    component: Contract
  },
  // {
  //   path: '/chi-tiet-hop-dong/:id',
  //   component: ContractDetail
  // },
  {
    path: '/chi-tiet-hop-dong/:id?/soan-hop-dong',
    component: DraftingContract
  },
  {
    path: '/chi-tiet-hop-dong/:id/thuong-luong',
    component: ContractNegotiation
  },
  {
    path: '/chi-tiet-hop-dong/:id/thanh-toan-theo-dot/:appendixId',
    component: InstallmentPayment
  },
  {
    path: '/chi-tiet-hop-dong/:id/kiem-tra-san-pham/:appendixId',
    component: ProductInspection
  },
  {
    path: '/chi-tiet-hop-dong/:id/hoan-tat/:appendixId',
    component: Completed
  },
  {
    path: '/lich-su-hop-dong/:id',
    component: ContractHistory
  },
  // {
  //   path: '/live-style',
  //   component: LiveStyle
  // },
  {
    path: '/chi-tiet-phu-luc/:id',
    component: AddendumDetail
  },
  {
    path: '/tao-moi-phu-luc/:id',
    component: CreateAddendum
  },
  {
    path: '/danh-sach-doi-tac',
    component: Partner
  },
  {
    path: '/chi-tiet-doi-tac/:id?',
    component: PartnerDetail
  },
  // {
  //   path: '/chi-tiet-doi-tac',
  //   component: PartnerDetail
  // },
  {
    path: '/danh-sach-bai-hat',
    component: Song
  },
  {
    path: '/danh-sach-nhan-vien',
    component: User
  },
  {
    path: '/danh-sach-quyen-han',
    component: SysRole
  },
  {
    path: '/danh-sach-master-data',
    component: MasterData
  },
  {
    path: '/ca-nhan',
    exact: true,
    component: () => <Redirect to="/ca-nhan/thong-tin-ca-nhan" />
  },
  {
    path: '/ca-nhan/:id',
    component: CurrentUserDetail
  },
  {
    path: '/chi-tiet-phong-ban/:id',
    component: DepartmentDetail
  },
  {
    path: '/danh-sach-phong-ban',
    component: Department
  },
  {
    path: '/chi-tiet-tuyen-tap/:id?',
    component: PlaylistDetail
  },
  {
    path: '/danh-sach-tuyen-tap',
    component: Playlist
  },
  {
    path: '/quan-ly-kenh',
    exact: true,
    component: () => <Redirect to="/quan-ly-kenh/kenh-phat-hanh" />
  },
  {
    path: '/quan-ly-kenh/:id',
    component: PublishChannel
  },
  {
    path: '/thong-ke-kenh-phat-hanh/:id',
    component: PublisherStatistic
  },
  // {
  //   path: '/link-phat-hanh/:id',
  //   component: SongPublication
  // },
  {
    path: '/link-phat-hanh',
    component: SongPublication
  },
  {
    path: '/chi-tiet-link-phat-hanh/:id?',
    component: SongPublicationDetail
  },
  {
    path: '/review-phu-luc/:id',
    component: AppendixReview
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/unauthorized',
    component: Unauthorized
  },
  {
    path: '/danh-sach-phat-hanh',
    component: SongRelease
  },
  {
    path: '/chi-tiet-phat-hanh/:id?',
    component: SongReleaseDetail
  },
  {
    path: '/reup/video-detail/:id?',
    component: ReupVideoDetail
  },
  {
    path: '/reup/:type',
    component: Reup
  }
];
