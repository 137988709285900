import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row, Spin } from 'antd';

import CurrencyInputNumber from 'components/CurrencyInputNumber';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';
import { calculatePercent } from 'utils/other';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import Select from 'components/Select';

const PaymentFormModalContent = ({ form, initValue }) => {
  const [loading, setLoading] = useState(false);
  const {
    getTotalMoney,
    payment,
    tempPayment,
    contractUseInAddendumDetail,
    calculateTotalMoney,
    addendum
  } = useAddendumDetail(
    useCallback(
      ({
        getTotalMoney,
        payment,
        tempPayment,
        contractUseInAddendumDetail,
        calculateTotalMoney,
        addendum
      }) => ({
        getTotalMoney,
        payment,
        tempPayment,
        contractUseInAddendumDetail,
        calculateTotalMoney,
        addendum
      }),
      []
    ),
    shallow
  );

  const generateOrdinal = useCallback(() => {
    const arr = [...payment, ...tempPayment].map(({ ordinal }) => ordinal);

    let res = 1;

    while (arr.includes(res)) {
      res++;
    }

    return res;
  }, [payment, tempPayment]);

  const onTakeHomeMoneyChange = async () => {
    const { takeHomeMoney: value } = form.getFieldsValue(['takeHomeMoney']);

    if (!value) return;

    const ordinal = initValue?.ordinal || generateOrdinal();

    setLoading(true);

    const res = await calculateTotalMoney({
      appendixId: addendum.id,
      ordinal,
      takeHomeMoney: value,
      calculateType: 0
    });

    setLoading(false);

    const totalMoney = Math.round(res.totalMoney);
    const taxMoney = Math.round(res.taxMoney);

    const percent = calculatePercent(
      totalMoney,
      getTotalMoney() - (initValue?.totalMoney || 0) + totalMoney
    );

    form.setFieldsValue({
      taxMoney,
      totalMoney,
      percent
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initValue,
      ordinal: initValue?.ordinal || generateOrdinal(),
      paymentDate: initValue?.paymentDate && moment(initValue.paymentDate),
      taxPercent: contractUseInAddendumDetail?.taxPercent || 5
    });
  }, [
    form,
    initValue,
    payment,
    tempPayment,
    generateOrdinal,
    contractUseInAddendumDetail
  ]);

  useEffect(
    () => () => {
      form.resetFields();
    },
    [form]
  );

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="ordinal" label="Thứ tự đợt">
              <InputNumber style={{ width: '100%' }} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="takeHomeMoney"
              label="Thực nhận (VNĐ)"
              rules={[{ required: true, message: 'Nhập tiền thực nhận!' }]}
            >
              <CurrencyInputNumber onBlur={onTakeHomeMoneyChange} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="taxPercent" label="Thuế xuất (%)">
              <Select
                disabled
                style={{ width: '100%' }}
                options={[
                  { label: '5%', value: 5 },
                  { label: '8%', value: 8 },
                  { label: '10%', value: 10 }
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="taxMoney" label="Tiền thuế (VNĐ)">
              <CurrencyInputNumber disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="totalMoney" label="Tổng tiền (VNĐ)">
              <CurrencyInputNumber disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="percent" label="Tỉ lệ (%)">
              <InputNumber
                disabled
                style={{ width: '100%' }}
                min={0}
                max={100}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="paymentDate" label="Ngày thanh toán">
          <DatePicker style={{ width: '100%' }} allowClear={false} />
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default PaymentFormModalContent;
