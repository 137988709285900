import React, { useCallback, useEffect, useState } from 'react';

import shallow from 'zustand/shallow';

import { usePartnerDetail } from 'stores/usePartnerDetail';

import Select from 'components/Select';
import { debounce } from 'utils/lodash';
import { DEBOUNCE_VALUE } from 'appConstants';

const CompanyAutoComplete = ({
  resetCompanyIdFormData,
  defaultOption,
  ...props
}) => {
  const [options, setOptions] = useState(
    defaultOption
      ? [{ label: defaultOption.companyName, value: defaultOption.companyId }]
      : []
  );

  const { searchForCompany, setUpdateCompanyrAutoCompleteOptions } =
    usePartnerDetail(
      useCallback(
        ({
          searchForCompany,
          resetSelectingCompany,
          setUpdateCompanyrAutoCompleteOptions
        }) => ({
          searchForCompany,
          resetSelectingCompany,
          setUpdateCompanyrAutoCompleteOptions
        }),
        []
      ),
      shallow
    );
  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }
    const results = await searchForCompany(searchText);

    if (!results) {
      return;
    }

    return setOptions(
      results.map(({ id, name }) => ({ label: name, value: id }))
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  useEffect(() => {
    setUpdateCompanyrAutoCompleteOptions(setOptions);
  }, [setUpdateCompanyrAutoCompleteOptions]);

  return (
    <Select
      filterOption={false}
      notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      options={options}
      placeHolder="Tìm công ty"
      {...props}
    />
  );
};

export default CompanyAutoComplete;
