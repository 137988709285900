import React from 'react';

import { Form, Row, Col, InputNumber } from 'antd';

import Input from 'components/Input';

const CompanyFormModalContent = ({ form }) => {
  return (
    <Form form={form} layout="vertical">
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Tên công ty"
            rules={[{ required: true, message: 'Nhập tên công ty!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="phone"
            label="Số điện thoại"
            rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address"
            label="Địa chỉ"
            rules={[{ required: true, message: 'Nhập địa chỉ!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Nhập email!' },
              { type: 'email', message: 'Nhập email!' }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="representationName"
            label="Họ tên người đại diện"
            rules={[{ required: true, message: 'Nhập tên người đại diện!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="representationIdentityNumber"
            label="CMND người đại diện"
            rules={[{ required: true, message: 'Nhập CMND!' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="representationIdentityPhone"
            label="Số điện thoại người đại diện"
            rules={[
              { required: true, message: 'Nhập số điện thoại đại diện!' }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="taxCode"
            label="Mã số thuế"
            rules={[{ required: true, message: 'Nhập mã số thuế!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CompanyFormModalContent;
