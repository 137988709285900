import { Collapse, notification, Typography } from 'antd';
import { APPENDIX_PROCESS_VALUE } from 'appConstants';
import Button from 'components/Button';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useContract } from 'stores/useContract';
import shallow from 'zustand/shallow';
import ContractDetailLayout from '../ContractDetailLayout';
import Appendix from './components/Appendix/Appendix';
import ChildContract from './components/ChildContract';
import ContractInfo from './components/ContractInfo';
import PartnerInfo from './components/PartnerInfo/PartnerInfo';
import RevenueSharing from './components/RevenueSharing';
import Users from './components/Users.js/Users';

const { Panel } = Collapse;

const DraftingContract = () => {
  const { id } = useParams();
  const history = useHistory();
  const { contract } = useContract(
    useCallback(
      ({ contract }) => ({
        contract
      }),
      []
    ),
    shallow
  );
  const isUpdateContract = useMemo(() => id, [id]);
  const isShowChildContract = useMemo(
    () =>
      contract?.contractType === 'DTHT' || contract?.doiSoatProjectId !== null,
    [contract]
  );

  const handleContinue = () => {
    const { partner, appendix, revenueSharing } = contract;

    const validateContract = () => {
      var errors = [];
      if (!partner) errors = [...errors, 'Đối tác'];
      if (!appendix.length) errors = [...errors, 'Phụ lục'];
      if (!revenueSharing.length) errors = [...errors, 'Phân chia doanh thu'];
      return errors;
    };
    const errors = validateContract();
    if (!errors.length) {
      history.push(`/chi-tiet-hop-dong/${id}/thuong-luong`);
      return;
    }
    notification.error({
      message: (
        <Typography.Paragraph>
          Bổ sung thêm thông tin hợp đồng:
          <ul>
            {errors.map(error => (
              <li>{error}</li>
            ))}
          </ul>
        </Typography.Paragraph>
      )
    });
  };

  const setting = {
    collapsible: isUpdateContract ? null : 'disabled'
  };

  return (
    <ContractDetailLayout step={APPENDIX_PROCESS_VALUE.COMPOSE}>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={
          isUpdateContract ? ['1', '2', '3', '4', '5', '6'] : ['1']
        }
        className="mb-md"
      >
        <Panel header="Thông tin hợp đồng" key="1">
          <ContractInfo contractId={id} isUpdateContract={isUpdateContract} />
        </Panel>
        {isShowChildContract && (
          <Panel header="Phụ lục liên kết" key="2">
            <ChildContract
              contractId={id}
              isUpdateContract={isUpdateContract}
            />
          </Panel>
        )}

        <Panel header="Đối tác" key="3" {...setting}>
          <PartnerInfo contractId={id} isUpdateContract={isUpdateContract} />
        </Panel>
        <Panel header="Người phụ trách" key="4" {...setting}>
          {/* <Users contractId={id} isUpdateContract={isUpdateContract} /> */}
          <Users />
        </Panel>

        <Panel header="Phân chia doanh thu" key="5" {...setting}>
          <RevenueSharing />
        </Panel>
        <Panel header="Phụ lục hợp đồng" key="6" {...setting}>
          <Appendix contractId={id} />
        </Panel>
      </Collapse>
      <Button block onClick={handleContinue} disabled={!isUpdateContract}>
        Tiếp theo
      </Button>
    </ContractDetailLayout>
  );
};

export default DraftingContract;
