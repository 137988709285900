import AutoComplete from 'components/AutoComplete/AutoComplete';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSongRelease } from 'stores/useSongRelease';
import { getAppendixCode } from 'utils/other';
import shallow from 'zustand/shallow';

const AppendixAutoComplete = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { getAppendixById, searchForAppendix } = useSongRelease(
    useCallback(
      ({ getAppendixById, searchForAppendix }) => ({
        getAppendixById,
        searchForAppendix
      }),
      []
    ),
    shallow
  );

  const form = useContext(FilterBoxFormContext);

  useEffect(() => {
    (async () => {
      const params = form.getFieldsValue();
      if (params.value) {
        const data = await getAppendixById(params.value);
        setOptions(
          [data].map(({ id, appendixCode01 }) => ({
            value: id,
            label: appendixCode01
          }))
        );
      }
    })();
  }, [form, getAppendixById]);

  return (
    <AutoComplete
      placeHolder={placeHolder}
      handleSearch={searchForAppendix}
      defaultOption={options}
      labelKey="appendixCode01"
      valueKey="id"
      getLabel={getAppendixCode}
      {...props}
    />
  );
};

export default AppendixAutoComplete;
