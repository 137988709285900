import { CheckOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
  Typography
} from 'antd';
import { DEBOUNCE_VALUE, ONLINE_STATUS } from 'appConstants';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import FormItem from 'components/FormItem';
import Select from 'components/Select';
import { CommonContainer } from 'components/StyledComponents';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useCurrentUser } from 'stores/useCurrentUser';
import { usePlaylistDetail } from 'stores/usePlaylistDetail';
import { useService } from 'stores/useService';
import { formatDate } from 'utils/date';
import { debounce } from 'utils/lodash';
import { reorder } from 'utils/other';
import shallow from 'zustand/shallow';
import CardInfo from './components/CardInfo';
import ImportSong from './components/ImportSong';
import PlaylistRevenueSharing from './components/PlaylistRevenueSharing/PlaylistRevenueSharing';
import PublisherSelect from './components/PublisherSelect';
import SongOfPlaylist from './components/SongOfPlaylist';
import PlaylistSongAutoComplete from './components/SongPlaylistAutoComplete';

const { Title } = Typography;

const PlaylistDetail = () => {
  const [songs, setSongs] = useState([]);
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [currentProvider, setCurrentProvider] = useState(null);

  const {
    getPlaylist,
    playlist,
    createPlaylist,
    updatePlaylist,
    resetPlaylist,
    importedSongs,
    importSongs,
    isLoading,
    providers
  } = usePlaylistDetail(
    useCallback(
      ({
        getPlaylist,
        playlist,
        createPlaylist,
        updatePlaylist,
        resetPlaylist,
        importedSongs,
        importSongs,
        isLoading,
        providers
      }) => ({
        getPlaylist,
        playlist,
        createPlaylist,
        updatePlaylist,
        resetPlaylist,
        importedSongs,
        importSongs,
        isLoading,
        providers
      }),
      []
    ),
    shallow
  );

  const { getLinkInfoByUrl, isLinkInfoLoading } = useService(
    useCallback(
      ({ getLinkInfoByUrl, isLinkInfoLoading }) => ({
        getLinkInfoByUrl,
        isLinkInfoLoading
      }),
      []
    ),
    shallow
  );

  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  const isUpdatePlaylist = useMemo(() => !!id, [id]);

  useEffect(() => {
    if (isUpdatePlaylist) getPlaylist(id);
  }, [getPlaylist, id, isUpdatePlaylist]);

  useEffect(() => {
    if (!!importedSongs.length) {
      const tempImportedIds = importedSongs.map(
        importedSong => importedSong.songId
      );
      const acceptSongs = songs.filter(
        song => !tempImportedIds.includes(song.songId)
      );
      const mergeSongs = [...acceptSongs, ...importedSongs];
      setSongs(mergeSongs);
      importSongs([]);
    }
  }, [importedSongs, songs, importSongs]);

  // Init data or reset store
  useEffect(() => {
    if (isUpdatePlaylist && playlist) {
      const {
        name,
        link,
        code,
        publisherId,
        playlistDetail,
        publisher,
        onlineStatus
      } = playlist;
      form.setFieldsValue({
        name,
        link,
        code,
        publisherId: publisher ? publisherId : null,
        providerId: publisher?.providerId,
        onlineStatus,
        publishDate: playlist?.publishDate
          ? moment(playlist?.publishDate)
          : null
      });
      setSongs(playlistDetail);
      return;
    }
    if (
      // Check create and playlist null
      !isUpdatePlaylist &&
      !(Object.keys(playlist).length === 0 && playlist.constructor === Object)
    ) {
      resetPlaylist();
    }
  }, [form, playlist, isUpdatePlaylist, resetPlaylist]);

  useEffect(() => {
    if (!isUpdatePlaylist) {
      form.resetFields();
      setSongs([]);
    }
  }, [form, isUpdatePlaylist]);

  // Hardcode --- default kênh Youtebe và disable chọn nhà cung cấp
  const youtubeProviderId = useMemo(
    () => providers.find(_provider => _provider.code === 'YOUTUBE')?.id,
    [providers]
  );

  useEffect(() => {
    if (!isUpdatePlaylist) {
      form.setFieldsValue({ providerId: youtubeProviderId });
      setCurrentProvider(youtubeProviderId);
    }
  }, [form, isUpdatePlaylist, youtubeProviderId]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newSongs = reorder(
      songs,
      result.source.index,
      result.destination.index
    );

    setSongs(newSongs);
  };

  const onSelect = ({ value, label, songTypeName, songName, singer }) => {
    setSongs(songs => [
      ...songs,
      { songId: value, song: { songName, songTypeName, singer } }
    ]);
    message.success('Đã thêm bài hát vào danh sách');
  };

  const onDelete = id => {
    setSongs(songs => songs.filter(song => song.songId !== id));
  };

  const onFinish = async values => {
    const {
      name,
      link,
      publisherId,
      code,
      onlineStatus,
      publishDate,
      approvedDate
    } = values;
    const data = {
      ...playlist,
      name,
      link,
      publisherId,
      code,
      onlineStatus,
      publishDate: publishDate ? formatDate(publishDate) : '',
      status: !songs.length ? 'waiting' : playlist.status,
      playlistDetail: songs.map((song, index) => ({
        ...song,
        ordinal: index + 1
      })),
      approvedDate: approvedDate && formatDate(approvedDate)
    };
    if (isUpdatePlaylist) {
      await updatePlaylist(data);
    } else {
      // Quằn - Vũ Minh Nhựt
      !data?.publishDate && delete data?.publishDate;

      await createPlaylist(data, id =>
        history.push(`/chi-tiet-tuyen-tap/${id}`)
      );
    }
  };

  const onChangeTime = (time, index) => {
    setSongs(songs =>
      songs.map((song, i) =>
        i === index ? { ...song, startTime: time[0], endTime: time[1] } : song
      )
    );
  };

  const onChangeSongStatus = useCallback((status, index) => {
    setSongs(songs =>
      songs.map((song, i) => (i === index ? { ...song, status: status } : song))
    );
  }, []);

  const handleApprove = () => {
    const data = {
      ...playlist,
      status: 'approved',
      approvedBy: currentUser.id,
      approvedDate: formatDate(moment())
    };
    updatePlaylist(data);
  };

  const debouncedOnChangeLink = debounce(async event => {
    const linkInfo = await getLinkInfoByUrl(event.target.value);
    if (linkInfo) {
      form.setFieldsValue({
        name: linkInfo?.title,
        code: linkInfo?.id,
        onlineStatus: linkInfo?.status,
        // providerId: linkInfo?.providerId,
        publisherId: linkInfo?.publisherId,
        publishDate: linkInfo?.publishedDate
          ? moment(linkInfo?.publishedDate)
          : null
      });
      setCurrentProvider(linkInfo?.providerId);
    }
  }, DEBOUNCE_VALUE);

  return (
    <Spin spinning={isLoading || isLinkInfoLoading}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row justify="space-between" align="middle">
          <Title>
            {isUpdatePlaylist ? 'Chi tiết tuyển tập' : 'Tạo tuyển tập'}
          </Title>
          <Col>
            <Space>
              {isUpdatePlaylist && (
                <Link to="/chi-tiet-tuyen-tap">
                  <Button icon={<PlusOutlined />} type="default">
                    Tạo tuyển tập
                  </Button>
                </Link>
              )}

              {isUpdatePlaylist &&
                playlist.status === 'waiting' &&
                playlist.totalSong > 0 && (
                  <Button
                    icon={<CheckOutlined />}
                    onClick={handleApprove}
                    style={{ background: '#009c03', border: 'none' }}
                  >
                    Duyệt
                  </Button>
                )}
            </Space>
          </Col>
        </Row>

        <CommonContainer>
          <Row gutter={16} align="middle">
            <Col span={12}>
              <FormItem
                label="Tên tuyển tập"
                name="name"
                rules={[{ required: true, message: 'Vui lòng tên tuyển tập!' }]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Link tuyển tập"
                name="link"
                rules={[
                  { required: true, message: 'Vui lòng link tuyển tập!' }
                ]}
              >
                <Input onChange={debouncedOnChangeLink} />
              </FormItem>
            </Col>
            <Col span={isUpdatePlaylist ? 12 : 24}>
              <Row gutter={16}>
                <PublisherSelect
                  form={form}
                  currentProvider={currentProvider}
                  isUpdatePlaylist={isUpdatePlaylist}
                />
                <Col span={isUpdatePlaylist ? 12 : 8}>
                  <FormItem
                    label="Mã code"
                    name="code"
                    rules={[
                      { required: true, message: 'Vui lòng nhập mã code!' }
                    ]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={isUpdatePlaylist ? 12 : 8}>
                  <FormItem
                    label="Trạng thái"
                    name="onlineStatus"
                    rules={[
                      { required: true, message: 'Vui lòng chọn trạng thái!' }
                    ]}
                  >
                    <Select options={ONLINE_STATUS} />
                  </FormItem>
                </Col>
                <Col span={isUpdatePlaylist ? 24 : 8}>
                  <FormItem label="Ngày phát hành" name="publishDate">
                    <DatePicker style={{ width: '100%' }} />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            {isUpdatePlaylist && (
              <Col span={12}>
                <CardInfo playlist={playlist} />
              </Col>
            )}
          </Row>
          <Card
            title="Danh sách bài hát"
            extra={
              <Space>
                <div>{songs?.length} bài hát</div>
                <ImportSong>
                  <Button
                    icon={<ImportOutlined />}
                    style={{ background: 'green', border: 'none' }}
                  >
                    Import
                  </Button>
                </ImportSong>
              </Space>
            }
            headStyle={{ background: '#80808017' }}
            className="mb-md"
          >
            <PlaylistSongAutoComplete
              onSelect={onSelect}
              currentSongs={songs}
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppabeProps}>
                    <SongOfPlaylist
                      songs={songs}
                      onDelete={onDelete}
                      onChangeTime={onChangeTime}
                      onChangeSongStatus={onChangeSongStatus}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Card>
          <Row justify="end">
            <FormItem>
              <Button htmlType="submit">
                {isUpdatePlaylist ? 'Cập nhật' : 'Tạo tuyển tập'}
              </Button>
            </FormItem>
          </Row>
        </CommonContainer>
        <br />
        {isUpdatePlaylist && (
          <CommonContainer>
            <PlaylistRevenueSharing />
          </CommonContainer>
        )}
      </Form>
    </Spin>
  );
};

export default PlaylistDetail;
