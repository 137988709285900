import {
  DeleteOutlined,
  DownloadOutlined,
  EditFilled,
  FileSearchOutlined
} from '@ant-design/icons';
import { Popconfirm, Row, Space, Typography } from 'antd';
import AddendumStatusTag from 'pages/AddendumDetail/components/AddendumStatusTag';
import { Link } from 'react-router-dom';
import { formatPrice } from 'utils/currency';

export const columns = [
  {
    title: 'Mã phụ lục',
    dataIndex: 'appendix',
    render: (_, { appendixCode01, appendixCode02, appendixCode03 }) => {
      return {
        children: !appendixCode03 ? (
          appendixCode01 === appendixCode02 ? (
            <Typography.Text strong>{appendixCode01}</Typography.Text>
          ) : (
            <Space direction="vertical">
              <Typography.Text strong>{appendixCode01}</Typography.Text>
              <Typography.Text strong>{appendixCode02}</Typography.Text>
            </Space>
          )
        ) : (
          <Typography.Text strong>{appendixCode03}</Typography.Text>
        )
      };
    }
  },
  {
    title: 'Chỉ mục',
    dataIndex: 'indexing',
    render: (
      _,
      {
        index01,
        index02,
        index03,
        appendixCode01,
        appendixCode02,
        appendixCode03
      }
    ) =>
      !appendixCode03 ? (
        appendixCode01 === appendixCode02 ? (
          <Typography.Text>{index01}</Typography.Text>
        ) : (
          <Space direction="vertical">
            <Typography.Text>{index01}</Typography.Text>
            <Typography.Text>{index02}</Typography.Text>
          </Space>
        )
      ) : (
        <Typography.Text>{index03}</Typography.Text>
      )
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: status => <AddendumStatusTag value={status} />
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'totalMoney',
    render: (_, { totalMoney, doiSoatId, advanceMoney }) => (
      <Typography.Text strong>
        {!doiSoatId ? formatPrice(totalMoney) : formatPrice(advanceMoney)}
      </Typography.Text>
    )
  },
  {
    title: 'Link phụ lục',
    dataIndex: 'file',
    render: (file, { evidenceFile }) => (
      <Space direction="vertical">
        {file.length > 0 ? (
          <Space direction="vertical">
            {file.map(f => (
              <a href={f?.fileLink} target="_blank" rel="noreferrer">
                <DownloadOutlined />
                {f?.fileName}
              </a>
            ))}
          </Space>
        ) : null}
        {evidenceFile.length > 0 ? (
          <Space direction="vertical">
            {evidenceFile.map(f => (
              <a
                href={f?.fileLink}
                target="_blank"
                rel="noreferrer"
                style={{ color: 'green' }}
              >
                <DownloadOutlined />
                {f?.fileName}
              </a>
            ))}
          </Space>
        ) : null}
      </Space>
    )
  },
  {
    title: 'Công cụ',
    dataIndex: '',
    render: (_, { handleDelete, id, status, handleChangeStatus }) => {
      return (
        <Row justify="end">
          <Space size={32}>
            <Link to={`/chi-tiet-phu-luc/${id}`}>
              <EditFilled style={{ color: 'gray' }} />
            </Link>
            <Popconfirm
              title="Bạn có chắc xoá phụ lục?"
              okText="Xoá"
              cancelText="Huỷ"
              onConfirm={() => handleDelete(id)}
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
            <Link to={`/review-phu-luc/${id}`}>
              <FileSearchOutlined style={{ fontSize: 16 }} />
            </Link>
          </Space>
        </Row>
      );
    }
  }
];
