import { FILTER_TYPE } from 'appConstants';
import FilterBox from 'components/FilterBox';
import { filterToUrl, genInitValueCustom } from 'components/FilterBox/utils';
import SongAutocompleteFilter from 'containers/SongAutocompleteFilter';
import SongTypeSelect from 'containers/SongTypeSelect';
import queryString from 'query-string';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useService } from 'stores/useService';
import { useSong } from 'stores/useSong';
import shallow from 'zustand/shallow';
import BusinessTypeAutocomplete from './BusinessTypeAutocomplete';
import SearchArtistAutoComplete from './SearchArtistAutoComplete';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Tên bài hát',
    name: 'SongName'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Thể loại bài hát',
    name: 'SongType',
    placeHolder: 'Chọn thể loại bài hát',
    isUsingChild: true,
    label: 'title',
    autocompleteComponent: (
      <SongTypeSelect placeHolder="Chọn thể loại bài hát" />
    )
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Nam Việt Code',
    name: 'namVietCode'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Ca sĩ',
    name: 'SingerIds',
    placeHolder: 'Tìm ca sĩ',
    isUsingChild: true,
    label: 'artistName',
    autocompleteComponent: (
      <SearchArtistAutoComplete placeHolder="Tìm ca sĩ (có thể chọn nhiều)" />
    ),
    isMultiple: true,
    otherType: true
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Tác giả',
    name: 'MusicianIds',
    placeHolder: 'Tìm tác giả',
    isUsingChild: true,
    label: 'artistName',
    autocompleteComponent: (
      <SearchArtistAutoComplete placeHolder="Tìm tác giả (có thể chọn nhiều)" />
    ),
    isMultiple: true,
    otherType: true
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Linh vực kinh doanh',
    name: 'BusinessTypeId',
    placeHolder: 'Chọn lĩnh vực kinh doanh',
    isUsingChild: true,
    label: 'name',
    autocompleteComponent: (
      <BusinessTypeAutocomplete placeHolder="Chọn lĩnh vực kinh doanh" />
    ),
    otherType: true
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Liên khúc chứa bài hát',
    name: 'SongIdInMedley',
    placeHolder: 'Tìm bài hát',
    isUsingChild: true,
    label: 'songName',
    autocompleteComponent: <SongAutocompleteFilter placeHolder="Tìm bài hát" />,
    otherType: true
  }
];

export const data = [
  {
    mainTitle: 'Phát hành',
    filterItems
  }
];

const SongFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  const { getPartnerFromIds } = useSong(
    useCallback(
      ({ getPartnerFromIds }) => ({
        getPartnerFromIds
      }),
      []
    ),
    shallow
  );

  const { getBusinessById, getSongTypeByCode, getSong } = useService(
    useCallback(
      ({ getBusinessById, getSongTypeByCode, getSong }) => ({
        getBusinessById,
        getSongTypeByCode,
        getSong
      }),
      []
    ),
    shallow
  );

  delete searchParams.page;
  delete searchParams.sort;
  delete searchParams.pageSize;

  const setFilter = (
    key,
    { value, operator, operatorFrom, operatorTo },
    otherType
  ) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo, otherType },
      searchParams,
      history,
      '/danh-sach-bai-hat'
    );
  };

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      initValue={genInitValueCustom(searchParams, filterItems, {
        SingerIds: getPartnerFromIds,
        MusicianIds: getPartnerFromIds,
        BusinessTypeId: getBusinessById,
        SongType: getSongTypeByCode,
        SongIdInMedley: getSong
      })}
      getExtendOptions={{
        SingerIds: getPartnerFromIds,
        MusicianIds: getPartnerFromIds,
        BusinessTypeId: getBusinessById,
        SongType: getSongTypeByCode,
        SongIdInMedley: getSong
      }}
    />
  );
};

export default React.memo(SongFilterBox);
