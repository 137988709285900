import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

export const useDepartmentDetail = create((set, get) => ({
  isDepartmentDetailLoading: false,
  setIsDepartmentDetailLoading: isDepartmentDetailLoading =>
    set({ isDepartmentDetailLoading }),
  //* ******************************* */
  //* NOTE: get and update department */
  //* ******************************* */

  department: null,
  fetchDepartment: async departmentId => {
    try {
      const {
        data: { data }
      } = await API.get(`/department/${departmentId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getDepartment: async departmentId => {
    try {
      get().setIsDepartmentDetailLoading(true);
      const data = await get().fetchDepartment(departmentId);
      get().setIsDepartmentDetailLoading(false);

      if (data) {
        set({
          department: data,
          subscribe: data.subscribe
          // access: data.access.map(item => ({
          //   ...item,
          //   layoutTitle: item.layoutDetail.title
          // }))
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateDepartment: async (params, departmentId) => {
    try {
      const { data } = await API.put(`/department/${departmentId}`, params);

      if (data) {
        get().getDepartment(departmentId);

        notification.success({
          message: 'Cập nhật phòng ban thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ****************/
  //* NOTE: subscribe */
  //* ****************/

  subscribe: [],
  tempSubscribe: [],
  subscribeToDelete: [],
  prevDeleteSubscribe: item => {
    if (item.id) {
      const newArr = get().subscribe.filter(({ id }) => id !== item.id);
      const itemToPush = get().subscribe.find(({ id }) => id === item.id);
      set({
        subscribe: newArr,
        subscribeToDelete: [...get().subscribeToDelete, itemToPush]
      });
    } else {
      const newArr = get().tempSubscribe.filter(
        ({ index }) => index !== item.index
      );

      set({ tempSubscribe: newArr });
    }
  },
  prevDeleteSubscribes: (thisCode, items) => {
    const newTempArr = get().tempSubscribe.filter(
      ({ notificationDetail }) => notificationDetail?.code !== thisCode
    );

    const newArr = [];
    const arrToDelete = [...get().subscribeToDelete];

    get().subscribe.forEach(item => {
      if (item?.notificationDetail?.code === thisCode) {
        arrToDelete.push(item);
      } else {
        newArr.push(item);
      }
    });

    set({
      subscribeToDelete: arrToDelete,
      subscribe: newArr,
      tempSubscribe: newTempArr
    });
  },
  pushToTempSubscribe: item => {
    const arr = get().tempSubscribe;
    const foundIndex = arr.findIndex(thisItem => thisItem.index === item.index);

    if (foundIndex !== -1) {
      const newArr = arr.map(thisItem =>
        thisItem.index === item.index ? { ...thisItem, ...item } : thisItem
      );

      return set({ tempSubscribe: newArr });
    }

    set({ tempSubscribe: [...get().tempSubscribe, item] });
  },
  pushToSubscribe: item => {
    const arr = get().subscribe;
    const newArr = arr.map(thisItem =>
      thisItem.id === item.id ? { ...thisItem, ...item } : thisItem
    );

    return set({ subscribe: newArr });
  },
  processAllSubscribe: async () => {
    try {
      const subscribe = get()
        .subscribe.filter(({ send }) => send)
        .map(
          ({
            id,
            objectType,
            objectId,
            receiveStatus,
            receiveNotification
          }) => ({
            id,
            objectType,
            objectId,
            receiveStatus,
            receiveNotification
          })
        );

      const tempSubscribe = get()
        .tempSubscribe.map(
          ({ objectType, objectId, receiveStatus, receiveNotification }) => ({
            objectType,
            objectId,
            receiveStatus,
            receiveNotification
          })
        )
        .filter(
          ({ receiveStatus, receiveNotification }) =>
            !(receiveNotification === 'changeStatusAppendix' && !receiveStatus)
        );

      const subscribeToDelete = get().subscribeToDelete.map(({ id }) => id);

      const data = await Promise.all([
        API.post('/subscribe/bulk', tempSubscribe),
        API.put('/subscribe/bulk', subscribe),
        API.delete('/subscribe', { data: { ids: subscribeToDelete } })
      ]);

      if (data) {
        get().getDepartment(get().department.id);
        set({ tempSubscribe: [], subscribeToDelete: [] });

        return notification.success({
          message: 'Cập nhật thông báo thành công!'
        });
      }
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* **************/
  //* NOTE: access */
  //* **************/

  access: [],
  tempAccess: [],
  accessToDelete: [],
  prevDeleteAccess: item => {
    if (item.id) {
      const newArr = get().access.filter(({ id }) => id !== item.id);
      const itemToPush = get().access.find(({ id }) => id === item.id);
      set({
        access: newArr,
        accessToDelete: [...get().accessToDelete, itemToPush]
      });
    } else {
      const newArr = get().tempAccess.filter(
        ({ index }) => index !== item.index
      );

      set({ tempAccess: newArr });
    }
  },
  pushToTempAccess: item => {
    const arr = get().tempAccess;
    const foundIndex = arr.findIndex(thisItem => thisItem.index === item.index);

    if (foundIndex !== -1) {
      const newArr = arr.map(thisItem =>
        thisItem.index === item.index ? { ...thisItem, ...item } : thisItem
      );

      return set({ tempAccess: newArr });
    }

    set({ tempAccess: [...get().tempAccess, item] });
  },
  pushToAccess: item => {
    const arr = get().access;
    const newArr = arr.map(thisItem =>
      thisItem.id === item.id ? { ...thisItem, ...item } : thisItem
    );

    return set({ access: newArr });
  },
  processAllAccess: async () => {
    try {
      const access = get()
        .access.filter(({ send }) => send)
        .map(({ id, objectType, objectId, layout, isAccepted }) => ({
          id,
          objectType,
          objectId,
          layout,
          isAccepted
        }));

      const tempAccess = get().tempAccess.map(
        ({ objectType, objectId, layout, isAccepted }) => ({
          objectType,
          objectId,
          layout,
          isAccepted
        })
      );

      const accessToDelete = get().accessToDelete.map(({ id }) => id);

      const data = await Promise.all([
        API.post('/access/bulk', tempAccess),
        API.put('/access/bulk', access),
        API.delete('/access', { data: { ids: accessToDelete } })
      ]);

      if (data) {
        get().getDepartment(get().department.id);
        set({ tempAccess: [], accessToDelete: [] });

        return notification.success({
          message: 'Cập nhật truy cập màn hình thành công!'
        });
      }
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForLayout: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata/search', {
        params: { limit: 5, freeText: searchValue, group: 'layout' }
      });

      if (data && data.length !== 0) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getAllNotificationType: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata', {
        params: { Page: 0, PageSize: 10000, 'Group[eq]': 'notificationType' }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getAllLayout: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata', {
        params: { Page: 0, PageSize: 10000, 'Group[eq]': 'layout' }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
