import React from 'react';

import { Form, Row, Col } from 'antd';

import Input from 'components/Input';
import DatePicker from 'components/DatePicker';
import GenderSelect from 'components/GenderSelect';

const ManagerFormModalContent = ({ form }) => {
  return (
    <Form form={form} layout="vertical">
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            name="fullName"
            label="Họ tên"
            rules={[{ required: true, message: 'Nhập tên!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="identityNumber"
            label="CMND"
            rules={[{ required: true, message: 'Nhập CMND!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Nhập email!' },
              { type: 'email', message: 'Nhập email!' }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="dateOfIdentification"
            label="Ngày cấp"
            rules={[{ required: true, message: 'Nhập ngày cấp!' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="phone"
            label="Số điện thoại"
            rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="placeOfIdentification"
            label="Nơi cấp"
            rules={[{ required: true, message: 'Nhập nơi cấp!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address"
            label="Địa chỉ"
            rules={[{ required: true, message: 'Nhập địa chỉ!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="dateOfBirth"
            label="Ngày sinh"
            rules={[{ required: true, message: 'Nhập ngày sinh!' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="taxCode"
            label="Mã số thuế"
            rules={[{ required: true, message: 'Nhập mã số thuế!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="gender"
            label="Giới tính"
            rules={[{ required: true, message: 'Nhập giới tính!' }]}
          >
            <GenderSelect />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ManagerFormModalContent;
