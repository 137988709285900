import { Button, Col, Row, Space, Typography } from 'antd';
import { PAGE_SIZE } from 'appConstants';
import DeleteButton from 'components/DeleteButton';
import { CommonContainer } from 'components/StyledComponents';
import Table from 'components/Table';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSongRelease } from 'stores/useSongRelease';
import shallow from 'zustand/shallow';
import { columns } from './columns';
import SongReleaseFilterBox from './components/SongReleaseFilterBox';

const SongRelease = () => {
  const {
    getSongReleases,
    songReleases,
    total,
    setParams,
    deleteSongsRelease,
    isSongReleaseLoading
  } = useSongRelease(
    useCallback(
      ({
        getSongReleases,
        songReleases,
        total,
        setParams,
        deleteSongsRelease,
        isSongReleaseLoading
      }) => ({
        getSongReleases,
        songReleases,
        total,
        setParams,
        deleteSongsRelease,
        isSongReleaseLoading
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-phat-hanh',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  useEffect(() => {
    const paramsForGet = { ...searchParams, page: page - 1 };
    setParams(paramsForGet);
    getSongReleases(paramsForGet);
  }, [getSongReleases, page, searchParams, setParams]);

  return (
    <div>
      <Typography.Title>Danh sách lịch phát hành</Typography.Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>
            <SongReleaseFilterBox />
          </Col>
          <Col>
            <Space>
              {selectedRows.length !== 0 && (
                <DeleteButton
                  title={`Xoá ${selectedRows.length} phát hành?`}
                  onDelete={() => {
                    deleteSongsRelease({ ids: selectedRows });
                    setSelectedRows([]);
                  }}
                  length={selectedRows.length}
                  // from={ROLE_CHECK.SONG}
                />
              )}
              <Link to="/chi-tiet-phat-hanh">
                <Button type="primary">Tạo mới</Button>
              </Link>
            </Space>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={songReleases.map(item => ({ key: item.id, ...item }))}
          paginationProps={{
            total: total,
            pageSize: PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
          scroll={{ x: 1500 }}
          rowSelection={rowSelection}
          loading={isSongReleaseLoading}
        />
      </CommonContainer>
    </div>
  );
};

export default SongRelease;
