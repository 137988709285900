import React, { useCallback, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';

import shallow from 'zustand/shallow';
import { Pagination, Row, Space, Table as AntdTable } from 'antd';

import { columns } from './columns';

import { usePublisher } from 'stores/usePublisher';
import { useProvider } from 'stores/useProvider';

import { EDITABLE_CELL_TYPE, PAGE_SIZE } from 'appConstants';
import PublisherRow from './components/PublisherRow';
import PublisherCell from './components/PublisherCell';
import Button from 'components/Button';
import { useHistory, useLocation } from 'react-router-dom';
import Tag from 'components/Tag';
import PublisherFilterBox from './components/PublisherFilterBox';
import ExportButton from 'components/ExportButton';

const Publisher = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    publishers,
    isPublisherLoading,
    updatePublisher,
    isAddingPublisher,
    toggleIsAddingPublisher,
    total,
    getPublishers,
    setParams,
    exportSongPublicationByIds,
    isExportLoading
  } = usePublisher(
    useCallback(
      ({
        publishers,
        isPublisherLoading,
        updatePublisher,
        isAddingPublisher,
        toggleIsAddingPublisher,
        total,
        getPublishers,
        setParams,
        exportSongPublicationByIds,
        isExportLoading
      }) => ({
        publishers,
        isPublisherLoading,
        updatePublisher,
        isAddingPublisher,
        toggleIsAddingPublisher,
        total,
        getPublishers,
        setParams,
        exportSongPublicationByIds,
        isExportLoading
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);

  const { isProviderLoading, allProviders, getAllProviders } = useProvider(
    useCallback(
      ({ isProviderLoading, allProviders, getAllProviders }) => ({
        isProviderLoading,
        allProviders,
        getAllProviders
      }),
      []
    ),
    shallow
  );

  const handleSave = row => {
    updatePublisher(row);
  };

  const anotherColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          focusIfAdd: col.focusIfAdd,
          handleSave: handleSave,
          ...(col.editable === EDITABLE_CELL_TYPE.SELECT
            ? {
                options: allProviders.map(({ name, id }) => ({
                  label: name,
                  value: id
                }))
              }
            : {})
        };
      }
    };
  });

  const components = {
    body: {
      row: PublisherRow,
      cell: PublisherCell
    }
  };

  const dataSource = publishers.map(item => ({
    ...item,
    key: item.id,
    providerId: item.provider ? item.providerId : null
  }));

  const onPaginate = page => {
    history.push({
      pathname: '/quan-ly-kenh/kenh-phat-hanh',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  useEffect(() => {
    getAllProviders();
  }, [getAllProviders]);

  useEffect(() => {
    const params = { ...searchParams, page: page - 1 };
    setParams(params);
    getPublishers(params);
  }, [getPublishers, searchParams, page, setParams]);

  const pagination = {
    total: total,
    pageSize: PAGE_SIZE,
    onChange: onPaginate,
    current: page * 1
  };
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };
  const PublisherToExport = useMemo(
    () => (!!selectedRows.length ? selectedRows : publishers.map(_p => _p.id)),
    [publishers, selectedRows]
  );

  return (
    <div>
      <Row justify="space-between">
        <PublisherFilterBox />
        <Space>
          <ExportButton
            loading={isExportLoading}
            // onExport={getExportContracts}
            onExportByIds={exportSongPublicationByIds}
            selectedItems={PublisherToExport}
            callback={() => setSelectedRows([])}
            tooltip={`Export link phát hành từ ${
              !!selectedRows.length ? selectedRows.length + ' kênh' : 'kênh'
            }`}
          />
          <Button onClick={toggleIsAddingPublisher}>Tạo mới</Button>
        </Space>
      </Row>

      <AntdTable
        // pagination={{
        //   total: totalPage * PAGE_SIZE,
        //   pageSize: PAGE_SIZE,
        //   onChange: onPaginate,
        //   current: page * 1
        // }}
        pagination={false}
        columns={anotherColumns}
        dataSource={
          isAddingPublisher ? [{ add: true }, ...dataSource] : dataSource
        }
        loading={isProviderLoading || isPublisherLoading}
        components={components}
        style={{ margin: '16px 0' }}
        scroll={{ x: 900 }}
        rowSelection={rowSelection}
      />
      <Row justify="space-between" className="mt-md">
        <Tag color="blue" style={{ display: 'flex', alignItems: 'center' }}>
          Tổng: {pagination.total}
        </Tag>
        <Pagination {...pagination} showSizeChanger={false} />
      </Row>
    </div>
  );
};

export default Publisher;
