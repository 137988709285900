import { SaveOutlined } from '@ant-design/icons';
import { Col, Form, Row, Table, Typography } from 'antd';
import Button from 'components/Button';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useContract } from 'stores/useContract';
import { useProjectAppendix } from 'stores/useProjectAppendix';
import shallow from 'zustand/shallow';
import { columns } from './columns';
import ProjectAppendixAutoComplete from './ProjectAppendixAutoComplete';

const ChildContract = ({ contractId, isUpdateContract }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { contract } = useContract(
    useCallback(
      ({ contract }) => ({
        contract
      }),
      []
    ),
    shallow
  );

  const {
    projectAppendixes,
    getProjectAppendixes,
    updateListProjectAppendix,
    isLoading
  } = useProjectAppendix(
    useCallback(
      ({
        projectAppendixes,
        getProjectAppendixes,
        updateListProjectAppendix,
        isLoading
      }) => ({
        projectAppendixes,
        getProjectAppendixes,
        updateListProjectAppendix,
        isLoading
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (id) {
      getProjectAppendixes(id);
    }
  }, [getProjectAppendixes, id]);

  const isProject = useMemo(() => !!contract?.doiSoatProjectId, [contract]);
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={() => updateListProjectAppendix(id)}
    >
      <Row className="mb-md">
        <Col span={24}>
          <Form.Item label="Thêm phụ lục liên kết:">
            <ProjectAppendixAutoComplete />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className="card-box" style={{ padding: '16px' }}>
            <Row justify="space-between">
              <Typography.Text>Phụ lục liên kết:</Typography.Text>
            </Row>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={projectAppendixes.map(appendix => ({
                ...appendix,
                key: appendix?.id,
                isProject
              }))}
              pagination={false}
              className="navi-table"
            />
          </div>
        </Col>
      </Row>
      <Row justify="end">
        <Button icon={<SaveOutlined />} htmlType="submit">
          Lưu
        </Button>
      </Row>
    </Form>
  );
};

export default ChildContract;
