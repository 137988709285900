import React from 'react';

const CommonContainer = ({ children }) => {
  return (
    <div
      style={{
        border: '1px solid #f0f0f0',
        borderRadius: '4px',
        padding: '16px',
        background: 'white'
      }}
    >
      {children}
    </div>
  );
};

export default CommonContainer;
