import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import ContractProgress from './ContractProgress';
import SummaryDetail from './SummaryDetail';
import { useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useContract } from 'stores/useContract';

const ContractDetailLayout = ({ children, step }) => {
  const { id } = useParams();
  const { getContract, setContract } = useContract(
    useCallback(
      ({ getContract, setContract }) => ({
        getContract,
        setContract
      }),
      []
    ),
    shallow
  );

  const isUpdateContract = useMemo(() => id, [id]);

  useEffect(() => {
    if (isUpdateContract) getContract(id);
    return () => {
      setContract(null);
    };
  }, [getContract, id, isUpdateContract, setContract]);

  return (
    <div>
      <ContractProgress step={step} />
      <Row gutter={16} wrap={false}>
        <Col flex="auto">{children}</Col>
        {isUpdateContract && (
          <Col>
            <SummaryDetail step={step} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ContractDetailLayout;
