import React, { useCallback } from 'react';
import Button from 'components/Button';
import { Popconfirm, Tooltip, Typography } from 'antd';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const DeactiveButton = ({ user }) => {
  const { deactive } = user;
  const { updateUser } = useUser(
    useCallback(
      ({ updateUser }) => ({
        updateUser
      }),
      []
    ),
    shallow
  );

  const styleButton = deactive
    ? { style: { background: 'green', border: 'none' } }
    : { danger: true };

  const handleOk = () => {
    updateUser({ ...user, deactive: !deactive });
  };

  const title = (
    <Typography>
      Bạn muốn{' '}
      <Typography.Text strong>{deactive ? 'mở khoá' : 'khoá'}</Typography.Text>{' '}
      hoạt động của nhân viên này?
    </Typography>
  );

  return (
    <Popconfirm
      title={title}
      onConfirm={handleOk}
      okText="Đồng ý"
      cancelText="Huỷ bỏ"
    >
      {/* <Button {...styleButton}>{deactive ? 'Active' : 'Deactive'}</Button> */}
      <Tooltip title={deactive ? 'Mở khoá tài khoản' : 'Khoá tài khoản'}>
        <Button
          {...styleButton}
          icon={deactive ? <UnlockOutlined /> : <LockOutlined />}
        ></Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default DeactiveButton;
