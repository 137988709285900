import { Col, Divider, Row, Table, Typography } from 'antd';
import React from 'react';
import { get } from 'utils/lodash';
import ChildContracts from './ChildContracts';
import { columns } from './columns';
import ContractInfoContent from './ContractInfoContent';

const { Text } = Typography;

const ContractInfo = ({ contract }) => {
  const revenueSharing = get(contract, 'revenueSharing', []);
  return (
    <div>
      <Divider orientation="right">
        <Text style={{ color: 'blue' }}>Thông tin hợp đồng</Text>
      </Divider>
      <ContractInfoContent contract={contract} />
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <Typography.Text strong>Phân chia doanh thu:</Typography.Text>
          <Table
            columns={columns}
            pagination={false}
            dataSource={revenueSharing.map(revenue => ({
              ...revenue,
              key: revenue.id
            }))}
            bordered
          />
        </Col>
        {/* {contract?.contractType === 'DTHT' && (
          <Col span={24}>
            <ChildContracts childContracts={contract.childContracts} />
          </Col>
        )} */}
      </Row>
    </div>
  );
};

export default ContractInfo;
