import { Col, Divider, Row, Space, Typography } from 'antd';
import TextContent from 'components/TextContent';
import React from 'react';

const { Text } = Typography;

const CompanyInfo = ({ company }) => {
  return (
    <div>
      <Divider orientation="right">
        <Text style={{ color: 'orange' }}>Công ty đại diện</Text>
      </Divider>
      <Row gutter={[32, 16]}>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <Text>Tên công ty:</Text>
            <TextContent value={company?.name} />
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <Text>Họ tên người đại diện:</Text>
            <TextContent value={company?.representationName} />
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <Text>Số điện thoại:</Text>
            <TextContent value={company?.phone} />
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <Text>Email:</Text>
            <TextContent value={company?.email} />
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <Text>Mã số thuế:</Text>
            <TextContent value={company?.taxCode} />
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <Text>CMND/CCCD người đại diện:</Text>
            <TextContent value={company?.representationIdentityNumber} />
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical" size={0}>
            <Text>Địa chỉ:</Text>
            <TextContent value={company?.address} />
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyInfo;
