import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';
import { notification } from 'antd';

export const useCurrentUser = create(set => ({
  currentUser: null,
  currentUserViews: null,
  currentUserActions: null,
  isAnywayRole: false,
  resetCurrentUser: () => set({ currentUser: null }),
  getCurrentUser: async () => {
    try {
      const { data } = await API.get('/auth/me');

      if (data) {
        const isAnywayRole = data.actions.some(
          action => action.key === 'Permission.Appendix.Update.Anyway'
        );
        set({
          currentUser: data,
          currentUserViews: data.views,
          currentUserActions: data.actions,
          isAnywayRole
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateProfile: async params => {
    try {
      const { data } = await API.put('/auth', params);

      if (data) {
        set(({ currentUser }) => ({
          currentUser: {
            ...currentUser,
            phone: data?.phone,
            fullName: data?.fullName,
            avatar: data?.avatar ? data?.avatar : currentUser?.avatar
          }
        }));
        notification.success({ message: 'Cập nhật thông tin thành công!' });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
