import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';

export const useContractHistory = create((set, get) => ({
  histories: [],
  isLoading: false,
  totalPage: 1,
  total: 1,
  page: 1,
  setPage: page => set({ page }),
  getContractHistory: async id => {
    try {
      set({ isLoading: true });
      const {
        data: { data, total }
      } = await API.get(`/contract/${id}/history`, {
        params: {
          page: get().page - 1,
          pageSize: PAGE_SIZE,
          sort: 'Latest'
        }
      });

      if (data) {
        set({
          histories: data,
          isLoading: false,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
      set({ isLoading: false });
      return null;
    } catch (error) {
      notification.error({
        message: error?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
