import React, { useCallback, useEffect } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import DashboardTable from '../DashboardTable';
import { columns } from './columns';

const NeedToCheckContract = () => {
  const { needToCheckContracts, getNeedToCheckContracts } = useDashboard(
    useCallback(
      ({ needToCheckContracts, getNeedToCheckContracts }) => ({
        needToCheckContracts,
        getNeedToCheckContracts
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getNeedToCheckContracts();
  }, [getNeedToCheckContracts]);

  return (
    <DashboardTable
      data={needToCheckContracts?.data?.map(contract => ({
        ...contract,
        key: contract.id
      }))}
      columns={columns}
      title="Hợp đồng chuẩn bị ký"
      total={needToCheckContracts?.total}
    />
  );
};

export default NeedToCheckContract;
