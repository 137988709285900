import React, { useCallback } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import { usePublisher } from 'stores/usePublisher';
import shallow from 'zustand/shallow';

const PublisherDeleteButton = ({ item }) => {
  const { deletePublisher } = usePublisher(
    useCallback(
      ({ deletePublisher }) => ({
        deletePublisher
      }),
      []
    ),
    shallow
  );

  return (
    <DeleteConfirmPopup
      title={`Xoá '${item.name}'?`}
      onDelete={() => deletePublisher(item.id)}
    >
      <Button type="danger" icon={<DeleteOutlined />} />
    </DeleteConfirmPopup>
  );
};

export default PublisherDeleteButton;
