import { Col, Row, Space, Table, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { useService } from 'stores/useService';
import shallow from 'zustand/shallow';

const { Text } = Typography;

const UpdateRevenue = ({ info }) => {
  const [typeOfBusiness, setTypeOfBusiness] = useState([]);
  const { currentValue, previousValue } = info;

  const { getBusinessAreas } = useService(
    useCallback(
      ({ getBusinessAreas }) => ({
        getBusinessAreas
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    (async () => {
      const data = await getBusinessAreas();
      if (data) setTypeOfBusiness(data);
    })();
  }, [getBusinessAreas]);

  const getBusinessLabel = code => {
    return typeOfBusiness.find(t => t.code === code)?.title;
  };

  const columns = [
    {
      title: 'Lĩnh vực kinh doanh',
      dataIndex: 'BusinessType',
      render: BusinessType =>
        !!typeOfBusiness.length && getBusinessLabel(BusinessType)
    },
    {
      title: 'Bên A',
      dataIndex: 'GroupA',
      render: (GroupA, { status }) => {
        if (status === 'updated') {
          return (
            <Space direction="vertical">
              <Text strong>{GroupA.current}</Text>
              <Text delete>{GroupA.previous}</Text>
            </Space>
          );
        }
        return GroupA;
      }
    },
    {
      title: 'Bên B Quyền liên quan',
      dataIndex: 'GroupBRelatedRight',
      render: (GroupBRelatedRight, { status }) => {
        if (status === 'updated') {
          return (
            <Space direction="vertical">
              <Text strong>{GroupBRelatedRight.current}</Text>
              <Text delete>{GroupBRelatedRight.previous}</Text>
            </Space>
          );
        }
        return GroupBRelatedRight;
      }
    },
    {
      title: 'Bên B Quyền tác giả',
      dataIndex: 'GroupBCopyRight',
      render: (GroupBCopyRight, { status }) => {
        if (status === 'updated') {
          return (
            <Space direction="vertical">
              <Text strong>{GroupBCopyRight.current}</Text>
              <Text delete>{GroupBCopyRight.previous}</Text>
            </Space>
          );
        }
        return GroupBCopyRight;
      }
    }
  ];

  const previousFormat = previousValue?.map(p => {
    const sameItem = currentValue?.find(c => {
      return JSON.stringify(p) === JSON.stringify(c);
    });

    const updateItem = currentValue?.find(c => {
      return p.BusinessType === c.BusinessType;
    });

    const status = sameItem ? 'origin' : updateItem ? 'updated' : 'deleted';
    return { ...p, status };
  });

  const currentFormat = currentValue?.map(c => {
    const sameItem = previousValue?.find(p => {
      return JSON.stringify(p) === JSON.stringify(c);
    });

    const updateItem = previousValue?.find(p => {
      return p.BusinessType === c.BusinessType;
    });

    const status = sameItem ? 'origin' : updateItem ? 'updated' : 'new';
    if (status === 'updated') {
      return {
        ...c,
        GroupA: { current: c.GroupA, previous: updateItem.GroupA },
        GroupBRelatedRight: {
          current: c.GroupBRelatedRight,
          previous: updateItem.GroupBRelatedRight
        },
        GroupBCopyRight: {
          current: c.GroupBCopyRight,
          previous: updateItem.GroupBCopyRight
        },
        status
      };
    }
    return { ...c, status };
  });

  const itemDeleted = previousFormat
    ? previousFormat.filter(p => p.status === 'deleted')
    : [];

  const currentDisplay = [...currentFormat, ...itemDeleted].filter(
    current => current.status !== 'origin'
  );

  return (
    <Row>
      <Col span={24}>
        <Table
          title={() => <Row justify="center">Phân chia doanh thu</Row>}
          columns={columns}
          dataSource={currentDisplay}
          pagination={false}
          bordered
          className="mb-md"
          rowClassName={record => `status-${record.status}`}
        />
      </Col>
    </Row>
  );
};

export default UpdateRevenue;
