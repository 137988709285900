import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Card, Col, Form, Row, Space, Tag, Typography } from 'antd';
import Button from 'components/Button';
import ExportButton from 'components/ExportButton';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { useCurrentUser } from 'stores/useCurrentUser';
import { formatDate } from 'utils/date';
import shallow from 'zustand/shallow';
import LeftAddendumDetailInfo from './LeftAddendumDetailInfo';
import RightAddendumDetailInfo from './RightAddendumDetailInfo';

const AddendumDetailInfo = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const {
    contractUseInAddendumDetail,
    disableEditAddendum,
    updateAddendum,
    addendum,
    getExportAppendix,
    isExportLoading,
    updateAppendixNote
  } = useAddendumDetail(
    useCallback(
      ({
        contractUseInAddendumDetail,
        disableEditAddendum,
        updateAddendum,
        addendum,
        getExportAppendix,
        isExportLoading,
        updateAppendixNote
      }) => ({
        contractUseInAddendumDetail,
        disableEditAddendum,
        updateAddendum,
        addendum,
        getExportAppendix,
        isExportLoading,
        updateAppendixNote
      }),
      []
    ),
    shallow
  );

  const { isAnywayRole } = useCurrentUser(
    useCallback(
      ({ isAnywayRole }) => ({
        isAnywayRole
      }),
      []
    ),
    shallow
  );

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      const { file, evidenceFile } = params;
      const fileParser = !!file?.length
        ? file?.map(f => {
            if (f.response) {
              return {
                fileName: f.response.fileName,
                fileLink: f.response.preview
              };
            }
            return { fileName: f.name, fileLink: f.url };
          })
        : null;

      const fileEvidenceParser = !!evidenceFile?.length
        ? evidenceFile?.map(f => {
            if (f.response) {
              return {
                fileName: f.response.fileName,
                fileLink: f.response.preview
              };
            }
            return { fileName: f.name, fileLink: f.url };
          })
        : null;

      const res = await updateAddendum(id, {
        ...params,
        index01: addendum.index01,
        index02: addendum.index02,
        advanceMoney: addendum.advanceMoney,
        totalMoney: addendum.totalMoney,
        finishDate: params.finishDate ? formatDate(params.finishDate) : '',
        expectedFileDeliveryDate: params.expectedFileDeliveryDate
          ? formatDate(params.expectedFileDeliveryDate)
          : '',
        startDate: params.startDate && formatDate(params.startDate),
        deductedInAdvanceMoney: params.deductedInAdvanceMoney,
        adjustmentFile: addendum?.adjustmentFile,
        doiSoatId: addendum?.doiSoatId,
        projectContractId: params?.projectContractId || '',
        file: isAnywayRole ? fileParser : addendum?.file,
        evidenceFile: isAnywayRole ? fileEvidenceParser : addendum?.evidenceFile
      });
      await updateAppendixNote(id, { note: params.note });

      return res;
    } catch ({ data }) {}
  };

  useEffect(() => {
    if (addendum) {
      form.setFieldsValue({
        startDate: addendum.startDate && moment(addendum.startDate),
        finishDate: addendum.finishDate && moment(addendum.finishDate),
        expectedFileDeliveryDate:
          addendum.expectedFileDeliveryDate &&
          moment(addendum.expectedFileDeliveryDate),
        deductedInAdvanceMoney: addendum.deductedInAdvanceMoney,
        note: addendum.note,
        projectContractId: addendum?.projectContract?.id,
        file: addendum?.file.map(f => ({ name: f.fileName, url: f.fileLink })),
        evidenceFile: addendum?.evidenceFile.map(f => ({
          name: f.fileName,
          url: f.fileLink
        }))
      });
    }
  }, [addendum, form]);

  return (
    <Form form={form} layout="vertical">
      <Card
        title={
          <Row justify="space-between">
            <Typography.Text strong>Thông tin cơ bản</Typography.Text>
            <ExportButton
              showTooltip={false}
              loading={isExportLoading}
              onExport={() => getExportAppendix(addendum?.id)}
            />
          </Row>
        }
      >
        <Row gutter={32}>
          <Col span={12}>
            <LeftAddendumDetailInfo />
          </Col>

          <Col span={12} className="addendum-detail-info--right">
            <RightAddendumDetailInfo contract={contractUseInAddendumDetail} />
          </Col>
        </Row>

        <Row justify="end" style={{ marginTop: '16px' }}>
          <Space>
            <Tag icon={<InfoCircleOutlined />} color="warning">
              Dữ liệu sẽ mất nếu như không nhấn lưu.
            </Tag>

            <Button
              icon={<SaveOutlined />}
              onClick={onSave}
              disabled={disableEditAddendum && !isAnywayRole}
            >
              Lưu
            </Button>
          </Space>
        </Row>
      </Card>
    </Form>
  );
};

export default AddendumDetailInfo;
