import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';

export const useDepartment = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* ******************** */
  //* NOTE: get department */
  //* ******************** */
  departments: [],
  totalPage: 1,
  total: 0,
  getDepartments: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get('/department', {
        params: {
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        }
      });

      if (data) {
        set({
          departments: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************* */
  //* NOTE: delete departments  */
  //* ************************* */

  deleteDepartments: async params => {
    try {
      const { data } = await API.delete('/department', { data: params });

      if (data) {
        get().getDepartments();

        return notification.success({
          message: 'Xoá phòng ban thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************ */
  //* NOTE: create department  */
  //* ************************ */

  createDepartment: async params => {
    try {
      const { data } = await API.post('/department', params);

      if (data) {
        await get().getDepartments();
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
