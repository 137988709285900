import React, { useCallback, useEffect, useState } from 'react';

import shallow from 'zustand/shallow';

import { usePartnerDetail } from 'stores/usePartnerDetail';

import Select from 'components/Select';
import { debounce } from 'utils/lodash';
import { DEBOUNCE_VALUE } from 'appConstants';

const ManagerAutoComplete = ({
  resetManagerIdFormData,
  defaultOption,
  ...props
}) => {
  const [options, setOptions] = useState(
    defaultOption
      ? [{ label: defaultOption.managerName, value: defaultOption.managerId }]
      : []
  );

  const { searchForManager, setUpdateManagerAutoCompleteOptions } =
    usePartnerDetail(
      useCallback(
        ({ searchForManager, setUpdateManagerAutoCompleteOptions }) => ({
          searchForManager,
          setUpdateManagerAutoCompleteOptions
        }),
        []
      ),
      shallow
    );

  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }
    const results = await searchForManager(searchText);

    if (!results) {
      return;
    }

    return setOptions(
      results.map(({ id, fullName }) => ({ label: fullName, value: id }))
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  useEffect(() => {
    setUpdateManagerAutoCompleteOptions(setOptions);
  }, [setUpdateManagerAutoCompleteOptions]);

  return (
    <Select
      filterOption={false}
      notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      options={options}
      placeHolder="Tìm quản lý"
      {...props}
    />
  );
};

export default ManagerAutoComplete;
