import React, { useCallback, useContext } from 'react';
import shallow from 'zustand/shallow';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import Button from 'components/Button';

import { EditableContext } from './ProviderRow';
import { useProvider } from 'stores/useProvider';

const ProviderAddButtons = () => {
  const form = useContext(EditableContext);
  const { createProvider, toggleIsAddingProvider } = useProvider(
    useCallback(
      ({ createProvider, toggleIsAddingProvider }) => ({
        createProvider,
        toggleIsAddingProvider
      }),
      []
    ),
    shallow
  );

  const onAdd = async () => {
    try {
      const params = await form.validateFields();
      createProvider(params);
    } catch ({ data }) {}
  };

  return (
    <Space>
      <Button type="default" icon={<CheckOutlined />} onClick={onAdd} />

      <Button
        type="default"
        icon={<CloseOutlined />}
        onClick={toggleIsAddingProvider}
      />
    </Space>
  );
};

export default ProviderAddButtons;
