import { Col, Row } from 'antd';
import FormItem from 'components/FormItem';
import Select from 'components/Select';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePlaylistDetail } from 'stores/usePlaylistDetail';
import { isEmptyObject } from 'utils/lodash';
import { formatOption } from 'utils/parser';
import shallow from 'zustand/shallow';

const PublisherSelect = ({ form, currentProvider, isUpdatePlaylist }) => {
  const [options, setOptions] = useState([]);

  const { publishers, getAllPublisher, providers, getAllProvider, playlist } =
    usePlaylistDetail(
      useCallback(
        ({
          publishers,
          getAllPublisher,
          providers,
          getAllProvider,
          playlist
        }) => ({
          publishers,
          getAllPublisher,
          providers,
          getAllProvider,
          playlist
        }),
        []
      ),
      shallow
    );

  useEffect(() => {
    getAllProvider();
    getAllPublisher();
  }, [getAllProvider, getAllPublisher]);

  useEffect(() => {
    if (currentProvider) {
      const publisherOptions = formatOption(
        publishers.filter(publisher => publisher.providerId === currentProvider)
      );
      setOptions(publisherOptions);
    }
  }, [currentProvider, publishers]);

  const providerOption = useMemo(() => formatOption(providers), [providers]);

  // useEffect(() => {
  //   if (publishers) {
  //     const _options = formatOption(publishers);
  //     setOptions(_options);
  //   }
  // }, [publishers]);

  useEffect(() => {
    if (!isEmptyObject(playlist)) {
      const publisherOptions = formatOption(
        publishers.filter(
          publisher => publisher.providerId === playlist?.publisher?.providerId
        )
      );
      setOptions(publisherOptions);
    }
  }, [playlist, publishers]);

  const onChangeProvider = value => {
    const publisherOptions = formatOption(
      publishers.filter(publisher => publisher.providerId === value)
    );
    setOptions(publisherOptions);
  };

  return (
    <Col span={24}>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem label="Nhà cung cấp" name="providerId">
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              options={providerOption}
              listHeight={150}
              onChange={onChangeProvider}
              disabled
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Kênh phát hành"
            name="publisherId"
            rules={[
              { required: true, message: 'Vui lòng chọn kênh phát hành!' }
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              options={options}
              listHeight={150}
            />
          </FormItem>
        </Col>
      </Row>
    </Col>
  );
};

export default PublisherSelect;
