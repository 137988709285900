import React from 'react';
import queryString from 'query-string';
import FilterBox from 'components/FilterBox';

import { FILTER_TYPE, SINGER_RANK, SYNC_STATUS } from 'appConstants';

import { useHistory, useLocation } from 'react-router-dom';
import { filterToUrl, genInitValue } from 'components/FilterBox/utils';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Nghệ danh',
    name: 'ArtistName'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Tên đối tác',
    name: 'FullName'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'CMND/CCCD',
    name: 'IdentityNumber'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Số điện thoại',
    name: 'Phone'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Email',
    name: 'Email'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Địa chỉ',
    name: 'Address'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Giới tính',
    name: 'Gender',
    options: [
      { label: 'Nam', value: 'male' },
      { label: 'Nữ', value: 'female' }
    ]
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Ngày sinh',
    name: 'DateOfBirth'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Hạng',
    name: 'SingerRank',
    options: SINGER_RANK
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Thời gian đồng bộ',
    name: 'SyncDate'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Trạng thái đồng bộ',
    name: 'SyncStatus',
    options: SYNC_STATUS
  }
];

export const data = [
  {
    mainTitle: 'Đối tác',
    filterItems
  }
];

const PartnerFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  delete searchParams.page;
  delete searchParams.sort;

  const setFilter = (key, { value, operator, operatorFrom, operatorTo }) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo },
      searchParams,
      history,
      '/danh-sach-doi-tac'
    );
  };

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      initValue={genInitValue(searchParams, filterItems)}
    />
  );
};

export default PartnerFilterBox;
