import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { Card, Col, Form, Row, Space, Tag, Typography } from 'antd';

import Button from 'components/Button';

import ExportButton from 'components/ExportButton';
import { Partnership } from 'components/Icons';
import ArtistTypeSelect from 'containers/ArtistTypeSelect';
import { useEffect } from 'react';
import { usePartnerDetail } from 'stores/usePartnerDetail';
import { formatDate } from 'utils/date';
import { get } from 'utils/lodash';
import { camelize } from 'utils/other';
import DeputyAuthorization from '../components/DeputyAuthorization';
import DeputyInfo from '../DeputiInfo';
import PartnerContentInfo from './PartnerContentInfo';
import { PARTNER_TYPE } from 'appConstants';

const PartnerInfo = ({ isUpdatePartner, form }) => {
  const { id } = useParams();
  const history = useHistory();

  const {
    createPartner,
    updatePartner,
    getExportPartner,
    isExportLoading,
    partnerType,
    isSinglePartner,
    setIsSinglePartner,
    partner
  } = usePartnerDetail(
    useCallback(
      ({
        createPartner,
        updatePartner,
        getExportPartner,
        isExportLoading,
        partnerType,
        isSinglePartner,
        setIsSinglePartner,
        partner
      }) => ({
        createPartner,
        updatePartner,
        getExportPartner,
        isExportLoading,
        partnerType,
        isSinglePartner,
        setIsSinglePartner,
        partner
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (partnerType && !isUpdatePartner) {
      const singleId = partnerType.find(
        partner => partner.code === PARTNER_TYPE.INDIVIDUAL
      )?.id;
      if (singleId) form.setFieldsValue({ masterdataId: singleId });
    }
  }, [form, isUpdatePartner, partnerType]);

  useEffect(() => {
    return () => {
      setIsSinglePartner(true);
    };
  }, [setIsSinglePartner]);

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const avatarValue = get(values, 'avatar[0]', null);

      const { identityPhoto, delegacyFile } = values;
      const identityPhotoParser = identityPhoto?.map(f => {
        if (f.response) {
          return {
            imageName: f.response.fileName,
            imageLink: f.response.preview
          };
        }
        return { imageName: f.name, imageLink: f.url };
      });

      const delegacyFileParser = delegacyFile?.map(f => {
        if (f.response) {
          return {
            fileName: f.response.fileName,
            fileLink: f.response.preview
          };
        }
        return { fileName: f.name, fileLink: f.url };
      });

      const avatar = avatarValue
        ? avatarValue?.response?.preview || avatarValue?.url
        : null;

      const params = {
        ...values,
        identityPhoto: identityPhotoParser,
        delegacyFile: delegacyFileParser,
        avatar,
        dateOfBirth: values.dateOfBirth && formatDate(values.dateOfBirth),
        dateOfIdentification:
          values.dateOfIdentification &&
          formatDate(values.dateOfIdentification),
        ...(!values.representationType
          ? { representationId: null }
          : values.representationType === 'manager'
          ? { representationId: values.managerId }
          : { representationId: values.companyId })
      };

      const setFieldsError = errs => {
        return form.setFields(
          Object.entries(errs).map(([key, value]) => ({
            name: [camelize(key)],
            errors: value
          }))
        );
      };

      if (isUpdatePartner) {
        await updatePartner(
          { ...params, singerRank: params?.singerRank || 0, id },
          setFieldsError
        );
      } else {
        await createPartner(
          params,
          id => history.push(`chi-tiet-doi-tac/${id}`),
          setFieldsError
        );
      }
    } catch (error) {}
  };

  const onChangePartnerType = typeId => {
    const type = partnerType.find(_partner => _partner.id === typeId);
    const isSingle = type?.code === PARTNER_TYPE.INDIVIDUAL;
    setIsSinglePartner(isSingle);
  };

  return (
    <Card
      className="mb-md"
      title={
        <Row justify="space-between">
          <Typography.Text strong>Thông tin đối tác</Typography.Text>
          <ExportButton
            showTooltip={false}
            loading={isExportLoading}
            onExport={() => getExportPartner(id)}
          />
        </Row>
      }
    >
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            label="Loại đối tác"
            name="masterdataId"
            rules={[{ required: true, message: 'Chọn loại đối tác!' }]}
          >
            <ArtistTypeSelect
              onChange={onChangePartnerType}
              disabled={isUpdatePartner && partner?.masterData}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Row>
            <Col>
              <Space>
                <Partnership style={{ fontSize: 60 }} />
                <Tag color={isSinglePartner ? 'cyan' : 'blue'}>
                  Nhập thông tin đối tác{' '}
                  {isSinglePartner ? 'cá nhân' : 'công ty'}
                </Tag>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <PartnerContentInfo
            isUpdatePartner={isUpdatePartner}
            form={form}
            isSinglePartner={isSinglePartner}
          />
        </Col>
        {isUpdatePartner && (
          <Col span={24}>
            <DeputyInfo
              isUpdatePartner={isUpdatePartner}
              form={form}
              isSinglePartner={isSinglePartner}
            />
            <DeputyAuthorization />
          </Col>
        )}

        <Col span={24}>
          <Row justify="end">
            <Button type="primary" onClick={onSave}>
              {isUpdatePartner ? 'Lưu' : 'Tạo'}
            </Button>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default PartnerInfo;
