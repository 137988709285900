import { EditOutlined } from '@ant-design/icons';
import { Space, Tag, Tooltip, Typography } from 'antd';
import { DIGITAL_VALUE, TIKTOK_VALUE } from 'appConstants';
import TimeMail from 'components/Icons/TimeMail';
import 'moment/locale/vi';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/date';
import SongReleaseDeleteButton from './components/SongReleaseDeleteButton';

export const columns = [
  {
    title: 'Tên bài hát phát hành',
    dataIndex: 'songName',
    width: 200,
    fixed: 'left',
    render: (songName, { id, isSentMail, sentMailDate }) => {
      return (
        <Space direction="vertical">
          <Link to={`/chi-tiet-phat-hanh/${id}`}>
            <Typography.Text strong>
              {songName} <EditOutlined />
            </Typography.Text>
          </Link>
          {isSentMail && sentMailDate && (
            <Tooltip title="Đã gửi mail">
              <Tag
                color={'cyan'}
                style={{ height: 28, display: 'flex', alignItems: 'center' }}
                icon={<TimeMail style={{ fontSize: 20 }} />}
              >
                {formatDateTime(sentMailDate)}
              </Tag>
            </Tooltip>
          )}
        </Space>
      );
    }
  },
  {
    title: 'Bài hát',
    dataIndex: 'song',
    width: 200,
    render: (song, { id }) => (
      <Typography.Text strong>{song?.songName}</Typography.Text>
    )
  },
  {
    title: 'Nghệ sĩ',
    dataIndex: 'partnerSong',
    width: 200,
    render: (_, { singer, musician }) => (
      <Space direction="vertical">
        {singer && (
          <Typography.Text>
            Trình bày: <Typography.Text strong>{singer}</Typography.Text>
          </Typography.Text>
        )}
        {musician && (
          <Typography.Text>
            Sáng tác: <Typography.Text strong>{musician}</Typography.Text>
          </Typography.Text>
        )}
      </Space>
    )
  },
  {
    title: 'Thông tin hợp đồng',
    dataIndex: 'appendix',
    render: appendix => {
      if (appendix) {
        const { index01, index02, appendixCode01 } = appendix;
        const arrIndex01 = [...index01];
        const isOldAppendix = arrIndex01.length > 1 && arrIndex01[0] === '0';
        const appendixCode = isOldAppendix
          ? `${appendixCode01}/${index02}`
          : `${appendixCode01}/0.2.${index02}`;
        return <Typography.Text>{appendixCode}</Typography.Text>;
      }
    },
    defaultHidden: true
  },
  {
    title: 'Nhạc số',
    dataIndex: 'digitalMusic',
    render: digitalMusic => (
      <Typography.Text>{DIGITAL_VALUE[digitalMusic]}</Typography.Text>
    )
  },
  {
    title: 'Tiktok',
    dataIndex: 'tiktok',
    render: tiktok => <Typography.Text>{TIKTOK_VALUE[tiktok]}</Typography.Text>
  },
  {
    title: 'Thời gian phát hành',
    dataIndex: 'releaseDate',
    render: (releaseDate, { releaseTime }) => (
      <Typography.Text>
        {releaseTime} - {formatDate(releaseDate)}
      </Typography.Text>
    ),
    defaultHidden: true
  },
  {
    title: 'Kênh',
    dataIndex: 'channelType',
    render: (channelType, { channel, publisher }) => (
      <Typography.Text>
        {channelType === 'internal' ? publisher.name : channel}
      </Typography.Text>
    )
  },
  {
    title: 'Thao tác',
    key: 'actions',
    dataIndex: 'actions',
    render: (_, item) => <SongReleaseDeleteButton item={item} />,
    align: 'center',
    width: 100
  }
];
