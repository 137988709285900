import { Row } from 'antd';
import Button from 'components/Button';
import React, { useCallback, useMemo } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';

const SendInvitation = () => {
  const { addendum, contractUseInAddendumDetail, sendReviewInvitation } =
    useAddendumDetail(
      useCallback(
        ({ addendum, contractUseInAddendumDetail, sendReviewInvitation }) => ({
          addendum,
          contractUseInAddendumDetail,
          sendReviewInvitation
        }),
        []
      ),
      shallow
    );

  const condition = useMemo(
    () =>
      !!addendum?.contractId &&
      (((!!addendum?.index01 || addendum?.index01 === 0) &&
        (!!addendum?.index02 || addendum?.index02 === 0)) ||
        !!addendum?.index03 ||
        addendum?.index03 === 0) &&
      !!addendum?.startDate &&
      addendum?.status === 'compose' &&
      !!contractUseInAddendumDetail?.revenueSharing.length &&
      (addendum?.canAddPayment
        ? addendum?.payment?.length > 0 ||
          (addendum?.index01 === '0' &&
            addendum?.index02 === '0' &&
            addendum?.songAppendix?.length > 0)
        : addendum?.songAppendix?.length > 0),
    [
      addendum?.contractId,
      addendum?.canAddPayment,
      addendum?.index01,
      addendum?.index02,
      addendum?.index03,
      addendum?.startDate,
      addendum?.payment?.length,
      addendum?.songAppendix?.length,
      addendum?.status,
      contractUseInAddendumDetail
    ]
  );

  return (
    <Row style={{ marginTop: 16 }}>
      <Button
        onClick={() => sendReviewInvitation({ id: addendum.id })}
        block
        disabled={!condition}
      >
        Gửi lời mời review thông tin phụ luc
      </Button>
    </Row>
  );
};

export default SendInvitation;
