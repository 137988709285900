import AutoComplete from 'components/AutoComplete/AutoComplete';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';

const UserAutoComplete = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { searchForUsers } = useUser(
    useCallback(
      ({ searchForUsers }) => ({
        searchForUsers
      }),
      []
    ),
    shallow
  );

  const form = useContext(FilterBoxFormContext);

  useEffect(() => {
    (async () => {
      const params = form.getFieldsValue();
      if (params.value) {
        const data = await searchForUsers(params.value);
        setOptions(
          data.map(({ id, fullName }) => ({
            value: id,
            label: fullName
          }))
        );
      }
    })();
  }, [form, searchForUsers]);

  return (
    <AutoComplete
      placeholder="Gõ để tìm nhân viên"
      handleSearch={searchForUsers}
      defaultOption={options}
      labelKey="fullName"
      valueKey="id"
      {...props}
    />
  );
};

export default UserAutoComplete;
