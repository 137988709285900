import moment from 'moment';
import 'moment/locale/vi';

import { EditOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

import { MASTER_DATA_GROUP_LABEL } from 'appConstants';
import MasterDataFormModal from './components/MasterDataFormModal';

export const columns = [
  {
    title: 'Code',
    dataIndex: 'code',
    render: (code, { id }) => (
      <Space direction="vertical">
        <MasterDataFormModal masterDataId={id}>
          <Typography.Text strong style={{ cursor: 'pointer' }}>
            {code} <EditOutlined />
          </Typography.Text>
        </MasterDataFormModal>
      </Space>
    )
  },
  {
    title: 'Tên',
    dataIndex: 'title',
    render: title => <Typography.Text>{title}</Typography.Text>
  },
  {
    title: 'Nhóm',
    dataIndex: 'group',
    render: group => (
      <Typography.Text>{MASTER_DATA_GROUP_LABEL[group]}</Typography.Text>
    )
  },
  // {
  //   title: 'Màu sắc',
  //   dataIndex: 'color',
  //   render: color => color && <Tag color={color}>{color}</Tag>
  // },
  {
    title: 'Chú thích',
    dataIndex: 'description',
    render: description => <Typography.Text>{description}</Typography.Text>
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(createDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
      </Space>
    )
    // defaultHidden: true
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    render: updateDate => (
      <Space direction="vertical">
        <Typography.Text>
          {updateDate && moment(updateDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>
          {updateDate && moment(updateDate).fromNow()}
        </Typography.Text>
      </Space>
    ),
    defaultHidden: true
  }
];
