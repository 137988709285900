import { Form, Modal } from 'antd';
import React, { useState } from 'react';
import ImportSongFormModalContent from './ImportSongCodeFormModalContent';

const ImportSongCodeFormModal = ({ children, uploadSuccess = f => f }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  const handleImportSuccess = async () => {
    try {
      uploadSuccess();
      setIsModalVisible(false);
    } catch (error) {}
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        destroyOnClose
        title="Import cập nhật lại mã NamViet của bài hát"
        visible={isModalVisible}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ImportSongFormModalContent
          form={form}
          onImportSuccess={handleImportSuccess}
        />
      </Modal>
    </>
  );
};

export default ImportSongCodeFormModal;
