import React, { useCallback, useEffect, useState } from 'react';

import Table from 'components/Table';

import shallow from 'zustand/shallow';
import ContractMainContent from 'pages/Contract/ContractMainContent';
import { appendixColumns, columns } from 'pages/Contract/columns';

import { Card } from 'antd';
import { usePartnerContracts } from 'stores/usePartnerContracts';
import PartnerContractFilterBox from './PartnerContractFilterBox';

const PartnerContracts = ({ partnerId }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    contracts,
    getContracts,
    filter,
    totalPage,
    page,
    setPage,
    deleteContracts,
    total
  } = usePartnerContracts(
    useCallback(
      ({
        contracts,
        getContracts,
        filter,
        totalPage,
        page,
        setPage,
        deleteContracts,
        total
      }) => ({
        contracts,
        getContracts,
        filter,
        totalPage,
        page,
        setPage,
        deleteContracts,
        total
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getContracts({}, partnerId);
  }, [getContracts, page, filter, partnerId]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };
  const onPaginate = page => setPage(page);

  const expandedRow = row => {
    let inTable = row?.appendix;
    const appendixData = inTable.map(data => ({
      ...data,
      key: data.id,
      contractCode: row?.contractCode,
      contractId: row?.id
    }));

    return (
      <Table
        columns={appendixColumns}
        dataSource={appendixData}
        pagination={false}
        paginationProps={false}
      />
    );
  };

  const rowExpandable = record => record.appendix.length > 0;

  return (
    <Card title="Hợp đồng đối tác">
      <ContractMainContent
        selectedRows={selectedRows}
        rowSelection={rowSelection}
        contracts={contracts}
        totalPage={totalPage}
        deleteContracts={deleteContracts}
        page={page}
        columns={columns}
        onPaginate={onPaginate}
        expandedRow={expandedRow}
        contractFilterBox={PartnerContractFilterBox}
        //NOTE: flag for partnerDetailPage
        partnerId={partnerId}
        rowExpandable={rowExpandable}
        total={total}
        showExport={false}
      />
    </Card>
  );
};

export default PartnerContracts;
