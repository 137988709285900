import { DatePicker, Form, Input } from 'antd';
import { EDITABLE_CELL_TYPE, MONTH_FORMAT } from 'appConstants';
import CurrencyInputNumber from 'components/CurrencyInputNumber';
import React from 'react';

const StatisticCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  editable,
  children,
  required,
  labelValid,
  ...restProps
}) => {
  const inputNode =
    editable === EDITABLE_CELL_TYPE.CURRENCY ? (
      <CurrencyInputNumber placeholder={labelValid || title} />
    ) : editable === EDITABLE_CELL_TYPE.DATEPICKER ? (
      <DatePicker
        picker="month"
        format={MONTH_FORMAT}
        allowClear={false}
        placeholder={labelValid || title}
      />
    ) : (
      <Input placeholder={labelValid || title} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: required,
              message: `Nhập ${labelValid || title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default StatisticCell;
