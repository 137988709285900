import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useReupBackground } from 'stores/useReup/useReupBackground';

const ReupBackgroundAutoComplete = ({ reupBackground, ...props }) => {
  const { searchForReupBackground } = useReupBackground(
    useCallback(
      ({ searchForReupBackground }) => ({
        searchForReupBackground
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      reupBackground
        ? { value: reupBackground.id, label: reupBackground.title }
        : null,
    [reupBackground]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm background"
      handleSearch={searchForReupBackground}
      labelKey="title"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ReupBackgroundAutoComplete;
