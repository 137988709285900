import { Form, Row, Space, Table } from 'antd';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE } from 'appConstants';
import OrderBySelect from 'components/OrderBySelect';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { usePublisherStatistic } from 'stores/usePublisherStatistic';
import shallow from 'zustand/shallow';
import { columns } from '../columns';
import AddStatisticButton from './AddStatisticButton';
import PublisherInfo from './PublisherInfo';
import StatisticCell from './StatisticCell';

const StatisticEditableContext = React.createContext(null);

const Statistic = () => {
  const [form] = Form.useForm();

  const { id } = useParams();
  const {
    getPublisher,
    publisher,
    getPublisherStatistics,
    publisherStatistics,
    setParams,
    total,
    editingKey,
    setEditingKey,
    tableData,
    setTableData,
    isStatisticLoading
  } = usePublisherStatistic(
    useCallback(
      ({
        getPublisher,
        publisher,
        getPublisherStatistics,
        publisherStatistics,
        setParams,
        total,
        editingKey,
        setEditingKey,
        tableData,
        setTableData,
        isStatisticLoading
      }) => ({
        getPublisher,
        publisher,
        getPublisherStatistics,
        publisherStatistics,
        setParams,
        total,
        editingKey,
        setEditingKey,
        tableData,
        setTableData,
        isStatisticLoading
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const onPaginate = page => {
    history.push({
      pathname: `/thong-ke-kenh-phat-hanh/${id}`,
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: `/thong-ke-kenh-phat-hanh/${id}`,
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    const paramsForGet = { ...searchParams, page: page - 1, sort };

    setParams(paramsForGet);
    getPublisher(id);
    getPublisherStatistics({ id, ...paramsForGet });
  }, [
    getPublisherStatistics,
    getPublisher,
    id,
    page,
    searchParams,
    sort,
    setParams
  ]);

  useEffect(() => {
    if (publisherStatistics) {
      const newData = publisherStatistics.map(_item => ({
        ..._item,
        key: _item.id
      }));
      setTableData(newData);
    }
    return () => {
      setEditingKey('');
    };
  }, [publisherStatistics, setEditingKey, setTableData]);
  const isEditing = record => record.key === editingKey;
  const isAdding = useMemo(
    () => tableData.some(_d => _d.key === 0),
    [tableData]
  );

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        required: col.required,
        labelValid: col.labelValid
      })
    };
  });
  return (
    <Form form={form} component={false}>
      <StatisticEditableContext.Provider value={form}>
        <Row justify="space-between" className="mb-md">
          <PublisherInfo publisher={publisher} />
          <Space>
            <OrderBySelect defaultValue={sort} onChange={setOrderBy} />
            <AddStatisticButton />
          </Space>
        </Row>
        <Table
          components={{
            body: {
              cell: StatisticCell
            }
          }}
          loading={isStatisticLoading}
          scroll={{ x: 1500 }}
          dataSource={tableData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            total: total,
            pageSize: isAdding ? PAGE_SIZE + 1 : PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
        />
      </StatisticEditableContext.Provider>
    </Form>
  );
};

export { StatisticEditableContext };
export default Statistic;
