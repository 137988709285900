import { Form, Modal, Popover, Space } from 'antd';
import Button from 'components/Button';
import { Filter } from 'components/Icons';
import Tag from 'components/Tag';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formatDate } from 'utils/date';
import FilterBoxForm from './FilterBoxForm';

const NaviFilterBox = ({
  options = [],
  setFilter = f => f,
  defaultFixedTag,
  initValue = [],
  childComponent,
  getExtendOptions
}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [currItem, setCurrItem] = useState(null);

  const [selectedValue, setSelectedValue] = useState([]);

  const { search } = useLocation();
  useEffect(() => {
    (async () => {
      const res = await initValue;
      if (!!res.length) {
        setSelectedValue(res);
      }
    })();
  }, [initValue]);

  useEffect(() => {
    if (!search) {
      setSelectedValue([]);
    }
    return () => {
      setSelectedValue([]);
    };
  }, [search]);

  const handleOk = async () => {
    const params = await form.validateFields();
    const { value, operator, operatorFrom, operatorTo } = params;

    let displayTag = `${currItem.formTitle}: `;

    if (Array.isArray(value) && value[0]?.isValid && value[0].isValid()) {
      displayTag = displayTag
        .concat(formatDate(value[0]))
        .concat('-')
        .concat(formatDate(value[1]));
    } else if (value?.isValid && value.isValid()) {
      displayTag = displayTag.concat(formatDate(value));
    } else {
      if (Array.isArray(value)) {
        // handle for multi select
        const isExtendOptionObj =
          typeof getExtendOptions === 'object' && getExtendOptions !== null;
        const funcGetValue = isExtendOptionObj
          ? getExtendOptions[currItem.name]
          : getExtendOptions;

        const extendOptions = await funcGetValue({ partnerIds: value });
        const label = extendOptions
          .map(({ artistName, fullname }) => artistName || fullname)
          .join(', ');

        displayTag = displayTag.concat(`${label || value}`);
      } else {
        if (currItem?.isUsingChild) {
          // Filter label for autocomplete - single value
          const isExtendOptionObj =
            typeof getExtendOptions === 'object' && getExtendOptions !== null;
          const funcGetValue = isExtendOptionObj
            ? getExtendOptions[currItem.name]
            : getExtendOptions;
          const extendOptions = await funcGetValue(value);

          const label = extendOptions?.code;
          displayTag = displayTag.concat(`${label || value}`);
        } else {
          const label = currItem?.options?.find(
            ({ value: _value }) => _value === value
          )?.label;

          displayTag = displayTag.concat(`${label || value}`);
        }
      }
    }

    setSelectedValue(prev => {
      const newArr = [...prev];
      const foundIndex = newArr.findIndex(
        ({ name }) => name && name === currItem.name
      );
      if (foundIndex === -1) {
        return [
          ...newArr,
          {
            tag: displayTag,
            name: currItem.name,
            value,
            operator,
            operatorFrom,
            operatorTo
          }
        ];
      }

      const newItem = {
        ...newArr[foundIndex],
        tag: displayTag,
        value,
        operator,
        operatorFrom,
        operatorTo
      };
      newArr[foundIndex] = newItem;

      return newArr;
    });

    setFilter(
      currItem.name,
      { value, operator, operatorFrom, operatorTo },
      currItem.otherType
    );
    setIsModalVisible(false);
  };

  const handleClickType = async item => {
    setIsPopoverVisible(false);
    setCurrItem(item);
    setIsModalVisible(true);

    const newArr = [...selectedValue];
    const foundIndex = newArr.findIndex(({ name }) => name === item.name);

    if (foundIndex !== -1) {
      form.setFieldsValue({
        value: selectedValue[foundIndex].value,
        operator: selectedValue[foundIndex].operator,
        operatorFrom: selectedValue[foundIndex].operatorFrom,
        operatorTo: selectedValue[foundIndex].operatorTo
      });
    }
  };

  const content = (
    <Space direction="vertical" style={{ padding: '8px 16px' }}>
      {options[0]?.filterItems?.map((item, index) => (
        <div
          onClick={() => handleClickType(item)}
          key={index}
          style={{ cursor: 'pointer' }}
        >
          {item.formTitle}
        </div>
      ))}
    </Space>
  );

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleVisibleChange = visible => {
    setIsPopoverVisible(visible);
  };

  const onCloseTag = name => {
    setSelectedValue(prev => prev.filter(item => item.name !== name));
    return setFilter(name, {});
  };
  return (
    <div className="mb-md">
      <Space size="middle">
        <Popover
          content={content}
          title={`Bộ lọc ${options[0].mainTitle.toLowerCase()}`}
          trigger="click"
          visible={isPopoverVisible}
          onVisibleChange={handleVisibleChange}
          overlayClassName="filter-box"
        >
          <Button type="default" icon={<Filter />}>
            Bộ lọc
          </Button>
        </Popover>

        <div>
          {defaultFixedTag && <Tag>{defaultFixedTag}</Tag>}

          {selectedValue.map(({ tag, name }) => (
            <Tag closable key={name} onClose={() => onCloseTag(name)}>
              {tag}
            </Tag>
          ))}
        </div>

        <Modal
          title={currItem?.formTitle}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText="Huỷ"
          destroyOnClose
        >
          <FilterBoxForm
            form={form}
            currItem={currItem}
            setCurrItem={setCurrItem}
            childComponent={childComponent}
            handleOk={handleOk}
          />
        </Modal>
      </Space>
    </div>
  );
};

export default NaviFilterBox;
