import { Card } from 'antd';
import Button from 'components/Button';
import PlaylistSongAutoComplete from 'pages/PlaylistDetail/components/SongPlaylistAutoComplete';
import React, { useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useSong } from 'stores/useSong';
import { reorder } from 'utils/other';
import shallow from 'zustand/shallow';
import SongMedleyDetail from './SongMedleyDetail';
import { SyncOutlined } from '@ant-design/icons';

const SongMedley = ({ songId, onUpdateArtist }) => {
  const { songOfMedley, setSongOfMedley } = useSong(
    useCallback(
      ({ songOfMedley, setSongOfMedley }) => ({
        songOfMedley,
        setSongOfMedley
      }),
      []
    ),
    shallow
  );

  const onSelect = ({ value, label, songType, songName, singer, musician }) => {
    setSongOfMedley([
      ...songOfMedley,
      {
        songId: value,
        song: { songName, songType: songType, singer, musician }
      }
    ]);
  };
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newSongs = reorder(
      songOfMedley,
      result.source.index,
      result.destination.index
    );

    setSongOfMedley(newSongs);
  };
  const onDelete = id => {
    setSongOfMedley(songOfMedley.filter(song => song.songId !== id));
  };

  return (
    <div>
      <Card
        title="Danh sách bài hát"
        // extra={`${songOfMedley?.length} bài hát`}
        extra={
          <Button type="link" onClick={onUpdateArtist} icon={<SyncOutlined />}>
            Cập nhật người trình bày, sáng tác dựa trên DS bài hát
          </Button>
        }
        headStyle={{ background: '#80808017' }}
        className="mb-md"
      >
        <div className="mb-md">
          <PlaylistSongAutoComplete
            onSelect={onSelect}
            currentSongs={songOfMedley}
          />
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="child-songs"
              >
                <SongMedleyDetail songs={songOfMedley} onDelete={onDelete} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    </div>
  );
};

export default SongMedley;
