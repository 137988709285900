import React, { useCallback, useEffect } from 'react';
import shallow from 'zustand/shallow';

import { Form, Input as AntdInput } from 'antd';
import Input from 'components/Input';

import { useUser } from 'stores/useUser';
import { useSysRole } from 'stores/useSysRole';
import Select from 'components/Select';

import DepartmentAutoComplete from '../DepartmentAutoComplete';

const UserFormModalContent = ({ form, userId, isUpdateUser }) => {
  const { getUser, resetUser, user } = useUser(
    useCallback(
      ({ getUser, resetUser, user }) => ({
        getUser,
        resetUser,
        user
      }),
      []
    ),
    shallow
  );

  const { getSysRole, sysRole } = useSysRole(
    useCallback(
      ({ getSysRole, sysRole }) => ({
        getSysRole,
        sysRole
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (isUpdateUser) {
      getUser(userId);
    }
  }, [isUpdateUser, getUser, userId]);

  useEffect(() => {
    getSysRole();
  }, [getSysRole]);

  useEffect(() => {
    if (isUpdateUser && user) {
      const {
        username,
        password,
        fullName,
        phone,
        email,
        sysRoleId,
        departmentId
      } = user;
      form.setFieldsValue({
        username,
        password,
        fullName,
        phone,
        email,
        sysRoleId,
        departmentId
      });
    }
  }, [isUpdateUser, user, form]);

  useEffect(
    () => () => {
      form.resetFields();
      if (user) {
        resetUser();
      }
    },
    [form, resetUser, user]
  );

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="username"
        label="Tên đăng nhập"
        rules={[{ required: true, message: 'Nhập tên đăng nhập!' }]}
      >
        <Input autoComplete="new-password" />
      </Form.Item>

      {!isUpdateUser && (
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
        >
          <AntdInput.Password autoComplete="new-password" />
        </Form.Item>
      )}

      <Form.Item
        name="fullName"
        label="Họ tên"
        rules={[{ required: true, message: 'Nhập họ tên!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Số điện thoại"
        rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Nhập email!' },
          { type: 'email', message: 'Nhập email!' }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="sysRoleId"
        label="Vai trò"
        rules={[{ required: true, message: 'Chọn vai trò!' }]}
      >
        <Select
          options={sysRole.map(role => ({ value: role.id, label: role.title }))}
        />
      </Form.Item>

      <Form.Item
        name="departmentId"
        label="Phòng ban"
        rules={[{ required: true, message: 'Chọn phòng ban!' }]}
      >
        <DepartmentAutoComplete />
      </Form.Item>
    </Form>
  );
};

export default UserFormModalContent;
