import { ImportOutlined } from '@ant-design/icons';
import { Col, Collapse, notification, Row, Space, Tag, Typography } from 'antd';
import Button from 'components/Button';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useContract } from 'stores/useContract';

import { APPENDIX_PROCESS_VALUE, CONTRACT_TYPE_VALUE } from 'appConstants';
import shallow from 'zustand/shallow';
import ContractDetailLayout from '../ContractDetailLayout';
import FileCheckList from './components/FileCheckList';
import ImportSong from './components/ImportSong';

const { Panel } = Collapse;

const ProductInspection = () => {
  const [nextStatus, setNextStatus] = useState(null);
  const { id, appendixId } = useParams();
  const history = useHistory();

  const {
    getSongsAppendix,
    songs,
    updateAppendixStatus,
    getAppendix,
    appendixContract,
    contract
  } = useContract(
    useCallback(
      ({
        getSongsAppendix,
        songs,
        updateAppendixStatus,
        getAppendix,
        appendixContract,
        contract
      }) => ({
        getSongsAppendix,
        songs,
        updateAppendixStatus,
        getAppendix,
        appendixContract,
        contract
      }),
      []
    ),
    shallow
  );
  useEffect(() => {
    getSongsAppendix(appendixId);
    getAppendix(appendixId);
  }, [getSongsAppendix, appendixId, getAppendix]);

  useEffect(() => {
    if (appendixContract) {
      appendixContract.payment.length <= 0
        ? setNextStatus('complete')
        : setNextStatus('pay');
    }
  }, [appendixContract]);

  const handleNextState = () => {
    const condition = obj => obj.appendixDetail.fileCheckList.length > 0;
    const isContractBQTG = contract.contractType === CONTRACT_TYPE_VALUE.BQTG;

    // Check change status => complete
    if (appendixContract.payment.length <= 0) {
      const shouldNext = songs.every(condition) || isContractBQTG;
      return shouldNext
        ? updateAppendixStatus(
            { appendixId, status: 'complete' },
            history.push(`/chi-tiet-hop-dong/${id}/hoan-tat/${appendixId}`)
          )
        : notification.error({ message: 'Bổ sung thông tin bài hát' });
    }

    // Check change status => pay
    const shouldNext = songs.some(condition) || isContractBQTG;
    return shouldNext
      ? updateAppendixStatus({ appendixId, status: 'pay' }, () =>
          history.push(
            `/chi-tiet-hop-dong/${id}/thanh-toan-theo-dot/${appendixId}`
          )
        )
      : notification.error({ message: 'Bổ sung thông tin bài hát' });
  };

  return (
    <ContractDetailLayout step={APPENDIX_PROCESS_VALUE.CHECK}>
      <Row justify="end" className="mb-md">
        <Col>
          <ImportSong>
            <Button
              icon={<ImportOutlined />}
              style={{ background: 'green', border: 'none' }}
            >
              Import
            </Button>
          </ImportSong>
        </Col>
      </Row>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={['0', '1', '2', '3', '4', '5']}
        className="mb-md"
      >
        {songs.map((song, index) => (
          <Panel
            header={
              <Space>
                <Typography.Text>{song.songName}</Typography.Text>
                <Space size={0}>
                  <Tag color="gray">Bài hát</Tag>
                  {song?.doiSoatCode && (
                    <Typography.Text>#{song?.doiSoatCode}</Typography.Text>
                  )}
                </Space>
              </Space>
            }
            key={index}
          >
            <FileCheckList song={song} />
          </Panel>
        ))}
      </Collapse>
      <Row>
        <Col span="24">
          <Button block onClick={handleNextState}>
            {nextStatus === 'complete'
              ? 'Hoàn tất'
              : nextStatus === 'pay'
              ? 'Chuyển tiếp cho kế toán'
              : 'Lỗi trạng thái'}
          </Button>
        </Col>
      </Row>
    </ContractDetailLayout>
  );
};

export default ProductInspection;
