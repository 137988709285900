import { FILTER_TYPE, MEDIA_TYPE_VALUE_OPTIONS } from 'appConstants';
import FilterBox from 'components/FilterBox';
import { filterToUrl, genInitValueCustom } from 'components/FilterBox/utils';
import PublisherAutocompleteFilter from 'containers/PublisherAutocompleteFilter';
import SongAutocompleteFilter from 'containers/SongAutocompleteFilter';
import queryString from 'query-string';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useService } from 'stores/useService';
import shallow from 'zustand/shallow';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Link',
    name: 'Link'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Bài hát',
    name: 'SongId',
    placeHolder: 'Tìm bài hát',
    isUsingChild: true,
    label: 'songName',
    autocompleteComponent: <SongAutocompleteFilter placeHolder="Tìm bài hát" />
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Kênh phát hành',
    name: 'PublisherId',
    placeHolder: 'Kênh phát hành',
    isUsingChild: true,
    label: 'name',
    autocompleteComponent: <PublisherAutocompleteFilter />
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Loại link',
    name: 'MediaType',
    options: MEDIA_TYPE_VALUE_OPTIONS,
    otherType: true
  }
];

export const data = [
  {
    mainTitle: 'Bài hát phát hành',
    filterItems
  }
];

const SongPublicationFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  const { getSong, getPublisher } = useService(
    useCallback(
      ({ getSong, getPublisher }) => ({
        getSong,
        getPublisher
      }),
      []
    ),
    shallow
  );

  delete searchParams.page;
  delete searchParams.sort;

  const setFilter = (
    key,
    { value, operator, operatorFrom, operatorTo },
    otherType
  ) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo, otherType },
      searchParams,
      history,
      '/link-phat-hanh'
    );
  };

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      initValue={genInitValueCustom(searchParams, filterItems, {
        SongId: getSong,
        PublisherId: getPublisher
      })}
      getExtendOptions={{
        SongId: getSong,
        PublisherId: getPublisher
      }}
    />
  );
};

export default React.memo(SongPublicationFilterBox);
