import { CreditCardOutlined } from '@ant-design/icons';
import { Card, Divider, Empty, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import { formatPrice } from 'utils/currency';
import { Link } from 'react-router-dom';
import { redirectByStatus } from 'utils/redirect';

const { Text } = Typography;

const PayContract = () => {
  const { payContracts, getPayContracts } = useDashboard(
    useCallback(
      ({ payContracts, getPayContracts }) => ({
        payContracts,
        getPayContracts
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getPayContracts();
  }, [getPayContracts]);

  const data = useMemo(() => payContracts?.data, [payContracts?.data]);

  return (
    <Card
      title="Chờ thanh toán"
      extra={
        <Text strong type="success">
          {data?.length} {payContracts?.total && `/${payContracts?.total}`}
        </Text>
      }
      bodyStyle={{
        padding: 0,
        maxHeight: '330px',
        minHeight: '330px',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: !data?.length ? 'center' : 'initial'
      }}
      style={{ borderRadius: 8 }}
    >
      {!!data?.length ? (
        <Space direction="vertical" style={{ width: '100%' }}>
          {data?.map(item => (
            <div key={item.id}>
              <Space align="center" style={{ padding: 16 }}>
                <Space direction="vertical" size={0}>
                  <Link
                    to={redirectByStatus(item.status, item.contractId, item.id)}
                  >
                    <Typography.Text strong>
                      {`${item.appendixCode01}-01.${item.index01}/02.${item.index02}`}
                    </Typography.Text>
                  </Link>
                  <Text>
                    <CreditCardOutlined style={{ fontSize: 16 }} /> Đợt{' '}
                    {item.payment[0]?.ordinal} -{' '}
                    {formatPrice(item.payment[0]?.takeHomeMoney)}
                  </Text>
                </Space>
              </Space>
              <Divider style={{ margin: 0 }} />
            </div>
          ))}
        </Space>
      ) : (
        <Empty description={<Text>Không có dữ liệu</Text>} />
      )}
    </Card>
  );
};

export default PayContract;
