import { DownloadOutlined, MinusOutlined } from '@ant-design/icons';
import { Checkbox, Space, Typography } from 'antd';
import { CONTRACT_TYPE_VALUE, COPYRIGHT_SOURCE_VALUE } from 'appConstants';
import { Medley } from 'components/Icons';
import PaymentStatusTag from 'pages/ContractDetail/InstallmentPayment/components/PaymentStatusTag';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { useReviewDetail } from 'stores/useReviewDetail';
import { formatPrice } from 'utils/currency';
import { extractPartnersFromSong } from 'utils/other';
import shallow from 'zustand/shallow';

const { Text } = Typography;

const SongPartner = ({ partner, type }) => {
  const groupPartner = partner.filter(p => p.type === type && p.partner);
  const { appendixReview } = useReviewDetail(
    useCallback(
      ({ appendixReview }) => ({
        appendixReview
      }),
      []
    ),
    shallow
  );

  const isBQTG = useMemo(
    () => appendixReview?.contract?.contractType === CONTRACT_TYPE_VALUE.BQTG,
    [appendixReview]
  );

  return isBQTG && type === 'singer' ? (
    <MinusOutlined />
  ) : (
    <Space direction="vertical" align="center">
      {groupPartner.map(partner => (
        <Text strong key={partner.id}>
          {partner.partner.artistName || partner.partner.fullName}
        </Text>
      ))}
    </Space>
  );
};

export const paymentColumns = [
  {
    title: 'Đợt',
    dataIndex: 'ordinal',
    render: ordinal => <Text strong>Thanh toán đợt {ordinal}</Text>,
    align: 'center'
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'totalMoney',
    render: totalMoney => <Text>{formatPrice(totalMoney)}</Text>,
    align: 'center'
  },
  {
    title: 'Thuế suất',
    dataIndex: 'taxPercent',
    render: taxPercent => <Text>{taxPercent}%</Text>,
    align: 'center'
  },
  {
    title: 'Tiền thuế',
    dataIndex: 'taxMoney',
    render: taxMoney => <Text>{formatPrice(taxMoney)}</Text>,
    align: 'center'
  },
  {
    title: 'Tiền thực nhận',
    dataIndex: 'takeHomeMoney',
    render: takeHomeMoney => <Text>{formatPrice(takeHomeMoney)}</Text>,
    align: 'center'
  },
  {
    title: 'Tỉ lệ',
    dataIndex: 'percent',
    render: percent => <Text>{percent}%</Text>,
    align: 'center'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: status => <PaymentStatusTag value={status} />,
    align: 'center'
  },
  {
    title: 'Chứng từ thanh toán',
    dataIndex: 'file',
    render: (file, { paymentDate }) =>
      !!file?.length ? (
        <Space direction="vertical">
          <a href={file[0]?.fileLink} target="_blank" rel="noreferrer">
            <DownloadOutlined />
            {file[0]?.fileName}
          </a>
        </Space>
      ) : null
  }
];

export const paymentOfOldAppendixColumns = [
  {
    title: 'Đợt',
    dataIndex: 'ordinal',
    render: ordinal => <Text strong>Thanh toán đợt {ordinal}</Text>,
    align: 'center'
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'totalMoney',
    render: totalMoney => <Text>{formatPrice(totalMoney)}</Text>,
    align: 'center'
  },
  {
    title: 'Thuế suất',
    dataIndex: 'taxPercent',
    render: taxPercent => <Text>{taxPercent}%</Text>,
    align: 'center'
  },
  {
    title: 'Tỉ lệ',
    dataIndex: 'percent',
    render: percent => <Text>{percent}%</Text>,
    align: 'center'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: status => <PaymentStatusTag value={status} />,
    align: 'center'
  },
  {
    title: 'Chứng từ thanh toán',
    dataIndex: 'file',
    render: (file, { paymentDate }) =>
      !!file?.length ? (
        <Space direction="vertical">
          <a href={file[0]?.fileLink} target="_blank" rel="noreferrer">
            <DownloadOutlined />
            {file[0]?.fileName}
          </a>
        </Space>
      ) : null
  }
];

export const songColumns = [
  {
    title: 'STT',
    dataIndex: 'index',
    render: (_, __, index) => <Text strong>{index + 1}</Text>,
    align: 'center',
    width: '70px',
    fixed: 'left'
  },
  {
    title: 'Tác phẩm',
    dataIndex: 'songName',
    render: (_, __) => (
      <Space direction="vertical">
        {__?.doiSoatCode && (
          <Typography.Text>#{__?.doiSoatCode}</Typography.Text>
        )}
        <Space size={0}>
          {__?.isMedley && (
            <Medley style={{ fontSize: 24, color: '#1DD05D' }} />
          )}
          <Typography.Text strong>{__?.songName}</Typography.Text>
        </Space>
      </Space>
    ),
    fixed: 'left',
    width: '250px'
  },
  {
    title: 'Người trình bày',
    dataIndex: 'songName',
    render: (_, record) => (
      <SongPartner partner={record?.partnerSong} type="singer" />
    ),
    align: 'center',
    width: '150px'
  },
  {
    title: 'Tác giả',
    dataIndex: 'songName',
    render: (_, record) => (
      <SongPartner partner={record?.partnerSong} type="musician" />
    ),
    align: 'center',
    width: '150px'
  },
  {
    title: 'Nguồn QTG',
    dataIndex: 'copyRightSourceContract',
    width: '250px',
    render: (_, record) => {
      const { appendixDetail } = record;
      const code = record.code
        ? record.code
        : appendixDetail?.copyRightSourceType === COPYRIGHT_SOURCE_VALUE.VCPMC
        ? COPYRIGHT_SOURCE_VALUE.VCPMC
        : appendixDetail?.copyRightSourceContract?.contractCode;

      const isFromSinger =
        appendixDetail?.copyRightSourceType ===
        COPYRIGHT_SOURCE_VALUE.fromSinger;
      return (
        <Space direction="vertical">
          <Typography.Text>{code}</Typography.Text>
          {isFromSinger && !!appendixDetail?.copyRightConfirmFile?.length && (
            <a
              href={appendixDetail?.copyRightConfirmFile[0].fileLink}
              target="_blank"
              rel="noreferrer"
            >
              {appendixDetail?.copyRightConfirmFile[0].fileName}
            </a>
          )}
        </Space>
      );
    }
  },
  {
    title: 'QLQ',
    dataIndex: 'relatedRight',
    render: (_, record) => (
      <Checkbox disabled checked={record?.appendixDetail?.relatedRight} />
    ),
    align: 'center',
    width: '100px'
  },
  {
    title: 'QTG',
    dataIndex: 'copyRight',
    render: (_, record) => (
      <Checkbox disabled checked={record?.appendixDetail?.copyRight} />
    ),
    align: 'center',
    width: '100px'
  },
  {
    title: 'Audio',
    dataIndex: 'audio',
    render: (_, record) => (
      <Checkbox disabled checked={record?.appendixDetail?.audio} />
    ),
    width: '100px'
  },
  {
    title: 'Video',
    dataIndex: 'video',
    render: (_, record) => (
      <Checkbox disabled checked={record?.appendixDetail?.video} />
    ),
    width: '100px'
  }
];

const Singers = ({ partnerSong, song }) => {
  const { contractUseInAddendumDetail } = useAddendumDetail(
    useCallback(
      ({ contractUseInAddendumDetail }) => ({
        contractUseInAddendumDetail
      }),
      []
    ),
    shallow
  );

  const isBQTG = useMemo(
    () =>
      contractUseInAddendumDetail?.contractType === CONTRACT_TYPE_VALUE.BQTG,
    [contractUseInAddendumDetail]
  );

  // eslint-disable-next-line no-unused-vars
  const [_, singers] = extractPartnersFromSong(partnerSong, true);

  const RenderSinger = () => {
    return partnerSong ? (
      <Space direction="vertical">
        {singers.map(({ label, value }) => (
          <Typography.Text key={value}>{label}</Typography.Text>
        ))}
      </Space>
    ) : (
      <Typography.Text>{song?.singerName}</Typography.Text>
    );
  };

  return isBQTG ? <MinusOutlined /> : <RenderSinger />;
};

export const medleyColumns = [
  {
    title: 'Tên bài hát',
    dataIndex: 'songName',
    render: (_, { song: { songName, doiSoatNamVietCode } }) => (
      <Space direction="vertical">
        {doiSoatNamVietCode && (
          <Typography.Text>#{doiSoatNamVietCode}</Typography.Text>
        )}
        <Typography.Text>{songName}</Typography.Text>
      </Space>
    )
  },
  {
    title: 'Nguồn QTG',
    dataIndex: 'copyRightSourceContract',
    render: (_, record) => {
      const {
        copyRightSourceType,
        copyRightSourceContract,
        copyRightConfirmFile
      } = record;
      const code = record.code
        ? record.code
        : copyRightSourceType === COPYRIGHT_SOURCE_VALUE.VCPMC
        ? COPYRIGHT_SOURCE_VALUE.VCPMC
        : copyRightSourceContract?.contractCode;
      const isFromSinger =
        copyRightSourceType === COPYRIGHT_SOURCE_VALUE.fromSinger;
      return (
        <Space direction="vertical">
          <Typography.Text>{code}</Typography.Text>
          {isFromSinger && !!copyRightConfirmFile?.length && (
            <a
              href={copyRightConfirmFile[0].fileLink}
              target="_blank"
              rel="noreferrer"
            >
              {copyRightConfirmFile[0].fileName}
            </a>
          )}
        </Space>
      );
    }
  },
  {
    title: 'Người trình bày',
    dataIndex: 'partnerSong',
    render: (_, { song }) => (
      <Singers partnerSong={song?.partnerSong} song={song} />
    ),
    width: '20%'
  },
  {
    title: 'Tác giả',
    dataIndex: 'partnerSong',
    render: (_, { song }) => {
      if (song?.partnerSong) {
        // eslint-disable-next-line no-unused-vars
        const [musicians] = extractPartnersFromSong(song?.partnerSong, true);

        return (
          <Space direction="vertical">
            {musicians.map(({ label, value }) => (
              <Typography.Text
                style={{ width: 180 }}
                key={value}
                ellipsis={{ tooltip: label }}
              >
                {label}
              </Typography.Text>
            ))}
          </Space>
        );
      }

      return _?.musicianName;
    },
    width: '20%'
  },
  {
    title: 'Thể loại',
    dataIndex: 'songTypeName',
    render: (_, { song: { songTypeName } }) => (
      <Typography.Text>{songTypeName}</Typography.Text>
    ),
    width: '20%'
  }
];
