import React from 'react';
import { Tag } from 'antd';

const DeactiveTag = ({ deactive }) => {
  return (
    <Tag color={deactive ? 'error' : 'success'}>
      {deactive ? 'Đang bị khoá' : 'Đang hoạt động'}
    </Tag>
  );
};

export default DeactiveTag;
