import { FilterOutlined } from '@ant-design/icons';
import { Badge, Grid, Pagination, Popover, Row, Table, Tag } from 'antd';
import CheckboxGroup from 'components/CheckboxGroup';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useMemo, useState } from 'react';

const { useBreakpoint } = Grid;

const NaviTable = ({
  columns,
  dataSource,
  defaultPageSize,
  isFilterColumns,
  paginationProps,
  scroll,
  expandable,
  ...rest
}) => {
  const { xxl } = useBreakpoint();
  const [activeColumns, setActiveColumns] = useState([]);

  useEffect(() => {
    const _columns = xxl
      ? columns
      : columns.filter(_column => !_column?.defaultHidden);
    setActiveColumns(_columns);
  }, [columns, xxl]);

  const filterOptions = columns
    .filter(col => col.title && col.dataIndex)
    .map(col => ({
      label: col.title,
      value: col.dataIndex,
      hidden: xxl ? false : col?.defaultHidden
    }));

  const handleChangeFilter = values => {
    const newColumns = columns.filter(col => values.includes(col.dataIndex));
    setActiveColumns(newColumns);
  };

  const isColumnEdited = useMemo(
    () => activeColumns?.length < columns.length - 1,
    [activeColumns?.length, columns.length]
  );
  return (
    <div className="navi-table">
      <Table
        columns={activeColumns}
        dataSource={dataSource}
        pagination={false}
        scroll={isColumnEdited ? { x: 'auto' } : scroll}
        expandable={{ ...expandable, columnWidth: 24 }}
        {...rest}
      />
      {paginationProps && (
        <Row justify="space-between" className="mt-md">
          <Tag color="blue" style={{ display: 'flex', alignItems: 'center' }}>
            Tổng: {paginationProps.total}
          </Tag>
          <Pagination
            {...paginationProps}
            showSizeChanger={paginationProps.showSizeChanger || false}
          />
        </Row>
      )}

      {isFilterColumns && (
        <Popover
          className="icon-filter"
          content={
            <CheckboxGroup
              options={filterOptions}
              defaultValue={filterOptions
                .filter(_column => !_column.hidden)
                .map(option => option.value)}
              onChange={handleChangeFilter}
            />
          }
          title="Điều chỉnh cột hiển thị"
          trigger="click"
        >
          <Badge count={activeColumns?.length} size="small">
            <FilterOutlined style={{ fontSize: 18 }} />
          </Badge>
        </Popover>
      )}
    </div>
  );
};

NaviTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  defaultPageSize: PropTypes.number,
  isFilterColumns: PropTypes.bool
};

NaviTable.defaultProps = {
  isFilterColumns: true,
  paginationProps: {}
};

export default NaviTable;
