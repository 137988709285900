import { FILTER_TYPE } from 'appConstants';
import FilterBox from 'components/FilterBox';
import { filterToUrl, genInitValueCustom } from 'components/FilterBox/utils';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useProvider } from 'stores/useProvider';
import shallow from 'zustand/shallow';
import ProviderAutoComplete from './ProviderAutoComplete';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Code',
    name: 'Code'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Tên kênh',
    name: 'Name'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Nhà cung cấp nội dung',
    name: 'ProviderId',
    placeHolder: 'Nhà cung cấp nội dung',
    isUsingChild: true,
    label: 'name',
    autocompleteComponent: <ProviderAutoComplete />
  }
];

export const data = [
  {
    mainTitle: 'Kênh phát hành',
    filterItems
  }
];

const PublisherFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  const { getProvider } = useProvider(
    useCallback(
      ({ getProvider }) => ({
        getProvider
      }),
      []
    ),
    shallow
  );

  delete searchParams.page;
  delete searchParams.sort;

  const setFilter = (
    key,
    { value, operator, operatorFrom, operatorTo },
    otherType
  ) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo, otherType },
      searchParams,
      history,
      '/quan-ly-kenh/kenh-phat-hanh'
    );
  };

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      initValue={genInitValueCustom(searchParams, filterItems, {
        ProviderId: getProvider
      })}
      getExtendOptions={{
        ProviderId: getProvider
      }}
    />
  );
};

export default PublisherFilterBox;
