import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';

export const useReupTag = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* ******************* */
  //* NOTE: get reupTags  */
  //* ******************* */
  reupTags: [],
  totalPage: 1,
  total: 0,
  getReupTags: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get(`/reup/tag`, {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });

      if (data) {
        set({
          reupTags: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************** */
  //* NOTE: delete reupTags  */
  //* ********************** */

  deleteReupTags: async params => {
    try {
      const { data } = await API.delete('/reup/tag', { data: params });

      if (data) {
        get().getReupTags();

        return notification.success({
          message: 'Xoá reup tag thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************** */
  //* NOTE: create reupTags  */
  //* ********************** */

  createReupTag: async params => {
    try {
      const { data } = await API.post('/reup/tag', params);

      if (data) {
        await get().getReupTags();

        notification.success({
          message: 'Tạo reup tag thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* **************************** */
  //* NOTE: get and update reupTag */
  //* **************************** */

  updateReupTag: async params => {
    try {
      const { data } = await API.put(`/reup/tag/${params.id}`, params);

      if (data) {
        get().getReupTags();

        notification.success({
          message: 'Cập nhật reup tag thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForReupTag: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/reup/tag/search', {
        params: { limit: 5, group: 'musicType', freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
