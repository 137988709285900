import { ORDER_BY_SELECT_DICTIONARY } from 'appConstants.js';
import Select from 'components/Select';
import React, { useEffect, useState } from 'react';

const OrderBySelect = ({ onChange, defaultValue, ...rest }) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const options = Object.entries(ORDER_BY_SELECT_DICTIONARY).map(
    ([value, label]) => ({ value, label })
  );

  return (
    <Select
      placeHolder="Sắp xếp"
      options={options}
      style={{ width: 200 }}
      onChange={value => {
        setValue(value);
        onChange(value);
      }}
      defaultValue={defaultValue}
      value={value}
      {...rest}
    />
  );
};

export default React.memo(OrderBySelect);
