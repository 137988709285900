import { DownloadOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Row, Space, Spin, Tag, Typography } from 'antd';
import { CONTRACT_STATUS_VALUE } from 'appConstants';
import navims from 'assets/images/navims.png';
import Button from 'components/Button';
import ExportButton from 'components/ExportButton';
import { Signature } from 'components/Icons';
import ContracTypeTag from 'pages/AddendumDetail/components/ContracTypeTag';
import { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useContract } from 'stores/useContract';
import { formatDate } from 'utils/date';
import shallow from 'zustand/shallow';

const SummaryDetail = ({ step }) => {
  const history = useHistory();
  const {
    contract,
    isExportLoading,
    getExportContract,
    resignContract,
    isContractLoading,
    isLoadingResignContract
  } = useContract(
    useCallback(
      ({
        contract,
        isExportLoading,
        getExportContract,
        resignContract,
        isContractLoading,
        isLoadingResignContract
      }) => ({
        contract,
        isExportLoading,
        getExportContract,
        resignContract,
        isContractLoading,
        isLoadingResignContract
      }),
      []
    ),
    shallow
  );

  const shouldResigning = useMemo(
    () =>
      contract?.status === CONTRACT_STATUS_VALUE.AVAILABLE &&
      !contract?.oldContract &&
      !contract?.reSignContract &&
      !contract?.doiSoatProjectId &&
      !contract?.contractCode.includes('***'),
    [
      contract?.contractCode,
      contract?.doiSoatProjectId,
      contract?.oldContract,
      contract?.reSignContract,
      contract?.status
    ]
  );

  return (
    <Spin spinning={isContractLoading}>
      <div className="contract-detail-sidebar">
        <Space direction="vertical">
          <div style={{ textAlign: 'center' }}>
            <img src={navims} alt="navims" style={{ width: '50%' }} />
          </div>
          <Typography.Text strong style={{ overflowWrap: 'anywhere' }}>
            #{contract?.contractCode}
          </Typography.Text>
          <div className="item">
            <Typography.Text>Loại hợp đồng</Typography.Text>
            <ContracTypeTag value={contract?.contractType} />
          </div>
          <div className="item">
            <Typography.Text>Loại đối tác</Typography.Text>
            <Tag color="default">
              {contract?.partner?.masterData?.title
                ? contract?.partner.masterData.title
                : 'Chưa có thông tin'}
            </Tag>
          </div>
          <div className="item">
            <Typography.Text>Đối tác:</Typography.Text>
            <Typography.Text strong>
              {contract?.partner
                ? contract?.partner.artistName
                : 'Chưa có thông tin'}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Text>File hợp đồng:</Typography.Text>
            {contract?.file.length > 0 ? (
              <a
                href={contract?.file[0].fileLink}
                target="_blank"
                rel="noreferrer"
              >
                <Typography.Text
                  strong
                  style={{ width: '200px' }}
                  ellipsis={{ tooltip: contract?.file[0].fileName }}
                >
                  <DownloadOutlined />
                  {contract?.file[0].fileName}
                </Typography.Text>
              </a>
            ) : (
              <Typography.Text strong>Chưa có thông tin</Typography.Text>
            )}
          </div>
          <div className="item">
            <Typography.Text>Ngày bắt đầu hiệu lực:</Typography.Text>
            <Typography.Text strong>
              {contract?.startDate
                ? formatDate(contract?.startDate)
                : 'Chưa có thông tin'}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Text>Ngày kết thúc hiệu lực:</Typography.Text>
            <Typography.Text strong>
              {contract?.finishDate
                ? formatDate(contract?.finishDate)
                : 'Chưa có thông tin'}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Text>Hợp đồng liên quan:</Typography.Text>
            <Typography.Text strong>
              {contract?.childContracts
                ? `${contract?.childContracts.length} hợp đồng con`
                : 'Chưa có thông tin'}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Text>Người phụ trách:</Typography.Text>
            <Typography.Text strong>
              {contract?.user ? contract?.user?.fullName : 'Chưa có thông tin'}
            </Typography.Text>
          </div>
          {contract?.reSignContract && (
            <div className="item">
              <Typography.Text>Hợp đồng tái ký:</Typography.Text>
              <Link
                to={`/chi-tiet-hop-dong/${contract?.reSignContract?.id}/soan-hop-dong`}
              >
                <Typography.Text strong>
                  {contract?.reSignContract?.contractCode}
                </Typography.Text>
              </Link>
            </div>
          )}
          {contract?.oldContract && (
            <div className="item">
              <Typography.Text>Hợp đồng cũ:</Typography.Text>
              <Link
                to={`/chi-tiet-hop-dong/${contract?.oldContract?.id}/soan-hop-dong`}
              >
                <Typography.Text strong>
                  {contract?.oldContract?.contractCode}
                </Typography.Text>
              </Link>
            </div>
          )}

          {/* <AddendumStatusTag value={step} /> */}

          <Row justify="center" style={{ marginTop: '32px' }}>
            <Link to={`/lich-su-hop-dong/${contract?.id}`}>
              Xem lịch sử chỉnh sửa
            </Link>
          </Row>
          <Row justify="center" gutter={16}>
            <Col>
              <ExportButton
                loading={isExportLoading}
                onExport={() => getExportContract(contract?.id)}
                tooltip="Export chi tiết hợp đồng"
              />
            </Col>
            <Col>
              <Popconfirm
                title="Bạn muốn tái ký hợp đồng này?"
                onConfirm={() =>
                  resignContract(contract?.id, id =>
                    history.push(`/chi-tiet-hop-dong/${id}/soan-hop-dong`)
                  )
                }
                okText="Đồng ý"
                cancelText="Huỷ bỏ"
                disabled={!shouldResigning}
              >
                <Button
                  icon={<Signature style={{ fontSize: 16 }} />}
                  disabled={!shouldResigning}
                  loading={isLoadingResignContract}
                >
                  Tái ký
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Space>
      </div>
    </Spin>
  );
};

export default SummaryDetail;
