import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { COPYRIGHT_SOURCE_TYPE, COPYRIGHT_SOURCE_VALUE } from 'appConstants';
import Input from 'components/Input';
import Select from 'components/Select';
import Upload from 'components/Upload';
import ContractAutocomplete from 'containers/ContractAutocomplete';
import { useCallback } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';

const ResourceQTGFormModalContent = ({ form, song }) => {
  const { copyRightSourceType, copyRightSource, copyRightConfirmFile } = song;
  const { contractUseInAddendumDetail } = useAddendumDetail(
    useCallback(
      ({ contractUseInAddendumDetail }) => ({
        contractUseInAddendumDetail
      }),
      []
    ),
    shallow
  );

  const code =
    song.copyRightSourceType === COPYRIGHT_SOURCE_VALUE.VCPMC
      ? COPYRIGHT_SOURCE_VALUE.VCPMC
      : song?.copyRightSourceContract?.contractCode;

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangeSourceType = value => {
    const checkCode = () => {
      switch (value) {
        case COPYRIGHT_SOURCE_VALUE.VCPMC:
          form.setFieldsValue({
            copyRightSource: COPYRIGHT_SOURCE_VALUE.VCPMC
          });
          return COPYRIGHT_SOURCE_VALUE.VCPMC;
        case COPYRIGHT_SOURCE_VALUE.fromSinger:
          form.setFieldsValue({
            copyRightSource: null
          });
          return contractUseInAddendumDetail.contractCode;
        case COPYRIGHT_SOURCE_VALUE.BQTG:
          form.setFieldsValue({
            copyRightSource: null
          });
          break;
        default:
          break;
      }
    };

    form.setFieldsValue({ code: checkCode() });
  };

  const onChangeResource = (_, option) => {
    form.setFieldsValue({ code: option?.children });
  };

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="copyRightSourceType"
            label="Loại nguồn QTG"
            rules={[{ required: true, message: 'Chọn loại nguồn QTG!' }]}
            initialValue={copyRightSourceType}
          >
            <Select
              options={COPYRIGHT_SOURCE_TYPE}
              onChange={onChangeSourceType}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.copyRightSourceType !== curValues.copyRightSourceType
            }
          >
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name="copyRightSource"
                  label="Nguồn QTG"
                  initialValue={
                    copyRightSourceType === COPYRIGHT_SOURCE_VALUE.fromSinger
                      ? null
                      : copyRightSource
                  }
                >
                  <ContractAutocomplete
                    placeholder="Tìm nguồn QTG"
                    disabled={getFieldValue('copyRightSourceType') !== 'BQTG'}
                    onChange={onChangeResource}
                    defaultOption={
                      song?.copyRightSourceContract
                        ? {
                            value: song?.copyRightSourceContract?.id,
                            label: song?.copyRightSourceContract?.contractCode
                          }
                        : []
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            name="code"
            rules={[{ required: true, message: 'Mã hợp đồng!' }]}
            initialValue={code}
          >
            <Input placeholder="Mã hợp đồng" disabled />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.copyRightSourceType !== curValues.copyRightSourceType
            }
          >
            {({ getFieldValue }) => {
              const shouldDisable =
                getFieldValue('copyRightSourceType') !==
                COPYRIGHT_SOURCE_VALUE.fromSinger;
              return (
                <Form.Item
                  name="copyRightConfirmFile"
                  label="File quyền tác giả"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  initialValue={
                    copyRightConfirmFile &&
                    copyRightConfirmFile.map(f => ({
                      name: f.fileName,
                      url: f.fileLink
                    }))
                  }
                >
                  <Upload
                    showUploadList={{ showRemoveIcon: false }}
                    multiple
                    // className="navi-upload"
                    maxCount={1}
                    disabled={shouldDisable}
                    name="file"
                  >
                    <Button disabled={shouldDisable} icon={<UploadOutlined />}>
                      Tải lên
                    </Button>
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ResourceQTGFormModalContent;
