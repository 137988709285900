import { Col, Divider, Row, Space, Table, Typography } from 'antd';
import TextContent from 'components/TextContent';
import TotalAppendix from 'components/TotalAppendix';
import AddendumStatusTag from 'pages/AddendumDetail/components/AddendumStatusTag';
import React, { useMemo } from 'react';
import { formatPrice } from 'utils/currency';
import { get } from 'utils/lodash';
import { getAppendixCode } from 'utils/other';
import {
  medleyColumns,
  paymentColumns,
  paymentOfOldAppendixColumns,
  songColumns
} from './columns';

const { Text } = Typography;

const AppendixInfo = ({ appendix }) => {
  const payment = useMemo(
    () =>
      appendix?.payment
        ?.sort((a, b) => a.ordinal - b.ordinal)
        .map(p => ({ ...p, key: p.id })),
    [appendix]
  );

  const index02Details = useMemo(
    () => appendix?.index02Details?.map(song => ({ ...song, key: song.id })),
    [appendix]
  );

  const revenueSharing = get(appendix?.contract, 'revenueSharing', []);

  const newSongColumns = useMemo(
    () => [
      ...songColumns,
      ...revenueSharing.map(revenue => ({
        title: revenue.businessType.name,
        dataIndex: revenue.businessType.code,
        align: 'center',
        width: '120px',
        render: (_, record) => {
          const value = record?.appendixDetail?.songBusiness.find(
            business => business.businessType.code === revenue.businessType.code
          )?.value;
          return (
            <Typography.Text>
              {typeof value !== 'undefined' ? (value ? 'ĐQ' : 'KĐQ') : '-'}
            </Typography.Text>
          );
        }
      }))
    ],
    [revenueSharing]
  );

  return (
    <div>
      <Divider orientation="left">
        <Text style={{ color: 'green' }}>Thông tin phụ lục</Text>
      </Divider>
      <Row gutter={[32, 16]}>
        <Col span={8}>
          <Space direction="vertical" size={0}>
            <Text>Mã phụ lục:</Text>
            <Text strong>{getAppendixCode(appendix)}</Text>
          </Space>
        </Col>

        <Col span={8}>
          <Space direction="vertical" size={0}>
            <Text>Ngày bắt đầu hiệu lực:</Text>
            <TextContent value={appendix?.startDate} isDate />
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical" size={0}>
            <Text>Ngày kết thúc hiệu lực:</Text>
            <TextContent value={appendix?.finishDate} isDate />
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical" size={0}>
            <Text>Ngày dự kiến giao sản phẩm:</Text>
            <TextContent value={appendix?.expectedFileDeliveryDate} isDate />
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical" size={0}>
            <Text>Trạng thái phụ lục:</Text>
            <AddendumStatusTag value={appendix?.status} />
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical" size={0}>
            <Text>Đợt thanh toán:</Text>
            <Text strong>{appendix?.payment.length} đợt</Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical" size={0}>
            <Text>
              {appendix?.contract?.partner?.masterData?.code === 'organization'
                ? 'Tổng tiền thanh toán'
                : 'Tổng chi phí:'}
            </Text>
            <Text strong>{formatPrice(appendix?.totalMoney)}</Text>
          </Space>
        </Col>
        {!!payment?.length &&
          (appendix?.doiSoatId ? (
            <Col span={24}>
              <Typography.Text strong>
                {appendix.appendixCode01}
              </Typography.Text>
              <Table
                columns={paymentOfOldAppendixColumns}
                pagination={false}
                bordered
                dataSource={payment}
              />
            </Col>
          ) : (
            <Col span={24}>
              <Typography.Text strong>
                {appendix.appendixCode01}
              </Typography.Text>
              <Table
                columns={paymentColumns}
                pagination={false}
                bordered
                dataSource={payment}
                summary={pageData => (
                  <TotalAppendix pageData={pageData} align="center" />
                )}
              />
            </Col>
          ))}
        {!!index02Details?.length && (
          <Col span={24}>
            <Typography.Text strong>{appendix.appendixCode02}</Typography.Text>
            {newSongColumns && (
              <Table
                columns={newSongColumns}
                pagination={false}
                bordered
                dataSource={index02Details}
                scroll={{ x: 1500 }}
                expandable={{
                  expandedRowRender: record => (
                    <Table
                      columns={medleyColumns}
                      dataSource={record?.songMedley}
                      paginationProps={false}
                    />
                  ),
                  rowExpandable: record => !!record?.songMedley?.length
                }}
              />
            )}
          </Col>
        )}
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Space align="baseline">
                <Typography.Text>File phụ lục:</Typography.Text>
                {!!appendix?.file.length ? (
                  <Space direction="vertical">
                    {appendix?.file.map((file, index) => (
                      <a key={index} href={file?.fileLink}>
                        {file.fileName}
                      </a>
                    ))}
                  </Space>
                ) : (
                  <Typography.Text type="danger">Chưa có</Typography.Text>
                )}
              </Space>
            </Col>
            <Col span={12}>
              <Space align="baseline">
                <Typography.Text>Biên bản điều chỉnh phụ lục:</Typography.Text>
                {!!appendix?.adjustmentFile.length ? (
                  <Space direction="vertical">
                    {appendix?.adjustmentFile.map((file, index) => (
                      <a key={index} href={file?.fileLink}>
                        {file.fileName}
                      </a>
                    ))}
                  </Space>
                ) : (
                  <Typography.Text type="danger">Chưa có</Typography.Text>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AppendixInfo;
