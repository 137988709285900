import { UploadOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Row } from 'antd';

import Button from 'components/Button';
import Upload from 'components/Upload';

const DeputyAuthorization = () => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <>
      <Divider orientation="left">Uỷ quyền cho người đại diện/công ty</Divider>
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item
            name="delegacyFile"
            label="File giấy tờ uỷ quyền"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              showUploadList={{ showRemoveIcon: true }}
              multiple
              className="partner-avatar-upload"
            >
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DeputyAuthorization;
