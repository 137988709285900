import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useReupMusicType } from 'stores/useReup/useReupMusicType';

const ReupMusicTypeAutoComplete = ({ reupMusicType, ref, ...props }) => {
  const { searchForReupMusicType } = useReupMusicType(
    useCallback(
      ({ searchForReupMusicType }) => ({
        searchForReupMusicType
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      reupMusicType
        ? { value: reupMusicType.id, label: reupMusicType.type }
        : null,
    [reupMusicType]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm thể loại"
      handleSearch={searchForReupMusicType}
      labelKey="type"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ReupMusicTypeAutoComplete;
