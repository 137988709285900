import { DownloadOutlined } from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import { CONTRACT_STATUS_VALUE } from 'appConstants';
import moment from 'moment';
import { formatDate } from 'utils/date';
import { Signature } from 'components/Icons';

const { Text } = Typography;

const ContractStatus = ({ contract }) => {
  const { startDate, finishDate, status, liquidationFile, autoRenew } =
    contract;
  return (
    <Space direction="vertical">
      {status !== CONTRACT_STATUS_VALUE.LIQUIDATED ? (
        // Check expire || availabel or forever
        !finishDate ? (
          <Text type="success">Vĩnh viễn</Text>
        ) : moment(finishDate).diff(moment(), 'days') >= 0 ? (
          <Space direction="vertical">
            <Text type="success">Còn hạn</Text>
            {startDate && (
              <Space direction="vertical" size={0}>
                <Text>Từ ngày</Text>
                <Text strong>{formatDate(startDate)}</Text>
              </Space>
            )}

            <Space direction="vertical" size={0}>
              <Text>Đến ngày</Text>
              <Text strong>{formatDate(finishDate)}</Text>
            </Space>
          </Space>
        ) : (
          <Text type="danger">Hết hạn</Text>
        )
      ) : (
        // Liquidated
        <Space direction="vertical" style={{ width: '100%' }}>
          <Text type="danger">Đã thanh lý</Text>
          {liquidationFile.map((f, index) => (
            <a href={f?.fileLink} target="_blank" rel="noreferrer" key={index}>
              <DownloadOutlined />
              {f?.fileName}
            </a>
          ))}
        </Space>
      )}
      {autoRenew && (
        <Tag color="purple" icon={<Signature style={{ fontSize: 16 }} />}>
          Tự động ký lại
        </Tag>
      )}
    </Space>
  );
};

export default ContractStatus;
