import { notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateFileDownload } from 'utils/redirect';
import create from 'zustand';

export const usePartner = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  isPartnerLoading: false,
  isExportLoading: false,
  setIsPartnerLoading: isPartnerLoading => set({ isPartnerLoading }),
  //NOTE: get partners
  partners: [],
  totalPage: 1,
  total: 0,

  getPartners: async params => {
    try {
      get().setIsPartnerLoading(true);

      const {
        data: { data, total }
      } = await API.get('/partner', {
        params: {
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        }
      });

      get().setIsPartnerLoading(false);

      if (data) {
        set({
          partners: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      get().setIsPartnerLoading(false);

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getExportPartners: async () => {
    try {
      set({ isExportLoading: true });

      const data = await API.get('/excel/export-partner', {
        params: {
          PageSize: PAGE_SIZE,
          ...get().params
        }
      });

      if (data?.status === 204) {
        set({ isExportLoading: false });
        return notification.warning({
          message: 'Không có dữ liệu!'
        });
      }

      if (data?.data?.link) {
        generateFileDownload(data?.data?.link);
        set({ isExportLoading: false });
        return data;
      }

      set({ isExportLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getExportPartnerByIds: async ids => {
    try {
      set({ isExportLoading: true });
      const { data } = await API.post('/excel/export-partner/bulk', {
        ids
      });

      if (data) {
        generateFileDownload(data?.link);
        set({ isExportLoading: false });
        return data;
      }
      set({ isExportLoading: false });
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  //NOTE: delete partners
  deletePartners: async params => {
    try {
      const { data } = await API.delete('/partner', { data: params });

      if (data) {
        get().getPartners();

        return notification.success({
          message: 'Xoá đối tác thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getPartner: async id => {
    try {
      const { data } = await API.get(`/partner/${id}`);

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  // Search partner
  // False : Tất cả đối tác.
  // True: Chỉ đối tác có đầy đủ thông tin.
  searchForPartner: async (searchValue, hasFullInformation = false) => {
    try {
      const {
        data: { data }
      } = await API.get('/partner/search', {
        params: { limit: 5, freeText: searchValue, hasFullInformation }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Search partner with full information
  searchForPartnerFullInfo: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/partner/search', {
        params: { limit: 5, freeText: searchValue, hasFullInformation: true }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
