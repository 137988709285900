import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { EDITABLE_CELL_TYPE } from 'appConstants';

import Input from 'components/Input';
import Select from 'components/Select';

import { EditableContext } from './PublisherRow';

const PublisherCell = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  options,
  focusIfAdd,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const toggleEdit = () => {
    setEditing(!editing);

    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      if (editable === EDITABLE_CELL_TYPE.TEXT) {
        toggleEdit();
      }

      handleSave({ ...record, ...values });
    } catch ({ data }) {}
  };

  let childNode = children;

  switch (true) {
    case editable === EDITABLE_CELL_TYPE.TEXT && record?.add:
      childNode = (
        <Form.Item
          rules={[{ required: true, message: 'Vui lòng điền đầy đủ!' }]}
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          <Input ref={inputRef} />
        </Form.Item>
      );
      break;

    case editable === EDITABLE_CELL_TYPE.TEXT && editing:
      childNode = (
        <Form.Item
          rules={[{ required: true, message: 'Vui lòng điền đầy đủ!' }]}
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      );
      break;

    case editable === EDITABLE_CELL_TYPE.TEXT && !editing:
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}
        >
          {record[dataIndex] || <div style={{ color: 'transparent' }}>_</div>}
        </div>
      );
      break;

    case editable === EDITABLE_CELL_TYPE.SELECT && record?.add:
      childNode = (
        <Form.Item
          rules={[{ required: true, message: 'Vui lòng điền đầy đủ!' }]}
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          <Select ref={inputRef} options={options} />
        </Form.Item>
      );
      break;

    case editable === EDITABLE_CELL_TYPE.SELECT:
      childNode = (
        <Form.Item
          rules={[{ required: true, message: 'Vui lòng điền đầy đủ!' }]}
          style={{
            margin: 0
          }}
          name={dataIndex}
        >
          <Select ref={inputRef} options={options} onSelect={save} />
        </Form.Item>
      );
      break;

    default:
      childNode = children;
  }

  useEffect(() => {
    if (editing && editable === EDITABLE_CELL_TYPE.TEXT) {
      inputRef.current.focus();
    }
  }, [editing, editable]);

  useEffect(() => {
    if (focusIfAdd && record?.add) {
      inputRef.current.focus();
    }
  }, [record, focusIfAdd]);

  useEffect(() => {
    if (editable === EDITABLE_CELL_TYPE.SELECT) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      });
    }
  }, [editable, form, dataIndex, record, options]);

  return <td {...restProps}>{childNode}</td>;
};

export default PublisherCell;
