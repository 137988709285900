import { notification, Row, Tag, Typography } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { extractPartnersFromSong } from 'utils/other';
import create from 'zustand';
import { generateQueryParams } from 'components/FilterBox/utils';
import Investment from 'components/Icons/Investment';

export const useSongRelease = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  songReleases: [],
  songRelease: null,
  isSongReleaseLoading: false,
  songAppendixes: [],
  mailGroups: [],

  resetSongRelease: () => set({ songRelease: null }),
  // Func: Get list song release
  getSongReleases: async params => {
    set({ isSongReleaseLoading: true });
    try {
      const {
        data: { data, total }
      } = await API.get('/song-release', {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });
      set({ isSongReleaseLoading: false });

      if (data) {
        const dataParser = data.map(songRelease => {
          if (songRelease?.song) {
            const { song } = songRelease;
            const { partnerSong } = song;
            const [musicians, singers] = extractPartnersFromSong(
              partnerSong,
              true
            );

            const musician = musicians.map(({ label }) => label).join(', ');
            const singer = singers.map(({ label }) => label).join(', ');
            return { ...songRelease, singer, musician };
          }
          return songRelease;
        });
        set({
          total: total,
          songReleases: dataParser
          // totalPage: Math.ceil(total / PAGE_SIZE)
        });
      }
    } catch (data) {
      set({ isSongReleaseLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Func: Get song release detail
  getSongRelease: async id => {
    set({ isSongReleaseLoading: true });
    try {
      const { data } = await API.get(`/song-release/${id}`);
      set({ isSongReleaseLoading: false });
      if (data) {
        set({ songRelease: data.data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isSongReleaseLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Create song release
  createSongRelease: async (params, callback = f => f) => {
    set({ isSongReleaseLoading: true });
    try {
      const { data } = await API.post(
        `/song-release?sendMail=${params.isSendMail}`,
        params
      );
      set({ isSongReleaseLoading: false });
      if (data) {
        notification.success({
          message: 'Tạo phát hành thành công!'
        });
        callback(data.id);
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isSongReleaseLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Update song release
  updateSongRelease: async params => {
    set({ isSongReleaseLoading: true });
    try {
      const { data } = await API.put(
        `/song-release/${params.id}?sendMail=${params.isSendMail}`,
        params
      );
      set({ isSongReleaseLoading: false });
      if (data) {
        // get().getPlaylist(data.id);
        set(({ songRelease }) => ({
          songRelease: {
            ...songRelease,
            isSentMail: data?.isSentMail,
            sentMailDate: data?.sentMailDate
          }
        }));
        notification.success({
          message: 'Cập nhật phát hành thành công!'
        });
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isSongReleaseLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Delete song release
  deleteSongsRelease: async params => {
    set({ isSongReleaseLoading: true });
    try {
      const { data } = await API.delete(`/song-release`, { data: params });
      set({ isSongReleaseLoading: false });
      if (data) {
        get().getSongReleases();
        notification.success({
          message: 'Xoá phát hành thành công!'
        });
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isSongReleaseLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Get appendix by song id
  getAppendixForSong: async id => {
    try {
      const { data } = await API.get(`/song/${id}/appendix`);

      if (data) {
        const appendixes = data.data;
        const arrOptions = appendixes.map(appendix => {
          const { index01, index02, appendixCode01, isNamVietInvest } =
            appendix;
          const arrIndex01 = [...index01];
          const isOldAppendix = arrIndex01.length > 1 && arrIndex01[0] === '0';
          const appendixCode = isOldAppendix
            ? `${appendixCode01}/${index02}`
            : `${appendixCode01}/0.2.${index02}`;
          const _label = (
            <Row justify="space-between" align="middle">
              <Typography.Text>{appendixCode}</Typography.Text>
              {isNamVietInvest && (
                <Tag
                  color="purple"
                  icon={<Investment style={{ fontSize: 16 }} />}
                >
                  Nam Việt Đầu Tư
                </Tag>
              )}
            </Row>
          );
          return { label: _label, value: appendix.id };
        });
        set({ songAppendixes: arrOptions });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Func: Get list mail group
  getMailGroup: async () => {
    try {
      const { data } = await API.get(`/mail-group/search`);

      if (data) {
        set({ mailGroups: data.data });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Create mail group
  createMailGroup: async params => {
    try {
      const { data } = await API.post('/mail-group', params);

      if (data) {
        const newGroups = [
          ...get().mailGroups,
          { ...data, mails: params.mails }
        ];
        set({ mailGroups: newGroups });

        notification.success({
          message: 'Tạo danh sách mail thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Update mail group
  updateMailGroup: async params => {
    try {
      const { data } = await API.put(`/mail-group/${params.id}`, params);

      if (data) {
        await get().getMailGroup();

        notification.success({
          message: 'Tạo danh sách mail thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  currentSong: null,
  setCurrentSong: song => set({ currentSong: song }),

  // Search song for song release
  searchForAppendix: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/appendix/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Get song by id
  getSong: async songId => {
    try {
      const { data } = await API.get(`/song/${songId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Get appendix by id
  getAppendixById: async appendixId => {
    try {
      const { data } = await API.get(`/appendix/${appendixId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
