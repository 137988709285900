import Button from 'components/Button';
import React, { useCallback } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { DeleteOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';

const PaymentDeleteButton = ({ item }) => {
  const { disableEditAndAddPayment, prevDeletePayment } = useAddendumDetail(
    useCallback(
      ({ disableEditAndAddPayment, prevDeletePayment }) => ({
        disableEditAndAddPayment,
        prevDeletePayment
      }),
      []
    ),
    shallow
  );

  return (
    <Button
      type="danger"
      icon={<DeleteOutlined />}
      disabled={disableEditAndAddPayment}
      onClick={() => prevDeletePayment(item)}
    />
  );
};

export default PaymentDeleteButton;
