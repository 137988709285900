import { MinusOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import { useCallback } from 'react';
import { useSongPublication } from 'stores/useSongPublication';
import shallow from 'zustand/shallow';

const PublicationRevenueSharing = ({ form }) => {
  const { songPublication } = useSongPublication(
    useCallback(
      ({ songPublication }) => ({
        songPublication
      }),
      []
    ),
    shallow
  );
  return (
    <Descriptions
      column={2}
      bordered
      title="Hợp đồng liên quan"
      className="mb-md"
    >
      <Descriptions.Item label="Mã hợp đồng quyền tác giả">
        {songPublication?.copyRightSourceContract?.contractCode || (
          <MinusOutlined />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Tỉ lệ quyền tác giả">
        {songPublication?.copyRightRatio ? (
          `${songPublication?.copyRightRatio}%`
        ) : (
          <MinusOutlined />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Hợp đồng quyền liên quan">
        {songPublication?.relatedRightSourceContract?.contractCode || (
          <MinusOutlined />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Tỉ lệ quyền liên quan">
        {songPublication?.relatedRightRatio ? (
          `${songPublication?.relatedRightRatio}%`
        ) : (
          <MinusOutlined />
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};
export default PublicationRevenueSharing;
