import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import {
  DEFAULT_ERROR_MESSAGE,
  ORDER_BY_SELECT_VALUE,
  PAGE_SIZE
} from 'appConstants.js';
import { generateFilterParams } from 'utils/other';

export const useRole = create((set, get) => ({
  //* **************** */
  //* NOTE: get roles  */
  //* **************** */
  roles: [],
  totalPage: 1,
  page: 1,
  setPage: page => set({ page }),
  orderBy: ORDER_BY_SELECT_VALUE.LATEST,
  setOrderBy: orderBy => set({ orderBy }),

  //NOTE: Filter
  filter: {
    Name: {}
  },
  setFilter: (key, value) =>
    set({ filter: { ...get().filter, [key]: value }, page: 1 }),

  // TODO: refactor here
  resetFilter: () =>
    set({
      page: 1,
      filter: {
        Name: {}
      }
    }),

  getRoles: async params => {
    try {
      const roleNameFilter = get().filter.Name;

      const {
        data: { data, total }
      } = await API.get('/role', {
        params: {
          Page: get().page - 1,
          PageSize: PAGE_SIZE,
          Sort: get().orderBy,
          ...generateFilterParams([
            {
              name: 'Name',
              operator: roleNameFilter.operator,
              value: roleNameFilter.value
            }
          ]),
          // orderby: [get().orderBy],
          ...params
        }
      });

      if (data) {
        set({ roles: data, totalPage: Math.ceil(total / PAGE_SIZE) });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: delete roles  */
  //* ******************* */

  deleteRoles: async params => {
    try {
      const { data } = await API.delete('/role', { data: params });

      if (data) {
        get().getRoles();

        return notification.success({
          message: 'Xoá vai trò thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: create roles  */
  //* ******************* */

  createRole: async params => {
    try {
      const { data } = await API.post('/role', params);

      if (data) {
        await get().getRoles();

        notification.success({
          message: 'Tạo vai trò thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************* */
  //* NOTE: get and update song */
  //* ************************* */

  role: null,
  resetRole: () => set({ role: null }),
  fetchRole: async roleId => {
    try {
      const { data } = await API.get(`/role/${roleId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getRole: async roleId => {
    const data = await get().fetchRole(roleId);
    set({ role: data });
  },
  updateRole: async params => {
    try {
      const { data } = await API.put(`/role/${params.id}`, params);

      if (data) {
        set({
          role: data,
          roles: get().roles.map(item => (item.id === data.id ? data : item))
        });

        notification.success({
          message: 'Cập nhật vai trò thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
