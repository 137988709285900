import React, { useEffect, useState } from 'react';

import Select from 'components/Select';

import { SearchOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';
import { DEBOUNCE_VALUE } from 'appConstants';
import { debounce } from 'utils/lodash';

const AutoComplete = ({
  defaultOption,
  labelKey,
  valueKey,
  handleSearch,
  isParentContract,
  getLabel,
  disabledOptions = [],
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (Array.isArray(defaultOption) && defaultOption.length !== 0) {
      setOptions(defaultOption);
    }

    if (!Array.isArray(defaultOption) && defaultOption) {
      setOptions([defaultOption]);
    }
  }, [defaultOption]);

  const onSearch = async searchText => {
    setFetching(true);
    if (!searchText) {
      setFetching(false);
      return;
    }

    const results = await handleSearch(searchText, isParentContract);
    setFetching(false);
    if (!results) {
      return;
    }

    return setOptions(
      results.map(result => ({
        label: getLabel ? getLabel(result) : result[labelKey],
        value: result[valueKey],
        disabled: disabledOptions.includes(result[valueKey])
      }))
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  return (
    <Select
      filterOption={false}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : (
          <Empty description="Nhập để tìm kiếm gợi ý !" />
        )
      }
      showSearch
      onSearch={debouncedOnSearch}
      options={options}
      suffixIcon={<SearchOutlined />}
      {...props}
    />
  );
};

export default React.memo(AutoComplete);
