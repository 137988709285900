import { APPENDIX_PROCESS_VALUE } from 'appConstants';
export const redirectByStatus = (status, contractId, appendixId) => {
  switch (status) {
    case APPENDIX_PROCESS_VALUE.COMPOSE:
      return `/chi-tiet-hop-dong/${contractId}/soan-hop-dong`;
    case APPENDIX_PROCESS_VALUE.DEAL:
      return `/chi-tiet-hop-dong/${contractId}/thuong-luong`;
    case APPENDIX_PROCESS_VALUE.PAY:
      return `/chi-tiet-hop-dong/${contractId}/thanh-toan-theo-dot/${appendixId}`;
    case APPENDIX_PROCESS_VALUE.CHECK:
      return `/chi-tiet-hop-dong/${contractId}/kiem-tra-san-pham/${appendixId}`;
    case APPENDIX_PROCESS_VALUE.COMPLETE:
      return `/chi-tiet-hop-dong/${contractId}/hoan-tat/${appendixId}`;
    default:
      break;
  }
};

export const generateFileDownload = link => {
  const linkFile = document.createElement('a');
  linkFile.target = '_blank';
  linkFile.href = `${process.env.REACT_APP_API_DOMAIN}/${link}`;
  linkFile.setAttribute('download', `File`);
  document.body.appendChild(linkFile);

  linkFile.click();

  linkFile.parentNode.removeChild(linkFile);
};
