import { ROLE_LABEL } from 'appConstants';

export const getPermissionKeys = (obj, includesFalseValues) => {
  const newObj = { ...obj };

  return Object.keys(newObj).filter(key => {
    const value = newObj[key];
    return includesFalseValues
      ? typeof value === 'boolean' && key !== 'isDelete'
      : value === true && key !== 'isDelete';
  });
};

export const getPermissionLabels = arr => arr.map(key => ROLE_LABEL[key]);
