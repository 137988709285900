import { Divider, Typography } from 'antd';
import React from 'react';
import ApproveList from './ApproveList';

const ApproveAppendix = () => {
  return (
    <div>
      <Divider orientation="right">
        <Typography.Text style={{ color: '#aa27ac' }}>
          Duyệt phụ lục
        </Typography.Text>
      </Divider>
      <ApproveList />
    </div>
  );
};

export default ApproveAppendix;
