import React, { useCallback, useState } from 'react';
import { Card, Row, Space, Tag } from 'antd';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import Table from 'components/Table';

import { columns } from './columns';
import { useDepartmentDetail } from 'stores/useDepartmentDetail';
import shallow from 'zustand/shallow';

import { groupSubscribe } from '../utils';
import SelectSubscribeToAdd from './SelectSubscribeToAdd';

const Subscribe = () => {
  const [loading, setLoading] = useState(false);
  const {
    processAllSubscribe,
    tempSubscribe,
    subscribe,
    isDepartmentDetailLoading
  } = useDepartmentDetail(
    useCallback(
      ({
        processAllSubscribe,
        tempSubscribe,
        subscribe,
        isDepartmentDetailLoading
      }) => ({
        processAllSubscribe,
        tempSubscribe,
        subscribe,
        isDepartmentDetailLoading
      }),
      []
    ),
    shallow
  );

  const dataSource = [
    ...subscribe.map(item => ({ key: item.id, ...item })),
    ...tempSubscribe
  ];
  const onClick = async () => {
    setLoading(true);
    await processAllSubscribe();
    setLoading(false);
  };

  return (
    <Card title="Thông báo đang theo dõi">
      <SelectSubscribeToAdd />

      <Table
        loading={loading || isDepartmentDetailLoading}
        dataSource={groupSubscribe(dataSource)}
        paginationProps={{ pageSize: 5 }}
        style={{ margin: '16px 0' }}
        columns={columns}
        rowClassName={() => 'editable-row'}
      />

      <Row justify="end">
        <Space>
          <Tag icon={<InfoCircleOutlined />} color="warning">
            Dữ liệu sẽ mất nếu như không nhấn lưu.
          </Tag>

          <Button
            loading={loading || isDepartmentDetailLoading}
            onClick={onClick}
            icon={<SaveOutlined />}
          >
            Lưu
          </Button>
        </Space>
      </Row>
    </Card>
  );
};

export default Subscribe;
