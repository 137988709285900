import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const NaviCheckboxGroup = React.forwardRef(
  ({ options, onChange, className, ...rest }, ref) => {
    return (
      <Checkbox.Group
        options={options}
        onChange={onChange}
        className={`navi-checkbox-group ${className}`}
        {...rest}
        ref={ref}
      />
    );
  }
);

NaviCheckboxGroup.propTypes = {
  onChange: PropTypes.func
};

export default NaviCheckboxGroup;
