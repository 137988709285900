import AutoComplete from 'components/AutoComplete/AutoComplete';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { usePartner } from 'stores/usePartner';
import shallow from 'zustand/shallow';

const PartnerAutoComplete = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { getPartner, searchForPartnerFullInfo } = usePartner(
    useCallback(
      ({ getPartner, searchForPartnerFullInfo }) => ({
        getPartner,
        searchForPartnerFullInfo
      }),
      []
    ),
    shallow
  );

  const form = useContext(FilterBoxFormContext);

  useEffect(() => {
    (async () => {
      const params = form.getFieldsValue();
      if (params.value) {
        const data = await getPartner(params.value);
        setOptions(
          [data].map(({ id, artistName }) => ({
            value: id,
            label: artistName
          }))
        );
      }
    })();
  }, [form, getPartner]);

  return (
    <AutoComplete
      placeHolder={placeHolder}
      handleSearch={searchForPartnerFullInfo}
      defaultOption={options}
      valueKey="id"
      getLabel={partner => partner?.artistName || partner?.fullName}
      {...props}
    />
  );
};

export default PartnerAutoComplete;
