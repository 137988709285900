import React, { useCallback } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import {
  UserOutlined,
  AuditOutlined,
  GlobalOutlined,
  CrownOutlined,
  HomeOutlined,
  TeamOutlined,
  ContainerOutlined,
  CommentOutlined,
  RedoOutlined
} from '@ant-design/icons';

import { useLayout } from 'stores/useLayout';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';

import logo from 'assets/images/navi-white.png';
import { Music, Playlist } from 'components/Icons';
import { REUP_TYPE } from 'appConstants';
const { Sider } = Layout;

const iconStyle = { fontSize: '20px' };

const items = [
  {
    to: '/',
    path: '/',
    name: 'Bảng điều khiển',
    icon: <HomeOutlined style={iconStyle} />
  },
  {
    to: '/danh-sach-hop-dong',
    path: '/danh-sach-hop-dong',
    name: 'Hợp đồng',
    icon: <AuditOutlined style={iconStyle} />
  },
  {
    to: '/danh-sach-doi-tac',
    path: '/danh-sach-doi-tac',
    name: 'Đối tác',
    icon: <TeamOutlined style={iconStyle} />
  },
  {
    to: '/danh-sach-bai-hat',
    path: '/danh-sach-bai-hat',
    name: 'Bài hát',
    icon: <Music style={iconStyle} />
  },
  {
    icon: <RedoOutlined style={iconStyle} />,
    name: 'Phát hành'
  },
  // {
  //   to: '/danh-sach-phat-hanh',
  //   path: '/danh-sach-phat-hanh',
  //   name: 'Lịch phát hành',
  //   icon: <Playlist style={iconStyle} />
  // },
  // {
  //   to: '/danh-sach-tuyen-tap',
  //   path: '/danh-sach-tuyen-tap',
  //   name: 'Tuyển tập',
  //   icon: <Playlist style={iconStyle} />
  // },
  // {
  //   to: '/link-phat-hanh',
  //   path: '/link-phat-hanh',
  //   name: 'Link phát hành',
  //   icon: <Playlist style={iconStyle} />
  // },
  {
    to: '/quan-ly-kenh',
    path: '/quan-ly-kenh/:id',
    name: 'Quản lý kênh',
    icon: <GlobalOutlined style={iconStyle} />
  },

  {
    to: '/danh-sach-quyen-han',
    path: '/danh-sach-quyen-han',
    name: 'Vai trò',
    icon: <CrownOutlined style={iconStyle} />
  },
  {
    to: '/danh-sach-phong-ban',
    path: '/danh-sach-phong-ban',
    name: 'Phòng ban',
    icon: <CommentOutlined style={iconStyle} />
  },
  {
    to: '/danh-sach-nhan-vien',
    path: '/danh-sach-nhan-vien',
    name: 'Nhân viên',
    icon: <UserOutlined style={iconStyle} />
  },
  {
    to: '/danh-sach-master-data',
    path: '/danh-sach-master-data',
    name: 'Master Data',
    icon: <ContainerOutlined style={iconStyle} />
  }
];

const publicationItems = {
  icon: <Playlist style={iconStyle} />,
  name: 'Phát hành',
  items: [
    {
      to: '/danh-sach-phat-hanh',
      path: '/danh-sach-phat-hanh',
      name: 'Lịch phát hành'
      // icon: <Playlist style={iconStyle} />
    },
    {
      to: '/danh-sach-tuyen-tap',
      path: '/danh-sach-tuyen-tap',
      name: 'Tuyển tập'
      // icon: <Playlist style={iconStyle} />
    },
    {
      to: '/link-phat-hanh',
      path: '/link-phat-hanh',
      name: 'Link phát hành'
      // icon: <Playlist style={iconStyle} />
    }
  ]
};

const reupItems = {
  icon: <RedoOutlined style={iconStyle} />,
  name: 'Reup',
  items: [
    {
      to: `/reup/${REUP_TYPE.VD}`,
      name: 'Video'
      // icon: <ContainerOutlined style={iconStyle} />
    },
    {
      to: `/reup/${REUP_TYPE.BG}`,
      name: 'Background'
      // icon: <ContainerOutlined style={iconStyle} />
    },
    {
      to: `/reup/${REUP_TYPE.HK}`,
      name: 'Hotkey'
      // icon: <ContainerOutlined style={iconStyle} />
    },
    {
      to: `/reup/${REUP_TYPE.TG}`,
      name: 'Tag'
      // icon: <ContainerOutlined style={iconStyle} />
    },
    {
      to: `/reup/${REUP_TYPE.ST}`,
      name: 'Spectrum'
      // icon: <ContainerOutlined style={iconStyle} />
    },
    {
      to: `/reup/${REUP_TYPE.EF}`,
      name: 'Effect'
      // icon: <ContainerOutlined style={iconStyle} />
    },
    {
      to: `/reup/${REUP_TYPE.SG}`,
      name: 'Bài hát'
      // icon: <ContainerOutlined style={iconStyle} />
    },
    {
      to: `/reup/${REUP_TYPE.MT}`,
      name: 'Thể loại'
      // icon: <ContainerOutlined style={iconStyle} />
    }
  ]
};

const Sidebar = () => {
  const { currentUserViews } = useCurrentUser(
    useCallback(
      ({ currentUserViews }) => ({
        currentUserViews
      }),
      []
    ),
    shallow
  );

  const location = useLocation();
  const collapsed = useLayout(state => state.collapsed);
  // const selectedKey = get(location.pathname.split('/'), [1], '');
  const selectedKey = [
    '/quan-ly-kenh/kenh-phat-hanh',
    '/quan-ly-kenh/nha-cung-cap-noi-dung'
  ].includes(location.pathname)
    ? 'quan-ly-kenh'
    : location.pathname.replace('/', '');

  const renderReupItems = useCallback(() => {
    return (
      <Menu.SubMenu key={'reup'} title={reupItems.name} icon={reupItems.icon}>
        {reupItems.items.map(({ to, icon, name, code, params }) => (
          <Menu.Item key={to.slice(1)} icon={icon}>
            <Link to={to + (params || '')}>{name}</Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    );
  }, []);

  const renderPublicationItems = useCallback(() => {
    return (
      <Menu.SubMenu
        key={'Phát hành'}
        title={publicationItems.name}
        icon={publicationItems.icon}
      >
        {publicationItems.items
          .filter(
            ({ path, name }) =>
              currentUserViews?.some(
                ({ value }) =>
                  value === path ||
                  (value === '/quan-ly-kenh' && path === '/quan-ly-kenh/:id') ||
                  path === '/link-phat-hanh'
              ) || path === '/'
          )
          .map(({ to, icon, name, code, params }) => (
            <Menu.Item key={to.slice(1)} icon={icon}>
              <Link to={to + (params || '')}>{name}</Link>
            </Menu.Item>
          ))}
      </Menu.SubMenu>
    );
  }, [currentUserViews]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width={170}>
      <div className="navi-logo">
        <Link to="/">
          <img alt="logo" src={logo} className="navi-logo-img" />
        </Link>
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
        {currentUserViews &&
          items
            .filter(
              ({ path, name }) =>
                currentUserViews.some(
                  ({ value }) =>
                    value === path ||
                    // path === '/danh-sach-phat-hanh' ||
                    (value === '/quan-ly-kenh' &&
                      path === '/quan-ly-kenh/:id') ||
                    path === '/link-phat-hanh'
                ) || path === '/'
            )
            .map(({ to, icon, name }) => {
              return (
                <Menu.Item key={to.slice(1)} icon={icon}>
                  <Link to={to}>{name}</Link>
                </Menu.Item>
              );
            })}
        {renderPublicationItems()}
        {renderReupItems()}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
