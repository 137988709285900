import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import Button from 'components/Button';
import Select from 'components/Select';

import CompanyAutoComplete from './CompanyAutoComplete';
import CompanyDetail from './CompanyDetail';
import CompanyFormModal from './CompanyFormModal';
import ManagerAutoComplete from './ManagerAutoComplete';
import ManagerDetail from './ManagerDetail';
import ManagerFormModal from './ManagerFormModal';

import { usePartnerDetail } from 'stores/usePartnerDetail';

const empty = {
  value: '',
  label: 'Không có'
};

const company = {
  value: 'company',
  label: 'Công ty'
};

const manager = {
  value: 'manager',
  label: 'Quản lý'
};

const CompanyLabel = () => (
  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
    <Col>{company.label}</Col>
    <Col>
      <CompanyFormModal>
        <Button type="link" size="small" icon={<PlusCircleOutlined />}>
          Tạo mới công ty
        </Button>
      </CompanyFormModal>
    </Col>
  </Row>
);

const ManagerLabel = () => (
  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
    <Col>{manager.label}</Col>
    <Col>
      <ManagerFormModal>
        <Button type="link" size="small" icon={<PlusCircleOutlined />}>
          Tạo mới quản lý
        </Button>
      </ManagerFormModal>
    </Col>
  </Row>
);

const DeputyInfo = ({ isUpdatePartner, form, isSinglePartner }) => {
  const {
    getSelectingManager,
    resetSelectingManager,
    resetSelectingCompany,
    getSelectingCompany,
    selectingCompany,
    selectingManager,
    partner
  } = usePartnerDetail(
    useCallback(
      ({
        getSelectingManager,
        resetSelectingManager,
        resetSelectingCompany,
        getSelectingCompany,
        selectingCompany,
        selectingManager,
        partner
      }) => ({
        getSelectingManager,
        resetSelectingManager,
        resetSelectingCompany,
        getSelectingCompany,
        selectingCompany,
        selectingManager,
        partner
      }),
      []
    ),
    shallow
  );

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (partner && isUpdatePartner) {
      const { representationType } = partner;
      setValue(representationType);
    }
  }, [partner, form, isUpdatePartner]);

  const companyName = selectingCompany?.name;
  const companyId = selectingCompany?.id;
  const companyAutoCompleteDefaultValue =
    companyName && companyId ? { companyName, companyId } : null;

  const managerName = selectingManager?.fullName;
  const managerId = selectingManager?.id;
  const managerAutoCompleteDefaultValue =
    managerName && managerId ? { managerName, managerId } : null;

  const onSelect = value => {
    form.setFieldsValue({
      companyId: null,
      managerId: null,
      representationId: null
    });

    resetSelectingManager();
    resetSelectingCompany();
    setValue(value);
  };

  return (
    <>
      <Divider orientation="left">Thông tin người đại diện</Divider>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            name="representationType"
            label="Loại đại diện:"
            initialValue={partner?.representationType}
          >
            <Select
              style={{ width: '100%' }}
              // defaultValue={partner?.representationType}
              onSelect={onSelect}
              options={
                isSinglePartner ? [empty, company, manager] : [empty, manager]
              }
            />
          </Form.Item>
        </Col>

        {value && (
          <Col span={14} offset={2}>
            {value === company.value ? (
              <Form.Item
                className="deputy-form-item"
                name="companyId"
                label={<CompanyLabel />}
                rules={[{ required: true, message: 'Chọn công ty!' }]}
              >
                <CompanyAutoComplete
                  onChange={getSelectingCompany}
                  defaultOption={companyAutoCompleteDefaultValue}
                />
              </Form.Item>
            ) : (
              <Form.Item
                className="deputy-form-item"
                name="managerId"
                label={<ManagerLabel />}
                rules={[{ required: true, message: 'Chọn người quản lý!' }]}
              >
                <ManagerAutoComplete
                  defaultOption={managerAutoCompleteDefaultValue}
                  onChange={getSelectingManager}
                />
              </Form.Item>
            )}
          </Col>
        )}
      </Row>

      {value === company.value && <CompanyDetail />}

      {value === manager.value && <ManagerDetail />}
    </>
  );
};

export default DeputyInfo;
