import { Tag } from 'antd';
import { APPROVE_STATUS } from 'appConstants';
import React, { useCallback } from 'react';

const ApproveStatusTag = ({ value }) => {
  const generateType = useCallback(() => {
    switch (value) {
      case 'waiting':
        return 'warning';

      case 'approved':
        return 'success';

      default:
        return '';
    }
  }, [value]);

  return <Tag color={generateType()}>{APPROVE_STATUS[value]}</Tag>;
};

export default ApproveStatusTag;
