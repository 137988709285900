import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';

import Table from 'components/Table';

import { CommonContainer } from 'components/StyledComponents';

import { ImportOutlined } from '@ant-design/icons';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, ROLE_CHECK } from 'appConstants';
import CreateButton from 'components/CreateButton';
import DeleteButton from 'components/DeleteButton';
import ExportButton from 'components/ExportButton';
import OrderBySelect from 'components/OrderBySelect';
import { useCurrentUser } from 'stores/useCurrentUser';
import { useSong } from 'stores/useSong';
import { columns } from './columns';
import ImportSongCode from './components/ImportSongCode';
import SongFilterBox from './components/SongFilterBox';

const Song = () => {
  const {
    songs,
    getSongs,
    deleteSongs,
    setParams,
    total,
    isSongsLoading,
    exportSongByIds,
    isExportLoading
  } = useSong(
    useCallback(
      ({
        songs,
        getSongs,
        deleteSongs,
        setParams,
        total,
        isSongsLoading,
        exportSongByIds,
        isExportLoading
      }) => ({
        songs,
        getSongs,
        deleteSongs,
        setParams,
        total,
        isSongsLoading,
        exportSongByIds,
        isExportLoading
      }),
      []
    ),
    shallow
  );

  const { isAnywayRole } = useCurrentUser(
    useCallback(
      ({ isAnywayRole }) => ({
        isAnywayRole
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const pageSize = useMemo(
    () => searchParams.pageSize || PAGE_SIZE,
    [searchParams.pageSize]
  );
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );
  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  const onPaginate = (page, pageSize) => {
    history.push({
      pathname: '/danh-sach-bai-hat',
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    history.push({
      pathname: '/danh-sach-bai-hat',
      search: queryString.stringify({
        ...searchParams,
        pageSize
      })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-bai-hat',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    const paramsForGet = { ...searchParams, page: page - 1, sort, pageSize };

    setParams(paramsForGet);
    getSongs(paramsForGet);
  }, [getSongs, page, searchParams, sort, setParams, pageSize]);

  const uploadSuccess = () => {
    history.push({
      pathname: '/danh-sach-bai-hat',
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        // 'UpdateBy[eq]': currentUser?.id,
        sort: ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE
      })
    });
    getSongs({
      ...searchParams,
      page: 1,
      // 'UpdateBy[eq]': currentUser?.id,
      sort: ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE
    });
  };

  const SongToExport = useMemo(
    () => (!!selectedRows.length ? selectedRows : songs.map(_song => _song.id)),
    [selectedRows, songs]
  );
  const isSelected = useMemo(() => !!selectedRows.length, [selectedRows]);

  return (
    <div>
      <Typography.Title>Bài hát</Typography.Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>
            <SongFilterBox />
          </Col>
          <Col>
            <Space>
              <ExportButton
                loading={isExportLoading}
                // onExport={getExportContracts}
                onExportByIds={exportSongByIds}
                selectedItems={SongToExport}
                callback={() => setSelectedRows([])}
                subject="bài hát"
                isSelected={isSelected}
              />
              {isAnywayRole && (
                <ImportSongCode uploadSuccess={uploadSuccess}>
                  <Tooltip title="Cập nhật mã NamViet cho bài hát">
                    <Button
                      icon={<ImportOutlined />}
                      style={{
                        background: 'green',
                        border: 'none',
                        color: '#fff'
                      }}
                    >
                      Import
                    </Button>
                  </Tooltip>
                </ImportSongCode>
              )}

              {selectedRows.length !== 0 && (
                <DeleteButton
                  title={`Xoá ${selectedRows.length} bài hát?`}
                  onDelete={() => {
                    deleteSongs({ ids: selectedRows });
                    setSelectedRows([]);
                  }}
                  length={selectedRows.length}
                  from={ROLE_CHECK.SONG}
                />
              )}

              <OrderBySelect defaultValue={sort} onChange={setOrderBy} />

              <CreateButton from={ROLE_CHECK.SONG} />
            </Space>
          </Col>
        </Row>

        <Table
          scroll={{ x: 'auto' }}
          loading={isSongsLoading}
          columns={columns}
          dataSource={songs.map(item => ({ key: item.id, ...item }))}
          paginationProps={{
            total: total,
            pageSize: pageSize,
            onChange: onPaginate,
            current: page * 1,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange
          }}
          rowSelection={rowSelection}
        />
      </CommonContainer>
    </div>
  );
};

export default Song;
