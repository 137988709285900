import { Table } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useReviewDetail } from 'stores/useReviewDetail';
import shallow from 'zustand/shallow';
import { columns } from './columns';

const ApproveList = () => {
  const { appendixReview } = useReviewDetail(
    useCallback(
      ({ appendixReview }) => ({
        appendixReview
      }),
      []
    ),
    shallow
  );

  const approveList = useMemo(() => {
    if (appendixReview) {
      return appendixReview.confirmedAppendix;
    }
  }, [appendixReview]);

  return <Table columns={columns} dataSource={approveList} />;
};

export default ApproveList;
