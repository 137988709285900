import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'appConstants';

const NaviDatePicker = ({ placeHolder, onChange, ...rest }) => {
  return (
    <DatePicker
      placeholder={placeHolder}
      onChange={onChange}
      format={DATE_FORMAT}
      {...rest}
    />
  );
};

NaviDatePicker.propTypes = {
  placeHolder: PropTypes.string,
  onChange: PropTypes.func
};

NaviDatePicker.defaultProps = {
  placeHolder: 'chọn ngày'
};

export default NaviDatePicker;
