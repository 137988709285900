import { Select } from 'antd';
import { APPENDIX_PROCESS } from 'appConstants';

import React, { useCallback } from 'react';
import { useDepartmentDetail } from 'stores/useDepartmentDetail';
import shallow from 'zustand/shallow';

const SubsribeMultiSelect = (value, record) => {
  const code = record?.key?.code;

  const {
    tempSubscribe,
    subscribe,
    department,
    pushToTempSubscribe,
    prevDeleteSubscribe
  } = useDepartmentDetail(
    useCallback(
      ({
        tempSubscribe,
        subscribe,
        department,
        pushToTempSubscribe,
        prevDeleteSubscribe
      }) => ({
        tempSubscribe,
        subscribe,
        department,
        pushToTempSubscribe,
        prevDeleteSubscribe
      }),
      []
    ),
    shallow
  );

  const onSelect = receiveStatus => {
    pushToTempSubscribe({
      receiveStatus,
      receiveNotification: code,
      objectId: department.id,
      objectType: 'Department',
      index: tempSubscribe.length + 1,
      notificationDetail: {
        code
      }
    });
  };

  const onDeselect = v => {
    const found = [...subscribe, ...tempSubscribe].find(
      ({ receiveStatus }) => receiveStatus === v
    );

    return prevDeleteSubscribe(found);
  };

  return (
    code === 'changeStatusAppendix' && (
      <Select
        mode="multiple"
        onSelect={onSelect}
        onDeselect={onDeselect}
        style={{ width: '100%' }}
        value={value.map(({ receiveStatus }) => receiveStatus).filter(i => !!i)}
      >
        {APPENDIX_PROCESS.map(({ value, label }) => (
          <Select.Option value={value}>{label}</Select.Option>
        ))}
      </Select>
    )
  );
};

export default SubsribeMultiSelect;
