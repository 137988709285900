import { Col, Row, Spin, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';
import FirstAddendum from '../../containers/FirstAddendum';
import SecondAddendum from '../../containers/SecondAddendum';
import AddendumDetailInfo from './components/AddendumDetailInfo';
import SendInvitation from './components/SendInvitation';

const AddendumDetail = () => {
  const { id } = useParams();

  const { getAddendum, isAppendixLoading } = useAddendumDetail(
    useCallback(
      ({ getAddendum, isAppendixLoading }) => ({
        getAddendum,
        isAppendixLoading
      }),
      []
    ),
    shallow
  );

  useEffect(() => getAddendum(id), [getAddendum, id]);

  return (
    <div>
      <Spin spinning={isAppendixLoading}>
        <Row justify="space-between" align="middle" className="mb-md">
          <Col>
            <Typography.Title>Chi tiết phụ lục</Typography.Title>
          </Col>
        </Row>

        <AddendumDetailInfo />

        <FirstAddendum />

        <SecondAddendum />

        <SendInvitation />

        {/* <ChangeAddendumStatus /> */}
      </Spin>
    </div>
  );
};

export default AddendumDetail;
