import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

export const useSysRole = create((set, get) => ({
  sysPermission: [],
  sysRole: [],
  currentRole: {},
  setCurrentRole: role => {
    set({ currentRole: role });
  },

  addNewRoleLocal: () =>
    set({
      sysRole: [
        ...get().sysRole,
        {
          id: 0,
          sysPermissionRole: [],
          name: 'createRole',
          title: 'Thêm vai trò'
        }
      ]
    }),

  cancelNewRoleLocal: () =>
    set({ sysRole: [...get().sysRole.filter(role => role.id !== 0)] }),

  getSysPermission: async params => {
    try {
      const {
        data: { data }
      } = await API.get('/syspermission', {
        params: {
          ...params,
          Page: 0,
          PageSize: 99999
        }
      });

      if (data) {
        set({
          sysPermission: data
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getSysRole: async params => {
    try {
      const {
        data: { data }
      } = await API.get('/sysrole', {
        params: {
          ...params,
          Page: 0,
          PageSize: 99999
        }
      });

      if (data) {
        set({ sysRole: data, currentRole: data[0] });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  createSysRole: async (params, callback = f => f, failCallback = f => f) => {
    try {
      const { data } = await API.post('/sysrole', params);

      if (data) {
        set({
          sysRole: [...get().sysRole, data].filter(role => role.id !== 0)
        });
        callback(data);
        return notification.success({
          message: 'Tạo vai trò thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      failCallback();
      notification.error({
        message: data?.errors?.Name[0] || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateSysRole: async params => {
    try {
      const { data } = await API.put(`/sysrole/${params.id}`, params);

      if (data) {
        const newSysRole = get().sysRole.map(role =>
          role.id === data.id
            ? { ...role, name: data.name, title: data.title }
            : role
        );
        set({ sysRole: newSysRole });
        return notification.success({
          message: 'Cập nhật vai trò thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors?.Name[0] || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  deleteSysRole: async (id, callback = f => f) => {
    try {
      const { data } = await API.delete(`/sysrole/${id}`);

      if (data) {
        const newSysRole = get().sysRole.filter(role => role.id !== id);
        set({ sysRole: newSysRole });
        callback(newSysRole[newSysRole.length - 1].id.toString());
        return notification.success({
          message: 'Xoá vai trò thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateSysRolePermission: async params => {
    try {
      const { data } = await API.patch(
        `/sysrole/${params.id}/permission`,
        params
      );
      if (data) {
        const newSysRole = get().sysRole.map(role =>
          role.id === data.id ? data : role
        );
        set({ sysRole: newSysRole, currentRole: data });
        return notification.success({
          message: 'Cập nhật quyền thành công!'
        });
      }
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
