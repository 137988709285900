import React, { useCallback, useEffect } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import DashboardTable from '../DashboardTable';
import { columns } from './columns';

const NewContract = () => {
  const { newContracts, getNewContracts } = useDashboard(
    useCallback(
      ({ newContracts, getNewContracts }) => ({
        newContracts,
        getNewContracts
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getNewContracts();
  }, [getNewContracts]);

  return (
    <DashboardTable
      data={newContracts?.data?.map(contract => ({
        ...contract,
        key: contract.id
      }))}
      columns={columns}
      title="Hợp đồng mới"
      total={newContracts?.total}
    />
  );
};

export default NewContract;
