import React from 'react';

import { Link } from 'react-router-dom';
import Button from 'components/Button';

import RoleFormModal from 'pages/Role/components/RoleFormModal';
import SongFormModal from 'pages/Song/components/SongFormModal';
import UserFormModal from 'pages/User/components/UserFormModal';

import { ROLE_CHECK } from 'appConstants';
const CreateButton = ({ from }) => {
  switch (true) {
    case from === ROLE_CHECK.ROLE:
      return (
        <RoleFormModal>
          <Button>Tạo mới</Button>
        </RoleFormModal>
      );

    case from === ROLE_CHECK.USER:
      return (
        <UserFormModal>
          <Button>Tạo mới</Button>
        </UserFormModal>
      );

    case from === ROLE_CHECK.SONG:
      return (
        <SongFormModal>
          <Button>Tạo mới</Button>
        </SongFormModal>
      );

    case from === ROLE_CHECK.PARTNER:
      return (
        <Link to="/chi-tiet-doi-tac">
          <Button>Tạo mới</Button>
        </Link>
      );

    default:
      return <></>;
  }
};

export default CreateButton;
