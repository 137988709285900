import React, { useCallback, useEffect } from 'react';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';

import { isLoggedIn } from 'utils/authority';
import UserDropdown from './UserDropdown';
import UserInfoDisplay from './components/UserInfoDisplay';

const CurrentUserMenu = () => {
  const { currentUser, getCurrentUser } = useCurrentUser(
    useCallback(
      ({ currentUser, getCurrentUser }) => ({
        currentUser,
        getCurrentUser
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUser();
    }
  }, [getCurrentUser]);

  return (
    <div className="current-user-menu">
      {currentUser && (
        <>
          <UserInfoDisplay />
          <UserDropdown />
        </>
      )}
    </div>
  );
};

export default CurrentUserMenu;
