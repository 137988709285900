import { UploadOutlined } from '@ant-design/icons';
import { Card, Col, Form, Radio, Row, Space, Typography } from 'antd';
import { APPENDIX_PROCESS_VALUE, CONTRACT_TYPE_VALUE } from 'appConstants';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import CommonContainer from 'components/StyledComponents/CommonContainer';

import TextArea from 'components/TextArea';
import Upload from 'components/Upload';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useContract } from 'stores/useContract';
import { useInstallPayment } from 'stores/useInstallPayment';
import { formatDate } from 'utils/date';
import { redirectByStatus } from 'utils/redirect';
import shallow from 'zustand/shallow';
import ContractDetailLayout from '../ContractDetailLayout';
import PaymentList from './components/PaymentList';

const InstallmentPayment = () => {
  const history = useHistory();
  const [recentPayment, setRecentPayment] = useState({ ordinal: '' });
  const [resultOrigin, setResultOrigin] = useState();
  const [nextStatus, setNextStatus] = useState();
  const [form] = Form.useForm();
  const { id, appendixId } = useParams();

  const {
    getAppendix,
    appendixContract,
    updateResult,
    createResult,
    updateAppendixStatus,

    contract
  } = useContract(
    useCallback(
      ({
        getAppendix,
        appendixContract,
        updateResult,
        createResult,
        updateAppendixStatus,

        contract
      }) => ({
        getAppendix,
        appendixContract,
        updateResult,
        createResult,
        updateAppendixStatus,

        contract
      }),
      []
    ),
    shallow
  );

  const {
    processAllPayment,
    wasPaymentEdited,
    mergePayment,
    payments,
    setPaymentEdited,
    updateAppendix
  } = useInstallPayment(
    useCallback(
      ({
        processAllPayment,
        wasPaymentEdited,
        mergePayment,
        payments,
        setPaymentEdited,
        updateAppendix
      }) => ({
        processAllPayment,
        wasPaymentEdited,
        mergePayment,
        payments,
        setPaymentEdited,
        updateAppendix
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getAppendix(appendixId);
  }, [appendixId, getAppendix]);

  useEffect(() => {
    if (!!payments.length) {
      if (recentPayment) {
        const remainPayment = payments.filter(
          p => p.id !== recentPayment.id && p.action !== 'delete'
        );
        const conditionPaid = ({ paymentDate, status }) =>
          paymentDate !== null && status === 'paid';
        const allPaid = remainPayment.every(conditionPaid);
        setNextStatus(allPaid ? 'complete' : 'check');
      }
    }
  }, [payments, recentPayment]);

  const isBQTG = useMemo(
    () => contract?.contractType === CONTRACT_TYPE_VALUE.BQTG,
    [contract]
  );

  // Handle redirect url by status
  useEffect(() => {
    if (appendixContract) {
      const { status } = appendixContract;
      if (status === 'pay') return;
      const urlRedirect = redirectByStatus(status, id, appendixId);
      history.push(urlRedirect);
    }
  }, [appendixContract, id, appendixId, history]);

  useEffect(() => {
    if (appendixContract) {
      const { payment, result, adjustmentFile } = appendixContract;
      if (!!adjustmentFile?.length) setPaymentEdited(true);
      // Get recent payment by smallest ordinal
      const recentPayment = payment
        .filter(
          p => !p.paymentDate || p.status === 'undue' || p.status === 'waiting'
        )
        .sort((a, b) => a.ordinal - b.ordinal)[0];
      setRecentPayment(recentPayment);
      form.setFieldsValue({
        paymentDate: recentPayment?.paymentDate
          ? moment(recentPayment?.paymentDate)
          : null,
        file: recentPayment?.file.map(f => ({
          name: f.fileName,
          url: f.fileLink
        })),
        adjustmentFile: adjustmentFile?.map(f => ({
          name: f.fileName,
          url: f.fileLink
        }))
      });

      // Get Question and Answer
      const resultData = result
        .filter(
          a =>
            a.status === 'pay' &&
            a.question === recentPayment?.ordinal.toString()
        )
        .shift();
      setResultOrigin(resultData);
      if (resultData) {
        const { isOtherReason, answer } = resultData;
        form.setFieldsValue({
          answer: isOtherReason ? 'other' : answer,
          reason: isOtherReason ? answer : ''
        });
      }

      // Check next status of appendix
      if (recentPayment) {
        const remainPayment = payment.filter(p => p.id !== recentPayment.id);
        const conditionPaid = ({ paymentDate, status }) =>
          paymentDate !== null && status === 'paid';
        const allPaid = remainPayment.every(conditionPaid);
        setNextStatus(allPaid ? 'complete' : 'check');
      }

      // if (advanceMoney > 0) {
      //   const anyPaid = payment.some(conditionPaid);
      //   if (payment.length > 1 && anyPaid) setNextStatus('check');
      // } else {
      //   setNextStatus('check');
      // }
    }
  }, [appendixContract, form, setPaymentEdited]);

  const onFinish = () => {
    handleSaveAppendix(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const handleSaveAppendix = async (isNextStep = false) => {
    const { paymentDate, answer, reason, file, adjustmentFile } =
      form.getFieldsValue();
    await form.validateFields(['adjustmentFile']);
    if (answer) {
      const isOtherReason = answer === 'other';
      isOtherReason && (await form.validateFields(['reason']));
      const answerData = isOtherReason ? reason : answer;
      const question = recentPayment.ordinal;
      const status = 'pay';
      const dataRequest = {
        appendixId,
        resultId: resultOrigin?.id,
        isOtherReason,
        answer: answerData,
        question,
        status
      };
      resultOrigin?.id ? updateResult(dataRequest) : createResult(dataRequest);
    }

    const adjustmentFileParser = adjustmentFile?.map(f => {
      if (f.response) {
        return { fileName: f.response.fileName, fileLink: f.response.preview };
      }
      return { fileName: f.name, fileLink: f.url };
    });

    const fileParser = file.map(f => {
      if (f.response) {
        return { fileName: f.response.fileName, fileLink: f.response.preview };
      }
      return { fileName: f.name, fileLink: f.url };
    });

    // update payment

    // const data = await updateAppendixPayment({
    //   ...recentPayment,
    //   file: fileParser,
    //   paymentDate: paymentDate ? formatDate(paymentDate) : null,
    //   status: isNextStep ? 'paid' : 'waiting'
    // });
    await mergePayment({
      ...recentPayment,
      file: fileParser,
      paymentDate: paymentDate ? formatDate(paymentDate) : null,
      status: isNextStep ? 'paid' : 'waiting'
    });

    const data = await processAllPayment();

    if (!!adjustmentFileParser?.length && data)
      updateAppendix({
        appendixId: appendixContract.id,
        adjustmentFile: adjustmentFileParser,
        advanceMoney: appendixContract.advanceMoney,
        file: appendixContract?.file,
        evidenceFile: appendixContract?.evidenceFile,
        projectContractId: appendixContract?.projectContractId,
        totalMoney: payments
          .filter(payment => payment.action !== 'delete')
          .map(item => item.totalMoney)
          .reduce((acc, cur) => acc + cur)
      });

    if (isNextStep && data) {
      if (isBQTG) {
        nextStatus === 'complete'
          ? updateAppendixStatus(
              {
                appendixId,
                status: nextStatus
              },
              history.push(`/chi-tiet-hop-dong/${id}/hoan-tat/${appendixId}`)
            )
          : history.push(
              `/chi-tiet-hop-dong/${id}/thanh-toan-theo-dot/${appendixId}`
            );
      } else {
        updateAppendixStatus(
          {
            appendixId,
            status: nextStatus === 'complete' ? 'complete' : 'check'
          },
          history.push(
            nextStatus === 'complete'
              ? `/chi-tiet-hop-dong/${id}/hoan-tat/${appendixId}`
              : `/chi-tiet-hop-dong/${id}/kiem-tra-san-pham/${appendixId}`
          )
        );
      }
    }
  };

  return (
    <ContractDetailLayout step={APPENDIX_PROCESS_VALUE.PAY}>
      <Card title="Thanh toán theo đợt" bordered className="mb-md">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="answer"
            label={`Tiến trình thanh toán đợt ${recentPayment?.ordinal}`}
            rules={[{ required: true, message: 'Chọn câu trả lời' }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="paid">
                  {`Đã thanh toán tổng tiền đợt ${recentPayment?.ordinal}`}
                </Radio>
                <Radio value="other">Không thể thanh toán</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item shouldUpdate={(prev, cur) => prev.answer !== cur.answer}>
            {({ getFieldValue }) => {
              const isOther = getFieldValue('answer') === 'other';
              return (
                <Form.Item
                  name="reason"
                  label="Ghi chú:"
                  hidden={!isOther}
                  rules={[{ required: isOther, message: 'Nhập ghi chú' }]}
                >
                  <TextArea />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Row>
            <Col>
              <Typography.Text strong>Thông tin phụ lục</Typography.Text>
            </Col>

            <Col span={24}>
              <CommonContainer>
                <PaymentList
                  appendixContract={appendixContract}
                  currentOrdinal={recentPayment?.ordinal}
                />

                <Form.Item
                  shouldUpdate={(prev, cur) => prev.answer !== cur.answer}
                >
                  {({ getFieldValue }) => {
                    const answer = getFieldValue('answer');
                    const shouldDisable = answer === 'other';
                    return (
                      <>
                        <Form.Item
                          label={`Ngày thanh toán tổng tiền đợt ${recentPayment?.ordinal}:`}
                          name="paymentDate"
                          rules={[
                            { required: !shouldDisable, message: 'Chọn ngày' }
                          ]}
                        >
                          <DatePicker
                            allowClear={false}
                            disabled={shouldDisable}
                          />
                        </Form.Item>
                        <Form.Item
                          label="bản scan chứng từ thanh toán"
                          name="file"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          rules={[
                            { required: !shouldDisable, message: 'Tải file' }
                          ]}
                        >
                          <Upload
                            className="navi-upload"
                            name="file"
                            maxCount={1}
                            showUploadList={{ showRemoveIcon: false }}
                            disabled={shouldDisable}
                          >
                            <Button
                              disabled={shouldDisable}
                              icon={<UploadOutlined />}
                            >
                              Tải lên
                            </Button>
                          </Upload>
                        </Form.Item>
                        {wasPaymentEdited && (
                          <Form.Item
                            label="Biên bản điều chỉnh phụ lục"
                            name="adjustmentFile"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: wasPaymentEdited,
                                message: 'Tải file điều chỉnh phụ lục'
                              }
                            ]}
                          >
                            <Upload
                              className="navi-upload"
                              name="file"
                              maxCount={1}
                              showUploadList={{ showRemoveIcon: false }}
                              disabled={shouldDisable}
                            >
                              <Button
                                disabled={shouldDisable}
                                icon={<UploadOutlined />}
                              >
                                Tải lên
                              </Button>
                            </Upload>
                          </Form.Item>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              </CommonContainer>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span="12">
              <Button block onClick={() => handleSaveAppendix(false)}>
                Lưu
              </Button>
            </Col>
            <Col span="12">
              <Form.Item
                shouldUpdate={(prev, cur) => prev.answer !== cur.answer}
              >
                {({ getFieldValue }) => {
                  const answer = getFieldValue('answer');
                  const shouldDisable = answer === 'other';
                  return (
                    <Button block htmlType="submit" disabled={shouldDisable}>
                      {nextStatus === 'complete'
                        ? 'Hoàn tất phụ lục'
                        : isBQTG
                        ? 'Thanh toán đợt tiếp theo'
                        : 'Kiểm tra sản phẩm'}
                    </Button>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      {/* <Link to={`/chi-tiet-hop-dong/${id}/kiem-tra-san-pham/${appendixId}`}>
        <Button block>Tiếp theo</Button>
      </Link> */}
    </ContractDetailLayout>
  );
};

export default InstallmentPayment;
