import { Space, Typography } from 'antd';
import 'moment/locale/vi';
import React from 'react';

const RelatedUser = ({ creator, updater }) => {
  return (
    <Space direction="vertical">
      <Typography.Text>
        <Typography.Text strong>Khởi tạo: </Typography.Text>
        {creator?.fullName}
      </Typography.Text>
      <Typography.Text>
        <Typography.Text strong>Cập nhật: </Typography.Text>
        {updater?.fullName}
      </Typography.Text>
    </Space>
  );
};

export default RelatedUser;
