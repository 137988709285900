import React from 'react';
import moment from 'moment';
import 'moment/locale/vi';

import { Button, Space, Typography } from 'antd';

import { EDITABLE_CELL_TYPE } from 'appConstants';
import PublisherDeleteButton from './components/PublisherDeleteButton';
import PublisherAddButtons from './components/PublisherAddButtons';
import { BarChartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const columns = [
  {
    title: 'Tên kênh',
    dataIndex: 'name',
    render: name => <Typography.Text>{name}</Typography.Text>,
    editable: EDITABLE_CELL_TYPE.TEXT,
    width: '20%',
    focusIfAdd: true
  },
  {
    title: 'Code',
    dataIndex: 'code',
    render: code => <Typography.Text>{code}</Typography.Text>,
    editable: EDITABLE_CELL_TYPE.TEXT,
    width: '15%'
  },
  {
    title: 'Nhà cung cấp nội dung',
    dataIndex: 'providerId',
    render: providerId => <Typography.Text>{providerId}</Typography.Text>,
    editable: EDITABLE_CELL_TYPE.SELECT,
    width: '15%'
  },
  {
    title: 'Mô tả',
    dataIndex: 'description',
    render: description => <Typography.Text>{description}</Typography.Text>,
    editable: EDITABLE_CELL_TYPE.TEXT,
    width: '20%'
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate =>
      createDate && (
        <Space direction="vertical">
          <Typography.Text>
            {moment(createDate).format('DD/MM/YYYY hh:mm')}
          </Typography.Text>

          <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
        </Space>
      ),
    width: '10%'
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    render: updateDate =>
      updateDate && (
        <Space direction="vertical">
          <Typography.Text>
            {moment(updateDate).format('DD/MM/YYYY hh:mm')}
          </Typography.Text>

          <Typography.Text>{moment(updateDate).fromNow()}</Typography.Text>
        </Space>
      ),
    width: '10%'
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    fixed: 'right',
    render: (_, item) => {
      return item?.add ? (
        <PublisherAddButtons />
      ) : (
        <Space>
          <Link to={`/thong-ke-kenh-phat-hanh/${item?.id}`}>
            <Button type="primary" icon={<BarChartOutlined />} />
          </Link>
          <PublisherDeleteButton item={item} />
        </Space>
      );
    },
    align: 'center',
    width: '10%'
  }
];
