import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, Space, Table, Typography } from 'antd';
import CurrencyInputNumber from 'components/CurrencyInputNumber';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { useInstallPayment } from 'stores/useInstallPayment';
import { calculatePercent } from 'utils/other';
import shallow from 'zustand/shallow';
import { columns } from '../columns';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <CurrencyInputNumber />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const PaymentList = ({ appendixContract, currentOrdinal }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const originPayments = useMemo(
    () => appendixContract?.payment,
    [appendixContract]
  );

  const { payments, setPayments, setPaymentEdited } = useInstallPayment(
    useCallback(
      ({ payments, setPayments, setPaymentEdited }) => ({
        payments,
        setPayments,
        setPaymentEdited
      }),
      []
    ),
    shallow
  );

  const isEditing = record => record.key === editingKey;

  useEffect(() => {
    if (originPayments) {
      setPayments(originPayments);
    }
    return () => {
      setPayments([]);
      setPaymentEdited(false);
    };
  }, [originPayments, setPayments, setPaymentEdited]);

  const { calculateTotalMoney } = useAddendumDetail(
    useCallback(
      ({ calculateTotalMoney }) => ({
        calculateTotalMoney
      }),
      []
    ),
    shallow
  );

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  const cancel = () => {
    setEditingKey('');
  };

  const edit = record => {
    form.setFieldsValue({
      takeHomeMoney: '',
      ...record
    });
    setEditingKey(record.key);
  };

  const reMapTaxPercent = payments => {
    const newTotal = payments
      .filter(payment => payment.action !== 'delete')
      .map(item => item.totalMoney)
      .reduce((acc, cur) => acc + cur);

    const newPaymentsWithTax = payments.map(payment => ({
      ...payment,
      percent: calculatePercent(payment.totalMoney, newTotal)
    }));

    setPayments(newPaymentsWithTax);
  };

  const onTakeHomeMoneyChange = async (takeHomeMoney, ordinal) => {
    if (!takeHomeMoney) return;

    // setLoading(true);

    const res = await calculateTotalMoney({
      appendixId: appendixContract?.id,
      ordinal,
      takeHomeMoney,
      calculateType: 0
    });

    // setLoading(false);

    const totalMoney = Math.round(res.totalMoney);
    const taxMoney = Math.round(res.taxMoney);

    const newPayments = payments.map(payment =>
      payment.ordinal === res.ordinal
        ? { ...payment, takeHomeMoney, taxMoney, totalMoney, action: 'update' }
        : payment
    );

    reMapTaxPercent(newPayments);
  };

  const onSave = async key => {
    try {
      const takeHomeMoney = await form.getFieldValue('takeHomeMoney');
      if (!takeHomeMoney) return;
      setPaymentEdited(true);
      onTakeHomeMoneyChange(takeHomeMoney, key);
      cancel();
    } catch (errInfo) {}
  };

  const onDelete = key => {
    const newPayments = payments.map(payment =>
      payment.ordinal === key ? { ...payment, action: 'delete' } : payment
    );
    setPaymentEdited(true);
    reMapTaxPercent(newPayments);
  };

  mergedColumns.push({
    title: 'Điều chỉnh',
    dataIndex: 'operation',
    render: (_, record) => {
      const editable = isEditing(record);
      return record.ordinal === 1 || record.status === 'paid' ? null : (
        <Space>
          {editable ? (
            <Space>
              <Typography.Link onClick={() => onSave(record.key)}>
                Lưu
              </Typography.Link>

              <Typography.Link onClick={cancel}>Huỷ</Typography.Link>
            </Space>
          ) : (
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Typography.Link>
          )}
          {currentOrdinal !== record.ordinal && (
            <Popconfirm
              title="Xoá đượt thanh toán?"
              onConfirm={cancel}
              disabled={editingKey !== ''}
            >
              <Button
                type="link"
                danger
                disabled={editingKey !== ''}
                onClick={() => onDelete(record.key)}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        </Space>
      );
    }
  });

  // const wasEdited = useMemo(
  //   () =>
  //     payments.some(payment => ['update', 'delete'].includes(payment?.action)),
  //   [payments]
  // );

  // const normFile = e => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const Footer = () => {
  //   return (
  //     <Row justify="space-between">
  //       <Form.Item
  //         label="Biên bản điều chỉnh phụ lục"
  //         name="file"
  //         valuePropName="fileList"
  //         getValueFromEvent={normFile}
  //         rules={[{ required: true, message: 'Upload biên bản điều chỉnh' }]}
  //       >
  //         <Upload
  //           className="navi-upload"
  //           name="file"
  //           maxCount={1}
  //           showUploadList={{ showRemoveIcon: false }}
  //           // disabled={shouldDisable}
  //         >
  //           <Button icon={<UploadOutlined />}>Tải lên</Button>
  //         </Upload>
  //       </Form.Item>
  //       <Button>Lưu thanh toán</Button>
  //     </Row>
  //   );
  // };

  return (
    <Form form={form} component={false}>
      <Table
        columns={mergedColumns}
        dataSource={payments
          .filter(payment => payment?.action !== 'delete')
          ?.sort((a, b) => a.ordinal - b.ordinal)
          .map(p => ({ ...p, key: p.ordinal }))}
        pagination={false}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        className="mb-md"
        // footer={wasEdited ? Footer : undefined}
      />
    </Form>
  );
};

export default PaymentList;
