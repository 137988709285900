import React from 'react';
import Select from 'components/Select';

const GenderSelect = props => {
  return (
    <Select
      {...props}
      options={[
        { value: 'male', label: 'Nam' },
        { value: 'female', label: 'Nữ' }
      ]}
    />
  );
};

export default GenderSelect;
