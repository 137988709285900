import { Typography } from 'antd';
import React from 'react';

const CardStatic = ({ title, total, Icon, color }) => {
  return (
    <div className="info-card-item" style={{ background: color }}>
      <Icon className="info-card-icon" style={{ color: color }} />
      <div className="info-card-content">
        <div>
          <Typography.Text>{title}</Typography.Text>
        </div>
        <div>
          <Typography.Text strong className="static-quantity">
            {total}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default CardStatic;
