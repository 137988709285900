import React, { useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import Select from 'components/Select';
import { useSong } from 'stores/useSong';

const SongTypeSelect = ({ valueKey = 'code', ...props }) => {
  const [options, setOptions] = useState([]);

  const { getSongTypeParent } = useSong(
    useCallback(
      ({ getSongTypeParent }) => ({
        getSongTypeParent
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    const handleGetOption = async () => {
      const res = await getSongTypeParent();

      if (!res) return;

      setOptions(
        res.map(({ title, ...other }) => ({
          value: other[valueKey],
          label: title
        }))
      );
    };
    handleGetOption();
  }, [getSongTypeParent, valueKey]);

  return (
    <Select
      showSearch
      options={options}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      {...props}
    />
  );
};

export default React.memo(SongTypeSelect);
