import React, { useCallback } from 'react';
import { Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { formatPrice } from 'utils/currency';
import Button from 'components/Button';
import PaymentFormModal from './PaymentFormModal';

import { useAddendumDetail } from 'stores/useAddendumDetail';
import { calculatePercent } from 'utils/other';
import PaymentDeleteButton from './PaymentDeleteButton';
import shallow from 'zustand/shallow';
import PaymentStatusTag from 'pages/ContractDetail/InstallmentPayment/components/PaymentStatusTag';

const spaceProps = {
  direction: 'vertical',
  size: null,
  style: { marginRight: '10px' }
};

const Percent = thisTotalMoney => {
  const { getTotalMoney } = useAddendumDetail(
    useCallback(
      ({ getTotalMoney }) => ({
        getTotalMoney
      }),
      []
    ),
    shallow
  );

  const thatTotalMoney = getTotalMoney();

  const percentToRender = calculatePercent(thisTotalMoney, thatTotalMoney);

  return <div>{percentToRender}</div>;
};

export const columns = [
  {
    title: <Space {...spaceProps}>Thứ tự đợt</Space>,
    dataIndex: 'ordinal',
    render: ordinal => <div>{ordinal}</div>,
    sorter: (a, b) => a.ordinal - b.ordinal,
    align: 'right',
    width: '10%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tổng tiền</span>
        <span>(bao gồm thuế, đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: totalMoney => <div>{formatPrice(totalMoney)}</div>,
    sorter: (a, b) => a.totalMoney - b.totalMoney,
    align: 'right',
    width: '15%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Thuế xuất</span>
        <span>(TNCN/VAT, đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'taxPercent',
    render: taxPercent => <div>{taxPercent}</div>,
    sorter: (a, b) => a.taxPercent - b.taxPercent,
    align: 'right',
    width: '20%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tiền thuế</span>
        <span>(TNCN/VAT, đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'taxMoney',
    render: taxMoney => <div>{formatPrice(taxMoney)}</div>,
    sorter: (a, b) => a.taxMoney - b.taxMoney,
    align: 'right',
    width: '15%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tiền thực nhận</span>
        <span>(đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'takeHomeMoney',
    render: takeHomeMoney => <div>{formatPrice(takeHomeMoney)}</div>,
    sorter: (a, b) => a.takeHomeMoney - b.takeHomeMoney,
    align: 'right',
    width: '10%'
  },
  {
    title: (
      <Space {...spaceProps} style={{ marginRight: '0px' }}>
        <span>Tỉ lệ</span>
        <span>(đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: Percent,
    sorter: (a, b) => a.percent - b.percent,
    align: 'center',
    width: '10%'
  },
  {
    title: 'Ghi chú',
    dataIndex: 'status',
    render: status => <PaymentStatusTag value={status} />,
    width: '10%'
  },

  {
    key: 'actions',
    render: (_, item) => (
      <Space>
        <PaymentFormModal initValue={item}>
          <Button type="default" icon={<EditOutlined />} />
        </PaymentFormModal>

        <PaymentDeleteButton item={item} />
      </Space>
    ),
    width: '10%',
    align: 'center'
  }
];

export const hiddenTaxMoneyColumns = [
  {
    title: <Space {...spaceProps}>Thứ tự đợt</Space>,
    dataIndex: 'ordinal',
    render: ordinal => <div>{ordinal}</div>,
    sorter: (a, b) => a.ordinal - b.ordinal,
    align: 'right',
    width: '10%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tổng tiền</span>
        <span>(bao gồm thuế, đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: totalMoney => <div>{formatPrice(totalMoney)}</div>,
    sorter: (a, b) => a.totalMoney - b.totalMoney,
    align: 'right',
    width: '15%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Thuế xuất</span>
        <span>(TNCN/VAT, đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'taxPercent',
    render: taxPercent => <div>{taxPercent}</div>,
    sorter: (a, b) => a.taxPercent - b.taxPercent,
    align: 'right',
    width: '20%'
  },
  {
    title: (
      <Space {...spaceProps} style={{ marginRight: '0px' }}>
        <span>Tỉ lệ</span>
        <span>(đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: Percent,
    sorter: (a, b) => a.percent - b.percent,
    align: 'center',
    width: '10%'
  },
  {
    title: 'Ghi chú',
    dataIndex: 'status',
    render: status => <PaymentStatusTag value={status} />,
    width: '10%'
  },

  {
    key: 'actions',
    render: (_, item) => (
      <Space>
        <PaymentFormModal initValue={item}>
          <Button type="default" icon={<EditOutlined />} />
        </PaymentFormModal>

        <PaymentDeleteButton item={item} />
      </Space>
    ),
    width: '10%',
    align: 'center'
  }
];

export const partnerIsCompanyColumns = [
  {
    title: <Space {...spaceProps}>Thứ tự đợt</Space>,
    dataIndex: 'ordinal',
    render: ordinal => <div>{ordinal}</div>,
    sorter: (a, b) => a.ordinal - b.ordinal,
    align: 'right',
    width: '10%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tiền trước thuế</span>
        <span>(bao gồm thuế, đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: totalMoney => <div>{formatPrice(totalMoney)}</div>,
    sorter: (a, b) => a.totalMoney - b.totalMoney,
    align: 'right',
    width: '15%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Thuế xuất</span>
        <span>(GTGT/VAT, đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'taxPercent',
    render: taxPercent => <div>{taxPercent}</div>,
    sorter: (a, b) => a.taxPercent - b.taxPercent,
    align: 'right',
    width: '20%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tiền thuế</span>
        <span>(GTGT/VAT, đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'taxMoney',
    render: taxMoney => <div>{formatPrice(taxMoney)}</div>,
    sorter: (a, b) => a.taxMoney - b.taxMoney,
    align: 'right',
    width: '15%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tổng tiền thanh toán</span>
        <span>(đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'takeHomeMoney',
    render: takeHomeMoney => <div>{formatPrice(takeHomeMoney)}</div>,
    sorter: (a, b) => a.takeHomeMoney - b.takeHomeMoney,
    align: 'right',
    width: '10%'
  },
  {
    title: (
      <Space {...spaceProps} style={{ marginRight: '0px' }}>
        <span>Tỉ lệ</span>
        <span>(đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: Percent,
    sorter: (a, b) => a.percent - b.percent,
    align: 'center',
    width: '10%'
  },
  {
    title: 'Ghi chú',
    dataIndex: 'status',
    render: status => <PaymentStatusTag value={status} />,
    width: '10%'
  },

  {
    key: 'actions',
    render: (_, item) => (
      <Space>
        <PaymentFormModal initValue={item}>
          <Button type="default" icon={<EditOutlined />} />
        </PaymentFormModal>

        <PaymentDeleteButton item={item} />
      </Space>
    ),
    width: '10%',
    align: 'center'
  }
];

export const partnerIsCompanyHiddenTaxMoneyColumns = [
  {
    title: <Space {...spaceProps}>Thứ tự đợt</Space>,
    dataIndex: 'ordinal',
    render: ordinal => <div>{ordinal}</div>,
    sorter: (a, b) => a.ordinal - b.ordinal,
    align: 'right',
    width: '10%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tiền trước thuế</span>
        <span>(bao gồm thuế, đơn vị: vnđ)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: totalMoney => <div>{formatPrice(totalMoney)}</div>,
    sorter: (a, b) => a.totalMoney - b.totalMoney,
    align: 'right',
    width: '15%'
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Thuế xuất</span>
        <span>(GTGT/VAT, đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'taxPercent',
    render: taxPercent => <div>{taxPercent}</div>,
    sorter: (a, b) => a.taxPercent - b.taxPercent,
    align: 'right',
    width: '20%'
  },
  {
    title: (
      <Space {...spaceProps} style={{ marginRight: '0px' }}>
        <span>Tỉ lệ</span>
        <span>(đơn vị: %)</span>
      </Space>
    ),
    dataIndex: 'totalMoney',
    render: Percent,
    sorter: (a, b) => a.percent - b.percent,
    align: 'center',
    width: '10%'
  },
  {
    title: 'Ghi chú',
    dataIndex: 'status',
    render: status => <PaymentStatusTag value={status} />,
    width: '10%'
  },

  {
    key: 'actions',
    render: (_, item) => (
      <Space>
        <PaymentFormModal initValue={item}>
          <Button type="default" icon={<EditOutlined />} />
        </PaymentFormModal>

        <PaymentDeleteButton item={item} />
      </Space>
    ),
    width: '10%',
    align: 'center'
  }
];
