import {
  EditOutlined,
  MinusOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { Checkbox, Space, Tooltip, Typography } from 'antd';
import {
  CONTRACT_TYPE_VALUE,
  COPYRIGHT_SOURCE_VALUE,
  EDITABLE_CELL_TYPE
} from 'appConstants';
import { Medley } from 'components/Icons';
import Verify from 'components/Icons/Verify';
import { useCallback, useMemo } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { extractPartnersFromSong } from 'utils/other';
import shallow from 'zustand/shallow';
import OriginSong from './components/OriginSong';
import ResourceQTG from './components/ResourceQTG';
import SongAddendumDeleteButton from './components/SongAddendumDeleteButton';

const Singers = ({ partnerSong, song }) => {
  const { contractUseInAddendumDetail } = useAddendumDetail(
    useCallback(
      ({ contractUseInAddendumDetail }) => ({
        contractUseInAddendumDetail
      }),
      []
    ),
    shallow
  );

  const isBQTG = useMemo(
    () =>
      contractUseInAddendumDetail?.contractType === CONTRACT_TYPE_VALUE.BQTG,
    [contractUseInAddendumDetail]
  );

  // eslint-disable-next-line no-unused-vars
  const [_, singers] = extractPartnersFromSong(partnerSong, true);

  const RenderSinger = () => {
    return partnerSong ? (
      <Space direction="vertical">
        {singers.map(({ label, value }) => (
          <Typography.Text key={value}>{label}</Typography.Text>
        ))}
      </Space>
    ) : (
      <Typography.Text>{song?.singerName}</Typography.Text>
    );
  };

  return isBQTG ? <MinusOutlined /> : <RenderSinger />;
};

const CheckboxColumn = ({ type }) => {
  const { mapAllRevenueSharingType } = useAddendumDetail(
    useCallback(
      ({ mapAllRevenueSharingType }) => ({
        mapAllRevenueSharingType
      }),
      []
    ),
    shallow
  );
  const handleChangeCheckbox = value => {
    mapAllRevenueSharingType(value, type);
  };
  return (
    <Checkbox onChange={event => handleChangeCheckbox(event.target.checked)} />
  );
};

export const columns = [
  {
    title: 'STT',
    render: (_, { index }) => <div>{index + 1}</div>,
    width: '70px',
    fixed: 'left'
  },
  {
    title: 'Tác phẩm',
    dataIndex: 'songId',
    fixed: 'left',
    width: '250px',
    render: (_, __) => (
      <Space direction="vertical">
        {__?.doiSoatCode && (
          <Typography.Text>#{__?.doiSoatCode}</Typography.Text>
        )}
        <Space size={0} align="center">
          {__?.isMedley && (
            <Medley style={{ fontSize: 24, color: '#1DD05D' }} />
          )}
          <Typography.Text strong>{__?.songName}</Typography.Text>
          {__?.namVietCode && (
            <Tooltip title="Đã có mã NamVietCode">
              <Verify style={{ color: '#ffcc00', fontSize: 18 }} />
            </Tooltip>
          )}
        </Space>
      </Space>
    )
  },
  {
    title: 'Người trình bày',
    dataIndex: 'partnerSong',
    render: (partnerSong, song) => (
      <Singers partnerSong={partnerSong} song={song} />
    ),
    width: '150px'
  },
  {
    title: 'Tác giả',
    dataIndex: 'partnerSong',
    render: (partnerSong, _) => {
      if (partnerSong) {
        // eslint-disable-next-line no-unused-vars
        const [musicians] = extractPartnersFromSong(partnerSong, true);

        return (
          <Space direction="vertical">
            {musicians.map(({ label, value }) => (
              <Typography.Text
                style={{ width: 180 }}
                key={value}
                ellipsis={{ tooltip: label }}
              >
                {label}
              </Typography.Text>
            ))}
          </Space>
        );
      }

      return _?.musicianName;
    },
    width: '200px'
  },
  {
    title: 'Bài hát gốc',
    dataIndex: 'relatedRightSongId',
    render: (relatedRightSongId, record) => {
      const { relatedRightSong } = record;
      return relatedRightSong ? (
        <Typography.Text>
          {record?.relatedRightSong?.songName}{' '}
          <OriginSong record={record}>
            <EditOutlined />
          </OriginSong>
        </Typography.Text>
      ) : (
        <OriginSong record={record}>
          <Typography.Link>
            <PlusCircleOutlined /> Thêm bài hát gốc
          </Typography.Link>
        </OriginSong>
      );
    },
    width: '200px'
  },
  {
    title: 'Nguồn QTG',
    dataIndex: 'copyRightSourceContract',
    render: (_, record) => {
      const code = record.code
        ? record.code
        : record.copyRightSourceType === COPYRIGHT_SOURCE_VALUE.VCPMC
        ? COPYRIGHT_SOURCE_VALUE.VCPMC
        : record?.copyRightSourceContract?.contractCode;

      const isFromSinger =
        record.copyRightSourceType === COPYRIGHT_SOURCE_VALUE.fromSinger;
      return !record?.isMedley ? (
        <Space direction="vertical">
          {code ? (
            <Typography.Text>
              {code}
              <ResourceQTG record={record}>
                <EditOutlined />
              </ResourceQTG>
            </Typography.Text>
          ) : (
            <ResourceQTG record={record}>
              <Typography.Link>
                <PlusCircleOutlined /> Cập nhật nguồn
              </Typography.Link>
            </ResourceQTG>
          )}
          {isFromSinger && !!record?.copyRightConfirmFile?.length && (
            <a
              href={record?.copyRightConfirmFile[0].fileLink}
              target="_blank"
              rel="noreferrer"
            >
              {record?.copyRightConfirmFile[0].fileName}
            </a>
          )}
        </Space>
      ) : null;
    },
    width: '300px'
  },

  {
    title: 'Thể loại',
    dataIndex: 'songTypeName',
    render: songTypeName => <Typography.Text>{songTypeName}</Typography.Text>,
    width: '150px'
  },
  {
    title: (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Text>QLQ</Typography.Text>
        <CheckboxColumn type="relatedRight" />
      </Space>
    ),
    dataIndex: 'relatedRight',
    editable: EDITABLE_CELL_TYPE.CHECK_BOX,
    width: '120px'
  },
  {
    title: (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Text>QTG</Typography.Text>
        <CheckboxColumn type="copyRight" />
      </Space>
    ),
    dataIndex: 'copyRight',
    editable: EDITABLE_CELL_TYPE.CHECK_BOX,
    width: '120px'
  },
  // {
  //   title: 'Độc quyền',
  //   dataIndex: 'monopoly',
  //   editable: EDITABLE_CELL_TYPE.CHECK_BOX,
  //   width: '120px'
  // },
  // {
  //   title: 'Nguồn quyền TG',
  //   dataIndex: 'monopoly',
  //   width: '120px'
  // },
  // {
  //   title: 'Mã hđ QTG',
  //   dataIndex: 'monopoly',
  //   width: '120px'
  // },
  {
    title: (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Text>Audio</Typography.Text>
        <CheckboxColumn type="audio" />
      </Space>
    ),
    dataIndex: 'audio',
    editable: EDITABLE_CELL_TYPE.CHECK_BOX,
    width: '120px'
  },
  {
    title: (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Text>Video</Typography.Text>
        <CheckboxColumn type="video" />
      </Space>
    ),
    dataIndex: 'video',
    editable: EDITABLE_CELL_TYPE.CHECK_BOX,
    width: '120px'
  },
  {
    key: 'actions',
    width: '80px',
    render: (_, item) => (
      <Space>
        <SongAddendumDeleteButton item={item} />
      </Space>
    ),
    align: 'center'
  }
];

export const medleyColumns = [
  {
    title: 'Tên bài hát',
    dataIndex: 'songName',
    render: (_, { song: { songName, doiSoatCode, namVietCode } }) => (
      <Space direction="vertical">
        {doiSoatCode && <Typography.Text>#{doiSoatCode}</Typography.Text>}
        <Space size={0}>
          <Typography.Text>{songName}</Typography.Text>
          {namVietCode && (
            <Tooltip title="Đã có mã NamVietCode">
              <Verify style={{ color: '#ffcc00', fontSize: 18 }} />
            </Tooltip>
          )}
        </Space>
      </Space>
    )
  },
  {
    title: 'Nguồn QTG',
    dataIndex: 'copyRightSourceContract',
    render: (_, record) => {
      const code = record.code
        ? record.code
        : record.copyRightSourceType === COPYRIGHT_SOURCE_VALUE.VCPMC
        ? COPYRIGHT_SOURCE_VALUE.VCPMC
        : record?.copyRightSourceContract?.contractCode;

      const isFromSinger =
        record.copyRightSourceType === COPYRIGHT_SOURCE_VALUE.fromSinger;
      return (
        <Space direction="vertical">
          {code ? (
            <Typography.Text>
              {code}
              <ResourceQTG record={record}>
                <EditOutlined />
              </ResourceQTG>
            </Typography.Text>
          ) : (
            <ResourceQTG record={record}>
              <Typography.Link>
                <PlusCircleOutlined /> Cập nhật nguồn
              </Typography.Link>
            </ResourceQTG>
          )}
          {isFromSinger && !!record?.copyRightConfirmFile?.length && (
            <a
              href={record?.copyRightConfirmFile[0].fileLink}
              target="_blank"
              rel="noreferrer"
            >
              {record?.copyRightConfirmFile[0].fileName}
            </a>
          )}
        </Space>
      );
    },
    width: '300px'
  },
  {
    title: 'Người trình bày',
    dataIndex: 'partnerSong',
    render: (_, { song }) => (
      <Singers partnerSong={song?.partnerSong} song={song} />
    ),
    width: '20%'
  },
  {
    title: 'Tác giả',
    dataIndex: 'partnerSong',
    render: (_, { song }) => {
      if (song?.partnerSong) {
        // eslint-disable-next-line no-unused-vars
        const [musicians] = extractPartnersFromSong(song?.partnerSong, true);

        return (
          <Space direction="vertical">
            {musicians.map(({ label, value }) => (
              <Typography.Text
                style={{ width: 180 }}
                key={value}
                ellipsis={{ tooltip: label }}
              >
                {label}
              </Typography.Text>
            ))}
          </Space>
        );
      }

      return _?.musicianName;
    },
    width: '20%'
  },
  {
    title: 'Thể loại',
    dataIndex: 'songType',
    render: (_, { song: { songTypeName } }) => (
      <Typography.Text>{songTypeName}</Typography.Text>
    ),
    width: '20%'
  }
];
