import React, { useCallback, useEffect } from 'react';
import shallow from 'zustand/shallow';
import { Form, Input as AntdInput } from 'antd';

import Input from 'components/Input';
import PermissionMultiSelect from '../PermissionMultiSelect';
import { useRole } from 'stores/useRole';

import { getPermissionKeys } from 'pages/Role/utils';
import RoleAutoComplete from 'pages/User/components/RoleAutoComplete';

const RoleFormModalContent = ({
  form,
  roleId,
  isUpdateRole,
  onRoleAutoCompleteChange
}) => {
  const { getRole, role, resetRole } = useRole(
    useCallback(
      ({ getRole, role, resetRole }) => ({
        getRole,
        role,
        resetRole
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (isUpdateRole) {
      getRole(roleId);
    }
  }, [isUpdateRole, getRole, roleId]);

  useEffect(() => {
    if (isUpdateRole && role) {
      const { name, description, ...others } = role;

      form.setFieldsValue({
        name,
        description,
        permissions: getPermissionKeys(others)
      });
    }
  }, [role, form, isUpdateRole]);

  useEffect(
    () => () => {
      form.resetFields();
      if (role) {
        resetRole();
      }
    },
    [form, resetRole, role]
  );
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Tên vai trò"
        rules={[{ required: true, message: 'Nhập tên vai trò' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="description" label="Chú thích">
        <AntdInput.TextArea />
      </Form.Item>

      {!isUpdateRole && (
        <Form.Item name="cloneFrom" label="Tạo vai trò dựa trên">
          <RoleAutoComplete onChange={onRoleAutoCompleteChange} />
        </Form.Item>
      )}

      <Form.Item
        name="permissions"
        label="Quyền áp dụng"
        rules={[{ required: true, message: 'Chọn quyền áp dụng' }]}
      >
        <PermissionMultiSelect />
      </Form.Item>
    </Form>
  );
};

export default RoleFormModalContent;
