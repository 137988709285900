import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

export const getRandomAssets = async ({
  type,
  params,
  single,
  magicKey,
  magicPair,
  formName,
  ...apiProps
}) => {
  try {
    const { data } = await API.get(`/reup/${type}/random`, {
      params: { ...(params ? params : {}) },
      ...apiProps
    });

    if (data) {
      return {
        type,
        data: single ? data : data?.data,
        [formName]: data?.id || data?.data?.map(({ id }) => id),
        defaultOptions: {
          [magicKey]: single ? data : data?.data?.map(item => item)
        }
      };
    }

    notification.error({
      message: data?.errors || DEFAULT_ERROR_MESSAGE
    });

    return null;
  } catch (e) {
    notification.error({
      message: e?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
    });

    throw e;
  }
};
