import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Card, Row, Space, Table, Tag, Typography } from 'antd';
import Button from 'components/Button';
// import Table from 'components/Table';
import TotalAppendix from 'components/TotalAppendix';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { formatPrice } from 'utils/currency';
import shallow from 'zustand/shallow';
import {
  columns,
  hiddenTaxMoneyColumns,
  partnerIsCompanyColumns,
  partnerIsCompanyHiddenTaxMoneyColumns
} from './columns';
import PaymentFormModal from './PaymentFormModal';

const FirstAddendum = () => {
  const {
    addendum,
    tempPayment,
    payment,
    processAllPayment,
    disableEditAndAddPayment,
    contractUseInAddendumDetail
  } = useAddendumDetail(
    useCallback(
      ({
        addendum,
        tempPayment,
        payment,
        processAllPayment,
        disableEditAndAddPayment,
        contractUseInAddendumDetail
      }) => ({
        addendum,
        tempPayment,
        payment,
        processAllPayment,
        disableEditAndAddPayment,
        contractUseInAddendumDetail
      }),
      []
    ),
    shallow
  );

  const acceptColumns = useMemo(() => {
    if (
      contractUseInAddendumDetail?.partner?.masterData?.code === 'organization'
    ) {
      return !addendum?.doiSoatId
        ? partnerIsCompanyColumns
        : partnerIsCompanyHiddenTaxMoneyColumns;
    }

    return !addendum?.doiSoatId ? columns : hiddenTaxMoneyColumns;
  }, [addendum?.doiSoatId, contractUseInAddendumDetail]);

  return (
    <Card
      title={
        addendum?.index02 === ''
          ? addendum?.appendixCode03
          : addendum?.appendixCode01
      }
    >
      <Row justify="space-between">
        <Space direction="vertical">
          <span>
            <Typography.Text strong>Các đợt thanh toán: </Typography.Text>
            <Typography.Text>
              {payment.length} đợt thanh toán - Tổng chi phí:{' '}
              <Typography.Text className="first-addendum-total">
                {formatPrice(addendum?.totalMoney)} vnđ
              </Typography.Text>
            </Typography.Text>
          </span>

          <span>
            <Typography.Text strong>Thuế xuất áp dụng: </Typography.Text>
            <Typography.Text>
              {contractUseInAddendumDetail?.taxPercent} %
            </Typography.Text>
          </span>
        </Space>

        <PaymentFormModal>
          <Button icon={<PlusCircleOutlined />} type="link">
            Thêm đợt thanh toán
          </Button>
        </PaymentFormModal>
      </Row>

      {addendum && (
        <Table
          paginationProps={false}
          columns={acceptColumns}
          style={{ margin: '16px 0px' }}
          dataSource={[...payment, ...tempPayment]}
          summary={pageData => (
            <TotalAppendix
              pageData={pageData}
              doiSoatId={addendum?.doiSoatId}
            />
          )}
        />
      )}

      <Row justify="end">
        <Space>
          <Tag icon={<InfoCircleOutlined />} color="warning">
            Dữ liệu sẽ mất nếu như không nhấn lưu.
          </Tag>
          <Button
            disabled={disableEditAndAddPayment}
            onClick={processAllPayment}
            icon={<SaveOutlined />}
          >
            Lưu
          </Button>
        </Space>
      </Row>
    </Card>
  );
};

export default FirstAddendum;
