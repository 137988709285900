import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import shallow from 'zustand/shallow';
import { useHistory, useParams } from 'react-router-dom';
import { CONTRACT_TYPE_VALUE } from 'appConstants';

import { UploadOutlined, SendOutlined } from '@ant-design/icons';
import {
  Col,
  Collapse,
  Form,
  Radio,
  Row,
  Space,
  Table,
  Typography,
  Popconfirm
} from 'antd';

import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import Upload from 'components/Upload';
import ContractDetailLayout from '../ContractDetailLayout';
import { columns } from './columns';

import { get } from 'utils/lodash';
import { APPENDIX_PROCESS_VALUE } from 'appConstants';

import { getAppendixStatusValue } from 'utils/parser';
import { useContract } from 'stores/useContract';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { formatDate } from 'utils/date';

const { Panel } = Collapse;

const ContractNegotiation = () => {
  const [nextStatus, setNextStatus] = useState(null);
  const history = useHistory();
  const [dealAppendix, setDealAppendix] = useState([]);
  const [form] = Form.useForm();
  const { id } = useParams();
  const {
    contract,
    getContract,
    getAppendix,
    appendixContract,
    updateResult,
    createResult,
    updateAppendix,
    updateAppendixStatus,
    sendEmailNotification
  } = useContract(
    useCallback(
      ({
        contract,
        getContract,
        getAppendix,
        appendixContract,
        updateResult,
        createResult,
        updateAppendix,
        updateAppendixStatus,
        sendEmailNotification
      }) => ({
        contract,
        getContract,
        getAppendix,
        appendixContract,
        updateResult,
        createResult,
        updateAppendix,
        updateAppendixStatus,
        sendEmailNotification
      }),
      []
    ),
    shallow
  );
  const { changeAddendumStatus } = useAddendumDetail(
    useCallback(
      ({ changeAddendumStatus }) => ({
        changeAddendumStatus
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getContract(id);
  }, [getContract, id]);

  useEffect(() => {
    if (appendixContract) {
      const { signDate } = appendixContract;
      const result = appendixContract?.result
        ?.filter(r => r.status === 'deal')
        ?.shift();
      if (result) {
        if (result.isOtherReason) {
          form.setFieldsValue({ answer: 'other', reason: result.answer });
        } else {
          form.setFieldsValue({ answer: result.answer, note: result.note });
        }
      }
      form.setFieldsValue({ signDate: signDate ? moment(signDate) : null });

      const { advanceMoney, songAppendix, payment } = appendixContract;
      const status =
        advanceMoney > 0 ||
        (!!payment.length &&
          contract?.contractType === CONTRACT_TYPE_VALUE.BQTG)
          ? 'pay'
          : !!songAppendix.length
          ? 'check'
          : 'complete';
      setNextStatus(status);
    }
  }, [appendixContract, form, contract]);

  const appendix = get(contract, 'appendix', null);

  useEffect(() => {
    if (appendix && appendix.length > 0) {
      const data = appendix.filter(a => a.status === 'deal');
      setDealAppendix(data);
    }
  }, [appendix]);

  const handleSaveAppendix = async (isNextStep = false) => {
    const appendixId = appendixContract?.id;
    if (!appendixId) return;

    const formData = form.getFieldsValue();
    const { file, signDate, reason, answer, evidenceFile, note } = formData;

    if (answer) {
      const resultId = appendixContract?.result
        .filter(r => r.status === 'deal')
        .shift()?.id;
      const isOtherReason = answer === 'other';

      isOtherReason && (await form.validateFields(['reason']));

      const answerData = isOtherReason ? reason : answer;
      const params = {
        appendixId: appendixContract?.id,
        resultId,
        isOtherReason,
        question: 'Kết quả sau khi gặp đối tác ?',
        answer: answerData,
        status: 'deal',
        note: answerData === 'modify-contract' ? note : ''
      };

      if (resultId) {
        updateResult(params);
      } else {
        createResult(params);
      }
    }
    const fileParser = !!file.length
      ? file.map(f => {
          if (f.response) {
            return {
              fileName: f.response.fileName,
              fileLink: f.response.preview
            };
          }
          return { fileName: f.name, fileLink: f.url };
        })
      : null;

    const fileEvidenceParser = !!evidenceFile.length
      ? evidenceFile.map(f => {
          if (f.response) {
            return {
              fileName: f.response.fileName,
              fileLink: f.response.preview
            };
          }
          return { fileName: f.name, fileLink: f.url };
        })
      : null;

    const { index01, index02, advanceMoney, totalMoney } = appendixContract;

    const appendixData = {
      appendixId,
      signDate: signDate ? formatDate(signDate) : null,
      file: fileParser,
      evidenceFile: fileEvidenceParser,
      index01,
      index02,
      advanceMoney,
      totalMoney
    };

    const data = await updateAppendix(appendixData);
    if (isNextStep) {
      // Handle change status of appendix
      if (data) {
        const res = await updateAppendixStatus({
          appendixId,
          status: nextStatus
        });
        if (res)
          history.push(
            `/chi-tiet-hop-dong/${id}/thanh-toan-theo-dot/${appendixContract.id}`
          );
      }
    }
  };

  const onConfirm = async () => {
    try {
      const values = await form.validateFields();
      if (values) handleSaveAppendix(true);
    } catch (error) {}
  };

  const onCancel = () => {
    handleSaveAppendix(false);
  };

  const onRollBackConfirm = async () => {
    try {
      const appendixId = appendixContract?.id;
      if (appendixId) {
        const res = await changeAddendumStatus(appendixId, 'compose');
        if (res) history.push(`/chi-tiet-phu-luc/${appendixId}`);
      }
    } catch (error) {}
  };

  const onFinish = () => {
    handleSaveAppendix(true);
  };

  const handleAppendixChange = value => {
    getAppendix(value);
    form.resetFields(['answer', 'reason', 'signDate', 'file', 'evidenceFile']);
    const selectedItem = dealAppendix.find(item => item.id === value);
    const { file, evidenceFile } = selectedItem;
    form.setFieldsValue({
      file: file.map(f => ({ name: f.fileName, url: f.fileLink })),
      evidenceFile: evidenceFile.map(f => ({
        name: f.fileName,
        url: f.fileLink
      })),
      signDate: selectedItem.signDate && moment(selectedItem.signDate)
    });
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <ContractDetailLayout step={APPENDIX_PROCESS_VALUE.DEAL}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Collapse
          expandIconPosition="right"
          defaultActiveKey={['1']}
          className="mb-md"
        >
          <Panel header="Thương lượng ký hợp đồng với đối tác" key="1">
            <div>
              <Form.Item
                name="appendix"
                label="Phụ lục:"
                rules={[{ required: true, message: 'Chọn phụ lục' }]}
              >
                <Select
                  options={dealAppendix.map(a => ({
                    value: a.id,
                    label: a.index02
                      ? `${a.appendixCode01}/02.${a.index02}`
                      : a.appendixCode03
                  }))}
                  onChange={handleAppendixChange}
                />
              </Form.Item>
              <Form.Item
                name="answer"
                label="Kết quả sau khi gặp đối tác ?"
                rules={[{ required: true, message: 'Chọn câu trả lời' }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value="signed-contract">Đã ký hợp đồng</Radio>
                    <Radio value="modify-contract">
                      Cần bổ sung/ sửa thông tin trong hợp đồng
                    </Radio>
                    <Radio value="other">Đối tác quyết định không ký nữa</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                shouldUpdate={(prev, cur) => prev.answer !== cur.answer}
                noStyle
              >
                {({ getFieldValue }) => {
                  const isOther = getFieldValue('answer') === 'other';
                  return (
                    <Form.Item
                      name="reason"
                      label="Ghi chú:"
                      hidden={!isOther}
                      rules={[{ required: isOther, message: 'Nhập ghi chú' }]}
                    >
                      <TextArea />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                shouldUpdate={(prev, cur) => prev.answer !== cur.answer}
                noStyle
              >
                {({ getFieldValue }) => {
                  const isNote = getFieldValue('answer') === 'modify-contract';
                  return (
                    <Form.Item name="note" label="Ghi chú:" hidden={!isNote}>
                      <TextArea />
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Typography.Text>Thông tin phụ lục</Typography.Text>
              <div className="card-box" style={{ padding: '16px' }}>
                <Form.Item
                  shouldUpdate={(prev, cur) => prev.appendix !== cur.appendix}
                >
                  {({ getFieldValue }) => {
                    const appendixId = getFieldValue('appendix');
                    const appendixSelected = dealAppendix.filter(
                      a => a.id === appendixId
                    );
                    return (
                      <div>
                        {appendixId && (
                          <Table
                            columns={columns}
                            dataSource={appendixSelected}
                            pagination={false}
                            className="navi-table mb-md"
                          />
                        )}
                      </div>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prev, cur) =>
                    prev.answer !== cur.answer || prev.appendix !== cur.appendix
                  }
                >
                  {({ getFieldValue }) => {
                    const appendixId = getFieldValue('appendix');
                    const appendixSelected = dealAppendix.filter(
                      a => a.id === appendixId
                    );
                    const answer = getFieldValue('answer');
                    const shouldDisable = answer === 'other';
                    return (
                      <Form.Item
                        name="signDate"
                        label="Ngày kí phụ lục"
                        initialValue={moment(appendixSelected[0]?.signDate)}
                        rules={[
                          { required: !shouldDisable, message: 'Chọn ngày' }
                        ]}
                      >
                        <DatePicker
                          disabled={shouldDisable}
                          allowClear={false}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prev, cur) => prev.answer !== cur.answer}
                >
                  {({ getFieldValue }) => {
                    const answer = getFieldValue('answer');
                    const shouldDisable = answer === 'other';
                    return (
                      <>
                        <Form.Item
                          label={
                            <Space>
                              <Typography.Text>
                                Bản scan phụ lục trước khi ký:
                              </Typography.Text>
                              {!!appendixContract?.file.length && (
                                <Button
                                  onClick={() =>
                                    sendEmailNotification({
                                      id: appendixContract.id
                                    })
                                  }
                                  type="primary"
                                  ghost
                                  icon={<SendOutlined />}
                                >
                                  Gửi email
                                </Button>
                              )}
                            </Space>
                          }
                          name="file"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          rules={[
                            {
                              required: !shouldDisable,
                              message: 'Tải file'
                            }
                          ]}
                        >
                          <Upload
                            className="navi-upload"
                            name="file"
                            // maxCount={1}
                            // showUploadList={{ showRemoveIcon: false }}
                            disabled={shouldDisable}
                          >
                            <Button
                              disabled={shouldDisable}
                              icon={<UploadOutlined />}
                            >
                              Tải lên
                            </Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          label="Bản scan phụ lục đã ký:"
                          name="evidenceFile"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          rules={[
                            { required: !shouldDisable, message: 'Tải file' }
                          ]}
                        >
                          <Upload
                            className="navi-upload"
                            name="file"
                            // maxCount={1}
                            // showUploadList={{ showRemoveIcon: false }}
                            disabled={shouldDisable}
                          >
                            <Button
                              disabled={shouldDisable}
                              icon={<UploadOutlined />}
                            >
                              Tải lên
                            </Button>
                          </Upload>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.Item>
              </div>
            </div>
          </Panel>
        </Collapse>
        <Row gutter={16}>
          <Col span="8">
            <Popconfirm
              title={
                <Typography.Paragraph>
                  Chuyển về trạng thái{' '}
                  <Typography.Text strong>
                    {getAppendixStatusValue('compose')}
                  </Typography.Text>
                </Typography.Paragraph>
              }
              onConfirm={onRollBackConfirm}
              okText="Đồng ý"
              cancelText="Hủy bỏ"
            >
              <Button block htmlType="submit">
                Trả về
              </Button>
            </Popconfirm>
          </Col>
          <Col span="8">
            <Button block onClick={() => handleSaveAppendix(false)}>
              Lưu
            </Button>
          </Col>
          <Col span="8">
            <Form.Item shouldUpdate={(prev, cur) => prev.answer !== cur.answer}>
              {({ getFieldValue }) => {
                const answer = getFieldValue('answer');
                const shouldDisable = answer === 'other';
                return (
                  <Popconfirm
                    title={
                      <Typography.Paragraph>
                        Chuyển sang trạng thái{' '}
                        <Typography.Text strong>
                          {getAppendixStatusValue(nextStatus)}
                        </Typography.Text>
                      </Typography.Paragraph>
                    }
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    okText="Đồng ý"
                    cancelText="Chỉ lưu"
                  >
                    <Button block htmlType="submit" disabled={shouldDisable}>
                      Tiếp theo
                    </Button>
                  </Popconfirm>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ContractDetailLayout>
  );
};

export default ContractNegotiation;
