import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined
} from '@ant-design/icons';
import {
  Col,
  Form,
  notification,
  Popover,
  Row,
  Select as AntdSelect,
  Typography
} from 'antd';
import Button from 'components/Button';
import Input from 'components/Input';
import React, { useCallback, useEffect, useState } from 'react';
import { useContract } from 'stores/useContract';
import { useMasterData } from 'stores/useMasterData';
import shallow from 'zustand/shallow';

const { Option } = AntdSelect;

const LinkFormContext = React.createContext(null);

const FileCheckList = ({ song }) => {
  const [form] = Form.useForm();
  const [fileType, setFileType] = useState([]);

  const { getMasterDataByGroup } = useMasterData(
    useCallback(({ getMasterDataByGroup }) => ({ getMasterDataByGroup }), [])
  );

  const { updateAppendixSong, importedSongs } = useContract(
    useCallback(
      ({ updateAppendixSong, importedSongs }) => ({
        updateAppendixSong,
        importedSongs
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    (async () => {
      const data = await getMasterDataByGroup('fileCheckType');
      const types = data.map(({ code, title }) => ({
        value: code,
        label: title
      }));
      setFileType(types);
      if (types && song) {
        const {
          appendixDetail: { fileCheckList }
        } = song;

        if (!fileCheckList.length) {
          const typesRemain = types.filter(
            f => !fileCheckList.map(c => c.fileType).includes(f.value)
          );
          form.setFieldsValue({
            fileCheckList: [
              ...fileCheckList,
              ...typesRemain.map(t => ({
                fileType: t.value
              }))
            ]
          });
          return;
        }

        form.setFieldsValue({
          fileCheckList: fileCheckList
        });
      }
    })();
    return () => {
      form.resetFields();
    };
  }, [getMasterDataByGroup, form, song]);

  useEffect(() => {
    if (importedSongs) {
      const thisSong = importedSongs.find(
        songImport => songImport.song.id === song.id
      );
      if (thisSong) {
        const { fileCheckList } = thisSong;
        const currentFiles = form.getFieldValue('fileCheckList');
        const currentType = [
          ...new Set(
            [...currentFiles, ...fileCheckList].map(file => file.fileType)
          )
        ];
        const mergeFiles = currentType.map(
          type =>
            currentFiles.find(file => file.fileType === type && file.fileUrl) ||
            fileCheckList.find(file => file.fileType === type) ||
            currentFiles.find(file => file.fileType === type)
        );
        form.setFieldsValue({
          fileCheckList: mergeFiles
        });
      }
    }
  }, [importedSongs, song, form]);

  const handleAddFile = add => {
    const files = form.getFieldValue('fileCheckList');
    if (files[files.length - 1] === undefined) {
      files[files.length - 1] = { fileType: '', fileUrl: '', uploaded: true };
    }
    files[files.length - 1].uploaded = true;
    form.setFieldsValue({
      fileCheckList: [...files]
    });
    if (form.getFieldValue('fileCheckList')?.length >= fileType?.length) {
      notification.error({
        message: 'Đã đủ loại file'
      });
      return;
    }
    add();
  };

  const onFinish = values => {
    const { appendixId, songId } = song.appendixDetail;
    updateAppendixSong({ appendixId, songId, ...values });
  };

  return (
    <div>
      <Form
        name="files-form"
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <LinkFormContext.Provider value={form}>
          <Form.List
            name="fileCheckList"
            initialValue={song.appendixDetail.fileCheckList}
            rules={[
              {
                validator: async (_, fileCheckList) => {
                  if (!fileCheckList || fileCheckList.length < 1) {
                    return Promise.reject(
                      new Error('Cần tối thiểu 1 loại file')
                    );
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={() => handleAddFile(add)}
                    type="link"
                  >
                    Thêm loại file
                  </Button>
                </div>
                <Row gutter={32} style={{ textAlign: 'left' }}>
                  <Col span={6}>
                    <Typography.Text strong>Loại file</Typography.Text>
                  </Col>
                  <Col span={14}>
                    <Typography.Text strong>Link file</Typography.Text>
                  </Col>
                </Row>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Row gutter={32} align="middle" key={key}>
                    <Col span={6}>
                      <Form.Item shouldUpdate>
                        {({ getFieldValue }) => {
                          const testData = getFieldValue('fileCheckList').map(
                            field => field?.fileType
                          );
                          return (
                            <Form.Item
                              {...restField}
                              name={[name, 'fileType']}
                              fieldKey={[fieldKey, 'fileType']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Vui lòng chọn loại file'
                                }
                              ]}
                              noStyle
                            >
                              <AntdSelect>
                                {fileType?.map(field => (
                                  <Option
                                    key={field.value}
                                    value={field.value}
                                    disabled={testData.includes(field.value)}
                                  >
                                    {field.label}
                                  </Option>
                                ))}
                              </AntdSelect>
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item
                        {...restField}
                        name={[name, 'fileUrl']}
                        fieldKey={[fieldKey, 'fileUrl']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập link'
                          }
                        ]}
                      >
                        <Input
                          suffix={
                            <Popover
                              content={
                                <Form.Item
                                  {...restField}
                                  name={[name, 'note']}
                                  fieldKey={[fieldKey, 'note']}
                                  noStyle
                                >
                                  <Input
                                    defaultValue={
                                      song.appendixDetail.fileCheckList[index]
                                        ?.note
                                    }
                                  />
                                </Form.Item>
                              }
                              title="Chú thích"
                            >
                              <InfoCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)' }}
                              />
                            </Popover>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item shouldUpdate>
                        {({ getFieldValue }) => {
                          return (
                            <Button
                              ghost
                              danger
                              onClick={() => remove(name)}
                              icon={<DeleteOutlined />}
                            ></Button>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Row justify="end">
                  <Form.ErrorList errors={errors} />
                </Row>
                <Row justify="end">
                  <Button icon={<SaveOutlined />} htmlType="submit">
                    Lưu
                  </Button>
                </Row>
              </>
            )}
          </Form.List>
        </LinkFormContext.Provider>
      </Form>
    </div>
  );
};

export { LinkFormContext };
export default FileCheckList;
