import { Form, Modal, Tooltip } from 'antd';
import Button from 'components/Button';
import React, { useCallback, useState } from 'react';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';
import { KeyOutlined } from '@ant-design/icons';
import ChangePasswordFormModalContent from './ChangePasswordFormModalContent';

const ChangePasswordFormModal = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { changePasswordUser } = useUser(
    useCallback(
      ({ changePasswordUser }) => ({
        changePasswordUser
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();
      changePasswordUser({ id: user.id, ...params });
      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  return (
    <>
      {/* <Button onClick={showModal} ghost>
        Đặt lại mật khẩu
      </Button> */}
      <Tooltip title="Đặt lại mật khẩu">
        <Button icon={<KeyOutlined />} onClick={showModal}></Button>
      </Tooltip>

      <Modal
        destroyOnClose
        title={'Đổi mật khẩu'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ bỏ"
        okText="Lưu"
      >
        <ChangePasswordFormModalContent form={form} fullName={user.fullName} />
      </Modal>
    </>
  );
};

export default ChangePasswordFormModal;
