import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { REPRESENTATION_TYPE } from 'appConstants';
import { formatDate } from 'utils/date';
import { getAppendixCode } from 'utils/other';
import {
  getAppendixProcessValue,
  getAppendixStatusValue,
  getContractStatusValue,
  getModelValue,
  getSingleRankValue
} from 'utils/parser';
import UpdateRevenue from './UpdateRevenue';
import { Link as LinkRouter } from 'react-router-dom';

const { Text, Link } = Typography;

const HistoryType = ({ history }) => {
  const {
    type,
    objectType,
    previousStatus,
    currentStatus,
    updateInfo,
    object
  } = history;

  const getLabel = {
    Contract: 'Hợp đồng',
    Appendix: 'Phụ lục',
    FinishDate: 'Ngày hết hạn',
    StartDate: 'Ngày bắt đầu',
    PartnerId: 'Đối tác',
    ParentContractId: 'Hợp đồng cha',
    AutoRenew: 'Tự động ký',
    SignDate: 'Ngày ký',
    File: 'File hợp đồng',
    EvidenceFile: 'File hợp đồng đã ký',
    TotalMoney: 'Tổng tiền',
    AdvanceMoney: 'Tiền tạm ứng',
    ContractCode: 'Mã hợp đồng',
    AppendixCode01: 'Mã phụ lục 01',
    AppendixCode02: 'Mã phụ lục 02',
    Status: 'Trạng thái',
    Partner: 'Đối tác',
    IdentityPhoto: 'Hình CMND',
    Avatar: 'Ảnh đại diện',
    FullName: 'Tên đầy đủ',
    ArtistName: 'Tên biệt danh',
    Email: 'Email',
    Phone: 'Số điện thoại',
    IdentityNumber: 'Số CMND',
    DateOfIdentification: 'Ngày cấp CMND',
    PlaceOfIdentification: 'Nơi cấp CMND',
    DateOfBirth: 'Ngày sinh',
    Gender: 'Giới tính',
    TaxCode: 'Mã số thuế',
    Address: 'Địa chỉ',
    RepresentationType: 'Loại đại diện',
    RepresentationId: 'Người đại diện',
    MasterdataId: 'Testing',
    SingerRank: 'Hạng đối tác',
    BankAccountId: 'Thông tin ngân hàng',
    DelegacyFile: 'File uỷ quyền',
    ProjectContractId: 'Hợp đồng liên kết',
    Note: 'Ghi chú',
    LiquidationFile: 'File thanh lý'
  };

  const typeReject = [
    'Code',
    'UpdateBy'
    // 'AppendixCode01',
    // 'AppendixCode02',
    // 'ContractCode'
  ];
  const action = type.replace(objectType, '');
  const getStatus = (isPrev = true) => {
    return objectType === 'Contract'
      ? getContractStatusValue(isPrev ? previousStatus : currentStatus)
      : getAppendixStatusValue(isPrev ? previousStatus : currentStatus);
  };
  const getProcess = (isPrev = true) => {
    return isPrev
      ? getAppendixProcessValue(previousStatus)
      : getAppendixProcessValue(currentStatus);
  };

  const CreateContent = () => (
    <Text>
      Khởi tạo {getModelValue(objectType)}{' '}
      {objectType === 'Appendix' ? (
        <LinkRouter to={`/chi-tiet-phu-luc/${object?.id}`}>
          <Typography.Text strong>{getAppendixCode(object)}</Typography.Text>
        </LinkRouter>
      ) : null}
    </Text>
  );

  const DeleteContent = () => (
    <Text>
      Xoá {getModelValue(objectType)}{' '}
      {objectType === 'Appendix' ? (
        <LinkRouter to={`/chi-tiet-phu-luc/${object?.id}`}>
          <Typography.Text strong>{getAppendixCode(object)}</Typography.Text>
        </LinkRouter>
      ) : null}
    </Text>
  );

  const ChangeStatusContent = () => {
    return (
      <Text>
        Chuyển trạng thái {getModelValue(objectType)}
        <Text strong>{getStatus()}</Text> thành{' '}
        <Text strong>{getStatus(false)}</Text>
      </Text>
    );
  };

  const GenerateInfoDisplay = ({ label, prev, curr }) => {
    return (
      <Text>
        Thay đổi {label} <Text strong>{prev}</Text>{' '}
        <Button type="link" danger icon={<ArrowRightOutlined />}></Button>{' '}
        <Text strong>{curr}</Text>
      </Text>
    );
  };

  const UpdateInfo = () => {
    return (
      <Space direction="vertical">
        <Text>
          Chỉnh sửa thông tin <Text strong>{getLabel[objectType]}</Text>{' '}
          {objectType === 'Appendix' ? (
            <LinkRouter to={`/chi-tiet-phu-luc/${object?.id}`}>
              <Typography.Text strong>
                {getAppendixCode(object)}
              </Typography.Text>
            </LinkRouter>
          ) : null}
        </Text>
        {updateInfo.map(info => {
          const columnLabel = getLabel[info.columnName];
          if (typeReject.includes(info.columnName)) return null;
          if (!info.previousValue && !info.currentValue) return null;
          switch (info.columnName) {
            case 'RevenueSharing':
              return <UpdateRevenue info={info} />;
            case 'File':
            case 'EvidenceFile':
            case 'AdjustmentFile':
              const prevFile = info.previousValue ? info.previousValue[0] : [];
              const currFile = info.currentValue ? info.currentValue[0] : [];
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={
                    <Link href={prevFile?.FileLink} target="_blank">
                      {prevFile?.FileName}
                    </Link>
                  }
                  curr={
                    <Link href={currFile?.FileLink} target="_blank">
                      {currFile?.FileName}
                    </Link>
                  }
                />
              );
            case 'IdentityPhoto':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={
                    <Space split={<Divider type="vertical" />}>
                      {info.previousValue?.map(value => (
                        <Link href={value?.ImageLink} target="_blank">
                          {value?.ImageName}
                        </Link>
                      ))}
                    </Space>
                  }
                  curr={
                    <Space split={<Divider type="vertical" />}>
                      {info.currentValue?.map(value => (
                        <Link href={value?.ImageLink} target="_blank">
                          {value?.ImageName}
                        </Link>
                      ))}
                    </Space>
                  }
                />
              );
            case 'LiquidationFile':
            case 'DelegacyFile':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={
                    <Space split={<Divider type="vertical" />}>
                      {info.previousValue?.map(value => (
                        <Link href={value?.FileLink} target="_blank">
                          {value?.FileName}
                        </Link>
                      ))}
                    </Space>
                  }
                  curr={
                    <Space split={<Divider type="vertical" />}>
                      {info.currentValue?.map(value => (
                        <Link href={value?.FileLink} target="_blank">
                          {value?.FileName}
                        </Link>
                      ))}
                    </Space>
                  }
                />
              );
            case 'Avatar':
              return (
                <Text>
                  Thay đổi {columnLabel}{' '}
                  {info?.previousValue && (
                    <Link href={info?.previousValue} target="_blank">
                      Ảnh
                    </Link>
                  )}{' '}
                  thành{' '}
                  <Link
                    href={info?.currentValue}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ảnh
                  </Link>
                </Text>
              );
            case 'PartnerId':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={
                    info.previousValue?.artistName ||
                    info.previousValue?.fullName
                  }
                  curr={info.currentValue?.artistName}
                />
              );
            case 'ParentContractId':
            case 'ProjectContractId':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={info.previousValue?.contractCode}
                  curr={info.currentValue?.contractCode}
                />
              );
            case 'SignDate':
            case 'StartDate':
            case 'FinishDate':
            case 'DateOfIdentification':
            case 'DateOfBirth':
              return (
                <Text>
                  Thay đổi {columnLabel}{' '}
                  <Text strong>
                    {info.previousValue && formatDate(info.previousValue)}
                  </Text>{' '}
                  thành <Text strong>{formatDate(info.currentValue)}</Text>
                </Text>
              );
            case 'AutoRenew':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={info.previousValue ? 'Có' : 'Không'}
                  curr={info.currentValue ? 'Có' : 'Không'}
                />
              );
            case 'Status':
              return objectType === 'Contract' ? (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={getContractStatusValue(info.previousValue)}
                  curr={getContractStatusValue(info.currentValue)}
                />
              ) : (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={getAppendixStatusValue(info.previousValue)}
                  curr={getAppendixStatusValue(info.currentValue)}
                />
              );
            case 'SingerRank':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={getSingleRankValue(info?.previousValue?.toString())}
                  curr={getSingleRankValue(info?.currentValue?.toString())}
                />
              );
            case 'RepresentationType':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={REPRESENTATION_TYPE[info.previousValue]}
                  curr={REPRESENTATION_TYPE[info.currentValue]}
                />
              );
            case 'MasterDataId':
              return (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={info.previousValue?.title}
                  curr={info.currentValue?.title}
                />
              );
            case 'BankAccountId':
              return (
                <Row gutter={[32, 16]}>
                  <Col span={24}>Thay đổi {columnLabel}</Col>
                  <Col>
                    <Row gutter={32} align="middle">
                      <Col>
                        <Card size="small">
                          <Space direction="vertical">
                            <Text>
                              Ngân hàng: {info.previousValue?.bankName}
                            </Text>
                            <Text>
                              Số tài khoản: {info.previousValue?.accountNumber}
                            </Text>
                            <Text>
                              Tên chủ tài khoản:{' '}
                              {info.previousValue?.accountName}
                            </Text>
                            <Text>
                              Chi nhánh: {info.previousValue?.bankBranch}
                            </Text>
                          </Space>
                        </Card>
                      </Col>
                      <Col>
                        <ArrowRightOutlined style={{ fontSize: 16 }} />
                      </Col>
                      <Col>
                        <Card size="small">
                          <Space direction="vertical">
                            <Text>
                              Ngân hàng: {info.currentValue?.bankName}
                            </Text>
                            <Text>
                              Số tài khoản: {info.currentValue?.accountNumber}
                            </Text>
                            <Text>
                              Tên chủ tài khoản:{' '}
                              {info.currentValue?.accountName}
                            </Text>
                            <Text>
                              Chi nhánh: {info.currentValue?.bankBranch}
                            </Text>
                          </Space>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );

            default:
              return !info.previousValue && !info.currentValue ? null : (
                <GenerateInfoDisplay
                  label={columnLabel}
                  prev={info.previousValue}
                  curr={info.currentValue}
                />
              );
          }
        })}
      </Space>
    );
  };

  const ChangeProcessContent = () => {
    return (
      <Text>
        Chuyển tiến độ {getModelValue(objectType)}
        <Text strong>{getProcess()}</Text> thành{' '}
        <Text strong>{getProcess(false)}</Text>
      </Text>
    );
  };

  const MainContent = () => {
    switch (action) {
      case 'create':
        return <CreateContent />;
      case 'delete':
        return <DeleteContent />;
      case 'update':
        return <UpdateInfo />;
      case 'changeStatus':
        return <ChangeStatusContent />;
      case 'changeProcess':
        return <ChangeProcessContent />;

      default:
        break;
    }
  };

  return <MainContent />;
};

export default HistoryType;
