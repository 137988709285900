import React, { useCallback, useEffect, useState } from 'react';
import { Card, Form, Row, Space, Tag } from 'antd';
import { SaveOutlined, InfoCircleOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';
import { useParams } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';

import { useDepartmentDetail } from 'stores/useDepartmentDetail';

const DepartmentInfo = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const { updateDepartment, department, isDepartmentDetailLoading } =
    useDepartmentDetail(
      useCallback(
        ({ updateDepartment, department, isDepartmentDetailLoading }) => ({
          updateDepartment,
          department,
          isDepartmentDetailLoading
        }),
        []
      ),
      shallow
    );

  const onClick = async () => {
    setLoading(true);

    const params = await form.getFieldsValue();
    await updateDepartment(params, id);

    setLoading(false);
  };

  useEffect(() => {
    if (department) {
      form.setFieldsValue({ name: department?.name });
    }
  }, [form, department]);

  return (
    <Card title="Thông tin phòng ban">
      <Form form={form}>
        <Form.Item
          label="Tên phòng ban"
          name="name"
          rules={[{ required: true, message: 'Nhập tên phòng ban' }]}
        >
          <Input loading={loading || isDepartmentDetailLoading} />
        </Form.Item>
      </Form>

      <Row justify="end">
        <Space>
          <Tag icon={<InfoCircleOutlined />} color="warning">
            Dữ liệu sẽ mất nếu như không nhấn lưu.
          </Tag>

          <Button
            onClick={onClick}
            icon={<SaveOutlined />}
            loading={loading || isDepartmentDetailLoading}
          >
            Lưu
          </Button>
        </Space>
      </Row>
    </Card>
  );
};

export default DepartmentInfo;
