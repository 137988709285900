import React from 'react';

import queryString from 'query-string';
import FilterBox from 'components/FilterBox';

import { FILTER_TYPE } from 'appConstants';

import { filterToUrl, genInitValueCustom } from 'components/FilterBox/utils';
import { useHistory, useLocation } from 'react-router-dom';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Tên nhân viên',
    name: 'FullName'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Username',
    name: 'Username'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Số điện thoại',
    name: 'Phone'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Email',
    name: 'Email'
  }
];

export const data = [
  {
    mainTitle: 'Nhân viên',
    filterItems
  }
];

const UserFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  delete searchParams.page;
  delete searchParams.sort;

  const setFilter = (key, { value, operator, operatorFrom, operatorTo }) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo },
      searchParams,
      history,
      '/danh-sach-nhan-vien'
    );
  };

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      initValue={genInitValueCustom(searchParams, filterItems)}
    />
  );
};

export default UserFilterBox;
