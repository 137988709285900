import { SaveOutlined } from '@ant-design/icons';
import { Card, Col, Form, Row, Space, Spin, Typography } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import Button from 'components/Button';
import RightAddendumDetailInfo from 'pages/AddendumDetail/components/AddendumDetailInfo/RightAddendumDetailInfo';

import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import Input from 'components/Input';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import ContractStatusTag from 'containers/ContractStatusTag';
import AddendumStatusTag from 'pages/AddendumDetail/components/AddendumStatusTag';
import ContracTypeTag from 'pages/AddendumDetail/components/ContracTypeTag';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateAddendum } from 'stores/useCreateAddendum';
import { formatDate } from 'utils/date';
import ContractAutocomplete from 'containers/ContractAutocomplete';

const CreateAddendum = () => {
  const { id } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const {
    getContractUseInCreateAddendum,
    contractUseInCreateAddendum,
    createAddendum,
    getAppendixIndex,
    appendixIndex,
    isAppendixLoading
  } = useCreateAddendum(
    useCallback(
      ({
        getContractUseInCreateAddendum,
        contractUseInCreateAddendum,
        createAddendum,
        getAppendixIndex,
        appendixIndex,
        isAppendixLoading
      }) => ({
        getContractUseInCreateAddendum,
        contractUseInCreateAddendum,
        createAddendum,
        getAppendixIndex,
        appendixIndex,
        isAppendixLoading
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (id) {
      getAppendixIndex(id);
    }
  }, [getAppendixIndex, id]);

  const indexOptions = useMemo(
    () =>
      appendixIndex.map(({ appendixCode, index01, index03 }) => ({
        value: appendixCode,
        label: appendixCode
      })),
    [appendixIndex]
  );

  // const addendumCodeOptions = () => {
  //   const getIndexes = () => {
  //     if (contractUseInCreateAddendum?.appendix.length === 0) {
  //       return [
  //         [0, 1],
  //         [1, 1]
  //       ];
  //     }

  //     const arr = contractUseInCreateAddendum
  //       ? contractUseInCreateAddendum?.appendix.map(({ index02 }) => index02)
  //       : [];

  //     let largest = 0;

  //     if (arr.length) {
  //       largest = Math.max(...arr);
  //     }

  //     const largestPlusOne = largest + 1;

  //     return [
  //       [largest, largestPlusOne],
  //       [largestPlusOne, largestPlusOne]
  //     ];
  //   };

  //   const [first, second] = getIndexes();

  //   return [
  //     {
  //       label: `${contractUseInCreateAddendum?.contractCode}-01.${first[0]}/02.${first[1]}`,
  //       value: `${first[0]}-${first[1]}`
  //     },
  //     {
  //       label: `${contractUseInCreateAddendum?.contractCode}-01.${second[0]}/02.${second[1]}`,
  //       value: `${second[0]}-${second[1]}`
  //     }
  //   ];
  // };

  const onSave = async () => {
    try {
      let params = await form.validateFields();
      const indexRecord = appendixIndex.find(
        index => index.appendixCode === params.indexes
      );
      const { index01, index02, index03 } = indexRecord;
      const callback = id => history.push(`/chi-tiet-phu-luc/${id}`);

      return createAddendum(
        {
          index01,
          index02,
          index03,
          contractId: id,
          finishDate: params.finishDate && formatDate(params.finishDate),
          startDate: params.startDate && formatDate(params.startDate),
          deductedInAdvanceMoney: params.deductedInAdvanceMoney,
          note: params.note,
          projectContractId: params.projectContractId || ''
        },
        callback
      );
    } catch ({ data }) {
      // eslint-disable-next-line no-console
    }
  };

  useEffect(() => {
    getContractUseInCreateAddendum(id);
  }, [id, getContractUseInCreateAddendum]);

  return (
    <Spin spinning={isAppendixLoading}>
      <Typography.Title>Tạo phụ lục mới</Typography.Title>
      <Card title="Thông tin cơ bản">
        <Row gutter={32}>
          <Col span={12}>
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Mã hợp đồng">
                    <Input
                      disabled
                      value={contractUseInCreateAddendum?.contractCode}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Mã phụ lục"
                    name="indexes"
                    rules={[{ required: true, message: 'Chọn mã phụ lục!' }]}
                  >
                    <Select
                      options={indexOptions}
                      placeHolder="Chọn mã phụ lục"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Hợp đồng liên kết" name="projectContractId">
                    <ContractAutocomplete />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Typography.Paragraph>
                    <Form.Item
                      label="Ngày bắt đầu hiệu lực"
                      name="startDate"
                      rules={[
                        { required: true, message: 'Chọn ngày bắt đầu!' }
                      ]}
                    >
                      <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                  </Typography.Paragraph>
                </Col>

                <Col span={12}>
                  <Typography.Paragraph>
                    <Form.Item label="Ngày kết thúc hiệu lực" name="finishDate">
                      <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                  </Typography.Paragraph>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Ngày dự kiến giao sản phẩm"
                    name="expectedFileDeliveryDate"
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Ghi chú" name="note">
                    <TextArea placeholder="Ghi chú" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Typography.Paragraph>Trừ ứng trước:</Typography.Paragraph>
                </Col>

                <Col span={16}>
                  <Form.Item
                    name="deductedInAdvanceMoney"
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Typography.Paragraph>Loại hợp đồng:</Typography.Paragraph>
                </Col>

                <Col span={16}>
                  <Typography.Paragraph>
                    <ContracTypeTag
                      value={contractUseInCreateAddendum?.contractType}
                    />
                  </Typography.Paragraph>
                </Col>

                <Col span={8}>
                  <Typography.Paragraph>
                    Trạng thái hợp đồng:
                  </Typography.Paragraph>
                </Col>

                <Col span={16}>
                  <Typography.Paragraph>
                    <ContractStatusTag
                      value={contractUseInCreateAddendum?.status}
                    />
                  </Typography.Paragraph>
                </Col>

                <Col span={8}>
                  <Typography.Paragraph>
                    Trạng thái phụ lục:
                  </Typography.Paragraph>
                </Col>

                <Col span={16}>
                  <Typography.Paragraph>
                    <AddendumStatusTag value={'compose'} />
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col span={12} className="addendum-detail-info--right">
            <RightAddendumDetailInfo contract={contractUseInCreateAddendum} />
          </Col>
        </Row>

        <Row justify="end" style={{ marginTop: '16px' }}>
          <Space>
            <Button onClick={onSave} icon={<SaveOutlined />}>
              Lưu
            </Button>
          </Space>
        </Row>
      </Card>
    </Spin>
  );
};

export default CreateAddendum;
