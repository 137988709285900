import React, { useCallback, useMemo, useState } from 'react';
import { Form, Modal } from 'antd';

import PaymentFormModalContent from './PaymentFormModalContent';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';

const PaymentFormModal = ({ children, initValue }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const isUpdatePayment = useMemo(() => !!initValue, [initValue]);

  const {
    pushTempPayment,
    tempPayment,
    findAndUpdatePayment,
    findAndUpdateTempPayment,
    disableEditAndAddPayment
  } = useAddendumDetail(
    useCallback(
      ({
        pushTempPayment,
        tempPayment,
        findAndUpdatePayment,
        findAndUpdateTempPayment,
        disableEditAndAddPayment
      }) => ({
        pushTempPayment,
        tempPayment,
        findAndUpdatePayment,
        findAndUpdateTempPayment,
        disableEditAndAddPayment
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      let values = await form.validateFields();
      const params = {
        ...values,
        paymentDate: values.paymentDate && formatDate(values.paymentDate)
      };

      if (!params.totalMoney) return;

      if (isUpdatePayment) {
        if (initValue.id) {
          findAndUpdatePayment({ ...initValue, ...params, send: true });
        } else {
          findAndUpdateTempPayment({ ...initValue, ...params, send: true });
        }
      } else {
        pushTempPayment({
          ...params,
          appendixId: id,
          send: true,
          index: tempPayment.length
        });
      }

      setIsModalVisible(false);
    } catch ({ data }) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal,
          disabled: disableEditAndAddPayment
        })}

      <Modal
        title={
          isUpdatePayment ? 'Chỉnh sửa đợt thanh toán ' : 'Thêm đợt thanh toán'
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
        destroyOnClose
      >
        <PaymentFormModalContent form={form} initValue={initValue} />
      </Modal>
    </>
  );
};

export default PaymentFormModal;
