import { Form } from 'antd';
// import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from 'antd';

const FormItem = ({ children, label, ...rest }) => {
  return (
    <Form.Item
      label={<Typography.Text strong>{label}</Typography.Text>}
      {...rest}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
