import { Card, Divider, Empty, Space, Typography } from 'antd';
import { Music } from 'components/Icons';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDashboard } from 'stores/useDashboard';
import { redirectByStatus } from 'utils/redirect';
import shallow from 'zustand/shallow';

const { Text } = Typography;

const CheckContract = () => {
  const { checkContracts, getCheckContracts } = useDashboard(
    useCallback(
      ({ checkContracts, getCheckContracts }) => ({
        checkContracts,
        getCheckContracts
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getCheckContracts();
  }, [getCheckContracts]);

  const data = useMemo(() => checkContracts?.data, [checkContracts?.data]);

  return (
    <Card
      title="Kiểm tra sản phẩm"
      extra={
        <Text strong type="success">
          {data?.length}
          {checkContracts?.total && `/${checkContracts?.total}`}
        </Text>
      }
      bodyStyle={{
        padding: 0,
        maxHeight: '330px',
        minHeight: '330px',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: !data?.length ? 'center' : 'initial'
      }}
      style={{ borderRadius: 8 }}
    >
      {!!data?.length ? (
        <Space direction="vertical" style={{ width: '100%' }}>
          {!!data?.length &&
            data?.map(item => (
              <div key={item.id}>
                <Space align="center" style={{ padding: 16 }}>
                  <Space direction="vertical" size={0}>
                    <Link
                      to={redirectByStatus(
                        item.status,
                        item.contractId,
                        item.id
                      )}
                    >
                      <Typography.Text strong>
                        {`${item.appendixCode01}-01.${item.index01}/02.${item.index02}`}
                      </Typography.Text>
                    </Link>
                    <Text>
                      <Music style={{ fontSize: 16 }} />{' '}
                      {item.songAppendix.length} bài hát
                    </Text>
                  </Space>
                </Space>
                <Divider style={{ margin: 0 }} />
              </div>
            ))}
        </Space>
      ) : (
        <Empty description={<Text>Không có dữ liệu</Text>} />
      )}
    </Card>
  );
};

export default CheckContract;
