import React, { useCallback, useEffect, useMemo, useState } from 'react';
import shallow from 'zustand/shallow';
import { Row, Col, Space, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Button from 'components/Button';
import Table from 'components/Table';

import { CommonContainer } from 'components/StyledComponents';
import MasterDataFormModal from './components/MasterDataFormModal';
import { columns } from './columns';
import OrderBySelect from 'components/OrderBySelect';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import { ORDER_BY_SELECT_VALUE, PAGE_SIZE } from 'appConstants';
import { useMasterData } from 'stores/useMasterData';
import MasterDataFilterBox from './components/MasterDataFilterBox';

const MasterData = () => {
  const { masterDatas, getMasterDatas, deleteMasterDatas, setParams, total } =
    useMasterData(
      useCallback(
        ({
          masterDatas,
          getMasterDatas,
          deleteMasterDatas,
          setParams,
          total
        }) => ({
          masterDatas,
          getMasterDatas,
          deleteMasterDatas,
          setParams,
          total
        }),
        []
      ),
      shallow
    );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-master-data',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-master-data',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    const paramsForGet = { ...searchParams, page: page - 1, sort };

    setParams(paramsForGet);
    getMasterDatas(paramsForGet);
  }, [getMasterDatas, page, searchParams, setParams, sort]);

  return (
    <div>
      <Typography.Title>Master data</Typography.Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>
            {/* <SearchBox /> */}
            <MasterDataFilterBox />
          </Col>
          <Col>
            <Space>
              {selectedRows.length !== 0 && (
                <DeleteConfirmPopup
                  title={`Xoá ${selectedRows.length} master data?`}
                  onDelete={() => {
                    deleteMasterDatas({ ids: selectedRows });
                    setSelectedRows([]);
                  }}
                >
                  <Button danger>Xoá ({selectedRows.length})</Button>
                </DeleteConfirmPopup>
              )}

              {/* <Dropdown
                title="Excel"
                items={[
                  { value: 'import', label: 'Nhập' },
                  { value: 'export', label: 'Xuất' }
                ]}
              /> */}

              <OrderBySelect defaultValue={sort} onChange={setOrderBy} />

              <MasterDataFormModal>
                <Button>Tạo mới</Button>
              </MasterDataFormModal>
            </Space>
          </Col>
        </Row>

        {/* <MasterDataFilterBox /> */}

        <Table
          columns={columns}
          dataSource={masterDatas.map(item => ({ key: item.id, ...item }))}
          paginationProps={{
            total: total,
            pageSize: PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
          rowSelection={rowSelection}
        />
      </CommonContainer>
    </div>
  );
};

export default MasterData;
