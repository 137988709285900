import { Button, Row, Space, Typography } from 'antd';
import { CommonContainer } from 'components/StyledComponents';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useReviewDetail } from 'stores/useReviewDetail';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';
import BankAccount from './components/BankAccount';
import CompanyInfo from './components/CompanyInfo';
import ManagerInfo from './components/ManagerInfo';
import PartnerInfo from './components/PartnerInfo';
import ContractInfo from './components/ContractInfo';
import AppendixInfo from './components/AppendixInfo';
import PartnerIsCompanyInfo from './components/PartnerIsCompanyInfo';
import ChangeAppendixStatus from './components/ChangeAppendixStatus';
import CommentReview from 'components/CommentReview';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ApproveAppendix from './components/ApproveAppendix/ApproveAppendix';
import ApproveButton from './components/ApproveAppendix/ApproveButton';

const AppendixReview = () => {
  const { id } = useParams();

  const { appendixReview, getAppendixReview } = useReviewDetail(
    useCallback(
      ({ appendixReview, getAppendixReview }) => ({
        appendixReview,
        getAppendixReview
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (id) getAppendixReview(id);
  }, [getAppendixReview, id]);

  const contract = useMemo(
    () => get(appendixReview, 'contract', null),
    [appendixReview]
  );

  const partner = get(contract, 'partner', null);
  const manager = get(partner, 'manager', null);
  const company = get(partner, 'company', null);
  const bankAccount = get(partner, 'bankAccount', null);
  const representationType = get(partner, 'representationType', null);

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Typography.Title>Review thông tin phụ lục</Typography.Title>

        <Space>
          <ApproveButton />
          <ChangeAppendixStatus />
          <Button>
            <Link to={`/chi-tiet-phu-luc/${appendixReview?.id}`}>
              <Typography.Text>
                Chỉnh sửa <EditOutlined />
              </Typography.Text>
            </Link>
          </Button>
        </Space>
      </Row>

      <CommonContainer>
        <CommentReview />
        {partner?.masterData?.code === 'organization' ? (
          <PartnerIsCompanyInfo partner={partner} />
        ) : (
          <PartnerInfo partner={partner} />
        )}
        {manager && representationType === 'manager' && (
          <ManagerInfo manager={manager} />
        )}
        {company && representationType === 'company' && (
          <CompanyInfo company={company} />
        )}
        {bankAccount && <BankAccount bankAccount={bankAccount} />}
        <ContractInfo contract={contract} />
        <AppendixInfo appendix={appendixReview} />
        <ApproveAppendix />
      </CommonContainer>
    </div>
  );
};

export default AppendixReview;
