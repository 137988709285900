import { Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';
import SortSongFormModalContent from './SortSongFormModalContent';

const SortSongFormModal = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();

  const { tempSortSongAddendum, orderSongAppendix } = useAddendumDetail(
    useCallback(
      ({ tempSortSongAddendum, orderSongAppendix }) => ({
        tempSortSongAddendum,
        orderSongAppendix
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      // Save songs
      await orderSongAppendix(
        {
          appendixId: id,
          songIds: tempSortSongAddendum.map(song => song.songId)
        },
        true,
        () => setIsModalVisible(false)
      );
      // setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}
      <Modal
        destroyOnClose
        title="Sắp xếp bài hát"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
        className="sort-modal"
        bodyStyle={{
          height: 'auto',
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'auto'
        }}
      >
        <SortSongFormModalContent />
      </Modal>
    </>
  );
};

export default SortSongFormModal;
