import React, { useCallback, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useDepartmentDetail } from 'stores/useDepartmentDetail';
import shallow from 'zustand/shallow';
import DepartmentInfo from './components/DepartmentInfo';
// import Access from './Access';
import Subscribe from './Subscribe';

const DepartmentDetail = () => {
  const { id } = useParams();

  const { getDepartment } = useDepartmentDetail(
    useCallback(
      ({ getDepartment }) => ({
        getDepartment
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getDepartment(id);
  }, [getDepartment, id]);

  return (
    <div>
      <Row justify="space-between" align="middle" className="mb-md">
        <Col>
          <Typography.Title>Chi tiết phòng ban</Typography.Title>
        </Col>
      </Row>

      <DepartmentInfo />

      <Subscribe />

      {/* <Access /> */}
    </div>
  );
};

export default DepartmentDetail;
