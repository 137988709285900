import React from 'react';
import { Typography, Row, Table, Card, Empty } from 'antd';

const { Text } = Typography;

const DashboardTable = ({ data, columns, title, total }) => {
  return !!data?.length ? (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      title={() => (
        <Row justify="space-between">
          <Text strong>{title}</Text>
          <Text strong type="success">
            {data.length}
            {total && `/${total}`}
          </Text>
        </Row>
      )}
      {...(data.length > 4 ? { scroll: { y: 330 } } : null)}
      className="table-dashboard"
    />
  ) : (
    <Card
      title={title}
      style={{ height: '100%' }}
      extra={
        <Text strong type="success">
          {data?.length}
        </Text>
      }
    >
      <Empty description={<Text>Không có dữ liệu</Text>} />
    </Card>
  );
};

export default DashboardTable;
