import { FILTER_TYPE } from 'appConstants';
import FilterBox from 'components/FilterBox';
import { filterToUrl, genInitValueCustom } from 'components/FilterBox/utils';
import SongAutocompleteFilter from 'containers/SongAutocompleteFilter';
import queryString from 'query-string';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSongRelease } from 'stores/useSongRelease';
import { getAppendixCode } from 'utils/other';
import shallow from 'zustand/shallow';
import AppendixAutoComplete from './AppendixAutoComplete';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Link',
    name: 'Link'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Bài hát',
    name: 'SongId',
    placeHolder: 'Tìm bài hát',
    isUsingChild: true,
    label: 'songName',
    autocompleteComponent: <SongAutocompleteFilter placeHolder="Tìm bài hát" />
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Phụ lục',
    name: 'AppendixId',
    placeHolder: 'Tìm phụ lục',
    isUsingChild: true,
    label: 'appendix',
    getLabel: getAppendixCode,
    autocompleteComponent: <AppendixAutoComplete />
  }
];

export const data = [
  {
    mainTitle: 'Phát hành',
    filterItems
  }
];

const SongReleaseFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  const { getSong, getAppendixById } = useSongRelease(
    useCallback(
      ({ getSong, getAppendixById }) => ({
        getSong,
        getAppendixById
      }),
      []
    ),
    shallow
  );

  delete searchParams.page;
  delete searchParams.sort;

  const setFilter = (
    key,
    { value, operator, operatorFrom, operatorTo },
    otherType
  ) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo, otherType },
      searchParams,
      history,
      '/danh-sach-phat-hanh'
    );
  };

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      initValue={genInitValueCustom(searchParams, filterItems, {
        SongId: getSong,
        AppendixId: getAppendixById
      })}
      // childComponent={<SongReleaseAutoComplete />}
      getExtendOptions={{
        SongId: getSong,
        AppendixId: getAppendixById
      }}
    />
  );
};

export default React.memo(SongReleaseFilterBox);
