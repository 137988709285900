import {
  CONTRACT_FILTER_STATUS,
  CONTRACT_TYPE,
  FILTER_TYPE,
  SYNC_STATUS
} from 'appConstants';
import FilterBox from 'components/FilterBox';
import { filterToUrl, genInitValueCustom } from 'components/FilterBox/utils';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePartner } from 'stores/usePartner';
import shallow from 'zustand/shallow';
import PartnerAutoComplete from './PartnerAutoComplete';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Mã hợp đồng',
    name: 'ContractCode'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Tự động ký lại',
    name: 'AutoRenew',
    options: [
      { value: 'true', label: 'Có' },
      { value: 'false', label: 'Không' }
    ]
  },

  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Đối tác',
    name: 'PartnerId',
    placeHolder: 'Tìm đối tác',
    isUsingChild: true,
    label: 'artistName',
    autocompleteComponent: <PartnerAutoComplete />
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Loại hợp đồng',
    name: 'ContractType',
    options: CONTRACT_TYPE
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Thời gian ký hợp đồng',
    name: 'SignDate'
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Ngày bắt đầu hợp đồng',
    name: 'StartDate'
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Thời hạn hợp đồng',
    name: 'FinishDate'
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Thời gian tạo hợp đồng',
    name: 'CreateDate'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Trạng thái hợp đồng',
    name: 'status',
    options: CONTRACT_FILTER_STATUS,
    otherType: true
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Thời gian đồng bộ',
    name: 'SyncDate'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Trạng thái đồng bộ',
    name: 'SyncStatus',
    options: SYNC_STATUS
  }
];
const options = [
  {
    mainTitle: 'Thông tin',
    filterItems
  }
];

const ContractFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  const { getPartner } = usePartner(
    useCallback(
      ({ getPartner }) => ({
        getPartner
      }),
      []
    ),
    shallow
  );

  const extraParams = {
    'UserId[eq]': searchParams['UserId[eq]'],
    UserName: searchParams.UserName
  };

  delete searchParams.page;
  delete searchParams.sort;
  delete searchParams['UserId[eq]'];
  delete searchParams.UserName;

  const setFilter = (
    key,
    { value, operator, operatorFrom, operatorTo },
    otherType
  ) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo, otherType },
      searchParams,
      history,
      '/danh-sach-hop-dong',
      extraParams
    );
  };

  return (
    <FilterBox
      options={options}
      setFilter={setFilter}
      initValue={genInitValueCustom(searchParams, filterItems, {
        PartnerId: getPartner
      })}
      getExtendOptions={{
        PartnerId: getPartner
      }}
    />
  );
};

export default ContractFilterBox;
