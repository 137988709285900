import create from 'zustand';
import API from 'api';

import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import { useAddendumDetail } from './useAddendumDetail';

const getContractUseInAddendumDetail =
  useAddendumDetail.getState().getContractUseInAddendumDetail;

export const useCreateAddendum = create((set, get) => ({
  contractUseInCreateAddendum: null,
  appendixIndex: [],
  isAppendixLoading: false,
  getContractUseInCreateAddendum: async id => {
    try {
      const data = await getContractUseInAddendumDetail(id);

      if (data) {
        set({ contractUseInCreateAddendum: data });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  createAddendum: async (params, callback) => {
    set({ isAppendixLoading: true });
    try {
      const { data } = await API.post('/appendix', params);

      set({ isAppendixLoading: false });

      if (data) {
        callback(data.id);
        notification.success({
          message: 'Tạo phụ lục thành công!'
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isAppendixLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getAppendixIndex: async id => {
    try {
      const {
        data: { data }
      } = await API.get(`/contract/${id}/valid-appendix-index`);

      if (data) {
        set({ appendixIndex: data });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
