import { Steps } from 'antd';
import { APPENDIX_PROCESS_VALUE } from 'appConstants';
import { useCallback } from 'react';
import { useContract } from 'stores/useContract';
import shallow from 'zustand/shallow';
const { Step } = Steps;

const ContractProgress = ({ step }) => {
  const { newestAppendix } = useContract(
    useCallback(
      ({ newestAppendix }) => ({
        newestAppendix
      }),
      []
    ),
    shallow
  );

  const customDot = (dot, { status, index }) => {
    return <span className={`ant-steps-icon-dot step-${index}`}></span>;
  };
  const getCurrentStep = useCallback(() => {
    switch (step) {
      case APPENDIX_PROCESS_VALUE.COMPOSE:
        switch (newestAppendix?.status) {
          case APPENDIX_PROCESS_VALUE.DEAL:
            return 1;
          case APPENDIX_PROCESS_VALUE.PAY:
            return 2;
          case APPENDIX_PROCESS_VALUE.CHECK:
            return 3;
          case APPENDIX_PROCESS_VALUE.COMPLETE:
            return 4;
          default:
            return 0;
        }
      case APPENDIX_PROCESS_VALUE.DEAL:
        return 1;
      case APPENDIX_PROCESS_VALUE.PAY:
        return 2;
      case APPENDIX_PROCESS_VALUE.CHECK:
        return 3;
      case APPENDIX_PROCESS_VALUE.COMPLETE:
        return 4;

      default:
        break;
    }
  }, [newestAppendix, step]);

  return (
    <div className="contract-progress-step">
      <Steps progressDot={customDot} current={getCurrentStep()}>
        <Step title="Soạn phụ lục" />
        <Step title="Trình đối tác ký hợp đồng" />
        <Step title="Thanh toán theo đợt" />
        <Step title="Kiểm tra sản phẩm" />
        <Step title="Hoàn tất" />
      </Steps>
    </div>
  );
};

export default ContractProgress;
