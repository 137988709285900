import {
  CopyOutlined,
  PlusCircleOutlined,
  SendOutlined
} from '@ant-design/icons';
import {
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
  Tag,
  TimePicker,
  Typography
} from 'antd';
import {
  CHANNEL_TYPE_OPTION,
  DIGITAL_OPTION,
  TIKTOK_OPTION
} from 'appConstants';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import FormItem from 'components/FormItem';
import TimeMail from 'components/Icons/TimeMail';
import Select from 'components/Select';
import { CommonContainer } from 'components/StyledComponents';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSongRelease } from 'stores/useSongRelease';
import { formatDate, formatDateTime, formatTime } from 'utils/date';
import { extractPartnersFromSong } from 'utils/other';
import shallow from 'zustand/shallow';
import PublisherAutoComplete from './components/PublisherAutoComplete';
import ReviewMail from './components/ReviewMail';
import SongReleaseAutoComplete from './components/SongReleaseAutoComplete';
import GroupMailFormModal from './GroupMailFormModal';

const GroupMailLabel = () => (
  <Row justify="space-between" align="middle" style={{ width: '100%' }}>
    <Col>
      <Typography.Text strong>Danh sách mail</Typography.Text>
    </Col>
    <Col>
      <GroupMailFormModal>
        <Button type="link" size="small" icon={<PlusCircleOutlined />}>
          Tạo mới danh sách mail
        </Button>
      </GroupMailFormModal>
    </Col>
  </Row>
);

const SongReleaseDetail = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [dataReview, setDataReview] = useState(null);
  const history = useHistory();
  const isInitialMount = useRef(true);
  const [shouldSyncSongName, setShouldSyncSongName] = useState(false);

  const {
    getSongRelease,
    songRelease,
    getAppendixForSong,
    songAppendixes,
    getMailGroup,
    mailGroups,
    createSongRelease,
    updateSongRelease,
    currentSong,
    setCurrentSong,
    resetSongRelease,
    isSongReleaseLoading
  } = useSongRelease(
    useCallback(
      ({
        getSongRelease,
        songRelease,
        getAppendixForSong,
        songAppendixes,
        getMailGroup,
        mailGroups,
        createSongRelease,
        updateSongRelease,
        currentSong,
        setCurrentSong,
        resetSongRelease,
        isSongReleaseLoading
      }) => ({
        getSongRelease,
        songRelease,
        getAppendixForSong,
        songAppendixes,
        getMailGroup,
        mailGroups,
        createSongRelease,
        updateSongRelease,
        currentSong,
        setCurrentSong,
        resetSongRelease,
        isSongReleaseLoading
      }),
      []
    ),
    shallow
  );

  const isUpdateSongRelease = useMemo(() => id, [id]);

  useEffect(() => {
    if (isUpdateSongRelease) {
      getSongRelease(id);
    }
    return () => {
      resetSongRelease();
      setDataReview(null);
      setCurrentSong(null);
    };
  }, [
    getSongRelease,
    id,
    isUpdateSongRelease,
    resetSongRelease,
    setCurrentSong
  ]);

  // Init value
  useEffect(() => {
    if (songRelease) {
      const {
        song,
        digitalMusic,
        tiktok,
        releaseDate,
        releaseTime,
        channelType,
        channel,
        link,
        appendixId,
        mailGroup,
        publisher,
        note,
        songName: songNameRelease
      } = songRelease;
      setDataReview({
        ...songRelease,
        publisherName: channelType === 'internal' ? publisher.name : channel
      });

      // get partner song name
      const { id, songName, partnerSong, songType, songTypeName } = song;
      const [musicians, singers] = extractPartnersFromSong(partnerSong, true);

      const musician = musicians.map(({ label }) => label).join(', ');
      const singer = singers.map(({ label }) => label).join(', ');
      const songParser = {
        label: songName + ' - ' + singer,
        songName,
        value: id,
        singer,
        musician,
        songType,
        songTypeName
      };
      setCurrentSong(songParser);

      form.setFieldsValue({
        songId: songRelease?.songId,
        digitalMusic,
        tiktok,
        releaseDate: moment(releaseDate),
        releaseTime: moment(releaseTime, 'HH:mm'),
        channelType,
        channel,
        link,
        appendixId,
        mailGroupId: mailGroup.id,
        note,
        songName: songNameRelease
      });
    }
  }, [songRelease, form, setCurrentSong]);

  useEffect(() => {
    getMailGroup();
  }, [getMailGroup]);

  useEffect(() => {
    if (currentSong) {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        form.resetFields(['appendixId']);
      }
      getAppendixForSong(currentSong.value);
    }
  }, [currentSong, getAppendixForSong, form]);

  const onCopyLinkDrive = () => {
    const link = form.getFieldValue('link');
    if (link) {
      navigator.clipboard.writeText(form.getFieldValue('link'));
      message.success('Link drive đã được copy');
    }
  };

  const handleChangeValues = (_, allValues) => {
    if (_.songId) {
      setShouldSyncSongName(true);
    }
    setDataReview({ ...dataReview, ...allValues });
  };

  const handleSave = async (isSendMail = true) => {
    const params = await form.validateFields();
    const paramParser = {
      ...params,
      releaseDate: formatDate(params.releaseDate),
      releaseTime: formatTime(params.releaseTime),
      id,
      isSendMail
    };
    isUpdateSongRelease
      ? updateSongRelease(paramParser)
      : createSongRelease(paramParser, id =>
          history.push(`/chi-tiet-phat-hanh/${id}`)
        );
    setShouldSyncSongName(false);
  };

  const handleSelectChannel = (_, record) => {
    setDataReview(dataReview => ({
      ...dataReview,
      publisherName: record.label || record.children
    }));
  };

  useEffect(() => {
    if (currentSong?.songName && shouldSyncSongName) {
      form.setFieldsValue({ songName: currentSong?.songName });
    }
  }, [currentSong?.songName, form, shouldSyncSongName]);

  return (
    <Spin spinning={isSongReleaseLoading}>
      <Typography.Title>
        {id ? 'Chi tiết lịch phát hành' : 'Tạo mới lịch phát hành'}
      </Typography.Title>
      <CommonContainer>
        <Form form={form} layout="vertical" onValuesChange={handleChangeValues}>
          <Row gutter={32}>
            <Col span={12}>
              <FormItem
                label="Bài hát"
                name="songId"
                rules={[{ required: true, message: 'Vui lòng chọn bài hát!' }]}
              >
                <SongReleaseAutoComplete />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Tên bài hát phát hành"
                name="songName"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập tên bài hát phát hành!'
                  }
                ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Thể loại">
                <Typography.Text>
                  {currentSong?.songTypeName || '-'}
                </Typography.Text>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Người trình bày">
                <Typography.Text> {currentSong?.singer || '-'}</Typography.Text>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Sáng tác">
                <Typography.Text>
                  {currentSong?.musician || '-'}
                </Typography.Text>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Nhạc số"
                name="digitalMusic"
                rules={[{ required: true, message: 'Vui lòng chọn nhạc số!' }]}
              >
                <Select options={DIGITAL_OPTION} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Tiktok"
                name="tiktok"
                rules={[{ required: true, message: 'Vui lòng chọn nhạc số!' }]}
              >
                <Select options={TIKTOK_OPTION} />
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem
                label="Loại kênh"
                name="channelType"
                rules={[
                  { required: true, message: 'Vui lòng chọn loại kênh!' }
                ]}
              >
                <Select
                  options={CHANNEL_TYPE_OPTION}
                  onChange={() => {
                    form.setFieldsValue({ channel: '' });
                    setDataReview({
                      ...dataReview,
                      channel: '',
                      publisherName: ''
                    });
                  }}
                />
              </FormItem>
            </Col>

            <Col span={12}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.channelType !== currentValues.channelType
                }
              >
                {({ getFieldValue }) => {
                  const channelType = getFieldValue('channelType');
                  return (
                    <FormItem
                      label="Kênh"
                      name="channel"
                      rules={[
                        { required: true, message: 'Vui lòng chọn kênh!' }
                      ]}
                    >
                      {channelType === 'internal' ? (
                        <PublisherAutoComplete
                          disabled={!channelType}
                          onSelect={handleSelectChannel}
                        />
                      ) : (
                        <Input disabled={!channelType} />
                      )}
                    </FormItem>
                  );
                }}
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormItem
                label="Link drive"
                name="link"
                rules={[
                  { required: true, message: 'Vui lòng nhập link drive!' }
                ]}
              >
                <Input
                  suffix={
                    <CopyOutlined
                      style={{ color: 'gray', cursor: 'pointer' }}
                      onClick={onCopyLinkDrive}
                    />
                  }
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Giờ phát hành"
                name="releaseTime"
                rules={[
                  { required: true, message: 'Vui lòng chọn giờ phát hành!' }
                ]}
              >
                <TimePicker
                  format={'HH:mm'}
                  allowClear={false}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Ngày phát hành"
                name="releaseDate"
                rules={[
                  { required: true, message: 'Vui lòng chọn ngày phát hành!' }
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  allowClear={false}
                  disabledDate={current =>
                    moment(moment()).isAfter(current, 'day')
                  }
                />
              </FormItem>
            </Col>

            <Col span={12}>
              <Form.Item
                className="deputy-form-item"
                label={GroupMailLabel()}
                name="mailGroupId"
                rules={[
                  { required: true, message: 'Vui lòng chọn danh sách mail!' }
                ]}
              >
                <Select
                  options={mailGroups.map(({ id, title }) => ({
                    value: id,
                    label: title
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <FormItem label="Thuộc phụ lục hợp đồng" name="appendixId">
                <Select options={songAppendixes} />
              </FormItem>
            </Col>
            <Col span={24}>
              <Form.Item label="Ghi chú" name="note">
                <Input.TextArea placeholder="Ghi chú" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <ReviewMail dataReview={dataReview} currentSong={currentSong} />
        <Row justify="space-between" style={{ marginTop: '16px' }}>
          <Col>
            <Space>
              <Button icon={<SendOutlined />} onClick={() => handleSave(true)}>
                Gửi mail
              </Button>
              {isUpdateSongRelease && (
                <Tag
                  color={songRelease?.isSentMail ? 'cyan' : ''}
                  style={{ height: 32, display: 'flex', alignItems: 'center' }}
                  icon={<TimeMail style={{ fontSize: 24 }} />}
                >
                  {songRelease?.isSentMail
                    ? formatDateTime(songRelease?.sentMailDate)
                    : 'Chưa gửi mail'}
                </Tag>
              )}
            </Space>
          </Col>
          <Col>
            <Space>
              {/* <Button type="default">Huỷ bỏ</Button> */}
              <Button onClick={() => handleSave(false)}>Lưu</Button>
            </Space>
          </Col>
        </Row>
      </CommonContainer>
    </Spin>
  );
};

export default SongReleaseDetail;
