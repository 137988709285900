import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space, Typography } from 'antd';
import shallow from 'zustand/shallow';
import queryString from 'query-string';

import Table from 'components/Table';

import { CommonContainer } from 'components/StyledComponents';
import OrderBySelect from 'components/OrderBySelect';

import { columns } from './columns';

import { usePartner } from 'stores/usePartner';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, ROLE_CHECK } from 'appConstants';
import CreateButton from 'components/CreateButton';
import DeleteButton from 'components/DeleteButton';
import PartnerFilterBox from './PartnerFilterBox';
import { useHistory, useLocation } from 'react-router-dom';
import ExportButton from 'components/ExportButton';

const Partner = () => {
  const {
    partners,
    getPartners,
    total,
    deletePartners,
    isPartnerLoading,
    setParams,
    getExportPartners,
    isExportLoading,
    getExportPartnerByIds
  } = usePartner(
    useCallback(
      ({
        partners,
        getPartners,
        total,
        deletePartners,
        isPartnerLoading,
        setParams,
        getExportPartners,
        isExportLoading,
        getExportPartnerByIds
      }) => ({
        partners,
        getPartners,
        total,
        deletePartners,
        isPartnerLoading,
        setParams,
        getExportPartners,
        isExportLoading,
        getExportPartnerByIds
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-doi-tac',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-doi-tac',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    const params = { ...searchParams, page: page - 1, sort };
    setParams(params);
    getPartners(params);
  }, [getPartners, page, sort, searchParams, setParams]);

  return (
    <div>
      <Typography.Title>Đối tác</Typography.Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>
            {/* <SearchBox /> */}
            <PartnerFilterBox />
          </Col>

          <Col>
            <Space>
              <ExportButton
                loading={isExportLoading}
                onExport={getExportPartners}
                onExportByIds={getExportPartnerByIds}
                selectedItems={selectedRows}
                callback={() => setSelectedRows([])}
                subject="đối tác"
              />
              {selectedRows.length !== 0 && (
                <DeleteButton
                  title={`Xoá ${selectedRows.length} đối tác?`}
                  onDelete={() => {
                    deletePartners({ ids: selectedRows });
                    setSelectedRows([]);
                  }}
                  length={selectedRows.length}
                  from={ROLE_CHECK.PARTNER}
                />
              )}

              {/* <Dropdown
                title="Excel"
                items={[
                  { value: 'import', label: 'Nhập' },
                  { value: 'export', label: 'Xuất' }
                ]}
              /> */}
              <OrderBySelect defaultValue={sort} onChange={setOrderBy} />

              <CreateButton from={ROLE_CHECK.PARTNER} />
            </Space>
          </Col>
        </Row>

        <Table
          scroll={{ x: 1500 }}
          className="partner-table"
          columns={columns}
          dataSource={partners.map(item => ({ ...item, key: item.id }))}
          paginationProps={{
            total: total,
            pageSize: PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          loading={isPartnerLoading}
        />
      </CommonContainer>
    </div>
  );
};

export default Partner;
