import AutoComplete from 'components/AutoComplete';
import React, { useCallback } from 'react';
import { usePublisher } from 'stores/usePublisher';
import { useSongRelease } from 'stores/useSongRelease';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';

const PublisherAutoComplete = props => {
  const { searchForPublisher } = usePublisher(
    useCallback(
      ({ searchForPublisher }) => ({
        searchForPublisher
      }),
      []
    ),
    shallow
  );

  const { songRelease } = useSongRelease(
    useCallback(
      ({ songRelease }) => ({
        songRelease
      }),
      []
    ),
    shallow
  );

  const publisher = get(songRelease, 'publisher', null);
  const defaultOption = publisher
    ? { value: publisher.id, label: publisher.name }
    : null;

  // const onSearch = async searchText => {
  //   if (!searchText) {
  //     return;
  //   }

  //   const results = await searchForPublisher(searchText);

  //   if (!results) {
  //     return;
  //   }

  //   return setOptions(
  //     results.map(({ id, name }) => {
  //       return {
  //         label: name,
  //         value: id
  //       };
  //     })
  //   );
  // };

  // const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const handleSelect = async e => {
    // const res = options.find(({ value }) => value === e);
    // setOptions(options);
    // onSelect(res);
  };

  return (
    <>
      <AutoComplete
        onSelect={handleSelect}
        defaultOption={defaultOption}
        placeHolder="Tìm kênh phát hành"
        handleSearch={searchForPublisher}
        labelKey="name"
        valueKey="id"
        style={{ width: '100%' }}
        {...props}
      />
    </>
  );
};

export default PublisherAutoComplete;
