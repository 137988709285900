import { UploadOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';

import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import GenderSelect from 'components/GenderSelect';
import Upload from 'components/Upload';

import SingerRankSelect from '../components/SingerRankSelect';

const PartnerContentInfo = ({ isSinglePartner }) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            label={isSinglePartner ? 'Họ tên' : 'Tên công ty'}
            name="fullName"
            rules={[{ required: true, message: 'Nhập tên!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={isSinglePartner ? 'Nghệ danh' : 'Công ty viết tắt'}
            name="artistName"
            rules={[
              {
                required: true,
                message: isSinglePartner
                  ? 'Nhập nghệ danh!'
                  : 'Nhập công ty viết tắt!'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Nhập email!' },
              { type: 'email', message: 'Nhập email!' }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Số điện thoại"
            name="phone"
            rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Mã số thuế"
            name="taxCode"
            rules={[{ required: true, message: 'Nhập mã số thuế!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Địa chỉ"
            name="address"
            // rules={[{ required: true, message: 'Nhập địa chỉ!' }]}
          >
            <Input />
          </Form.Item>
        </Col>

        {isSinglePartner && (
          <>
            <Col span={8}>
              <Form.Item
                label="CMND/CCCD"
                name="identityNumber"
                // rules={[{ required: true, message: 'Nhập CMND/CCCD!' }]}
              >
                <Input style={{ width: '100%' }} placeholder="CMND/CCCD" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Ngày cấp" name="dateOfIdentification">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Nơi cấp" name="placeOfIdentification">
                <Input placeholder="Nơi cấp CMND/CCCD" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Giới tính" name="gender">
                <GenderSelect />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Ngày sinh" name="dateOfBirth">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Hạng đối tác"
                name="singerRank"
                // rules={[{ required: true, message: 'Chọn hạng đối tác!' }]}
              >
                <SingerRankSelect style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={24}>
          <Row gutter={32}>
            <Col span={8}>
              <Form.Item
                label="Ảnh đại diện"
                name="avatar"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  maxCount={1}
                  showUploadList={{ showRemoveIcon: false }}
                >
                  <Button type="link" icon={<UploadOutlined />}>
                    Tải lên
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            {isSinglePartner && (
              <Col span={16}>
                <Form.Item
                  label="Ảnh CMND 2 mặt"
                  name="identityPhoto"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    accept="image/*"
                    listType="picture-card"
                    showUploadList={{ showRemoveIcon: true }}
                    multiple={true}
                  >
                    <Button type="link" icon={<UploadOutlined />}>
                      Tải lên
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PartnerContentInfo;
