import { CalendarOutlined } from '@ant-design/icons';
import { Card, Divider, Empty, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDashboard } from 'stores/useDashboard';
import { formatDate } from 'utils/date';
import shallow from 'zustand/shallow';

const { Text } = Typography;

const NearExpiredContract = () => {
  const { nearExpiredContracts, getNearExpiredContracts } = useDashboard(
    useCallback(
      ({ nearExpiredContracts, getNearExpiredContracts }) => ({
        nearExpiredContracts,
        getNearExpiredContracts
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getNearExpiredContracts();
  }, [getNearExpiredContracts]);

  const data = useMemo(
    () => nearExpiredContracts?.data,
    [nearExpiredContracts?.data]
  );

  return (
    <Card
      title="Sắp hết hạn"
      extra={
        <Text strong type="success">
          {data?.length}
          {nearExpiredContracts?.total && `/${nearExpiredContracts?.total}`}
        </Text>
      }
      bodyStyle={{
        padding: 0,
        maxHeight: '330px',
        minHeight: '330px',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: !data?.length ? 'center' : 'initial'
      }}
      style={{ borderRadius: 8 }}
    >
      {!!data?.length ? (
        <Space direction="vertical" style={{ width: '100%' }}>
          {data?.map(item => (
            <div key={item.id}>
              <Space align="center" style={{ padding: 16 }}>
                <Space direction="vertical" size={0}>
                  <Link to={`/chi-tiet-hop-dong/${item.id}/soan-hop-dong`}>
                    <Typography.Text strong>
                      #{item.contractCode}
                    </Typography.Text>
                  </Link>
                  <Text>
                    <CalendarOutlined style={{ fontSize: 16 }} />{' '}
                    {formatDate(item.finishDate)}
                  </Text>
                </Space>
              </Space>
              <Divider style={{ margin: 0 }} />
            </div>
          ))}
        </Space>
      ) : (
        <Empty description={<Text>Không có dữ liệu</Text>} />
      )}
    </Card>
  );
};

export default NearExpiredContract;
