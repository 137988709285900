import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Col, Form, Row, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CONTRACT_TYPE, CONTRACT_TYPE_VALUE } from 'appConstants';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import Input from 'components/Input';
import Select from 'components/Select';
import Upload from 'components/Upload';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useContract } from 'stores/useContract';
import { formatDate } from 'utils/date';
import shallow from 'zustand/shallow';

const ContractInfo = ({ contractId, isUpdateContract }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const {
    contract,
    updateContract,
    createContract,
    updateContractNote,
    isContractLoading
  } = useContract(
    useCallback(
      ({
        contract,
        updateContract,
        createContract,
        updateContractNote,
        isContractLoading
      }) => ({
        contract,
        updateContract,
        createContract,
        updateContractNote,
        isContractLoading
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (contract && isUpdateContract) {
      const {
        contractType,
        contractCode,
        parentContractId,
        signDate,
        startDate,
        finishDate,
        autoRenew,
        doiSoatPaid,
        taxPercent,
        file,
        exclusivityFinishDate,
        note
      } = contract;

      form.setFieldsValue({
        contractType,
        contractCode,
        parentContractId,
        signDate: signDate && moment(signDate),
        startDate: startDate && moment(startDate),
        finishDate: finishDate && moment(finishDate),
        autoRenew,
        doiSoatPaid,
        taxPercent,
        note,
        file: file.map(f => ({ name: f.fileName, url: f.fileLink })),
        exclusivityFinishDate:
          exclusivityFinishDate && moment(exclusivityFinishDate)
      });
    }
  }, [form, contract, isUpdateContract]);

  const onFinish = values => {
    const returnDuplicateContractCodeErr = () => {
      form.setFields([
        { errors: ['Mã hợp đồng đã tồn tại!'], name: ['contractCode'] }
      ]);
    };

    const formData = form.getFieldsValue();
    const { file } = formData;
    const fileParser = !!file?.length
      ? file?.map(f => {
          if (f.response) {
            return {
              fileName: f.response.fileName,
              fileLink: f.response.preview
            };
          }
          return { fileName: f.name, fileLink: f.url };
        })
      : null;
    const params = {
      ...values,
      file: fileParser,
      finishDate: values.finishDate && formatDate(values.finishDate),
      startDate: values.startDate && formatDate(values.startDate),
      signDate: values.signDate && formatDate(values.signDate),
      exclusivityFinishDate:
        values.exclusivityFinishDate &&
        formatDate(values.exclusivityFinishDate),
      contractId
    };
    if (isUpdateContract) {
      updateContract(params, returnDuplicateContractCodeErr);
      updateContractNote(
        { contractId, note: params.note },
        returnDuplicateContractCodeErr
      );
    } else {
      createContract(
        params,
        id => history.push(`/chi-tiet-hop-dong/${id}/soan-hop-dong`),
        returnDuplicateContractCodeErr
      );
    }
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const generateContractCode = formatCode => {
    const currYear = moment().year();
    const nextNumber = moment().unix();
    const formatPrefix = 'HĐHT';
    const codeGenerate = `${nextNumber}/${formatPrefix}-${formatCode}/${currYear}/NV_***`;
    return form.setFieldsValue({ contractCode: codeGenerate });
  };

  const handleChangeValues = (changedValue, allValues) => {
    // Check hợp đồng thuê - generate với thuế xuất
    if (
      !isUpdateContract &&
      (changedValue?.contractType === CONTRACT_TYPE_VALUE.THUE ||
        changedValue?.taxPercent) &&
      allValues?.taxPercent &&
      allValues?.contractType === CONTRACT_TYPE_VALUE.THUE
    ) {
      let formatCode;
      formatCode = `TH${
        allValues?.taxPercent >= 10
          ? allValues?.taxPercent
          : '0' + allValues?.taxPercent
      }`;
      generateContractCode(formatCode);
      return;
    }

    if (changedValue?.contractType && !isUpdateContract) {
      let formatCode;
      switch (changedValue?.contractType) {
        case 'DTHT':
          formatCode = 'ĐTHT';
          break;
        case 'KENH':
          formatCode = 'YOTU';
          break;

        default:
          formatCode = changedValue?.contractType;
          break;
      }
      generateContractCode(formatCode);
    }
  };

  return (
    <Spin spinning={isContractLoading}>
      {contract?.reSignContract && (
        <Alert
          message="Hợp đồng này đã được tái ký, vui lòng không thao tác hoặc kiểm tra lại !"
          type="error"
          showIcon
          closable
          className="mb-md"
        />
      )}

      <Form
        layout="vertical"
        name="contract-info"
        form={form}
        onFinish={onFinish}
        initialValues={{
          autoRenew: false,
          doiSoatPaid: false
        }}
        onValuesChange={handleChangeValues}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contractType"
              label="Loại hợp đồng:"
              rules={[{ required: true, message: 'Chọn loại hợp đồng' }]}
            >
              <Select
                disabled={isUpdateContract}
                options={CONTRACT_TYPE}
                dropdownRender={menu => {
                  return menu;
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contractCode"
              label="Mã hợp đồng:"
              // rules={[{ required: true, message: 'Nhập mã hợp đồng' }]}
            >
              <Input placeholder="Mã hợp đồng" disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="signDate" label="Ngày kí hợp đồng">
              <DatePicker style={{ width: '100%' }} allowClear={false} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="startDate" label="Ngày bắt đầu hiệu lực">
              <DatePicker style={{ width: '100%' }} allowClear={false} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="finishDate" label="Ngày kết thúc hiệu lực">
              <DatePicker style={{ width: '100%' }} allowClear={false} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="taxPercent"
              label="Thuế xuất (%)"
              rules={[{ required: true, message: 'Nhập thuế xuất!' }]}
            >
              <Select
                style={{ width: '100%' }}
                options={[
                  { label: '5%', value: 5 },
                  { label: '8%', value: 8 },
                  { label: '10%', value: 10 }
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="exclusivityFinishDate"
              label="Ngày kết thúc độc quyền"
            >
              <DatePicker style={{ width: '100%' }} allowClear={false} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="autoRenew"
              valuePropName="checked"
              label="Tự động ký lại"
            >
              <Checkbox>Có</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="doiSoatPaid"
              valuePropName="checked"
              label="Đã thanh toán đối soát"
            >
              <Checkbox>Có</Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="note" label="Ghi chú">
              <TextArea placeholder="Ghi chú" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="File hợp đồng"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                className="navi-upload"
                name="file"
                // maxCount={1}
                // showUploadList={{ showRemoveIcon: false }}
              >
                <Button icon={<UploadOutlined />}>Tải lên</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button icon={<SaveOutlined />} htmlType="submit">
            Lưu
          </Button>
        </Row>
      </Form>
    </Spin>
  );
};

export default ContractInfo;
