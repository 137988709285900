export const formatPrice = price => {
  return Number(price) > 0
    ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '-';
};

export const formatView = view => {
  if (Number(view) === 0) return view;
  return Number(view) > 0
    ? view.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '';
};
