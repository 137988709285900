import { Card, Col, Row, Space, Typography } from 'antd';
import { formatView } from 'utils/currency';
import ApproveStatusTag from './ApproveStatusTag';

const { Text } = Typography;

const CardInfo = ({ playlist }) => {
  return (
    <Card bodyStyle={{ background: 'rgb(128 128 128 / 20%)' }}>
      <Row>
        <Col span={12}>
          <Space direction="vertical">
            <Space>
              <Text>Trạng thái</Text>
              <ApproveStatusTag value={playlist.status} />
            </Space>
            <Text>Lượt view: {formatView(playlist.views)}</Text>
            <Text>Lượt like: {formatView(playlist.likes)}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Text>Tạo mới: {playlist.createByUser?.fullName}</Text>
            <Text>Cập nhật: {playlist.updateByUser?.fullName}</Text>
            {playlist.approver?.fullName && (
              <Text>Phê duyệt: {playlist.approver?.fullName}</Text>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default CardInfo;
