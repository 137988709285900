import { Space, Typography, Button } from 'antd';
import cheerImg from 'assets/images/cheers.png';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ContractDetailLayout from '../ContractDetailLayout';
import { APPENDIX_PROCESS_VALUE } from 'appConstants';

const Completed = () => {
  const { id } = useParams();
  return (
    <ContractDetailLayout step={APPENDIX_PROCESS_VALUE.COMPLETE}>
      <div className="completed-wrapper mb-md">
        <Space direction="vertical" align="center">
          <img src={cheerImg} alt="img" style={{ maxWidth: '100px' }} />
          <Typography.Title type="success">Chúc mừng</Typography.Title>
          <Typography.Text strong>
            Bạn đã hoàn tất các tiến độ trong phụ lục hợp đồng
          </Typography.Text>
          <Link to={`/tao-moi-phu-luc/${id}`}>
            <Button type="default">Bổ sung phụ lục mới</Button>
          </Link>
        </Space>
      </div>
    </ContractDetailLayout>
  );
};

export default Completed;
