import Button from 'components/Button';
import React, { useCallback } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { DeleteOutlined } from '@ant-design/icons';
import shallow from 'zustand/shallow';

const SongAddendumDeleteButton = ({ item }) => {
  const { disableEditAndAddAddendumSong, prevDeleteSongAddendum } =
    useAddendumDetail(
      useCallback(
        ({ disableEditAndAddAddendumSong, prevDeleteSongAddendum }) => ({
          disableEditAndAddAddendumSong,
          prevDeleteSongAddendum
        }),
        []
      ),
      shallow
    );

  return (
    <Button
      type="danger"
      icon={<DeleteOutlined />}
      disabled={disableEditAndAddAddendumSong}
      onClick={() => prevDeleteSongAddendum(item)}
    />
  );
};

export default SongAddendumDeleteButton;
