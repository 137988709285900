import React, { useCallback } from 'react';
import FilterBox from 'components/FilterBox';

import { FILTER_TYPE, CONTRACT_TYPE } from 'appConstants';

import shallow from 'zustand/shallow';
import { usePartnerContracts } from 'stores/usePartnerContracts';
import { usePartnerDetail } from 'stores/usePartnerDetail';

export const data = [
  {
    mainTitle: 'Nhân viên',
    filterItems: [
      {
        type: FILTER_TYPE.TEXT,
        formTitle: 'Mã hợp đồng',
        name: 'ContractCode'
      },
      {
        type: FILTER_TYPE.SELECT,
        formTitle: 'Tự động ký lại',
        name: 'AutoRenew',
        options: [
          { value: 'true', label: 'Có' },
          { value: 'false', label: 'Không' }
        ]
      },
      {
        type: FILTER_TYPE.SELECT,
        formTitle: 'Loại hợp đồng',
        name: 'ContractType',
        options: CONTRACT_TYPE
      },
      {
        type: FILTER_TYPE.RANGE_PICKER,
        formTitle: 'Ngày bắt đầu hợp đồng',
        name: 'StartDate'
      },
      {
        type: FILTER_TYPE.RANGE_PICKER,
        formTitle: 'Thời hạn hợp đồng',
        name: 'FinishDate'
      }
    ]
  }
];

const PartnerContractFilterBox = () => {
  const { setFilter } = usePartnerContracts(
    useCallback(
      ({ setFilter }) => ({
        setFilter
      }),
      []
    ),
    shallow
  );

  const { partner } = usePartnerDetail(
    useCallback(
      ({ partner }) => ({
        partner
      }),
      []
    ),
    shallow
  );

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      defaultFixedTag={'Đối tác: ' + (partner?.artistName || partner?.fullName)}
      initValue={[]}
    />
  );
};

export default PartnerContractFilterBox;
