import React, { useCallback, useMemo, useState } from 'react';
import shallow from 'zustand/shallow';

import { Modal, Form } from 'antd';

import RoleFormModalContent from './RoleFormModalContent';
import { useRole } from 'stores/useRole';

import { getPermissionKeys } from 'pages/Role/utils';

const RoleFormModal = ({ children, roleId }) => {
  const { updateRole, createRole, fetchRole } = useRole(
    useCallback(
      ({ updateRole, createRole, fetchRole }) => ({
        updateRole,
        createRole,
        fetchRole
      }),
      []
    ),
    shallow
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const isUpdateRole = useMemo(() => !!roleId, [roleId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      let params = await form.validateFields();
      const permissions = params.permissions;

      delete params.permissions;

      const arrayToObject = arr => {
        const obj = {};

        arr.forEach(item => {
          obj[item] = true;
        });

        return obj;
      };

      params = { ...params, ...arrayToObject(permissions) };

      if (isUpdateRole) {
        await updateRole({ id: roleId, ...params });
      } else {
        await createRole(params);
      }

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onRoleAutoCompleteChange = async value => {
    const res = await fetchRole(value);

    form.setFieldsValue({ permissions: getPermissionKeys(res) });
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        title={isUpdateRole ? 'Chỉnh sửa vai trò' : 'Tạo mới vai trò'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <RoleFormModalContent
          form={form}
          roleId={roleId}
          isUpdateRole={isUpdateRole}
          onRoleAutoCompleteChange={onRoleAutoCompleteChange}
        />
      </Modal>
    </>
  );
};

export default RoleFormModal;
