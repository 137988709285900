import {
  EditOutlined,
  FileSearchOutlined,
  MailOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ORDER_BY_SELECT_VALUE, PARTNER_TYPE } from 'appConstants';
import Button from 'components/Button';
import AddendumStatusTag from 'pages/AddendumDetail/components/AddendumStatusTag';
import ContracTypeTag from 'pages/AddendumDetail/components/ContracTypeTag';
import { formatDate } from 'utils/date';
import { redirectByStatus } from 'utils/redirect';
import ChangeStatusButton from './components/ChangeStatusButton';
import ContractStatus from './components/ContractStatus';
import ContractStatusTag from 'containers/ContractStatusTag';
import { getAppendixCode } from 'utils/other';

const { Text } = Typography;

const User = ({ user }) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );
  const onClick = () => {
    history.push({
      pathname: '/danh-sach-hop-dong',
      search: queryString.stringify({
        ...searchParams,
        'UserId[eq]': user.id,
        UserName: user.fullName,
        page: 1,
        sort
      })
    });
  };

  return (
    <Space direction="vertical">
      {user ? (
        <Button type="dashed">
          <Text strong onClick={onClick}>
            {user?.fullName}
          </Text>
        </Button>
      ) : (
        <Button type="text">
          <Text type="danger">Không có dữ liệu</Text>
        </Button>
      )}
    </Space>
  );
};

export const columns = [
  {
    title: 'Thông tin hợp đồng',
    dataIndex: 'info',
    fixed: 'left',
    width: 'auto',
    minWidth: '300px',
    render: (_, { id, contractCode, contractType, status }) => {
      return (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Link to={`/chi-tiet-hop-dong/${id}/soan-hop-dong`}>
            <Text strong>
              #{contractCode} <EditOutlined />
            </Text>
          </Link>
          <ContracTypeTag value={contractType} />
          <ContractStatusTag value={status} />
        </Space>
      );
    }
  },
  {
    title: 'Đối tác',
    dataIndex: 'partner',
    width: '250px',
    render: partner =>
      partner && (
        <Space direction="vertical" style={{ width: '100%' }}>
          {partner.artistName && (
            <Link to={`/chi-tiet-doi-tac/${partner.id}`}>
              <Text strong>{partner.artistName}</Text>
            </Link>
          )}
          {partner.email && (
            <div>
              <MailOutlined /> {partner.email}
            </div>
          )}
          {partner.phone && (
            <div>
              <PhoneOutlined /> {partner.phone}
            </div>
          )}
          <div>
            {partner?.masterData?.title && (
              <Tag
                color={
                  partner?.masterData?.code === PARTNER_TYPE.INDIVIDUAL
                    ? 'orange'
                    : 'green'
                }
              >
                {partner?.masterData?.title}
              </Tag>
            )}
          </div>
        </Space>
      )
  },
  {
    title: 'Thời gian hiệu lực',
    dataIndex: 'effectiveTime',
    width: '150px',
    render: (_, contract) => <ContractStatus contract={contract} />
  },
  {
    title: 'Người phụ trách',
    dataIndex: 'user',
    width: '200px',
    render: user => <User user={user} />
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    width: '150px',
    render: createDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(createDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
      </Space>
    ),
    defaultHidden: true
  },
  {
    title: 'Thao tác',
    dataIndex: 'status',
    width: '120px',
    render: (_, contract) => <ChangeStatusButton contract={contract} />
  }
];

export const appendixColumns = [
  {
    fixed: 'left',
    title: 'Thông tin phụ lục',
    dataIndex: 'info',
    render: (_, appendix) => (
      <Link to={`/chi-tiet-phu-luc/${appendix?.id}`}>
        <Typography.Text strong>{getAppendixCode(appendix)}</Typography.Text>
      </Link>
    )
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (_, { status, contractId, id }) => {
      const urlRedirect = redirectByStatus(status, contractId, id);
      return (
        <Link to={urlRedirect}>
          <AddendumStatusTag value={status} editable />
        </Link>
      );
    }
  },
  {
    title: 'Thời gian hiệu lực',
    dataIndex: 'effectiveTime',
    render: (_, { startDate, finishDate }) => (
      <Space direction="vertical">
        <span>
          {(moment(finishDate).diff(moment(), 'days') >= 0 && startDate) ||
          !finishDate ? (
            <Space direction="vertical">
              <Text type="success">Còn hạn</Text>
              {startDate && (
                <Text>
                  Từ ngày: <Text strong>{formatDate(startDate)}</Text>
                </Text>
              )}
              {finishDate && (
                <Text>
                  Đến ngày: <Text strong>{formatDate(finishDate)}</Text>
                </Text>
              )}
            </Space>
          ) : (
            <Text type="danger">Hết hạn</Text>
          )}
        </span>
      </Space>
    )
  },
  {
    title: 'Thời gian ký',
    dataIndex: 'signDate',
    render: (_, { signDate }) => (
      <Text strong>{signDate && formatDate(signDate)}</Text>
    )
  },
  {
    title: 'Review',
    dataIndex: 'review',
    width: '100px',
    align: ' center',
    render: (_, { id }) => (
      <Link to={`/review-phu-luc/${id}`}>
        <Button
          size="small"
          // shape="circle"
          ghost
          icon={<FileSearchOutlined />}
        ></Button>
      </Link>
    )
  }
];
