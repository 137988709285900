import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { get } from 'utils/lodash';

const { Text } = Typography;

const CompanyContent = ({ company }) => {
  const name = get(company, 'name', null);
  const representationName = get(company, 'representationName', null);
  const phone = get(company, 'phone', null);
  const email = get(company, 'email', null);
  const taxCode = get(company, 'taxCode', null);
  const representationIdentityNumber = get(
    company,
    'representationIdentityNumber',
    null
  );
  const address = get(company, 'address', null);
  return (
    <Row className="p-lg">
      <Col span={8}>
        <Space>
          <Text strong>Tên công ty:</Text>
          <Text strong>{name}</Text>
        </Space>
      </Col>
      <Col span={8}>
        <Space>
          <Text strong>Họ tên người đại diện:</Text>
          {representationName}
        </Space>
      </Col>
      <Col span={8}>
        <Space>
          <Text strong>Số điện thoại:</Text>
          {phone}
        </Space>
      </Col>
      <Col span={8}>
        <Space>
          <Text strong>Email:</Text>
          {email}
        </Space>
      </Col>
      <Col span={8}>
        <Space>
          <Text strong>Mã số thuế:</Text>
          {taxCode}
        </Space>
      </Col>
      <Col span={8}>
        <Space>
          <Text strong>CMND/CCCD người đại diện:</Text>
          {representationIdentityNumber}
        </Space>
      </Col>
      <Col span={8}>
        <Space>
          <Text strong>Địa chỉ:</Text>
          {address}
        </Space>
      </Col>
    </Row>
  );
};

export default CompanyContent;
