import React from 'react';
import { Popconfirm } from 'antd';

const DeleteConfirmPopup = ({ children, title, onDelete, ...rest }) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onDelete}
      okButtonProps={{ danger: true }}
      okText="Xoá"
      cancelText="Huỷ"
      {...rest}
    >
      {children}
    </Popconfirm>
  );
};

export default DeleteConfirmPopup;
