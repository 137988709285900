import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';

export const useReupEffect = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* **************** */
  //* NOTE: get reupEffects  */
  //* **************** */
  reupEffects: [],
  totalPage: 1,
  total: 0,
  getReupEffects: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get(`/reup/effect`, {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });

      if (data) {
        set({
          reupEffects: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************************* */
  //* NOTE: get and update reupEffect */
  //* ******************************* */

  updateReupEffect: async params => {
    try {
      const { data } = await API.put(`/reup/effect/${params.id}`, params);

      if (data) {
        get().getReupEffects();

        notification.success({
          message: 'Cập nhật reup effect thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForReupEffect: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/reup/effect/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
