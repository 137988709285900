import { CheckCircleOutlined } from '@ant-design/icons';
import { Divider, Empty, Space, Typography } from 'antd';
import { MANAGER_PARTNER_TYPE } from 'appConstants';
import React from 'react';
import { get } from 'utils/lodash';
import CompanyContent from './CompanyContent';
import PartnerContent from './PartnerContent';

const PartnerCard = ({ partner }) => {
  // const { manager, bankAccount } = partner;
  const manager = get(partner, MANAGER_PARTNER_TYPE.MANAGER, null);
  const company = get(partner, MANAGER_PARTNER_TYPE.COMPANY, null);
  const bankAccount = get(partner, 'bankAccount', null);
  const representationType = get(partner, 'representationType', null);

  return (
    <div className="card-box">
      <div>
        {partner ? (
          <PartnerContent partner={partner} />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Chưa có thông tin đối tác"
          />
        )}

        {representationType === MANAGER_PARTNER_TYPE.MANAGER && manager && (
          <>
            <Divider className="m-none" />
            <PartnerContent partner={manager} />
          </>
        )}
        {representationType === MANAGER_PARTNER_TYPE.COMPANY && company && (
          <>
            <Divider className="m-none" />
            <CompanyContent company={company} />
          </>
        )}

        {bankAccount && (
          <>
            <Divider className="m-none" />
            <Space size="large" className="p-lg">
              <Typography.Text strong>
                Số tài khoản: {bankAccount.accountNumber}
              </Typography.Text>
              <Typography.Text strong>
                Ngân hàng: {bankAccount.bankName}
              </Typography.Text>
              <Typography.Text strong>
                Chi nhánh: {bankAccount.bankBranch}
              </Typography.Text>
            </Space>
            <Divider className="m-none" />
          </>
        )}

        {bankAccount && (
          <div className="p-lg" style={{ textAlign: 'center', color: 'green' }}>
            <Space>
              <CheckCircleOutlined />
              <span>Chấp nhận</span>
            </Space>
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerCard;
