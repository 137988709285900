import React from 'react';

import { Typography } from 'antd';

import SubscribeDeleteButton from './SubscribeDeleteButton';
import SubsribeMultiSelect from './SubsribeMultiSelect';

export const columns = [
  {
    title: 'Loại thông báo',
    dataIndex: 'key',
    render: (key, _) => {
      return (
        <Typography.Text strong>{key?.title || key?.code}</Typography.Text>
      );
    },
    width: '30%'
  },
  {
    title: 'Loại trạng thái',
    dataIndex: 'value',
    render: SubsribeMultiSelect,
    width: '60%'
  },
  {
    title: 'Thao tác',
    dataIndex: 'action',
    render: SubscribeDeleteButton,
    width: '10%',
    align: 'center'
  }
];
