import { Row, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { COMFIRM_APPENDIX_STATUS, USER_COMFIRMED_TYPE } from 'appConstants';
import Button from 'components/Button';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReviewDetail } from 'stores/useReviewDetail';
import shallow from 'zustand/shallow';

const ChangeAppendixStatus = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();

  const { changeAppendixStatus, appendixReview } = useReviewDetail(
    useCallback(
      ({ changeAppendixStatus, appendixReview }) => ({
        changeAppendixStatus,
        appendixReview
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  // check at least 2 record with status approved and type diff.
  const isValidApproved = useMemo(() => {
    if (appendixReview) {
      const isHasAccount = appendixReview.confirmedAppendix.some(
        item =>
          item.status === COMFIRM_APPENDIX_STATUS.APPROVED &&
          item.type === USER_COMFIRMED_TYPE.ACCOUNTANT_TYPE
      );
      const isHasComposer = appendixReview.confirmedAppendix.some(
        item =>
          item.status === COMFIRM_APPENDIX_STATUS.APPROVED &&
          item.type === USER_COMFIRMED_TYPE.COMPOSER_TYPE
      );
      const isPaymentNull = !appendixReview.payment.length;
      return isPaymentNull ? isHasComposer : isHasAccount && isHasComposer;
    }
  }, [appendixReview]);

  const condition = useMemo(() => {
    if (appendixReview) {
      return (
        !!appendixReview?.contractId &&
        (((!!appendixReview?.index01 || appendixReview?.index01 === 0) &&
          (!!appendixReview?.index02 || appendixReview?.index02 === 0)) ||
          !!appendixReview?.index03 ||
          appendixReview?.index03 === 0) &&
        !!appendixReview?.startDate &&
        appendixReview?.status === 'compose' &&
        !!appendixReview.contract?.revenueSharing.length &&
        (appendixReview?.canAddPayment
          ? appendixReview?.payment?.length > 0 ||
            (appendixReview?.index01 === '0' &&
              appendixReview?.index02 === '0' &&
              appendixReview?.index02Details?.length > 0)
          : appendixReview?.index02Details?.length > 0) &&
        isValidApproved
      );
    }
  }, [appendixReview, isValidApproved]);

  const handleOk = async () => {
    await changeAppendixStatus(id, 'deal');
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row>
      <Button onClick={showModal} block disabled={!condition}>
        Chuyển sang trạng thái trình đối tác ký phụ lục
      </Button>

      <Modal
        title="Xác nhận"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
      >
        <Typography.Text>
          Chuyển sang trạng thái trình đối tác ký phụ lục?
        </Typography.Text>
      </Modal>
    </Row>
  );
};

export default ChangeAppendixStatus;
