import React, { useCallback, useEffect, useMemo, useState } from 'react';
import shallow from 'zustand/shallow';

import { Modal, Form } from 'antd';

import CompanyFormModalContent from './CompanyFormModalContent';
import { usePartnerDetail } from 'stores/usePartnerDetail';

const CompanyFormModal = ({ children, defaultValue }) => {
  const { createCompany, updateCompany, updateCompanyrAutoCompleteOptions } =
    usePartnerDetail(
      useCallback(
        ({
          createCompany,
          updateCompany,
          updateCompanyrAutoCompleteOptions
        }) => ({
          createCompany,
          updateCompany,
          updateCompanyrAutoCompleteOptions
        }),
        []
      ),
      shallow
    );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const isUpdateCompany = useMemo(() => !!defaultValue, [defaultValue]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();

      if (isUpdateCompany) {
        await updateCompany(
          { id: defaultValue.id, ...params },
          //NOTE: edit label sau khi update tên của manager
          //vì label của option là tên của company
          () =>
            updateCompanyrAutoCompleteOptions(prev =>
              prev.map(item =>
                item.value === defaultValue.id
                  ? { ...item, label: params.name }
                  : item
              )
            )
        );
      } else {
        await createCompany(params);
      }
      setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isUpdateCompany && defaultValue) {
      const {
        name,
        email,
        phone,
        address,
        taxCode,
        identityNumber,
        representationName,
        representationIdentityPhone,
        representationIdentityNumber
      } = defaultValue;

      form.setFieldsValue({
        name,
        email,
        phone,
        address,
        taxCode,
        identityNumber,
        representationName,
        representationIdentityPhone,
        representationIdentityNumber
      });
    }
  }, [isUpdateCompany, defaultValue, form]);

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        title={isUpdateCompany ? 'Chỉnh sửa công ty' : 'Tạo mới công ty'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        width={800}
        forceRender
      >
        <CompanyFormModalContent form={form} />
      </Modal>
    </>
  );
};

export default CompanyFormModal;
