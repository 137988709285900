import React from 'react';
import { Typography } from 'antd';
import ContractContainer from './ContractContainer';

const Contract = () => {
  return (
    <div>
      <Typography.Title>Danh sách hợp đồng</Typography.Title>

      <ContractContainer />
    </div>
  );
};

export default Contract;
