import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateFileDownload } from 'utils/redirect';

export const usePlaylist = create((set, get) => ({
  playlists: [],
  totalPage: 1,
  total: 0,
  getPlaylists: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get('/playlist', {
        params: {
          PageSize: PAGE_SIZE,
          ...params
        }
      });

      if (data) {
        data.map(d => ({
          ...d,
          playlistDetail: d.playlistDetail.sort(
            (firstEl, secondEl) => firstEl.ordinal - secondEl.ordinal
          )
        }));
        set({
          playlists: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  deletePlaylists: async params => {
    try {
      const { data } = await API.delete('/playlist', { data: params });

      if (data) {
        set({
          playlists: get().playlists.filter(p => !params.ids.includes(p.id))
        });

        return notification.success({
          message: 'Xoá tuyển tập thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updatePlaylists: async params => {
    try {
      const { data } = await API.put('/playlist/bulk', params);

      if (data) {
        get().getPlaylists();
        return notification.success({
          message: 'Cập nhật duyệt tuyển tập thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  extractData: async id => {
    try {
      const { data } = await API.get(`/playlist/${id}/sync-statistics`);

      if (data) {
        get().getPlaylists();
      }

      return data;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                            Export playlist from ids                                  //
  //--------------------------------------------------------------------------------------//
  isExportLoading: false,
  exportPlaylistByIds: async ids => {
    set({ isExportLoading: true });
    try {
      const res = await API.post('/excel/export-playlists/bulk', {
        ids
      });
      const { data } = res;

      set({ isExportLoading: false });
      if (data) {
        generateFileDownload(data?.link);
        return data;
      }

      if (res.status === 204)
        return notification.error({
          message: 'Tuyển tập lỗi, vui lòng kiểm tra lại!'
        });
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
