import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';

export const useReupHotkey = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* **************** */
  //* NOTE: get reupHotkeys  */
  //* **************** */
  reupHotkeys: [],
  totalPage: 1,
  total: 0,
  getReupHotkeys: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get(`/reup/hotkey`, {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });

      if (data) {
        set({
          reupHotkeys: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************* */
  //* NOTE: delete reupHotkeys  */
  //* ************************* */

  deleteReupHotkeys: async params => {
    try {
      const { data } = await API.delete('/reup/hotkey', { data: params });

      if (data) {
        get().getReupHotkeys();

        return notification.success({
          message: 'Xoá reup hotkey thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************* */
  //* NOTE: create reupHotkeys  */
  //* ************************* */

  createReupHotkey: async params => {
    try {
      const { data } = await API.post('/reup/hotkey', params);

      if (data) {
        await get().getReupHotkeys();

        notification.success({
          message: 'Tạo reup hotkey thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************************* */
  //* NOTE: get and update reupHotkey */
  //* ******************************* */

  updateReupHotkey: async params => {
    try {
      const { data } = await API.put(`/reup/hotkey/${params.id}`, params);

      if (data) {
        get().getReupHotkeys();

        notification.success({
          message: 'Cập nhật reup hotkey thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForReupHotkey: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/reup/hotkey/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
