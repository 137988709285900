import React, { useCallback } from 'react';
import { Col, Row, Tabs, Typography } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';

import PersonalInfo from './components/PersonalInfo';
import RoleInfo from './components/RoleInfo';
import { CommonContainer } from 'components/StyledComponents';
import { useHistory, useParams } from 'react-router-dom';
import ChangePassword from './components/ChangePassword';
import DepartmentInfo from './DepartmentInfo';
import LoginHistory from './LoginHistory';
import UserAvatar from 'components/UserAvatar';
import Button from 'components/Button';
import Upload from 'components/Upload';
const { TabPane } = Tabs;

const PERSONAL_INFO_URL_VALUES = {
  PERSONAL_INFO: 'thong-tin-ca-nhan',
  DEPARTMENT: 'phong-ban',
  ROLE: 'vai-tro',
  LOGIN_HISTORY: 'lich-su-dang-nhap'
};

const CurrentUserDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const { currentUser, updateProfile } = useCurrentUser(
    useCallback(
      ({ currentUser, updateProfile }) => ({
        currentUser,
        updateProfile
      }),
      []
    ),
    shallow
  );

  const onChange = value => history.push('/ca-nhan/' + value);

  const onChangeAvatar = async args => {
    try {
      if (args.file.status === 'done') {
        const avatar = args?.file?.response?.preview;
        await updateProfile({
          avatar,
          fullName: currentUser?.fullName,
          phone: currentUser?.phone
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div className="avatar-wrapper" style={{ width: 128 }}>
            <UserAvatar
              fullName={currentUser?.fullName}
              size={128}
              style={{ fontSize: 64 }}
              avatar={currentUser?.avatar}
            />

            <Upload
              className="navi-upload"
              name="file"
              maxCount={1}
              showUploadList={false}
              onChange={onChangeAvatar}
            >
              <span className="avatar-btn">
                <Button
                  type="default"
                  shape="circle"
                  icon={<CameraOutlined />}
                />
              </span>
            </Upload>
          </div>
        </Col>

        <Col>
          <Typography.Title>{currentUser?.fullName}</Typography.Title>
        </Col>
      </Row>

      <CommonContainer>
        <Tabs
          onChange={onChange}
          activeKey={id}
          type="card"
          className="current-user-tabs"
        >
          <TabPane
            tab="Thông tin cá nhân"
            key={PERSONAL_INFO_URL_VALUES.PERSONAL_INFO}
          >
            <>
              <PersonalInfo />
              <ChangePassword />
            </>
          </TabPane>

          <TabPane tab="Vai trò" key={PERSONAL_INFO_URL_VALUES.ROLE} disabled>
            <RoleInfo />
          </TabPane>

          <TabPane
            tab="Phòng ban"
            key={PERSONAL_INFO_URL_VALUES.DEPARTMENT}
            disabled
          >
            <DepartmentInfo />
          </TabPane>

          <TabPane
            tab="Lịch sử đăng nhập"
            key={PERSONAL_INFO_URL_VALUES.LOGIN_HISTORY}
          >
            <LoginHistory />
          </TabPane>
        </Tabs>
      </CommonContainer>
    </>
  );
};

export default CurrentUserDetail;
