import { SaveOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Button from 'components/Button';
import Input from 'components/Input';
import React, { useCallback } from 'react';
import { useContract } from 'stores/useContract';
import shallow from 'zustand/shallow';

const Users = () => {
  const { contract } = useContract(
    useCallback(
      ({ contract }) => ({
        contract
      }),
      []
    ),
    shallow
  );

  // useEffect(() => {
  //   if (contract && isUpdateContract) {
  //     const { user } = contract;
  //     if (user)
  //       form.setFieldsValue({
  //         fullName: user.fullName
  //       });
  //   }
  // }, [form, contract, isUpdateContract]);

  // const onFinish = values => {
  //   const userList = values.ids;
  //   if (isUpdateContract) {
  //     updateContract({
  //       userList,
  //       contractId,
  //       taxPercent: contract?.taxPercent
  //     });
  //   }
  // };

  // const user = get(contract, 'user', null);
  // const usersDefaultValue = user ? [user] : null;

  return (
    // <Form form={form}>
    //   <Form.Item name="fullName">
    //     {/* <UserAutoComplete defaultOption={usersDefaultValue} /> */}
    //     <Input disabled placeholder="Tìm nhân viên" />
    //   </Form.Item>
    //   {/* Do nothing ^^! */}
    //   {/* <Row justify="end">
    //     <Button icon={<SaveOutlined />} htmlType="submit" disabled>
    //       Lưu
    //     </Button>
    //   </Row> */}
    // </Form>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Input
          disabled
          placeholder="Tìm nhân viên"
          value={contract?.user?.fullName}
        />
      </Col>
      <Col span={24}>
        <Row justify="end">
          <Button icon={<SaveOutlined />} disabled>
            Lưu
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default React.memo(Users);
