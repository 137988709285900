import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const SongMedleyDetail = ({ songs, onDelete }) => {
  const SongItem = ({ song, index }) => {
    return (
      <Draggable draggableId={song.songId} index={index}>
        {provided => (
          <Row
            gutter={16}
            align="middle"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Col flex="auto">
              <Row justify="space-between" className="playlist-item">
                <Col>
                  <Space>
                    <MenuOutlined />
                    <Typography.Text
                      strong
                      style={{ maxWidth: '200px' }}
                      ellipsis={{ tooltip: song.song?.songName }}
                    >
                      {song.song?.songName}
                    </Typography.Text>
                    <Typography.Text
                      style={{ maxWidth: '150px' }}
                      ellipsis={{ tooltip: song.song?.singer }}
                    >
                      ({song.song?.singer}
                    </Typography.Text>
                    -
                    <Typography.Text
                      style={{ maxWidth: '150px' }}
                      ellipsis={{ tooltip: song.song?.musician }}
                    >
                      {song.song?.musician})
                    </Typography.Text>
                  </Space>
                </Col>
                <Col>{song.song?.songTypeName}</Col>
              </Row>
            </Col>
            <Col>
              <DeleteOutlined
                style={{ color: 'red', fontSize: '16px' }}
                onClick={() => onDelete(song.songId)}
              />
            </Col>
          </Row>
        )}
      </Draggable>
    );
  };

  return (
    !!songs?.length &&
    songs?.map((song, index) => (
      <SongItem song={song} index={index} key={song.songId} />
    ))
  );
};

export default SongMedleyDetail;
