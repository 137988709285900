import {
  CONTRACT_TYPE,
  CONTRACT_STATUS,
  MODEL,
  APPENDIX_PROCESS,
  APPENDIX_STATUS,
  SINGER_RANK,
  COPYRIGHT_SOURCE_TYPE
} from 'appConstants';

const getValueByKey = (key, array) => {
  const obj = array.find(obj => obj.value === key);
  return obj ? obj.label : '';
};

export const getContractTypeValue = key => {
  return getValueByKey(key, CONTRACT_TYPE);
};

export const getContractStatusValue = key => {
  return getValueByKey(key, CONTRACT_STATUS);
};

export const getAppendixStatusValue = key => {
  return getValueByKey(key, APPENDIX_STATUS);
};

export const getSingleRankValue = key => {
  return getValueByKey(key, SINGER_RANK);
};

export const getAppendixProcessValue = key => {
  return getValueByKey(key, APPENDIX_PROCESS);
};

export const getCopyrightSourceValue = key => {
  return getValueByKey(key, COPYRIGHT_SOURCE_TYPE);
};

export const getModelValue = key => {
  return getValueByKey(key, MODEL);
};

export const getKeyByValue = (object, value) =>
  Object.keys(object).find(key => object[key] === value);

export const formatOption = (data, label = 'name') => {
  return data.map(item => ({ value: item?.id, label: item[label] }));
};
