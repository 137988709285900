import { APPENDIX_PROCESS } from 'appConstants';

export const groupSubscribe = arr => {
  const res = [];

  for (let index = 0; index < arr.length; index++) {
    const temp = arr[index];

    const element = {
      ...temp,
      label: APPENDIX_PROCESS.find(({ value }) => value === temp.receiveStatus)
        ?.label,
      value: temp.receiveStatus
    };

    const foundIndex = res.findIndex(({ key }) => {
      return key.code === element.notificationDetail.code;
    });

    if (foundIndex !== -1) {
      const item = res[foundIndex];

      res[foundIndex] = { ...item, value: [...item.value, element] };

      continue;
    }

    const obj = { key: element.notificationDetail, value: [element] };

    res.push(obj);
  }

  return res;
};
