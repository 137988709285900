import { CloudDownloadOutlined } from '@ant-design/icons';

const ImportFileTemplate = ({ file }) => {
  return (
    <div>
      <a
        href={`https://view.officeapps.live.com/op/view.aspx?src=${process.env.REACT_APP_API_DOMAIN}/Excel/${file}`}
        target="_blank"
        rel="noreferrer"
      >
        Tải file import mẫu <CloudDownloadOutlined style={{ fontSize: 16 }} />
      </a>
    </div>
  );
};

export default ImportFileTemplate;
