import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import shallow from 'zustand/shallow';

import { useCurrentUser } from 'stores/useCurrentUser';

const PrivateRoute = ({ children, isLoggedIn, path, ...props }) => {
  const { currentUserViews } = useCurrentUser(
    useCallback(
      ({ currentUserViews }) => ({
        currentUserViews
      }),
      []
    ),
    shallow
  );

  const render = () => {
    if (currentUserViews)
      switch (true) {
        case currentUserViews.some(
          ({ value }) =>
            value === path ||
            //temporarily cheating
            (value === '/quan-ly-kenh' && path === '/quan-ly-kenh/:id')
        ):
        case path === '/404':
        case path === '/unauthorized':
        case path === '/':
        case path === '/ca-nhan/:id':
        case path === '/ca-nhan':
        case path === '/danh-sach-phat-hanh':
        case path === '/chi-tiet-phat-hanh/:id?':
        case path === '/review-phu-luc/:id':
        case path === '/reup/:type':
        case path === '/reup/video-detail/:id?':
        case path === '/thong-ke-kenh-phat-hanh/:id':
        case path === '/link-phat-hanh':
        case path === '/chi-tiet-link-phat-hanh/:id?':
          // case path === '/reup/video':
          // case path === '/reup/background':
          // case path === '/reup/hotkey':
          // case path === '/reup/tag':
          // case path === '/reup/spectrum':
          // case path === '/reup/effect':
          // case path === '/reup/song':
          //above is a whitelist
          return (
            <Route path={path} {...props}>
              {isLoggedIn ? children : <Redirect exact to="/dang-nhap" />}
            </Route>
          );

        default:
          return <Redirect to="/unauthorized" />;
      }
    else {
      return <></>;
    }
  };

  return render();
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
  isLoggedIn: PropTypes.bool
};

export default PrivateRoute;
