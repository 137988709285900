import AutoComplete from 'components/AutoComplete/AutoComplete';
import React, { useCallback, useMemo } from 'react';
import { useService } from 'stores/useService';
import shallow from 'zustand/shallow';

const OriginSongAutocomplete = ({
  selectCallback = f => f,
  song,
  ...props
}) => {
  const { searchForSong, songsBySearch } = useService(
    useCallback(
      ({ searchForSong, songsBySearch }) => ({
        searchForSong,
        songsBySearch
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      song
        ? {
            value: song?.id,
            label: song?.songName
          }
        : [],
    [song]
  );

  const handleSelectSong = id => {
    const songSelected = songsBySearch.find(_song => _song.id === id);
    selectCallback(songSelected);
  };

  return (
    <AutoComplete
      allowClear={true}
      placeHolder="Tìm bài hát gốc"
      handleSearch={searchValue => searchForSong(searchValue, true)}
      defaultOption={defaultOption}
      labelKey="songName"
      valueKey="id"
      onSelect={handleSelectSong}
      {...props}
    />
  );
};

export default React.memo(OriginSongAutocomplete);
