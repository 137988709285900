import { message, notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';

export const useProjectAppendix = create((set, get) => ({
  isLoading: false,
  originProjectAppendixes: [],
  projectAppendixes: [],
  //--------------------------------------------------------------------------------------//
  //                        Get project appendixes by contract id                         //
  //--------------------------------------------------------------------------------------//

  getProjectAppendixes: async contractId => {
    set({ isLoading: true });
    try {
      const {
        data: { data }
      } = await API.get(`/contract/${contractId}/project-appendix`);

      set({ isLoading: false });

      if (data) {
        set({ projectAppendixes: data, originProjectAppendixes: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  deleteProjectAppendix: async appendixId => {
    set(({ projectAppendixes }) => ({
      projectAppendixes: projectAppendixes.filter(
        _appendix => _appendix.id !== appendixId
      )
    }));
    message.success('Đã xoá phụ lục liên kết');
  },

  addProjectAppendix: async appendixId => {
    const _projectAppendix = get().currentSearchAppendixes.find(
      result => result?.id === appendixId
    );
    set(({ projectAppendixes }) => ({
      projectAppendixes: [...projectAppendixes, _projectAppendix]
    }));
    message.success('Đã thêm phụ lục liên kết');
  },

  currentSearchAppendixes: [],
  searchForProjectAppendix: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/appendix/search', {
        params: { freeText: searchValue, noProjectId: true }
      });

      if (data) {
        set({ currentSearchAppendixes: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateListProjectAppendix: async contractId => {
    set({ isLoading: true });
    try {
      const originIds = get().originProjectAppendixes.map(
        _origin => _origin.id
      );
      const currentIds = get().projectAppendixes.map(_current => _current.id);
      const appendixAdded = originIds
        .concat(currentIds)
        .filter(id => !originIds.includes(id));

      const appendixRemoved = originIds
        .concat(currentIds)
        .filter(id => !currentIds.includes(id));

      const addPromises = appendixAdded?.length
        ? API.post(`/contract/${contractId}/project-appendix`, {
            ids: appendixAdded
          })
        : null;

      const deletePromises = appendixRemoved?.length
        ? API.delete(`/contract/${contractId}/project-appendix`, {
            data: { ids: appendixRemoved }
          })
        : null;

      const data = await Promise.all([addPromises, deletePromises]);
      set({ isLoading: false });

      if (data) {
        await get().getProjectAppendixes(contractId);
        return notification.success({
          message: 'Cập nhật phụ lục liên kết thành công!'
        });
      }

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
