import { BulbOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, List, Popover } from 'antd';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePlaylistDetail } from 'stores/usePlaylistDetail';
import shallow from 'zustand/shallow';

const Top5Artists = ({ add, disabledOptions }) => {
  const { id } = useParams();
  const { top5Artists, getTop5Artists } = usePlaylistDetail(
    useCallback(
      ({ top5Artists, getTop5Artists }) => ({
        top5Artists,
        getTop5Artists
      }),
      []
    ),
    shallow
  );
  useEffect(() => {
    if (id) {
      getTop5Artists(id);
    }
  }, [getTop5Artists, id]);

  const onAddArtist = id => {
    add({ partnerId: id });
  };

  return (
    <Popover
      content={
        <List
          size="small"
          bordered
          dataSource={top5Artists}
          renderItem={artist => (
            <List.Item
              actions={[
                <Button
                  disabled={disabledOptions.includes(artist?.id)}
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => onAddArtist(artist?.id)}
                  size="small"
                ></Button>
              ]}
            >
              {artist?.artistName || artist?.fullName}
            </List.Item>
          )}
        />
      }
      title="Top nghệ sĩ gợi ý"
      trigger="click"
    >
      <Button icon={<BulbOutlined />} size="small"></Button>
    </Popover>
  );
};

export default Top5Artists;
