import React from 'react';
import { Upload as AntdUpload } from 'antd';
import { getAccessToken } from 'utils/authority';

const Upload = ({ children, endPoint = '/cloud/upload', ...props }) => {
  return (
    <AntdUpload
      action={process.env.REACT_APP_API + endPoint}
      headers={{ authorization: `Bearer ${getAccessToken()}` }}
      {...props}
    >
      {children}
    </AntdUpload>
  );
};

export default Upload;
