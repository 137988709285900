import { Col, Form, Input, Row } from 'antd';
import { FILTER_TYPE } from 'appConstants';
import Select from 'components/Select';
import React, { useEffect } from 'react';
import FilterByType from './FilterByType';

const operator = {
  EQ: { label: 'Bằng', value: 'eq' },
  CTN: { label: 'Chứa', value: 'ctn' },
  STW: { label: 'Bắt đầu bằng', value: 'stw' },
  GT: { label: 'Lớn hơn', value: 'gt' },
  GTE: { label: 'Lớn hơn hoặc bằng', value: 'gte' },
  LT: { label: 'Bé hơn', value: 'lt' },
  LTE: { label: 'Bé hơn hoặc bằng', value: 'lte' }
};

const generateOperatorOptions = type => {
  switch (type) {
    case FILTER_TYPE.TEXT:
      return [operator.EQ, operator.CTN, operator.STW];

    case FILTER_TYPE.DATE_PICKER:
      return [
        operator.EQ,
        operator.GT,
        operator.GTE,
        operator.LT,
        operator.LTE
      ];

    default:
      return Object.values(operator);
  }
};

const FilterBoxFormContext = React.createContext(null);

const FilterBoxForm = ({ currItem, form, childComponent, handleOk }) => {
  useEffect(() => form.resetFields, [form]);

  const renderByType = type => {
    switch (type) {
      case FILTER_TYPE.RANGE_PICKER:
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="operatorFrom" style={{ display: 'none' }}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="operatorTo" style={{ display: 'none' }}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        );

      case FILTER_TYPE.SELECT:
        return (
          <Form.Item name="operator" style={{ display: 'none' }}>
            <Input disabled />
          </Form.Item>
        );
      case FILTER_TYPE.AUTO_COMPLETE:
        return null;

      default:
        return (
          <Form.Item
            label="Toán tử"
            name="operator"
            rules={[{ required: true, message: 'Chọn toán tử!' }]}
            initialValue={operator.CTN.value}
          >
            <Select options={generateOperatorOptions(currItem.type)} />
          </Form.Item>
        );
    }
  };

  useEffect(() => {
    if (currItem.type === FILTER_TYPE.SELECT) {
      form.setFieldsValue({ operator: operator.EQ.value });
    }

    if (currItem.type === FILTER_TYPE.RANGE_PICKER) {
      form.setFieldsValue({
        operatorFrom: operator.GTE.value,
        operatorTo: operator.LTE.value
      });
    }
  }, [currItem, form]);

  return (
    <Form layout="vertical" form={form}>
      <FilterBoxFormContext.Provider value={form}>
        <Form.Item
          label={currItem?.formTitle + ':'}
          name="value"
          rules={[{ required: true, message: 'Nhập giá trị!' }]}
        >
          {currItem.autocompleteComponent ? (
            currItem.autocompleteComponent
          ) : currItem?.isUsingChild ? (
            childComponent
          ) : (
            <FilterByType
              type={currItem?.type}
              options={currItem?.options}
              form={form}
              handleOk={handleOk}
            />
          )}
        </Form.Item>

        {renderByType(currItem.type)}
      </FilterBoxFormContext.Provider>
    </Form>
  );
};

export { FilterBoxFormContext };
export default FilterBoxForm;
