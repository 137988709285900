import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { CommonContainer } from 'components/StyledComponents';
import Table from 'components/Table';

import { useContract } from 'stores/useContract';
import shallow from 'zustand/shallow';
import { appendixColumns, columns } from './columns';
import ContractFilterBox from './components/ContractFilterBox';
import ContractMainContent from './ContractMainContent';
import { ORDER_BY_SELECT_VALUE } from 'appConstants';

const ContractContainer = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE,
    [searchParams.sort]
  );

  const {
    contracts,
    getContracts,
    orderBy,
    totalPage,
    deleteContracts,
    userSelected,
    setParams,
    total,
    getExportContracts,
    isExportLoading,
    getExportContractByIds
  } = useContract(
    useCallback(
      ({
        contracts,
        getContracts,
        orderBy,
        totalPage,
        deleteContracts,
        userSelected,
        setParams,
        total,
        getExportContracts,
        isExportLoading,
        getExportContractByIds
      }) => ({
        contracts,
        getContracts,
        orderBy,
        totalPage,
        deleteContracts,
        userSelected,
        setParams,
        total,
        getExportContracts,
        isExportLoading,
        getExportContractByIds
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    const paramsForGet = { ...searchParams, page: page - 1, sort };

    setParams(paramsForGet);
    getContracts(paramsForGet);
  }, [
    getContracts,
    page,
    orderBy,
    userSelected,
    searchParams,
    sort,
    setParams
  ]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };
  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-hop-dong',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = useCallback(
    sort => {
      history.push({
        pathname: '/danh-sach-hop-dong',
        search: queryString.stringify({ ...searchParams, page: 1, sort })
      });
    },
    [history, searchParams]
  );

  const expandedRow = row => {
    let inTable = row?.appendix;
    const appendixData = inTable.map(data => ({
      ...data,
      key: data.id,
      contractCode: row?.contractCode,
      contractId: row?.id
    }));

    return (
      <Table
        scroll={{ x: true }}
        columns={appendixColumns}
        dataSource={appendixData}
        paginationProps={false}
        isFilterColumns={false}
      />
    );
  };
  const rowExpandable = record => record.appendix.length > 0;

  return (
    <CommonContainer>
      <ContractMainContent
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rowSelection={rowSelection}
        contracts={contracts}
        totalPage={totalPage}
        deleteContracts={deleteContracts}
        page={page * 1}
        columns={columns}
        onPaginate={onPaginate}
        expandedRow={expandedRow}
        contractFilterBox={ContractFilterBox}
        rowExpandable={rowExpandable}
        orderBy={sort}
        setOrderBy={setOrderBy}
        total={total}
        getExportContracts={getExportContracts}
        isExportLoading={isExportLoading}
        showExport={true}
        getExportContractByIds={getExportContractByIds}
      />
    </CommonContainer>
  );
};

export default ContractContainer;
