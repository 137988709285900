import React from 'react';
import FilterBox from 'components/FilterBox';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FILTER_TYPE, MASTER_DATA_GROUP_LABEL } from 'appConstants';

import { filterToUrl, genInitValue } from 'components/FilterBox/utils';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Code',
    name: 'Code'
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Tên',
    name: 'Title'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Nhóm',
    name: 'Group',
    options: Object.keys(MASTER_DATA_GROUP_LABEL).map(key => ({
      value: key,
      label: MASTER_DATA_GROUP_LABEL[key]
    }))
  },
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Màu sắc',
    name: 'Color'
  }
];

export const data = [
  {
    mainTitle: 'Master Data',
    filterItems
  }
];

const MasterDataFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  delete searchParams.page;
  delete searchParams.sort;

  const setFilter = (key, { value, operator, operatorFrom, operatorTo }) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo },
      searchParams,
      history,
      '/danh-sach-master-data'
    );
  };

  return (
    <div className="mb-md">
      <FilterBox
        options={data}
        setFilter={setFilter}
        initValue={genInitValue(searchParams, filterItems)}
      />
    </div>
  );
};

export default MasterDataFilterBox;
