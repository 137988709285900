import React, { useCallback, useEffect, useMemo, useState } from 'react';
import shallow from 'zustand/shallow';
import moment from 'moment';

import { Modal, Form } from 'antd';

import ManagerFormModalContent from './ManagerFormModalContent';
import { usePartnerDetail } from 'stores/usePartnerDetail';
import { formatDate } from 'utils/date';

const ManagerFormModal = ({ children, defaultValue }) => {
  const { createManager, updateManager, updateManagerAutoCompleteOptions } =
    usePartnerDetail(
      useCallback(
        ({
          createManager,
          updateManager,
          updateManagerAutoCompleteOptions
        }) => ({
          createManager,
          updateManager,
          updateManagerAutoCompleteOptions
        }),
        []
      ),
      shallow
    );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const isUpdateManager = useMemo(() => !!defaultValue, [defaultValue]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        ...values,
        dateOfBirth: values.dateOfBirth && formatDate(values.dateOfBirth),
        dateOfIdentification:
          values.dateOfIdentification && formatDate(values.dateOfIdentification)
      };

      if (isUpdateManager) {
        await updateManager(
          { id: defaultValue.id, ...params },
          //NOTE: edit label sau khi update tên của manager
          //vì label của option là tên của company
          () =>
            updateManagerAutoCompleteOptions(prev =>
              prev.map(item =>
                item.value === defaultValue.id
                  ? { ...item, label: params.fullName }
                  : item
              )
            )
        );
      } else {
        await createManager(params);
      }
      setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isUpdateManager && defaultValue) {
      const {
        fullName,
        email,
        phone,
        address,
        gender,
        taxCode,
        identityNumber,
        dateOfIdentification,
        placeOfIdentification,
        dateOfBirth
      } = defaultValue;

      form.setFieldsValue({
        fullName,
        email,
        phone,
        address,
        gender,
        taxCode,
        identityNumber,
        dateOfIdentification: moment(dateOfIdentification),
        placeOfIdentification,
        dateOfBirth: moment(dateOfBirth)
      });
    }
  }, [isUpdateManager, defaultValue, form]);

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        title={isUpdateManager ? 'Chỉnh sửa quản lý' : 'Tạo mới quản lý'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        width={800}
        forceRender
      >
        <ManagerFormModalContent form={form} defaultValue={defaultValue} />
      </Modal>
    </>
  );
};

export default ManagerFormModal;
