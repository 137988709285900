import React from 'react';
import moment from 'moment';
import 'moment/locale/vi';

import { Space, Typography } from 'antd';
import { EDITABLE_CELL_TYPE } from 'appConstants';
import ProviderAddButtons from './components/ProviderAddButtons';
import ProviderDeleteButton from './components/ProviderDeleteButton';

export const columns = [
  {
    title: 'Tên nhà cung cấp nội dung',
    dataIndex: 'name',
    render: name => <Typography.Text>{name}</Typography.Text>,
    width: '20%',
    editable: EDITABLE_CELL_TYPE.TEXT,
    focusIfAdd: true
  },
  {
    title: 'Code',
    dataIndex: 'code',
    render: code => <Typography.Text>{code}</Typography.Text>,
    width: '15%',
    editable: EDITABLE_CELL_TYPE.TEXT
  },
  {
    title: 'Lĩnh vực kinh doanh',
    dataIndex: 'businessTypeId',
    render: businessTypeId => (
      <Typography.Text>{businessTypeId}</Typography.Text>
    ),
    width: '15%',
    editable: EDITABLE_CELL_TYPE.SELECT
  },
  {
    title: 'Mô tả',
    dataIndex: 'description',
    render: description => <Typography.Text>{description}</Typography.Text>,
    width: '20%',
    editable: EDITABLE_CELL_TYPE.TEXT
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate =>
      createDate && (
        <Space direction="vertical">
          <Typography.Text>
            {moment(createDate).format('DD/MM/YYYY hh:mm')}
          </Typography.Text>

          <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
        </Space>
      ),
    width: '10%'
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    render: updateDate =>
      updateDate && (
        <Space direction="vertical">
          <Typography.Text>
            {moment(updateDate).format('DD/MM/YYYY hh:mm')}
          </Typography.Text>

          <Typography.Text>{moment(updateDate).fromNow()}</Typography.Text>
        </Space>
      ),
    width: '10%'
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    render: (_, item) => {
      return item?.add ? (
        <ProviderAddButtons />
      ) : (
        <ProviderDeleteButton item={item} />
      );
    },
    align: 'center',
    width: '10%'
  }
];
