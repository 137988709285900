import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';

export const useReupBackground = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* **************** */
  //* NOTE: get reupBackgrounds  */
  //* **************** */
  reupBackgrounds: [],
  totalPage: 1,
  total: 0,

  getReupBackgrounds: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get(`/reup/image`, {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });

      if (data) {
        set({
          reupBackgrounds: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getRandomReupBackgrounds: async params => {
    try {
      const {
        data: { data }
      } = await API.get(`/reup/image/random`, {
        params
      });

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (e) {
      notification.error({
        message: e?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw e;
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForReupBackground: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/reup/image/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
