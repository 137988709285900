import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Form } from 'antd';
import shallow from 'zustand/shallow';

import MasterDataFormModalContent from './MasterDataFormModalContent';

import { useMasterData } from 'stores/useMasterData';

const MasterDataFormModal = ({ children, masterDataId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { createMasterData, updateMasterData } = useMasterData(
    useCallback(
      ({ createMasterData, updateMasterData }) => ({
        createMasterData,
        updateMasterData
      }),
      []
    ),
    shallow
  );

  const isUpdateMasterData = useMemo(() => !!masterDataId, [masterDataId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();

      if (isUpdateMasterData) {
        await updateMasterData({ id: masterDataId, ...params });
      } else {
        await createMasterData(params);
      }

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        title={
          isUpdateMasterData ? 'Chỉnh sửa master data' : 'Tạo mới master data'
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
        width={800}
        destroyOnClose
      >
        <MasterDataFormModalContent
          form={form}
          masterDataId={masterDataId}
          isUpdateMasterData={isUpdateMasterData}
        />
      </Modal>
    </>
  );
};

export default MasterDataFormModal;
