import React from 'react';
import SongsWrapper from './SongsWrapper';

const SortSongFormModalContent = () => {
  return (
    <div className="sort-song-content">
      <SongsWrapper />
    </div>
  );
};

export default SortSongFormModalContent;
