import React, { useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import { usePartnerDetail } from 'stores/usePartnerDetail';
import Select from 'components/Select';

const ArtistTypeSelect = props => {
  const [options, setOptions] = useState([]);

  const { getAllArtistType } = usePartnerDetail(
    useCallback(
      ({ getAllArtistType }) => ({
        getAllArtistType
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    const handleGetOption = async () => {
      const res = await getAllArtistType();
      const options = res
        ? res?.map(({ title, id }) => ({ value: id, label: title }))
        : [];

      setOptions(options);
    };

    handleGetOption();
  }, [getAllArtistType]);

  return <Select options={options} {...props} />;
};

export default ArtistTypeSelect;
