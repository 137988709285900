import React, { useCallback } from 'react';
import { Tag } from 'antd';
import { CONTRACT_STATUS_VALUE } from 'appConstants';
import { getContractStatusValue } from 'utils/parser';

const ContractStatusTag = ({ value }) => {
  const generateColor = useCallback(() => {
    switch (value) {
      case CONTRACT_STATUS_VALUE.COMPOSE:
        return 'gold';

      case CONTRACT_STATUS_VALUE.LIQUIDATED:
        return 'processing';

      case CONTRACT_STATUS_VALUE.EXPIRED:
        return 'magenta';

      case CONTRACT_STATUS_VALUE.AVAILABLE:
        return 'green';

      default:
        return '';
    }
  }, [value]);

  return <Tag color={generateColor()}>{getContractStatusValue(value)}</Tag>;
};

export default ContractStatusTag;
