import { Col, Row, Space, Typography } from 'antd';
import TextContent from 'components/TextContent';
import ContracTypeTag from 'pages/AddendumDetail/components/ContracTypeTag';
import React from 'react';

const { Text } = Typography;

const ContractInfoContent = ({ contract }) => {
  return (
    <Row gutter={[32, 16]}>
      <Col span={8}>
        <Space direction="vertical" size={0}>
          <Text>Loại hợp đồng:</Text>
          <ContracTypeTag value={contract?.contractType} />
        </Space>
      </Col>
      <Col span={8}>
        <Space direction="vertical" size={0}>
          <Text>Mã hợp đồng:</Text>
          <Text strong>{contract?.contractCode}</Text>
        </Space>
      </Col>
      <Col span={8}>
        <Space direction="vertical" size={0}>
          <Text>Thuế suất:</Text>
          <Text strong>{contract?.taxPercent}%</Text>
        </Space>
      </Col>
      <Col span={8}>
        <Space direction="vertical" size={0}>
          <Text>Tự động ký lại:</Text>
          <Text strong>{contract?.autoRenew ? ' Có' : 'Không'}</Text>
        </Space>
      </Col>
      <Col span={8}>
        <Space direction="vertical" size={0}>
          <Text>Ngày bắt đầu hiệu lực:</Text>
          <TextContent value={contract?.startDate} isDate />
        </Space>
      </Col>
      <Col span={8}>
        <Space direction="vertical" size={0}>
          <Text>Ngày kết thúc hiệu lực:</Text>
          <TextContent value={contract?.finishDate} isDate />
        </Space>
      </Col>
      <Col span={8}>
        <Space direction="vertical" size={0}>
          <Text>Đã thanh toán đối soát:</Text>
          <Text strong>{contract?.doiSoatPaid ? ' Có' : 'Không'}</Text>
        </Space>
      </Col>
      <Col span={8} offset={16}>
        <Space align="baseline">
          <Typography.Text>File hợp đồng:</Typography.Text>
          {!!contract?.file.length ? (
            <Space direction="vertical">
              {contract?.file.map((file, index) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  href={file?.fileLink}
                >
                  {file.fileName}
                </a>
              ))}
            </Space>
          ) : (
            <Typography.Text type="danger">Chưa có</Typography.Text>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default ContractInfoContent;
