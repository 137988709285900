import { Space, Timeline, Typography } from 'antd';
import React from 'react';
import { formatDate } from 'utils/date';
import HistoryType from './HistoryType';

const { Text } = Typography;
const HistoryTimeline = ({ histories }) => {
  return (
    <Timeline>
      {histories.map(history => {
        const { type, objectType, updateInfo } = history;
        const action = type.replace(objectType, '');
        const isHidden =
          (action === 'update' && updateInfo.length === 0) ||
          updateInfo[0]?.columnName === 'SyncDate';
        return !isHidden ? (
          <Timeline.Item key={history.id}>
            <Space direction="vertical" size={0}>
              <Text strong>{history?.user?.fullName}</Text>
              <Text italic="true">{formatDate(history.createDate)}</Text>
              <HistoryType history={history} />
            </Space>
          </Timeline.Item>
        ) : null;
      })}
    </Timeline>
  );
};

export default HistoryTimeline;
