import Button from 'components/Button';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

const DeleteButton = ({ onDelete, from, title, length }) => {
  // switch (true) {
  //   case from === ROLE_CHECK.USER:
  //   case from === ROLE_CHECK.ROLE:
  //   case from === ROLE_CHECK.SONG:
  //   case from === ROLE_CHECK.PARTNER:
  //     return (
  //       <DeleteConfirmPopup title={title} onDelete={onDelete}>
  //         <Button danger>Xoá ({length})</Button>
  //       </DeleteConfirmPopup>
  //     );

  //   default:
  //     return <></>;
  // }
  return (
    <DeleteConfirmPopup title={title} onDelete={onDelete}>
      <Button danger>Xoá ({length})</Button>
    </DeleteConfirmPopup>
  );
};

export default DeleteButton;
