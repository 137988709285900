import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Empty, Row, Spin, Typography, Pagination } from 'antd';
import Button from 'components/Button';
import SummaryDetail from 'pages/ContractDetail/SummaryDetail';
import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useContract } from 'stores/useContract';
import { useContractHistory } from 'stores/useContractHistory';
import shallow from 'zustand/shallow';
import HistoryTimeline from './components/HistoryTimeline';

const ContractHistory = () => {
  const { id } = useParams();
  const history = useHistory();
  const { getContractHistory, histories, isLoading, setPage, page, total } =
    useContractHistory(
      useCallback(
        ({
          getContractHistory,
          histories,
          isLoading,

          setPage,
          page,
          total
        }) => ({
          getContractHistory,
          histories,
          isLoading,

          setPage,
          page,
          total
        }),
        []
      ),
      shallow
    );

  const { getContract } = useContract(
    useCallback(
      ({ getContract }) => ({
        getContract
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getContractHistory(id);
  }, [getContractHistory, id, page]);

  useEffect(() => {
    getContract(id);
  }, [getContract, id]);

  const onChange = pageNumber => {
    setPage(pageNumber);
  };

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Typography.Title>Lịch sử hợp đồng</Typography.Title>
        <Button
          icon={<ArrowLeftOutlined />}
          type="default"
          onClick={history.goBack}
        >
          Quay lại
        </Button>
      </Row>

      <Row gutter={16} wrap={false}>
        <Col
          flex="auto"
          style={{
            border: '1px solid rgb(240, 240, 240)',
            borderRadius: '4px',
            padding: '16px 24px',
            background: 'white'
          }}
        >
          {isLoading ? (
            <Row justify="center" align="middle" style={{ height: '100%' }}>
              <Spin size="large" />
            </Row>
          ) : !!histories.length ? (
            <HistoryTimeline histories={histories} />
          ) : (
            <Row justify="center">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Không tìm thấy dữ liệu"
              />
            </Row>
          )}
          <Row justify="end">
            <Pagination
              defaultCurrent={1}
              total={total}
              onChange={onChange}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        <Col>
          <SummaryDetail />
        </Col>
      </Row>
    </div>
  );
};

export default ContractHistory;
