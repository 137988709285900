import { notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';

export const useService = create((set, get) => ({
  //--------------------------------------------------------------------------------------//
  //                            Get link information from url                             //
  //--------------------------------------------------------------------------------------//

  isLinkInfoLoading: false,
  getLinkInfoByUrl: async url => {
    set({ isLinkInfoLoading: true });
    try {
      const { data } = await API.get(`/playlist/link-info?path=${url}`);
      set({ isLinkInfoLoading: false });
      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isLinkInfoLoading: false });
      notification.warning({
        message: 'Không có dữ liệu gợi ý từ link này'
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                 Search Songs by Text                                 //
  //--------------------------------------------------------------------------------------//
  songsBySearch: [],
  searchForSong: async (searchValue, isReleaseLink) => {
    try {
      const {
        data: { data }
      } = await API.get('/song/search', {
        params: {
          freeText: searchValue,
          isReleaseLink: isReleaseLink || null
        }
      });

      if (data) {
        set({ songsBySearch: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                    Get Song by Id                                    //
  //--------------------------------------------------------------------------------------//

  getSong: async songId => {
    try {
      const { data } = await API.get(`/song/${songId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                              Search Publishers by Text                               //
  //--------------------------------------------------------------------------------------//

  searchForPublisher: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/publisher/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                 Get Publisher by Id                                  //
  //--------------------------------------------------------------------------------------//

  getPublisher: async publisherId => {
    try {
      const { data } = await API.get(`/publisher/${publisherId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                  Get business type                                   //
  //--------------------------------------------------------------------------------------//

  getBusinessAreas: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/business-type?pageSize=100000');

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                               Get Business type by Id                                //
  //--------------------------------------------------------------------------------------//

  getBusinessById: async businessId => {
    try {
      const { data } = await API.get(`/business-type/${businessId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                Get Song type by code                                 //
  //--------------------------------------------------------------------------------------//

  getSongTypeByCode: async code => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata', {
        params: {
          'Group[eq]': 'musicType',
          'Code[eq]': code
        }
      });

      if (data) {
        return data[0];
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                               Search contract by text                                //
  //--------------------------------------------------------------------------------------//

  searchForContract: async (searchValue, IsParentContract = false) => {
    try {
      const {
        data: { data }
      } = await API.get('/contract/search', {
        params: {
          freeText: searchValue,
          IsParentContract: IsParentContract
        }
      });

      if (data && data.length !== 0) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                              Get contract by contractId                              //
  //--------------------------------------------------------------------------------------//

  getContract: async contractId => {
    try {
      const { data } = await API.get(`/contract/${contractId}`);
      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
