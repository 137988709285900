import React, { useCallback, useEffect, useMemo } from 'react';
import { Pagination, Row, Table } from 'antd';
import queryString from 'query-string';

import shallow from 'zustand/shallow';

import { columns } from './columns';

import Button from 'components/Button';
import ProviderRow from './components/ProviderRow';
import ProviderCell from './components/ProviderCell';

import { useProvider } from 'stores/useProvider';
import { usePublisher } from 'stores/usePublisher';
import { EDITABLE_CELL_TYPE, PAGE_SIZE } from 'appConstants';
import { useHistory, useLocation } from 'react-router-dom';
import Tag from 'components/Tag';

const Provider = () => {
  const {
    providers,
    businessAreas,
    isProviderLoading,
    toggleIsAddingProvider,
    isAddingProvider,
    updateProvider,
    getProviders,
    setParams,
    total
  } = useProvider(
    useCallback(
      ({
        providers,
        businessAreas,
        isProviderLoading,
        toggleIsAddingProvider,
        isAddingProvider,
        updateProvider,
        getProviders,
        setParams,
        total
      }) => ({
        providers,
        businessAreas,
        isProviderLoading,
        toggleIsAddingProvider,
        isAddingProvider,
        updateProvider,
        getProviders,
        setParams,
        total
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);

  const { isPublisherLoading } = usePublisher(
    useCallback(
      ({ isPublisherLoading }) => ({
        isPublisherLoading
      }),
      []
    ),
    shallow
  );

  const handleSave = row => {
    updateProvider(row);
  };

  const anotherColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => {
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          focusIfAdd: col.focusIfAdd,
          handleSave: handleSave,
          ...(col.editable === EDITABLE_CELL_TYPE.SELECT
            ? {
                options: businessAreas.map(({ name, id }) => ({
                  label: name,
                  value: id
                }))
              }
            : {})
        };
      }
    };
  });

  const components = {
    body: {
      row: ProviderRow,
      cell: ProviderCell
    }
  };

  const dataSource = providers.map(item => ({ ...item, key: item.id }));

  const onPaginate = page => {
    history.push({
      pathname: '/quan-ly-kenh/nha-cung-cap-noi-dung',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  useEffect(() => {
    const params = { ...searchParams, page: page - 1 };
    setParams(params);
    getProviders(params);
  }, [getProviders, searchParams, page, setParams]);

  const pagination = {
    total: total,
    pageSize: PAGE_SIZE,
    onChange: onPaginate,
    current: page * 1
  };

  return (
    <div>
      <Row justify="end">
        <Button onClick={toggleIsAddingProvider}>Tạo mới</Button>
      </Row>

      <Table
        // pagination={{
        //   total: totalPage * PAGE_SIZE,
        //   pageSize: PAGE_SIZE,
        //   onChange: onPaginate,
        //   current: page * 1
        // }}
        pagination={false}
        columns={anotherColumns}
        dataSource={
          isAddingProvider ? [{ add: true }, ...dataSource] : dataSource
        }
        loading={isPublisherLoading || isProviderLoading}
        components={components}
        style={{ margin: '16px 0' }}
      />
      <Row justify="space-between" className="mt-md">
        <Tag color="blue" style={{ display: 'flex', alignItems: 'center' }}>
          Tổng: {pagination.total}
        </Tag>
        <Pagination {...pagination} showSizeChanger={false} />
      </Row>
    </div>
  );
};

export default Provider;
