import {
  ClockCircleOutlined,
  EditOutlined,
  EyeOutlined,
  LikeOutlined
} from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { Music } from 'components/Icons';
import { Reaction } from 'components/Icons';
import RelatedUser from 'components/RelatedUser';
import TimeLine from 'components/TimeLine';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatView } from 'utils/currency';
import SongPublicationDeleteButton from './components/SongPublicationDeleteButton';

const { Text } = Typography;

export const columns = [
  {
    title: 'Mã Code',
    dataIndex: 'code',
    width: 200,
    render: (code, { song, id }) =>
      song ? (
        <Link to={`/chi-tiet-link-phat-hanh/${id}`}>
          <Text strong>
            {code} <EditOutlined />
          </Text>
        </Link>
      ) : (
        <Text strong>{code}</Text>
      )
  },
  {
    title: 'Link phát hành',
    dataIndex: 'link',
    width: 200,
    render: link => {
      const shortLink = link.match(/.{1,30}/g) || [];
      return (
        <a href={link} target="_blank" rel="noreferrer">
          <Typography.Text
            style={{ width: 200, color: '#1890ff' }}
            ellipsis={{
              tooltip: link
            }}
          >
            {shortLink[0]}
          </Typography.Text>
        </a>
      );
    }
  },
  {
    title: 'Bài hát',
    dataIndex: 'song',
    width: 200,
    render: (_, { song }) =>
      song ? (
        <Space direction="vertical">
          <Text strong>{song?.songName}</Text>
          <Text>{song?.songTypeName}</Text>
        </Space>
      ) : (
        <Typography.Text type="danger">Bài hát đã bị xoá</Typography.Text>
      )
  },
  {
    title: 'Kênh phát hành',
    dataIndex: 'publisher',
    width: 200,
    render: (publisher, { publisher: { provider } }) => (
      <Space direction="vertical">
        <Text strong>{publisher?.name}</Text>
        <Text>{provider?.name}</Text>
      </Space>
    )
  },
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    width: 200
  },
  {
    title: 'Thống kê',
    dataIndex: 'views',
    width: 200,
    render: (
      views,
      {
        scannedDate,
        likes,
        soundUsedTimes,
        publisher: {
          provider: { code }
        }
      }
    ) => (
      <Space direction="vertical">
        {(views || views === 0) && (
          <Tooltip title="Lượt xem">
            <Space>
              <EyeOutlined />
              <Typography.Text>{formatView(views)}</Typography.Text>
            </Space>
          </Tooltip>
        )}
        {(likes || likes === 0) && (
          <Tooltip title={code === 'FACEBOOK' ? 'Lượt reaction' : 'Lượt thích'}>
            <Space>
              {code === 'FACEBOOK' ? (
                <Reaction style={{ fontSize: 18 }} />
              ) : (
                <LikeOutlined />
              )}
              <Typography.Text>{formatView(likes)}</Typography.Text>
            </Space>
          </Tooltip>
        )}
        {(soundUsedTimes || soundUsedTimes === 0) && (
          <Tooltip title="Lượt sử dụng audio">
            <Space>
              <Music />
              <Typography.Text>{formatView(soundUsedTimes)}</Typography.Text>
            </Space>
          </Tooltip>
        )}

        {scannedDate && (
          <Space>
            <ClockCircleOutlined />
            <Typography.Text style={{ color: 'gray' }}>
              {moment(scannedDate).format('DD/MM/YYYY hh:mm')}
            </Typography.Text>
          </Space>
        )}
      </Space>
    )
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: 250,
    render: (_, { createDate, updateDate, publicTime }) => (
      <TimeLine
        createDate={createDate}
        updateDate={updateDate}
        publicTime={publicTime}
      />
    ),
    defaultHidden: true
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: 250,
    render: (_, { createByUser, updateByUser }) => (
      <RelatedUser creator={createByUser} updater={updateByUser} />
    ),
    defaultHidden: true
  },
  {
    title: 'Thao tác',
    key: 'actions',
    dataIndex: 'actions',
    render: (_, item) => <SongPublicationDeleteButton item={item} />,
    align: 'center',
    width: 100,
    defaultHidden: true
  }
];
