import { Space, Typography } from 'antd';
import Tag from 'components/Tag';
import React from 'react';
import { Link } from 'react-router-dom';
import ApproveButton from './ApproveButton';

const { Text } = Typography;

export const columns = [
  {
    title: 'Tuyển tập',
    dataIndex: 'name',
    render: (name, { id }) => (
      <Space direction="vertical">
        <Link to={`/chi-tiet-tuyen-tap/${id}`}>
          <Text strong>{name}</Text>
        </Link>
      </Space>
    )
  },
  {
    title: 'Bài hát',
    dataIndex: 'playlistDetail',
    render: playlistDetail => <Text>{playlistDetail.length} bài hát</Text>
  },
  {
    dataIndex: 'totalSong',
    render: (totalSong, playlist) =>
      totalSong > 0 ? (
        <ApproveButton playlist={playlist} />
      ) : (
        <Tag color="error">Chưa đủ điều kiện duyệt</Tag>
      )
  }
];
