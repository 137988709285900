import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import Input from 'components/Input';
import DatePicker from 'components/DatePicker';
import RangePicker from 'components/RangePicker';

import { FILTER_TYPE } from 'appConstants';
import FilterSelect from './FilterSelect';
import { useEffect } from 'react';

const FilterByType = ({ type, options, handleOk, ...props }) => {
  const inputRef = useRef();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  const renderFilter = type => {
    switch (type) {
      case FILTER_TYPE.TEXT:
        return <Input {...props} onPressEnter={handleOk} ref={inputRef} />;

      case FILTER_TYPE.DATE_PICKER:
        return <DatePicker {...props} />;

      case FILTER_TYPE.RANGE_PICKER:
        return <RangePicker {...props} style={{ width: '100%' }} />;

      case FILTER_TYPE.SELECT:
        return <FilterSelect options={options} {...props} />;

      default:
        break;
    }
  };
  return renderFilter(type);
};

FilterByType.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func
};

export default FilterByType;
