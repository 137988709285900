import { notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';
import { useContract } from './useContract';

export const useInstallPayment = create((set, get) => ({
  payments: [],
  wasPaymentEdited: false,
  setPaymentEdited: value => set({ wasPaymentEdited: value }),
  setPayments: payments => set({ payments }),
  mergePayment: async mergePayment => {
    const newPayments = get().payments.map(payment =>
      payment.id === mergePayment.id
        ? {
            ...payment,
            file: mergePayment.file,
            paymentDate: mergePayment.paymentDate,
            status: mergePayment.status,
            action: 'update'
          }
        : payment
    );
    await set({ payments: newPayments });
  },

  processAllPayment: async () => {
    try {
      const updatePaymentPromises = get()
        .payments.filter(params => params.action === 'update')
        .map(params =>
          API.put(`/payment/${params.id}`, {
            ...params,
            paymentDate: params.paymentDate
          })
        );

      const deletePromises = get()
        .payments.filter(params => params.action === 'delete')
        .map(({ id }) => API.delete(`/payment/${id}`));
      if (!updatePaymentPromises.length && !deletePromises.length) return;
      const data = await Promise.all([
        ...updatePaymentPromises,
        ...deletePromises
      ]);

      if (data) {
        notification.success({
          message: 'Chỉnh sửa đợt thanh toán thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateAppendix: async params => {
    try {
      const { data } = await API.put(`/appendix/${params.appendixId}`, params);

      if (data) {
        useContract.getState().getAppendix(params.appendixId);
        notification.success({
          message: 'Chỉnh sửa phụ lục thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
