import { Col, Row } from 'antd';
import React from 'react';
import CheckContract from './components/CheckContract';
import ExpiredContract from './components/ExpiredContract/ExpiredContract';
import LatestSong from './components/LatestSong';
import NearExpiredContract from './components/NearExpiredContract';
import NewContract from './components/NewContract';
import PayContract from './components/PayContract';
import Statistics from './components/Statistics';
import WaitingPlaylist from './components/WaitingPlaylist';
import NeedToCheckContract from './components/NeedToCheckContract';

const Dashboard = () => {
  return (
    <div>
      <Statistics />
      <Row className="mb-md" gutter={16}>
        <Col span={24}>
          <NeedToCheckContract />
        </Col>
      </Row>
      <Row className="mb-md" gutter={16}>
        <Col span={12}>
          <NewContract />
        </Col>
        <Col span={12}>
          <ExpiredContract />
        </Col>
      </Row>
      <Row className="mb-md" gutter={16}>
        <Col span={8}>
          <NearExpiredContract />
        </Col>
        <Col span={8}>
          <PayContract />
        </Col>
        <Col span={8}>
          <CheckContract />
        </Col>
      </Row>
      <Row className="mb-md" gutter={16}>
        <Col span={12}>
          <LatestSong />
        </Col>
        <Col span={12}>
          <WaitingPlaylist />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
