import { Form, Modal } from 'antd';
import { CONTRACT_STATUS_VALUE } from 'appConstants';
import React, { useCallback, useState } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import LiquidateFormModalContent from './LiquidateFormModalContent';

const LiquidateFormModal = ({ children, contractId, contract }) => {
  const { changeContractStatus } = useDashboard(
    useCallback(
      ({ changeContractStatus }) => ({
        changeContractStatus
      }),
      []
    ),
    shallow
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();

      const liquidationFile = params.liquidationFile.map(f => {
        if (f.response) {
          return {
            fileName: f.response.fileName,
            fileLink: f.response.preview
          };
        }
        return f;
      });

      await changeContractStatus(
        contractId,
        CONTRACT_STATUS_VALUE.LIQUIDATED,
        liquidationFile
      );

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        title="Thanh lý hợp đồng"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <LiquidateFormModalContent form={form} contract={contract} />
      </Modal>
    </>
  );
};

export default LiquidateFormModal;
