import React from 'react';
import { InputNumber } from 'antd';

const CurrencyInputNumber = React.forwardRef(
  ({ children, type, className, style, ...otherProps }, ref) => {
    return (
      <InputNumber
        ref={ref}
        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/\$\s?|(,*)/g, '')}
        style={{ width: '100%', ...style }}
        className={`navi-button ${className}`}
        min={0}
        {...otherProps}
      />
    );
  }
);

export default CurrencyInputNumber;
