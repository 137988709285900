import React, { useCallback, useState } from 'react';

import shallow from 'zustand/shallow';

import Select from 'components/Select';

import { useAddendumDetail } from 'stores/useAddendumDetail';
import { debounce } from 'utils/lodash';
import { DEBOUNCE_VALUE } from 'appConstants';

import { useParams } from 'react-router-dom';
import { Empty, notification, Spin, Typography } from 'antd';
import { extractPartnersFromSong } from 'utils/other';

const otherInitValue = {
  relatedRight: false,
  copyRight: false,
  audio: false,
  video: false
};

const getOtherOtherInitValue = arr => {
  const res = {};

  if (!arr) return res;

  arr.forEach(({ businessType: { code } }) => {
    res[code] = null;
  });

  return res;
};

const AddendumSongAutoComplete = ({ form, ...props }) => {
  const [options, setOptions] = useState([]);
  const { id } = useParams();
  const [fetching, setFetching] = useState(false);

  const {
    searchForAddendumSong,
    pushTempSongAddendum,
    songAddendum,
    tempSongAddendum,
    contractUseInAddendumDetail,
    disableEditAndAddAddendumSong
  } = useAddendumDetail(
    useCallback(
      ({
        searchForAddendumSong,
        pushTempSongAddendum,
        songAddendum,
        tempSongAddendum,
        contractUseInAddendumDetail,
        disableEditAndAddAddendumSong
      }) => ({
        searchForAddendumSong,
        pushTempSongAddendum,
        songAddendum,
        tempSongAddendum,
        contractUseInAddendumDetail,
        disableEditAndAddAddendumSong
      }),
      []
    ),
    shallow
  );

  const onSearch = async searchText => {
    setFetching(true);
    if (!searchText) {
      setFetching(false);
      return;
    }

    const results = await searchForAddendumSong(searchText);
    setFetching(false);

    if (!results) {
      return;
    }

    return setOptions(
      results.map(
        ({ id, songName, songType, partnerSong, doiSoatNamVietCode }) => {
          const [musicians, singers] = extractPartnersFromSong(
            partnerSong,
            true
          );

          const musician = musicians.map(({ label }) => label).join(', ');
          const singer = singers.map(({ label }) => label).join(', ');

          return {
            label: songName + ' - ' + singer,
            songName,
            value: id,
            singer,
            musician,
            songType,
            partnerSong,
            doiSoatNamVietCode
          };
        }
      )
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const onSelect = async e => {
    const res = options.find(({ value }) => value === e);

    const found = [...songAddendum, ...tempSongAddendum].find(
      ({ songId }) => res.value === songId
    );

    if (!found) {
      const obj = {
        singerName: res?.singer,
        musicianName: res?.musician,
        songType: res?.songType,
        songName: res.songName,
        songId: res.value,
        partnerSong: res?.partnerSong,
        doiSoatNamVietCode: res?.doiSoatNamVietCode,
        appendixId: id,
        index: tempSongAddendum.length,
        send: true,
        ...otherInitValue,
        ...getOtherOtherInitValue(contractUseInAddendumDetail?.revenueSharing)
      };

      return pushTempSongAddendum(obj);
    }

    return notification.error({ message: 'Bài hát đã tồn tại!' });
  };

  return (
    <>
      <Typography.Text strong>Thêm bài hát:</Typography.Text>{' '}
      <Select
        disabled={disableEditAndAddAddendumSong}
        value={null}
        showArrow={false}
        filterOption={false}
        notFoundContent={
          fetching ? (
            <Spin size="small" />
          ) : (
            <Empty description="Nhập để tìm kiếm gợi ý !" />
          )
        }
        showSearch
        onSearch={debouncedOnSearch}
        options={options}
        placeHolder="Tìm bài hát"
        onSelect={onSelect}
        style={{ width: 500, marginLeft: 10 }}
        {...props}
      />
    </>
  );
};

export default AddendumSongAutoComplete;
