import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { Row, Col, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import ManagerFormModal from './ManagerFormModal';
import { usePartnerDetail } from 'stores/usePartnerDetail';
import { get } from 'utils/lodash';

import { formatDate } from 'utils/date';
import { GENDER_DICTIONARY } from 'appConstants';

const { Text } = Typography;

const ManagerDetail = () => {
  const { selectingManager } = usePartnerDetail(
    useCallback(
      ({ selectingManager }) => ({
        selectingManager
      }),
      []
    ),
    shallow
  );

  const fullName = get(selectingManager, 'fullName', null);
  const gender = get(selectingManager, 'gender', null);
  const phone = get(selectingManager, 'phone', null);
  const email = get(selectingManager, 'email', null);
  const dateOfBirth = get(selectingManager, 'dateOfBirth', null);
  const taxCode = get(selectingManager, 'taxCode', null);
  const identityNumber = get(selectingManager, 'identityNumber', null);
  const dateOfIdentification = get(
    selectingManager,
    'dateOfIdentification',
    null
  );
  const placeOfIdentification = get(
    selectingManager,
    'placeOfIdentification',
    null
  );
  const address = get(selectingManager, 'address', null);

  if (selectingManager) {
    return (
      <Row className="deputy-info-detail-wrapper">
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Space>
                <Text strong>Họ tên:</Text>
                <ManagerFormModal defaultValue={selectingManager}>
                  <Typography.Text strong style={{ cursor: 'pointer' }}>
                    {fullName} <EditOutlined />
                  </Typography.Text>
                </ManagerFormModal>
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Giới tính:</Text>
                {GENDER_DICTIONARY[gender]}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Số điện thoại:</Text>
                {phone}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Email:</Text>
                {email}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Ngày sinh:</Text>
                {formatDate(dateOfBirth)}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Mã số thuế:</Text>
                {taxCode}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>CMND /CCDD:</Text>
                {identityNumber}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Ngày cấp:</Text>
                {formatDate(dateOfIdentification)}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Nơi cấp:</Text>
                {placeOfIdentification}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Địa chỉ:</Text>
                {address}
              </Space>
            </Col>
          </Row>
        </Col>
        {/* <Col span={4}>
          <Space direction="vertical" align="center">
            <Avatar
              size={96}
              src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSusoQHrrN1QD_DMEYP5yYn1bBI-oj7rnnA7WeK7429w1NyCeYV"
              icon={<UserOutlined />}
            />
            <Typography.Text strong>{fullName}</Typography.Text>
          </Space>
        </Col> */}
      </Row>
    );
  }

  return <></>;
};

export default ManagerDetail;
