import React from 'react';

import { Row, Col, Typography, Space } from 'antd';

import { formatDate } from 'utils/date';
import UserAvatar from 'components/UserAvatar';
import { GENDER_DICTIONARY } from 'appConstants';

const removeMarginBottom = {
  marginBottom: '0px'
};

const RightAddendumDetailInfo = ({ contract }) => {
  const partner = contract?.partner;

  return (
    <Row>
      <Col span={24}>
        <Row justify="center" className="mb-md">
          <Space align="center" direction="vertical">
            <UserAvatar
              fullName={partner?.artistName}
              avatar={partner?.avatar}
              size={96}
            />
            <Typography.Paragraph strong>
              {partner?.artistName}
            </Typography.Paragraph>
          </Space>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={6}>
            <Typography.Paragraph strong>Họ tên:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>{partner?.fullName}</Typography.Paragraph>
          </Col>

          <Col span={6}>
            <Typography.Paragraph strong>Giới tính:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>
              {GENDER_DICTIONARY[partner?.gender]}
            </Typography.Paragraph>
          </Col>

          <Col span={6}>
            <Typography.Paragraph strong>Ngày sinh:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>
              {partner?.dateOfBirth && formatDate(partner.dateOfBirth)}
            </Typography.Paragraph>
          </Col>

          <Col span={6}>
            <Typography.Paragraph strong>Số điện thoại:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>{partner?.phone}</Typography.Paragraph>
          </Col>
          <Col span={6}>
            <Typography.Paragraph strong>Email:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>{partner?.email}</Typography.Paragraph>
          </Col>
          <Col span={6}>
            <Typography.Paragraph strong>CMND/CCCD:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>
              {partner?.identityNumber}
            </Typography.Paragraph>
          </Col>
          <Col span={6}>
            <Typography.Paragraph strong>Ngày cấp:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>
              {partner?.dateOfIdentification &&
                formatDate(partner.dateOfIdentification)}
            </Typography.Paragraph>
          </Col>
          <Col span={6}>
            <Typography.Paragraph strong>Nơi cấp:</Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph>
              {partner?.placeOfIdentification}
            </Typography.Paragraph>
          </Col>
          <Col span={6}>
            <Typography.Paragraph style={removeMarginBottom} strong>
              Địa chỉ:
            </Typography.Paragraph>
          </Col>

          <Col span={18}>
            <Typography.Paragraph style={removeMarginBottom}>
              {partner?.address}
            </Typography.Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RightAddendumDetailInfo;
