import moment from 'moment';
import 'moment/locale/vi';

import { Popover, Space, Typography } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { ROLE_CHECK } from 'appConstants';
import { Medley } from 'components/Icons';
import UpdateButton from 'components/UpdateButton';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSong } from 'stores/useSong';
import { extractPartnersFromSong } from 'utils/other';
import shallow from 'zustand/shallow';

const ContentAction = ({ songId, children }) => {
  const [visible, setVisible] = useState(false);
  const { song } = useSong(
    useCallback(
      ({ song }) => ({
        song
      }),
      []
    ),
    shallow
  );
  const Content = () => {
    return (
      <div>
        <Space direction="vertical">
          <Link to={`/danh-sach-bai-hat?SongIdInMedley=${songId}`}>
            Liên khúc
          </Link>
          <Link to={`/danh-sach-tuyen-tap?SongId=${songId}`}>Tuyển tập</Link>
          <Link to={`/link-phat-hanh?SongId%5Beq%5D=${songId}`}>
            Link phát hành
          </Link>
        </Space>
      </div>
    );
  };

  return (
    <Popover
      title={() => (
        <>
          <SearchOutlined /> Tìm bài hát này thuộc
        </>
      )}
      placement="bottom"
      content={<Content />}
      trigger="hover"
      visible={visible}
      onVisibleChange={visible =>
        song ? setVisible(false) : setVisible(visible)
      }
    >
      {React.cloneElement(children, {
        onClick: () => setVisible(false)
      })}
    </Popover>
  );
};

export const columns = [
  {
    title: 'Tên bài hát',
    dataIndex: 'songName',
    fixed: 'left',
    render: (songName, { id, namVietCode, isMedley }) => (
      <Space direction="vertical">
        {namVietCode && <Typography.Text>#{namVietCode}</Typography.Text>}
        <ContentAction songId={id}>
          <Space size={0}>
            {isMedley && <Medley style={{ fontSize: 24, color: '#1DD05D' }} />}
            <UpdateButton name={songName} id={id} from={ROLE_CHECK.SONG} />
          </Space>
        </ContentAction>
      </Space>
    )
  },
  {
    title: 'Người trình bày',
    dataIndex: 'singer',
    render: (__, { partnerSong }) => {
      // eslint-disable-next-line no-unused-vars
      const [_, singers] = extractPartnersFromSong(partnerSong, true);

      return (
        <Space direction="vertical">
          {singers.map(({ label, value }) => (
            <Link to={`/chi-tiet-doi-tac/${value}`}>
              <Typography.Text strong key={value}>
                {label}
              </Typography.Text>
            </Link>
          ))}
        </Space>
      );
    }
  },
  {
    title: 'Tác giả',
    dataIndex: 'musician',
    render: (__, { partnerSong }) => {
      // eslint-disable-next-line no-unused-vars
      const [musicians] = extractPartnersFromSong(partnerSong, true);
      return (
        <Space direction="vertical">
          {musicians.map(({ label, value }) => (
            <Link to={`/chi-tiet-doi-tac/${value}`}>
              <Typography.Text strong key={value}>
                {label}
              </Typography.Text>
            </Link>
          ))}
        </Space>
      );
    }
  },
  {
    title: 'Thể loại',
    dataIndex: 'songTypeName',
    render: (songTypeName, { songTypeDetailName }) => (
      <Space direction="vertical">
        <Typography.Text>{songTypeName}</Typography.Text>
        {songTypeDetailName && (
          <Typography.Text type="secondary">
            {songTypeDetailName}
          </Typography.Text>
        )}
      </Space>
    )
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(createDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
      </Space>
    ),
    defaultHidden: true
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    render: updateDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(updateDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(updateDate).fromNow()}</Typography.Text>
      </Space>
    )
    // defaultHidden: true
  }
];
