import React from 'react';
import { Button, Form, Input, Popover, Row } from 'antd';
import { useReviewDetail } from 'stores/useReviewDetail';
import { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { useCurrentUser } from 'stores/useCurrentUser';
import { useMemo } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const ApproveButton = () => {
  const [form] = Form.useForm();
  const { approveAppendixReview, appendixReview } = useReviewDetail(
    useCallback(
      ({ approveAppendixReview, appendixReview }) => ({
        approveAppendixReview,
        appendixReview
      }),
      []
    ),
    shallow
  );

  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  const hasApproved = useMemo(() => {
    if (appendixReview) {
      return !!appendixReview.confirmedAppendix?.find(
        item => item.userId === currentUser.id && item.status === 'approved'
      );
    }
  }, [appendixReview, currentUser]);

  const onFinish = () => {
    approveAppendixReview({
      id: appendixReview.id,
      status: hasApproved ? 'denied' : 'approved',
      note: form.getFieldValue('note')
    });
    form.resetFields();
  };

  return (
    appendixReview && (
      <Popover
        title={hasApproved ? 'Từ chối duyệt' : 'Duyệt'}
        content={
          <Form form={form} onFinish={onFinish}>
            <Form.Item name="note">
              <Input.TextArea placeholder="Ghi chú" />
            </Form.Item>
            <Row justify="end">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Đồng ý
                </Button>
              </Form.Item>
            </Row>
          </Form>
        }
      >
        <Button
          type="primary"
          danger={hasApproved}
          // onClick={onApprove}
          icon={hasApproved ? <CloseOutlined /> : <CheckOutlined />}
        >
          {hasApproved ? 'Từ chối duyệt' : 'Duyệt'}
        </Button>
      </Popover>
    )
  );
};

export default ApproveButton;
