import React, { useCallback, useMemo, useState } from 'react';

import { Modal, Form, Popconfirm, Button, Space } from 'antd';
import { useSong } from 'stores/useSong';
import shallow from 'zustand/shallow';
import SongFormModalContent from './SongFormModalContent';

const SongFormModal = ({ children, songId }) => {
  const [isMedley, setIsMedley] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { createSong, updateSong, songOfMedley, getArtistsFromMedley } =
    useSong(
      useCallback(
        ({ createSong, updateSong, songOfMedley, getArtistsFromMedley }) => ({
          createSong,
          updateSong,
          songOfMedley,
          getArtistsFromMedley
        }),
        []
      ),
      shallow
    );

  const isUpdateSong = useMemo(() => !!songId, [songId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onUpdateArtist = async () => {
    const songIds = songOfMedley.map(({ songId }) => songId);
    const artists = await getArtistsFromMedley(songIds);

    const { singers, musicians } = artists;
    form.setFieldsValue({
      singerIds: singers.map(singer => singer.id),
      musicianIds: musicians.map(musician => musician.id)
    });
  };

  const handleOk = async (_, isSyncArtist = false) => {
    try {
      isSyncArtist && (await onUpdateArtist());

      const params = await form.validateFields();
      const childSongIds = songOfMedley.map(({ songId }) => songId);
      if (isUpdateSong) {
        await updateSong({
          id: songId,
          ...params,
          songTypeDetail: params?.songTypeDetail || '',
          childSongIds
        });
      } else {
        await createSong({ ...params, childSongIds });
      }

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeIsMedley = useCallback(value => {
    setIsMedley(value);
  }, []);

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}
      <Modal
        title={isUpdateSong ? 'Chỉnh sửa bài hát' : 'Tạo mới bài hát'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
        width={800}
        destroyOnClose
        footer={
          <Space style={{ padding: 8 }}>
            <Button onClick={handleCancel}>Huỷ</Button>
            {isMedley ? (
              <Popconfirm
                title="Bạn có muôn cập nhật người trình bày, sáng tác từ DS bài hát ?"
                okText="Có"
                cancelText="Không"
                placement="topRight"
                onConfirm={() => handleOk({}, true)}
                onCancel={handleOk}
              >
                <Button type="primary">Lưu</Button>
              </Popconfirm>
            ) : (
              <Button type="primary" onClick={handleOk}>
                Lưu
              </Button>
            )}
          </Space>
        }
      >
        <SongFormModalContent
          form={form}
          songId={songId}
          isUpdateSong={isUpdateSong}
          onUpdateArtist={onUpdateArtist}
          onChangeIsMedley={onChangeIsMedley}
        />
      </Modal>
    </>
  );
};

export default SongFormModal;
