import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import { useSong } from 'stores/useSong';

import AutoComplete from 'components/AutoComplete';

const PartnerAutoComplete = ({ partner, ...props }) => {
  const { searchForPartner } = useSong(
    useCallback(
      ({ searchForPartner }) => ({
        searchForPartner
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () => (partner ? { value: partner.id, label: partner.artistName } : null),
    [partner]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm đối tác"
      handleSearch={searchForPartner}
      valueKey="id"
      style={{ width: '100%' }}
      getLabel={partner => partner?.artistName || partner?.fullName}
      {...props}
    />
  );
};

export default PartnerAutoComplete;
