import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';

import { Col, Form, Row, Typography } from 'antd';

import BankingInfo from './components/BankingInfo';
import PartnerContracts from './PartnerContracts';
import PartnerInfo from './PartnerInfo';

import { usePartnerDetail } from 'stores/usePartnerDetail';
import { get } from 'utils/lodash';

const PartnerDetailContext = React.createContext(null);

const PartnerDetail = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { getPartner, partner } = usePartnerDetail(
    useCallback(
      ({ getPartner, partner }) => ({
        getPartner,
        partner
      }),
      []
    ),
    shallow
  );

  const isUpdatePartner = useMemo(() => !!id, [id]);

  useEffect(() => {
    if (partner && isUpdatePartner) {
      const {
        fullName,
        avatar,
        identityPhoto,
        email,
        identityNumber,
        artistName,
        dateOfBirth,
        dateOfIdentification,
        gender,
        phone,
        placeOfIdentification,
        taxCode,
        address,
        representationType,
        representationId,
        masterdataId,
        singerRank
      } = partner;

      const delegacyFile = get(partner, 'delegacyFile', []);

      form.setFieldsValue({
        fullName,
        avatar: avatar ? [{ name: avatar, url: avatar }] : [],
        identityPhoto: identityPhoto.map(({ imageName, imageLink }) => ({
          name: imageName,
          url: imageLink
        })),
        email,
        identityNumber,
        artistName,
        dateOfBirth: dateOfBirth && moment(dateOfBirth),
        dateOfIdentification:
          dateOfIdentification && moment(dateOfIdentification),
        gender,
        phone,
        placeOfIdentification,
        taxCode,
        address,
        representationType,
        delegacyFile: delegacyFile.map(({ fileName, fileLink }) => ({
          name: fileName,
          url: fileLink
        })),
        companyId: representationId,
        managerId: representationId,
        masterdataId,
        singerRank:
          (singerRank || typeof singerRank === 'number') &&
          singerRank.toString()
      });
    }
    return () => {
      form.resetFields();
    };
  }, [partner, form, isUpdatePartner]);

  useEffect(() => {
    if (isUpdatePartner) {
      getPartner(id);
    }
  }, [id, getPartner, isUpdatePartner]);

  return (
    <Form form={form} layout="vertical">
      <PartnerDetailContext.Provider value={form}>
        <Row justify="space-between" align="middle" className="mb-md">
          <Col>
            <Typography.Title>
              {isUpdatePartner ? 'Chi tiết đối tác' : 'Thêm đối tác mới'}
            </Typography.Title>
          </Col>
        </Row>

        <PartnerInfo isUpdatePartner={isUpdatePartner} form={form} />

        {isUpdatePartner && (
          <>
            <BankingInfo isUpdatePartner={isUpdatePartner} />
            <PartnerContracts
              partnerId={id}
              partnerName={partner?.artistName || partner?.fullName}
            />
          </>
        )}
      </PartnerDetailContext.Provider>
    </Form>
  );
};

export { PartnerDetailContext };
export default PartnerDetail;
