import API from 'api';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import create from 'zustand';
import { notification } from 'antd';
import { generateFilterParams } from 'utils/other';

export const usePartnerContracts = create((set, get) => ({
  contracts: [],
  totalPage: 1,
  total: 0,
  page: 1,
  setPage: page => set({ page }),
  contract: null,
  appendixContract: null,
  contractHistory: [],
  songs: [],
  //NOTE: Filter
  filter: {
    ContractCode: {},
    FinishDate: {},
    AutoRenew: {},
    ContractType: {},
    StartDate: {}
  },
  setFilter: (key, value) =>
    set({ filter: { ...get().filter, [key]: value }, page: 1 }),

  getContracts: async (params, partnerId) => {
    try {
      const { ContractCode, FinishDate, AutoRenew, ContractType, StartDate } =
        get().filter;
      const {
        data: { data, total }
      } = await API.get('/contract', {
        params: {
          Page: get().page - 1,
          PageSize: PAGE_SIZE,
          ...generateFilterParams([
            {
              name: 'ContractCode',
              operator: ContractCode.operator,
              value: ContractCode.value
            },
            {
              name: 'AutoRenew',
              operator: AutoRenew.operator,
              value: AutoRenew.value
            },
            {
              name: 'ContractType',
              operator: ContractType.operator,
              value: ContractType.value
            },
            {
              name: 'StartDate',
              operatorFrom: StartDate.operatorFrom,
              operatorTo: StartDate.operatorTo,
              value: StartDate.value
            },
            {
              name: 'FinishDate',
              operatorFrom: FinishDate.operatorFrom,
              operatorTo: FinishDate.operatorTo,
              value: FinishDate.value
            }
          ]),
          'partnerId[eq]': partnerId,
          ...params
        }
      });

      if (data) {
        const activeContract = data
          .filter(contract => !contract.isDelete)
          .map(contract => {
            return {
              ...contract,
              appendix: contract.appendix.filter(appendix => !appendix.isDelete)
            };
          });
        set({
          contracts: activeContract,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
      }
    } catch (data) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  deleteContracts: async (params, partnerId) => {
    try {
      const { data } = await API.delete('/contract', { data: params });

      if (data) {
        get().getContracts({}, partnerId);

        return notification.success({
          message: 'Xoá hợp đồng thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
