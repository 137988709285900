import {
  ImportOutlined,
  InfoCircleOutlined,
  MergeCellsOutlined,
  RetweetOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Card, Col, Popover, Row, Space, Table, Tag, Typography } from 'antd';
import { CONTRACT_FOR_ORIGIN_SONG, EDITABLE_CELL_TYPE } from 'appConstants';
import Button from 'components/Button';
import Select from 'components/Select';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';
import { columns, medleyColumns } from './columns';
import AddendumSongAutoComplete from './components/AddendumSongAutoComplete';
import EditableCell from './components/EditableCell';
import EditableRow from './components/EditableRow';
import ImportSong from './components/ImportSong';
import SongAddendumDeleteMultiButton from './components/SongAddendumDeleteMultiButton';
import SortSong from './components/SortSong';

const SecondAddendum = () => {
  const { id } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const {
    addendum,
    songAddendum,
    tempSongAddendum,
    processAllSongAddendum,
    disableEditAndAddAddendumSong,
    findAndUpdateSongAddendum,
    findAndUpdateTempSongAddendum,
    contractUseInAddendumDetail,
    diasbleSortSong,
    mapAllRevenueSharingType,
    isSongAppendixLoading
  } = useAddendumDetail(
    useCallback(
      ({
        addendum,
        songAddendum,
        tempSongAddendum,
        processAllSongAddendum,
        disableEditAndAddAddendumSong,
        findAndUpdateSongAddendum,
        findAndUpdateTempSongAddendum,
        contractUseInAddendumDetail,
        diasbleSortSong,
        mapAllRevenueSharingType,
        isSongAppendixLoading
      }) => ({
        addendum,
        songAddendum,
        tempSongAddendum,
        processAllSongAddendum,
        disableEditAndAddAddendumSong,
        findAndUpdateSongAddendum,
        findAndUpdateTempSongAddendum,
        contractUseInAddendumDetail,
        diasbleSortSong,
        mapAllRevenueSharingType,
        isSongAppendixLoading
      }),
      []
    ),
    shallow
  );

  const revenueSharingCols = useMemo(
    () =>
      (contractUseInAddendumDetail?.revenueSharing || []).map(
        ({ businessType: { code, name } }) => ({
          title: (
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Typography.Text>{name}</Typography.Text>
              <Popover
                content={
                  <Select
                    style={{ width: '100%' }}
                    options={[
                      { value: null, label: 'Không có' },
                      { value: false, label: 'KĐQ' },
                      { value: true, label: 'ĐQ' }
                    ]}
                    onChange={value => mapAllRevenueSharingType(value, code)}
                  />
                }
                title="Chuyển tất cả trạng thái"
                trigger="click"
              >
                <MergeCellsOutlined />
              </Popover>
            </Space>
          ),
          dataIndex: code,
          editable: EDITABLE_CELL_TYPE.SELECT,
          width: '150px'
        })
      ),
    [contractUseInAddendumDetail?.revenueSharing, mapAllRevenueSharingType]
  );

  const handleSave = row => {
    if (row.id) {
      findAndUpdateSongAddendum({
        ...row,
        appendixId: id,
        songId: row.songId,
        index: tempSongAddendum.length,
        send: true
      });
    } else {
      findAndUpdateTempSongAddendum({
        ...row,
        appendixId: id,
        index: tempSongAddendum.length,
        send: true
      });
    }
  };

  const anotherColumns = [
    ...columns.slice(0, columns.length - 1),
    ...revenueSharingCols,
    ...columns.slice(columns.length - 1)
  ]
    // Check contract is HAPK
    .filter(column => {
      return CONTRACT_FOR_ORIGIN_SONG?.includes(
        contractUseInAddendumDetail?.contractType
      )
        ? true
        : column.dataIndex !== 'relatedRightSongId';
    })
    .map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => {
          return {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            subKey: col.subKey,
            handleSave: handleSave,
            ...(col.editable === EDITABLE_CELL_TYPE.SELECT
              ? {
                  options: [
                    { value: null, label: 'Không có' },
                    { value: false, label: 'KĐQ' },
                    { value: true, label: 'ĐQ' }
                  ]
                }
              : {})
          };
        }
      };
    });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  return (
    <Card title={addendum?.index02 !== '' && addendum?.appendixCode02}>
      <Row justify="space-between">
        <Typography.Paragraph>
          <Typography.Text strong>Số lượng sản phẩm: </Typography.Text>

          <Typography.Text>{songAddendum.length} sản phẩm</Typography.Text>
        </Typography.Paragraph>
      </Row>

      <Row justify="space-between">
        <Col>
          <AddendumSongAutoComplete />
        </Col>
        <Col>
          <Space>
            {!disableEditAndAddAddendumSong && (
              <SongAddendumDeleteMultiButton
                ids={selectedRowKeys}
                callback={() => setSelectedRowKeys([])}
              />
            )}
            <Space>
              <SortSong>
                <Button
                  type="default"
                  icon={<RetweetOutlined />}
                  disabled={diasbleSortSong}
                  // style={{ background: 'blue', border: 'none' }}
                >
                  Sắp xếp
                </Button>
              </SortSong>
              <ImportSong>
                <Button
                  icon={<ImportOutlined />}
                  style={{ background: 'green', border: 'none' }}
                >
                  Import
                </Button>
              </ImportSong>
            </Space>
          </Space>
        </Col>
      </Row>

      <Table
        scroll={{ x: 1500 }}
        components={components}
        columns={anotherColumns}
        rowClassName={() => 'editable-row'}
        loading={isSongAppendixLoading}
        dataSource={[...songAddendum, ...tempSongAddendum].map(
          (song, index) => ({
            ...song,
            key: song.id || song.songId,
            index
          })
        )}
        style={{ margin: '16px 0px' }}
        paginationProps={false}
        pagination={{ onChange: () => setSelectedRowKeys([]) }}
        {...(!disableEditAndAddAddendumSong
          ? { rowSelection: rowSelection }
          : null)}
        expandable={{
          expandedRowRender: record => (
            <Table
              columns={medleyColumns}
              dataSource={record?.songMedley}
              paginationProps={false}
            />
          ),
          rowExpandable: record => !!record?.songMedley?.length
        }}
      />

      <Row justify="end">
        <Space>
          <Tag icon={<InfoCircleOutlined />} color="warning">
            Dữ liệu sẽ mất nếu như không nhấn lưu.
          </Tag>

          <Button
            disabled={disableEditAndAddAddendumSong}
            onClick={processAllSongAddendum}
            icon={<SaveOutlined />}
          >
            Lưu
          </Button>
        </Space>
      </Row>
    </Card>
  );
};

export default SecondAddendum;
