import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import Button from 'components/Button';
import AddendumStatusTag from 'pages/AddendumDetail/components/AddendumStatusTag';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useProjectAppendix } from 'stores/useProjectAppendix';
import { getAppendixCode } from 'utils/other';
import shallow from 'zustand/shallow';

const DeleteButton = ({ id, isProject }) => {
  const { deleteProjectAppendix } = useProjectAppendix(
    useCallback(
      ({ deleteProjectAppendix }) => ({
        deleteProjectAppendix
      }),
      []
    ),
    shallow
  );
  return (
    <Button
      danger
      icon={<DeleteOutlined />}
      onClick={() => deleteProjectAppendix(id)}
      disabled={isProject}
    ></Button>
  );
};

export const columns = [
  {
    title: 'Mã phụ lục',
    dataIndex: 'appendix',
    render: (_, appendix) => (
      <Link to={`/chi-tiet-phu-luc/${appendix?.id}`}>
        <Typography.Text strong>{getAppendixCode(appendix)}</Typography.Text>
      </Link>
    )
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: status => <AddendumStatusTag value={status} />
  },
  {
    title: 'Link phụ lục',
    dataIndex: 'file',
    render: (file, { evidenceFile }) => (
      <Space direction="vertical">
        {file?.length > 0 ? (
          <Space direction="vertical">
            {file?.map(f => (
              <a href={f?.fileLink} target="_blank" rel="noreferrer">
                <DownloadOutlined />
                {f?.fileName}
              </a>
            ))}
          </Space>
        ) : null}
        {evidenceFile?.length > 0 ? (
          <Space direction="vertical">
            {evidenceFile?.map(f => (
              <a
                href={f?.fileLink}
                target="_blank"
                rel="noreferrer"
                style={{ color: 'green' }}
              >
                <DownloadOutlined />
                {f?.fileName}
              </a>
            ))}
          </Space>
        ) : null}
      </Space>
    )
  },
  {
    render: (_, { id, isProject }) => (
      <DeleteButton id={id} isProject={isProject} />
    )
  }
];
