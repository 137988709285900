import { Col, DatePicker, Form, Input, Row, Select, Spin } from 'antd';
import { DEBOUNCE_VALUE, ONLINE_STATUS } from 'appConstants';
import CurrencyInputNumber from 'components/CurrencyInputNumber';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useService } from 'stores/useService';
import { useSongPublication } from 'stores/useSongPublication';
import { debounce } from 'utils/lodash';
import shallow from 'zustand/shallow';
import MediaTypeTag from './MediaTypeTag';
import PublicationRevenueSharing from './PublicationRevenueSharing';
import PublisherSelect from './PublisherSelect';
import SongPublicationAutoComplete from './SongPublicationAutoComplete';

const PublicationDetailForm = ({ form, isUpdatePublication }) => {
  const [currentProvider, setCurrentProvider] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const { getLinkInfoByUrl, isLinkInfoLoading } = useService(
    useCallback(
      ({ getLinkInfoByUrl, isLinkInfoLoading }) => ({
        getLinkInfoByUrl,
        isLinkInfoLoading
      }),
      []
    ),
    shallow
  );

  const { isProviderFacebook, songPublication } = useSongPublication(
    useCallback(
      ({ isProviderFacebook, songPublication }) => ({
        isProviderFacebook,
        songPublication
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (songPublication?.mediaType) {
      setMediaType(songPublication?.mediaType);
    }
  }, [songPublication?.mediaType]);

  const debouncedOnChangeLink = debounce(async event => {
    const linkInfo = await getLinkInfoByUrl(event.target.value);
    if (linkInfo) {
      form.setFieldsValue({
        title: linkInfo?.title,
        code: linkInfo?.id,
        onlineStatus: linkInfo?.status,
        providerId: linkInfo?.providerId,
        publisherId: linkInfo?.publisherId,
        publishedDate: linkInfo?.publishedDate
          ? moment(linkInfo?.publishedDate)
          : null
      });
      setCurrentProvider(linkInfo?.providerId);
    }
  }, DEBOUNCE_VALUE);

  return (
    <Spin spinning={isLinkInfoLoading}>
      <Row gutter={16} align="middle">
        <Col span={12}>
          <Form.Item label="Tiêu đề" name="title">
            <Input placeholder="Tiêu đề" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Link phát hành"
            name="link"
            rules={[{ required: true, message: 'Vui lòng link phát hành!' }]}
          >
            <Input
              onChange={debouncedOnChangeLink}
              placeholder="Link bài hát phát hành"
              suffix={<MediaTypeTag mediaType={mediaType} />}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Row>
            <PublisherSelect form={form} currentProvider={currentProvider} />
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Mã code"
            name="code"
            rules={[{ required: true, message: 'Vui lòng nhập mã code!' }]}
          >
            <Input placeholder="Mã code" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Trạng thái"
            name="onlineStatus"
            rules={[{ required: true, message: 'Vui lòng chọn trạng thái!' }]}
          >
            <Select options={ONLINE_STATUS} placeholder="Chọn trạng thái" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="startDate" label="Ngày bắt đầu hiệu lực">
            <DatePicker
              style={{ width: '100%' }}
              allowClear={false}
              placeholder="Ngày bắt đầu hiệu lực"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="finishDate" label="Ngày kết thúc hiệu lực">
            <DatePicker
              style={{ width: '100%' }}
              allowClear={false}
              placeholder="Ngày kết thúc hiệu lực"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="publishedDate"
            label="Ngày phát hành"
            rules={[{ required: true, message: 'Chọn ngày phát hành!' }]}
          >
            <DatePicker style={{ width: '100%' }} allowClear={false} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="songId"
            label="Bài hát"
            rules={[{ required: true, message: 'Vui lòng chọn bài hát!' }]}
          >
            <SongPublicationAutoComplete />
          </Form.Item>
        </Col>
        {isProviderFacebook && (
          <>
            <Col span={4}>
              <Form.Item
                name="views"
                label="Lượt views"
                rules={[{ required: true, message: 'Nhập lượt xem!' }]}
              >
                <CurrencyInputNumber placeholder="Lượt xem" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="likes"
                label="Lượt likes"
                rules={[{ required: true, message: 'Nhập lượt reaction!' }]}
              >
                <CurrencyInputNumber placeholder="Lượt reaction" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="reaches"
                label="Lượt tiếp cận"
                rules={[{ required: true, message: 'Nhập lượt tiếp cận!' }]}
              >
                <CurrencyInputNumber placeholder="Lượt tiếp cận" />
              </Form.Item>
            </Col>
          </>
        )}
        {isUpdatePublication && (
          <Col span={24}>
            <PublicationRevenueSharing form={form} />
          </Col>
        )}
      </Row>
    </Spin>
  );
};

export default PublicationDetailForm;
