import React, { useCallback, useEffect } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import DashboardTable from '../DashboardTable';
import { columns } from './columns';

const WaitingPlaylist = () => {
  const { waitingPlaylists, getWaitingPlaylists } = useDashboard(
    useCallback(
      ({ waitingPlaylists, getWaitingPlaylists }) => ({
        waitingPlaylists,
        getWaitingPlaylists
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getWaitingPlaylists();
  }, [getWaitingPlaylists]);

  return (
    <DashboardTable
      data={waitingPlaylists?.data?.map(playlist => ({
        ...playlist,
        key: playlist.id
      }))}
      columns={columns}
      title="Tuyển tập đợi duyệt"
      total={waitingPlaylists?.total}
    />
  );
};

export default WaitingPlaylist;
