import { Button } from 'antd';
import React, { useCallback, useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { usePublisherStatistic } from 'stores/usePublisherStatistic';
import shallow from 'zustand/shallow';
import { StatisticEditableContext } from './Statistic';

const AddStatisticButton = () => {
  const form = useContext(StatisticEditableContext);
  const { editingKey, setEditingKey, setTableData, tableData } =
    usePublisherStatistic(
      useCallback(
        ({ editingKey, setEditingKey, setTableData, tableData }) => ({
          editingKey,
          setEditingKey,
          setTableData,
          tableData
        }),
        []
      ),
      shallow
    );
  const onEdit = record => {
    const { year, month } = record;
    const time = moment({ year, month });
    form.setFieldsValue({
      ...record,
      time
    });
    setEditingKey(record.key);
  };
  const handleAddStatistic = () => {
    const _data = { year: moment().year(), month: moment().month(), key: 0 };
    setTableData([_data, ...tableData]);
    onEdit(_data);
  };
  return (
    <Button
      onClick={handleAddStatistic}
      disabled={editingKey !== ''}
      icon={<PlusOutlined />}
      type="primary"
    >
      Thêm thống kê
    </Button>
  );
};

export default AddStatisticButton;
