import React from 'react';
import { Table, Typography } from 'antd';
import { formatPrice } from 'utils/currency';

const TotalAppendix = ({ pageData, align = 'right', doiSoatId }) => {
  let totalAllMoney = 0;
  let totalTakeHomeMoney = 0;
  let totalTaxMoney = 0;
  pageData.forEach(({ totalMoney, taxMoney, takeHomeMoney }) => {
    totalAllMoney += totalMoney;
    totalTakeHomeMoney += takeHomeMoney;
    totalTaxMoney += taxMoney;
  });
  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell align={align}>
          <Typography.Text strong>Tổng</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell align={align}>
          <Typography.Text type="danger">
            {formatPrice(totalAllMoney)}
          </Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
        {!doiSoatId ? (
          <>
            <Table.Summary.Cell align={align}>
              <Typography.Text type="danger">
                {formatPrice(totalTaxMoney)}
              </Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell align={align}>
              <Typography.Text type="danger">
                {formatPrice(totalTakeHomeMoney)}
              </Typography.Text>
            </Table.Summary.Cell>
          </>
        ) : (
          <></>
        )}

        <Table.Summary.Cell></Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
        <Table.Summary.Cell></Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

export default TotalAppendix;
