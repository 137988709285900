import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const NaviButton = React.forwardRef(
  ({ children, type, className, ...otherProps }, ref) => {
    return (
      <Button
        type={type}
        ref={ref}
        {...otherProps}
        className={`navi-button ${className}`}
      >
        {children}
      </Button>
    );
  }
);

NaviButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};

NaviButton.defaultProps = {
  type: 'primary'
};

export default NaviButton;
