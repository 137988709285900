import React, { useCallback } from 'react';

import shallow from 'zustand/shallow';

import { useUser } from 'stores/useUser';

import AutoComplete from 'components/AutoComplete/AutoComplete';

const RoleAutoComplete = props => {
  const { searchForRole } = useUser(
    useCallback(
      ({ searchForRole }) => ({
        searchForRole
      }),
      []
    ),
    shallow
  );

  return (
    <AutoComplete
      placeHolder="Tìm vai trò"
      handleSearch={searchForRole}
      labelKey="name"
      valueKey="id"
      {...props}
    />
  );
};

export default RoleAutoComplete;
