import { Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';
import ImportSongFormModalContent from './ImportSongFormModalContent';

const { Text } = Typography;

const getOtherOtherInitValue = (revenueSharing, songBusiness) => {
  const res = {};
  if (!revenueSharing) return res;

  const parserSongBusiness = songBusiness.map(business => ({
    ...business,
    businessType: revenueSharing.find(
      revenue => revenue.businessTypeId === business.businessTypeId
    )?.businessType
  }));

  parserSongBusiness.forEach(({ businessType: { code }, value }) => {
    res[code] = value;
  });

  return res;
};

const ImportSongFormModal = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();

  const {
    pushTempSongAddendum,
    songAddendum,
    tempSongAddendum,
    contractUseInAddendumDetail
  } = useAddendumDetail(
    useCallback(
      ({
        pushTempSongAddendum,
        songAddendum,
        tempSongAddendum,
        contractUseInAddendumDetail
      }) => ({
        pushTempSongAddendum,
        songAddendum,
        tempSongAddendum,
        contractUseInAddendumDetail
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const mapSongToArr = async (song, index) => {
    const found = [...songAddendum, ...tempSongAddendum].find(
      ({ songId }) => song.songId === songId
    );

    if (!found) {
      const obj = {
        singerName: song?.singer,
        musicianName: song?.musician,
        songType: song?.songType,
        songName: song.songName,
        partnerSong: song.partnerSong,
        songId: song.songId,
        appendixId: id,
        index: tempSongAddendum.length + index,
        send: true,
        relatedRight: song.relatedRight,
        copyRight: song.copyRight,
        audio: song.audio,
        video: song.video,
        // ...otherInitValue,
        ...getOtherOtherInitValue(
          contractUseInAddendumDetail?.revenueSharing,
          song.songBusiness
        )
      };
      return pushTempSongAddendum(obj);
    }
    return notification.error({
      message: (
        <Text>
          Bài hát <Text strong>{song.songName}</Text> đã tồn tại!
        </Text>
      )
    });
  };

  const handleOk = async () => {
    try {
      const file = await form.validateFields(['file']);
      const songs = file?.file.shift().response.data;
      const parserSongs = songs.map(song => ({
        ...song,
        songName: song.song.songName,
        songType: song.song.songType,
        partnerSong: song.song.partnerSong
      }));
      parserSongs.forEach((song, index) => {
        mapSongToArr(song, index);
        return true;
      });
      setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}
      <Modal
        destroyOnClose
        title="Import danh sách bài hát cho phụ lục"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Import"
      >
        <ImportSongFormModalContent form={form} />
      </Modal>
    </>
  );
};

export default ImportSongFormModal;
