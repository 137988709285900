import moment from 'moment';
import React, { useCallback } from 'react';
import { useCurrentUser } from 'stores/useCurrentUser';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { formatDate } from 'utils/date';

const ApproveButton = ({ playlist }) => {
  const { updatePlaylist } = useDashboard(
    useCallback(
      ({ updatePlaylist }) => ({
        updatePlaylist
      }),
      []
    ),
    shallow
  );

  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  const handleApprove = () => {
    const data = {
      ...playlist,
      status: 'approved',
      approvedBy: currentUser.id,
      approvedDate: formatDate(moment()),
      publishDate: playlist?.publishDate && formatDate(playlist?.publishDate)
    };
    updatePlaylist(data);
  };
  return (
    playlist.status === 'waiting' &&
    playlist.totalSong > 0 && (
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={handleApprove}
        style={{ background: '#009c03', border: 'none' }}
      >
        Duyệt
      </Button>
    )
  );
};

export default ApproveButton;
