import { hot } from 'react-hot-loader';

import MainLayout from 'layouts/MainLayout';
import './App.less';

function App() {
  return <MainLayout />;
}

export default hot(module)(App);
