import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { useDepartmentDetail } from 'stores/useDepartmentDetail';

const SubscribeDeleteButton = (value, record) => {
  const code = record?.key?.code;
  const { prevDeleteSubscribes } = useDepartmentDetail(
    useCallback(
      ({ prevDeleteSubscribes }) => ({
        prevDeleteSubscribes
      }),
      []
    ),
    shallow
  );

  const onClick = () => {
    return prevDeleteSubscribes(code);
  };

  return <Button type="danger" icon={<DeleteOutlined />} onClick={onClick} />;
};

export default SubscribeDeleteButton;
