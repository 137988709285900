import { Typography } from 'antd';
import React from 'react';

export const columns = [
  {
    title: 'Lĩnh vực kinh doanh',
    dataIndex: 'businessType',
    render: businessType => (
      <Typography.Text strong>{businessType.name}</Typography.Text>
    ),
    align: ' center'
  },
  {
    title: 'Bên A',
    dataIndex: 'groupA',
    render: groupA => <Typography.Text>{groupA}%</Typography.Text>,
    align: ' center'
  },
  {
    title: 'Bên B',
    align: ' center',
    children: [
      {
        title: 'Quyền liên quan',
        dataIndex: 'groupBRelatedRight',
        render: groupBRelatedRight => (
          <Typography.Text>{groupBRelatedRight}%</Typography.Text>
        ),
        align: ' center'
      },
      {
        title: 'Quyền tác giả',
        dataIndex: 'groupBCopyRight',
        render: groupBCopyRight => (
          <Typography.Text>{groupBCopyRight}%</Typography.Text>
        ),
        align: ' center'
      }
    ]
  }
];
