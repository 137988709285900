import React, { useCallback, useContext } from 'react';
import shallow from 'zustand/shallow';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import Button from 'components/Button';

import { EditableContext } from './PublisherRow';
import { usePublisher } from 'stores/usePublisher';

const PublisherAddButtons = () => {
  const form = useContext(EditableContext);
  const { createPublisher, toggleIsAddingPublisher } = usePublisher(
    useCallback(
      ({ createPublisher, toggleIsAddingPublisher }) => ({
        createPublisher,
        toggleIsAddingPublisher
      }),
      []
    ),
    shallow
  );

  const onAdd = async () => {
    try {
      const params = await form.validateFields();
      createPublisher(params);
    } catch ({ data }) {}
  };

  return (
    <Space>
      <Button type="default" icon={<CheckOutlined />} onClick={onAdd} />

      <Button
        type="default"
        icon={<CloseOutlined />}
        onClick={toggleIsAddingPublisher}
      />
    </Space>
  );
};

export default PublisherAddButtons;
