import {
  CommentOutlined,
  MinusOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import dialogBox from 'assets/images/dialog-box.png';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReviewDetail } from 'stores/useReviewDetail';
import shallow from 'zustand/shallow';

const CommentReview = () => {
  const [visibleMessage, setVisibleMessage] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();

  const { sendModifyDemand } = useReviewDetail(
    useCallback(
      ({ sendModifyDemand }) => ({
        sendModifyDemand
      }),
      []
    ),
    shallow
  );

  const onFinish = async () => {
    const note = form.getFieldValue('note');
    await sendModifyDemand({ id, note });
    form.resetFields();
    setVisibleMessage(false);
  };

  return (
    <div className="navi-message">
      {!visibleMessage && (
        <div className="message-icon">
          <CommentOutlined
            style={{ fontSize: 32, color: '#72b09c' }}
            onClick={() => setVisibleMessage(true)}
          />
        </div>
      )}

      {visibleMessage && (
        <div
          className="message-box"
          style={{ backgroundImage: `url(${dialogBox})` }}
        >
          <MinusOutlined
            className="message-box-minus"
            onClick={() => setVisibleMessage(false)}
          />
          <Form form={form} onFinish={onFinish}>
            <Row align="middle">
              <Col flex="auto">
                <Form.Item
                  name="note"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập nội dung'
                    }
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="Ghi chú" />
                </Form.Item>
              </Col>
              <Col>
                <Tooltip title="Gửi mail">
                  <Button
                    type="link"
                    icon={<SendOutlined />}
                    htmlType="submit"
                  />
                </Tooltip>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

export default CommentReview;
