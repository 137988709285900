import React, { useCallback } from 'react';

import shallow from 'zustand/shallow';

import { get } from 'utils/lodash';

import AutoComplete from 'components/AutoComplete/AutoComplete';
import { usePartnerDetail } from 'stores/usePartnerDetail';

const BankNameAutoComplete = props => {
  const { searchForBankName, partner } = usePartnerDetail(
    useCallback(
      ({ searchForBankName, partner }) => ({
        searchForBankName,
        partner
      }),
      []
    ),
    shallow
  );

  const bankName = get(partner, 'bankAccount.bankName', null);
  const defaultOption = bankName ? { label: bankName, value: bankName } : null;

  return (
    <AutoComplete
      placeHolder="Tìm ngân hàng"
      handleSearch={searchForBankName}
      defaultOption={defaultOption}
      labelKey="title"
      valueKey="title"
      {...props}
    />
  );
};

export default BankNameAutoComplete;
