import PropTypes from 'prop-types';

export const options = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    label: PropTypes.string
  })
);

export const specialOptions = PropTypes.oneOfType([
  options,
  PropTypes.arrayOf(PropTypes.string)
]);
