import { notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import moment from 'moment';
import { generateFilterParams } from 'utils/other';
import { generateFileDownload } from 'utils/redirect';
import create from 'zustand';

export const useContract = create((set, get) => ({
  contracts: [],
  totalPage: 1,
  total: 0,
  contract: null,
  appendixContract: null,
  contractHistory: [],
  songs: [],
  userSelected: null,
  params: {},
  isContractLoading: false,
  isExportLoading: false,
  setParams: params => set({ params }),
  setUserSelected: user => set({ userSelected: user, page: 1 }),
  setContract: contract => set({ contract: contract }),
  resetFilter: () =>
    set({
      page: 1,
      userSelected: null,
      sort: 'Latest',
      filter: {
        ContractCode: {},
        FinishDate: {},
        AutoRenew: {},
        ContractType: {},
        StartDate: {}
      }
    }),

  getContracts: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get('/contract', {
        params: {
          PageSize: PAGE_SIZE,
          ...generateFilterParams([
            {
              ...(get().userSelected && {
                name: 'UserId',
                operator: 'eq',
                value: get().userSelected.id
              })
            }
          ]),
          ...params,
          ...get().params
        }
      });

      if (data) {
        const parserContracts = data.map(contract => ({
          ...contract,
          appendix: contract?.appendix?.sort(
            (a, b) => moment(b.createDate) - moment(a.createDate)
          )
        }));

        set({
          total: total,
          contracts: parserContracts,
          totalPage: Math.ceil(total / PAGE_SIZE)
        });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getExportContracts: async () => {
    try {
      set({ isExportLoading: true });
      const data = await API.get('/excel/export-contract', {
        params: {
          PageSize: PAGE_SIZE,
          ...generateFilterParams([
            {
              ...(get().userSelected && {
                name: 'UserId',
                operator: 'eq',
                value: get().userSelected.id
              })
            }
          ]),
          ...get().params
        }
      });

      if (data?.status === 204) {
        set({ isExportLoading: false });
        return notification.warning({
          message: 'Không có dữ liệu!'
        });
      }

      if (data?.data?.link) {
        const { link } = data?.data;
        generateFileDownload(link);
        set({ isExportLoading: false });
        return data;
      }
      set({ isExportLoading: false });
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getExportContractByIds: async ids => {
    try {
      set({ isExportLoading: true });
      const { data } = await API.post('/excel/export-contract/bulk', {
        ids
      });

      if (data) {
        generateFileDownload(data?.link);
        set({ isExportLoading: false });
        return data;
      }
      set({ isExportLoading: false });
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getExportContract: async contractId => {
    try {
      set({ isExportLoading: true });
      const { data } = await API.get(`/excel/export-contract/${contractId}`);

      if (data) {
        generateFileDownload(data?.link);
        set({ isExportLoading: false });
        return data;
      }
      set({ isExportLoading: false });
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  deleteContracts: async params => {
    try {
      const { data } = await API.delete('/contract', { data: params });

      if (data) {
        get().getContracts();

        return notification.success({
          message: 'Xoá hợp đồng thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getContractHistory: async id => {
    try {
      const { data } = await API.get(`/contract/${id}/history`);
      set({ contractHistory: data?.data });
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getContract: async contractId => {
    set({ isContractLoading: true });
    try {
      const { data } = await API.get(`/contract/${contractId}`);
      set({ isContractLoading: false });
      if (data) {
        set({ contract: data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isContractLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  createContract: async (params, callback = f => f, returnErr = f => f) => {
    try {
      const { data } = await API.post('/contract', params);

      if (data) {
        callback(data.id);
        notification.success({
          message: 'Tạo hợp đồng thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data, status }) {
      if (status === 409) {
        returnErr();
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateContractRevenue: async (contractId, revenueSharing) => {
    try {
      const { data } = await API.put(
        `/contract/${contractId}/revenue-contract`,
        revenueSharing
      );

      if (data) {
        get().getContract(contractId);

        notification.success({
          message: 'Chỉnh sửa đồng thành thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data, status }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateContract: async (params, returnErr = f => f) => {
    try {
      const { contractCode, contractType } = get().contract;
      const { data } = await API.put(`/contract/${params.contractId}`, {
        ...params,
        contractCode,
        contractType
      });
      if (data) {
        get().getContract(params.contractId);

        notification.success({
          message: 'Chỉnh sửa đồng thành thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data, status }) {
      if (status === 409) {
        returnErr();
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateContractNote: async (params, returnErr = f => f) => {
    try {
      const { data } = await API.put(`/contract/${params.contractId}/note`, {
        ...params
      });
      if (data) {
        // get().getContract(params.contractId);

        // notification.success({
        //   message: 'Chỉnh sửa đồng thành thành công!'
        // });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data, status }) {
      if (status === 409) {
        returnErr();
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  changeContractStatus: async (id, status, liquidationFile) => {
    try {
      const { data } = await API.post(`/contract/${id}/change-status`, {
        status,
        liquidationFile
      });

      if (data) {
        set(({ contracts }) => ({
          contracts: contracts.map(contract =>
            contract.id === data.id ? data : contract
          )
        }));
        notification.success({
          message: 'Thay đổi trạng thái thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  AddChildContract: async ({ contractId, ids }) => {
    try {
      const { data } = await API.post(`/contract/${contractId}/contract`, {
        ids
      });

      if (data) {
        get().getContract(contractId);
        notification.success({
          message: 'Cập nhật hợp đồng con thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  DeleteChildContract: async ({ contractId, ids }) => {
    try {
      const { data } = await API.delete(`/contract/${contractId}/contract`, {
        data: { ids }
      });

      if (data) {
        get().getContract(contractId);
        notification.success({
          message: 'Cập nhật hợp đồng con thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getAppendix: async appendixId => {
    try {
      const { data } = await API.get(`/appendix/${appendixId}`);
      if (data) {
        set({ appendixContract: data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateAppendix: async params => {
    try {
      const { data } = await API.put(`/appendix/${params.appendixId}`, params);

      if (data) {
        // get().getContract(data.id);
        get().getAppendix(data.id);
        // set(state => ({ contract: { ...state.contract, ...params } }));
        // set(state => ({
        //   appendixContract: { ...state.appendixContract, ...data }
        // }));
        notification.success({
          message: 'Chỉnh sửa phụ lục thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  DeleteAppendixOfContract: async params => {
    try {
      const { data } = await API.delete('/appendix', {
        data: params
      });

      if (data) {
        set(state => ({
          contract: {
            ...state.contract,
            appendix: state.contract.appendix.filter(a => a.id !== data.id)
          }
        }));
        notification.success({
          message: 'Xoá phụ lục thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  createResult: async params => {
    try {
      const { data } = await API.post(
        `/appendix/${params.appendixId}/result`,
        params
      );

      if (data) {
        notification.success({
          message: 'Tạo câu trả lời thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateResult: async params => {
    try {
      const { data } = await API.put(
        `/appendix/${params.appendixId}/result/${params.resultId}`,
        params
      );

      if (data) {
        // get().getContract(data.id);
        // set(state => ({ contract: { ...state.contract, ...params } }));

        const newAppendix = {
          ...get().appendixContract,
          result: get().appendixContract.result.map(result =>
            result.id === data.id ? data : result
          )
        };
        set({ appendixContract: newAppendix });

        notification.success({
          message: 'Chỉnh sửa thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateAppendixStatus: async (params, callback = f => f) => {
    try {
      const { data } = await API.post(
        `/appendix/${params.appendixId}/change-status`,
        params
      );

      if (data) {
        set(state => ({
          appendixContract: { ...state.appendixContract, status: data.status }
        }));
        if (data.status === 'deal') get().getContract(data.contractId);
        callback();
        notification.success({
          message: 'Thay đổi trạng thái thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateAppendixPayment: async params => {
    try {
      const { data } = await API.put(`/payment/${params.id}`, params);

      if (data) {
        const newAppendix = {
          ...get().appendixContract,
          payment: get().appendixContract.payment.map(pay =>
            pay.id === data.id ? data : pay
          )
        };
        set({ appendixContract: newAppendix });
        notification.success({
          message: 'Chỉnh sửa đợt thanh toán thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Get songList of Appendix
  getSongsAppendix: async appendixId => {
    try {
      const { data } = await API.get(`/appendix/${appendixId}/index02`);
      if (data) {
        set({ songs: data.data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // update song appendix
  updateAppendixSong: async params => {
    try {
      const { data } = await API.put(
        `/appendix/${params.appendixId}/song/${params.songId}`,
        params
      );

      if (data) {
        // get().getSongsAppendix(params.appendixId);
        const newSongs = get().songs.map(song =>
          song.id === data.songId
            ? {
                ...song,
                appendixDetail: {
                  ...song.appendixDetail,
                  fileCheckList: data.fileCheckList
                }
              }
            : song
        );
        set({
          songs: newSongs
        });
        notification.success({
          message: 'Chỉnh sửa bài hát phụ lục thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  sendEmailNotification: async params => {
    try {
      const { data } = await API.post(
        '/notification/send-appendix-file',
        params
      );

      if (data) {
        notification.success({
          message: 'Gửi thông báo thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  importedSongs: [],
  importSongs: songs => set({ importedSongs: songs }),

  //--------------------------------------------------------------------------------------//
  //                            Resign Contract by contractId                             //
  //--------------------------------------------------------------------------------------//
  isLoadingResignContract: false,
  resignContract: async (contractId, callback = f => f) => {
    set({ isLoadingResignContract: true });
    try {
      const {
        data: { data }
      } = await API.post(`/contract/${contractId}/resign-contract`, {});
      set({ isLoadingResignContract: false });
      if (data) {
        callback(data.id);
        notification.success({
          message: 'Tái ký hợp đồng thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoadingResignContract: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  newestAppendix: null,
  setNewestAppendix: newestAppendix => set({ newestAppendix })
}));
