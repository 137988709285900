import { Button, Col, Form, Row, Space, Typography } from 'antd';
import { useCallback } from 'react';
import shallow from 'zustand/shallow';

import Input from 'components/Input';

import { useAddendumDetail } from 'stores/useAddendumDetail';
import { useCurrentUser } from 'stores/useCurrentUser';

import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import TextArea from 'components/TextArea';
import ContractStatusTag from 'containers/ContractStatusTag';
import AddendumStatusTag from '../AddendumStatusTag';
import ContracTypeTag from '../ContracTypeTag';
import { getAppendixCode } from 'utils/other';
import ContractAutocomplete from 'containers/ContractAutocomplete';
import Upload from 'components/Upload';
import { UploadOutlined } from '@ant-design/icons';

const LeftAddendumDetailInfo = () => {
  const { addendum, contractUseInAddendumDetail, disableEditAddendum } =
    useAddendumDetail(
      useCallback(
        ({ addendum, contractUseInAddendumDetail, disableEditAddendum }) => ({
          addendum,
          contractUseInAddendumDetail,
          disableEditAddendum
        }),
        []
      ),
      shallow
    );

  const { isAnywayRole } = useCurrentUser(
    useCallback(
      ({ isAnywayRole }) => ({
        isAnywayRole
      }),
      []
    ),
    shallow
  );

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item label="Mã hợp đồng">
          <Input disabled value={contractUseInAddendumDetail?.contractCode} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Mã phụ lục">
          <Input disabled value={getAppendixCode(addendum)} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label="Hợp đồng liên kết" name="projectContractId">
          <ContractAutocomplete
            isParentContract={true}
            placeHolder="Tìm hợp đồng liên kết"
            allowClear={true}
            defaultOption={
              addendum?.projectContract
                ? {
                    value: addendum?.projectContract?.id,
                    label: addendum?.projectContract?.contractCode
                  }
                : []
            }
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="Ngày bắt đầu độc quyền"
          name="startDate"
          rules={[{ required: true, message: 'Chọn ngày bắt đầu!' }]}
        >
          <DatePicker
            disabled={disableEditAddendum && !isAnywayRole}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label="Ngày kết thúc độc quyền" name="finishDate">
          <DatePicker
            disabled={disableEditAddendum && !isAnywayRole}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label="Ngày dự kiến giao sản phẩm"
          name="expectedFileDeliveryDate"
        >
          <DatePicker
            disabled={disableEditAddendum && !isAnywayRole}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label="Ghi chú" name="note">
          <TextArea placeholder="Ghi chú" />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Typography.Paragraph>Trừ ứng trước:</Typography.Paragraph>
      </Col>

      <Col span={16}>
        <Form.Item
          name="deductedInAdvanceMoney"
          valuePropName="checked"
          noStyle
        >
          <Checkbox />
        </Form.Item>
      </Col>

      {isAnywayRole && (
        <Col span={24}>
          <Form.Item
            label={
              <Space>
                <Typography.Text>
                  Bản scan phụ lục trước khi ký:
                </Typography.Text>
              </Space>
            }
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              className="navi-upload"
              name="file"
              // maxCount={1}
              // showUploadList={{ showRemoveIcon: false }}
            >
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Bản scan phụ lục đã ký:"
            name="evidenceFile"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              className="navi-upload"
              name="file"
              // maxCount={1}
              // showUploadList={{ showRemoveIcon: false }}
            >
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          </Form.Item>
        </Col>
      )}

      <Col span={8}>
        <Typography.Paragraph>Loại hợp đồng:</Typography.Paragraph>
      </Col>

      <Col span={16}>
        <Typography.Paragraph>
          <ContracTypeTag value={contractUseInAddendumDetail?.contractType} />
        </Typography.Paragraph>
      </Col>
      <Col span={8}>
        <Typography.Paragraph>Trạng thái hợp đồng:</Typography.Paragraph>
      </Col>
      <Col span={16}>
        <Typography.Paragraph>
          <ContractStatusTag value={contractUseInAddendumDetail?.status} />
        </Typography.Paragraph>
      </Col>
      <Col span={8}>
        <Typography.Paragraph>Trạng thái phụ lục:</Typography.Paragraph>
      </Col>
      <Col span={16}>
        <Typography.Paragraph>
          <AddendumStatusTag value={addendum?.status} />
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};

export default LeftAddendumDetailInfo;
