import AutoComplete from 'components/AutoComplete/AutoComplete';
import React, { useCallback, useEffect } from 'react';
import { useContext } from 'react';
import { useSong } from 'stores/useSong';
import shallow from 'zustand/shallow';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';
import { useState } from 'react';

const SearchArtistAutoComplete = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { searchForPartner, getPartnerFromIds } = useSong(
    useCallback(
      ({ searchForPartner, getPartnerFromIds }) => ({
        searchForPartner,
        getPartnerFromIds
      }),
      []
    ),
    shallow
  );
  const form = useContext(FilterBoxFormContext);

  useEffect(() => {
    (async () => {
      const params = form.getFieldsValue();
      const data = await getPartnerFromIds({ partnerIds: params.value });
      setOptions(
        data.map(({ id, artistName, fullName }) => ({
          value: id,
          label: artistName || fullName
        }))
      );
    })();
  }, [form, getPartnerFromIds]);

  return (
    <AutoComplete
      placeHolder={placeHolder}
      handleSearch={searchForPartner}
      defaultOption={options}
      valueKey="id"
      mode="multiple"
      getLabel={partner => partner?.artistName || partner?.fullName}
      {...props}
    />
  );
};

export default SearchArtistAutoComplete;
