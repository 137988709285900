import React, { useCallback } from 'react';
import { Divider, Menu } from 'antd';
import shallow from 'zustand/shallow';

import { LogoutOutlined } from '@ant-design/icons';

import UserInfoMenuItem from './UserInfoMenuItem';

import useAuth from 'stores/useAuth';

const UserDropdownContent = ({ closeDropdown }) => {
  const { logout } = useAuth(
    useCallback(
      ({ logout }) => ({
        logout
      }),
      []
    ),
    shallow
  );

  return (
    <Menu selectable={false}>
      <UserInfoMenuItem closeDropdown={closeDropdown} />
      <Divider plain style={{ margin: '12px 0px' }} />
      {/* <Menu.Item key="setting" icon={<SettingOutlined />}>
        Cài đặt
      </Menu.Item> */}
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  );
};

export default UserDropdownContent;
