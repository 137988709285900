import { Form, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import shallow from 'zustand/shallow';
import ResourceQTGFormModalContent from './ResourceQTGFormModalContent';
import SongInfo from './SongInfo';

const ResourceQTGFormModal = ({ children, record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();

  const {
    tempSongAddendum,
    findAndUpdateSongAddendum,
    findAndUpdateTempSongAddendum
  } = useAddendumDetail(
    useCallback(
      ({
        tempSongAddendum,
        findAndUpdateSongAddendum,
        findAndUpdateTempSongAddendum
      }) => ({
        tempSongAddendum,
        findAndUpdateSongAddendum,
        findAndUpdateTempSongAddendum
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSave = row => {
    if (row.id) {
      findAndUpdateSongAddendum({
        ...row,
        appendixId: id,
        songId: row.songId,
        index: tempSongAddendum.length,
        send: true
      });
    } else {
      findAndUpdateTempSongAddendum({
        ...row,
        appendixId: id,
        index: tempSongAddendum.length,
        send: true
      });
    }
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();
      const { copyRightConfirmFile } = params;
      const fileParser =
        copyRightConfirmFile &&
        copyRightConfirmFile.map(f => {
          if (f.response) {
            return {
              fileName: f.response.fileName,
              fileLink: f.response.preview
            };
          }
          return { fileName: f.name, fileLink: f.url };
        });
      handleSave({ ...record, ...params, copyRightConfirmFile: fileParser });

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        destroyOnClose
        title="Nguồn quyền tác giả"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
        width={800}
      >
        <SongInfo song={record} />
        <ResourceQTGFormModalContent form={form} song={record} />
      </Modal>
    </>
  );
};

export default ResourceQTGFormModal;
