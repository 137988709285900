import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useReupVideo } from 'stores/useReup/useReupVideo';
import shallow from 'zustand/shallow';
import PublishedReupVideoInfo from './components/PublishedReupVideoInfo';
import ReupVideoInfo from './components/ReupVideoInfo';

const ReupVideoDetail = () => {
  const { getReupVideo, resetReupVideo } = useReupVideo(
    useCallback(
      ({ getReupVideo, resetReupVideo }) => ({
        getReupVideo,
        resetReupVideo
      }),
      []
    ),
    shallow
  );

  const { id } = useParams();

  useEffect(() => {
    id && getReupVideo(id);
  }, [getReupVideo, id]);

  useEffect(() => () => resetReupVideo(), [resetReupVideo]);

  return (
    <>
      <ReupVideoInfo />

      {!!id && <PublishedReupVideoInfo />}
    </>
  );
};

export default ReupVideoDetail;
