import { Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { formatDate } from 'utils/date';

const { Text } = Typography;

const filterPartner = (partnerSong, type) => {
  return partnerSong.filter(p => p.type === type).map(singer => singer.partner);
};

export const columns = [
  {
    title: 'Bài hát',
    dataIndex: 'songName',
    render: songName => (
      <Space direction="vertical">
        <Text strong>{songName}</Text>
      </Space>
    )
  },
  {
    title: 'Ca sĩ',
    dataIndex: 'singer',
    render: (_, { partnerSong }) => {
      const singers = filterPartner(partnerSong, 'singer');
      return (
        <Space direction="vertical">
          {singers.map(singer => (
            <Text strong key={singer?.id}>
              {singer?.artistName || singer?.fullName}
            </Text>
          ))}
        </Space>
      );
    }
  },
  {
    title: 'Tác giả',
    dataIndex: 'artist',
    render: (_, { partnerSong }) => {
      const musicians = filterPartner(partnerSong, 'musician');
      return (
        <Space direction="vertical">
          {musicians.map(musician => (
            <Text strong key={musician?.id}>
              {musician?.artistName || musician?.fullName}
            </Text>
          ))}
        </Space>
      );
    }
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate => (
      <Space direction="vertical" size={0}>
        <Text>{formatDate(createDate)}</Text>
        <Text>{moment(createDate).fromNow()}</Text>
      </Space>
    )
  }
];
