import { ImportOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE } from 'appConstants';
import ExportButton from 'components/ExportButton';
import OrderBySelect from 'components/OrderBySelect';
import { CommonContainer } from 'components/StyledComponents';
import Table from 'components/Table';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSongPublication } from 'stores/useSongPublication';
import shallow from 'zustand/shallow';
import { columns } from './columns';
import ImportSongPublication from './components/ImportSongPublication';
import PlaylistFilterBox from './components/SongPublicationFilterBox';

const { Title } = Typography;

const SongPublication = () => {
  const [loading, setLoading] = useState(false);
  const {
    getSongsPublication,
    songsPublication,
    total,
    isExportLoading,
    exportSongPublicationByIds
  } = useSongPublication(
    useCallback(
      ({
        getSongsPublication,
        songsPublication,
        total,
        isExportLoading,
        exportSongPublicationByIds
      }) => ({
        getSongsPublication,
        songsPublication,
        total,
        isExportLoading,
        exportSongPublicationByIds
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);

  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getSongsPublication({ ...searchParams, page: page - 1 });
      setLoading(false);
    })();
  }, [searchParams, page, getSongsPublication]);

  const onPaginate = page => {
    history.push({
      pathname: '/link-phat-hanh',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/link-phat-hanh',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  const uploadSuccess = () => {
    history.push({
      pathname: '/link-phat-hanh',
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort: ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE
      })
    });
  };

  const SongToExport = useMemo(
    () =>
      !!selectedRows.length
        ? selectedRows
        : songsPublication.map(_song => _song.id),
    [selectedRows, songsPublication]
  );
  const isSelected = useMemo(() => !!selectedRows.length, [selectedRows]);

  return (
    <>
      <Title>Danh sách link phát hành</Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>
            <PlaylistFilterBox />
          </Col>
          <Col>
            <Space>
              <ExportButton
                loading={isExportLoading}
                onExportByIds={exportSongPublicationByIds}
                selectedItems={SongToExport}
                callback={() => setSelectedRows([])}
                subject="link phát hành"
                isSelected={isSelected}
              />
              <ImportSongPublication uploadSuccess={uploadSuccess}>
                <Button
                  icon={<ImportOutlined />}
                  style={{ background: 'green', border: 'none', color: '#fff' }}
                >
                  Import
                </Button>
              </ImportSongPublication>
              <OrderBySelect defaultValue={sort} onChange={setOrderBy} />

              <Link to="/chi-tiet-link-phat-hanh">
                <Button type="primary">Tạo mới</Button>
              </Link>
            </Space>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={songsPublication?.map(_s => ({
            ..._s,
            key: _s.id
          }))}
          paginationProps={{
            total: total,
            pageSize: PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
          scroll={{ x: 1500 }}
          loading={loading}
          rowSelection={rowSelection}
        />
      </CommonContainer>
    </>
  );
};

export default SongPublication;
