import AutoComplete from 'components/AutoComplete/AutoComplete';
import { useCallback, useEffect, useState } from 'react';
import { usePartner } from 'stores/usePartner';
import shallow from 'zustand/shallow';

const PartnerAutoComplete = ({
  placeHolder,
  defaultOption,
  disabledOptions = [],
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const { searchForPartner } = usePartner(
    useCallback(
      ({ searchForPartner }) => ({
        searchForPartner
      }),
      []
    ),
    shallow
  );
  useEffect(() => {
    (async () => {
      if (defaultOption) {
        const option = {
          value: defaultOption?.id,
          label: defaultOption?.artistName
        };
        setOptions(option);
      }
    })();
  }, [defaultOption]);

  return (
    <AutoComplete
      placeHolder={placeHolder}
      handleSearch={searchForPartner}
      defaultOption={options}
      valueKey="id"
      getLabel={partner => partner?.artistName || partner?.fullName}
      disabledOptions={disabledOptions}
      {...props}
    />
  );
};

export default PartnerAutoComplete;
