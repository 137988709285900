import { Space, Typography, Row } from 'antd';
import { EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const columns = [
  {
    title: 'Mã phụ lục',
    dataIndex: 'appendix',
    colSpan: 8,
    render: (_, { appendixCode01, appendixCode02, appendixCode03 }) => {
      return {
        children: (
          <Space direction="vertical">
            {appendixCode03 ? (
              <Typography.Text strong>{appendixCode03}</Typography.Text>
            ) : (
              <>
                <Typography.Text strong>{appendixCode01}</Typography.Text>
                <Typography.Text strong>{appendixCode02}</Typography.Text>
              </>
            )}
          </Space>
        ),
        props: {
          colSpan: 8
        }
      };
    }
  },
  {
    title: 'Chỉ mục',
    dataIndex: 'indexing',
    render: (_, { index01, index02, index03 }) => (
      <Space direction="vertical">
        {index03 ? (
          <Typography.Text>{index03}</Typography.Text>
        ) : (
          <>
            <Typography.Text>{index01}</Typography.Text>
            <Typography.Text>{index02}</Typography.Text>
          </>
        )}
      </Space>
    )
  },
  {
    title: 'Tạm ứng',
    dataIndex: 'advance',
    render: (_, { advanceMoney }) => (
      <Typography.Text strong>{advanceMoney}</Typography.Text>
    )
  },
  {
    title: 'Link phụ lục',
    dataIndex: 'appendixLink',
    render: (_, { file }) => {
      return (
        <Space direction="vertical">
          {file.map((f, index) => (
            <a key={index} href={f?.fileLink} target="_blank" rel="noreferrer">
              <DownloadOutlined />
              {f?.fileName}
            </a>
          ))}
        </Space>
      );
    }
  },
  {
    title: 'Công cụ',
    dataIndex: '',
    render: (_, { id }) => {
      return (
        <Row justify="end">
          <Link to={`/chi-tiet-phu-luc/${id}`} target="_blank">
            <EditOutlined />
          </Link>
        </Row>
      );
    }
  }
];
