import {
  CloseCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { Col, Empty, Form, Row, Space, Tag, Typography } from 'antd';
import Button from 'components/Button';
import Select from 'components/Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlaylistDetail } from 'stores/usePlaylistDetail';
import { generatePertage } from 'utils/common';
import { formatOption } from 'utils/parser';
import shallow from 'zustand/shallow';
import PartnerAutoComplete from './PartnerAutoComplete';
import Top5Artists from './Top5Artists';

const pertages = generatePertage();

const PlaylistRevenueSharing = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [isRelatedTotalValid, setIsRelatedTotalValid] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [currentPartners, setCurrentPartners] = useState([]);

  const {
    revenuePlaylist,
    getRevenuePlaylist,
    updateRevenuePlaylist,
    playlist,
    top5Artists
  } = usePlaylistDetail(
    useCallback(
      ({
        revenuePlaylist,
        getRevenuePlaylist,
        updateRevenuePlaylist,
        playlist,
        top5Artists
      }) => ({
        revenuePlaylist,
        getRevenuePlaylist,
        updateRevenuePlaylist,
        playlist,
        top5Artists
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (id) {
      getRevenuePlaylist(id);
    }
  }, [getRevenuePlaylist, id]);

  useEffect(() => {
    setIsEmpty(revenuePlaylist.length === 0);
    handleFormChange(revenuePlaylist);
    if (!!revenuePlaylist.length) {
      const formData = revenuePlaylist.map(_revenue => ({
        partnerId: _revenue.partnerId,
        relatedRightContractId: playlist?.contracts?.find(
          _contract => _contract.id === _revenue.relatedRightContractId
        )
          ? _revenue.relatedRightContractId
          : null,
        relatedRightRatio: _revenue.relatedRightRatio
      }));
      form.setFieldsValue({ revenue: formData });
    }
    return () => {
      form.setFieldsValue({ revenue: [] });
    };
  }, [form, playlist?.contracts, revenuePlaylist]);

  const handleAddRevenue = add => {
    add();
  };

  const onSubmit = async () => {
    try {
      if (!isRelatedTotalValid) return;
      const values = await form.validateFields();
      const revenue = values?.revenue;
      const params = revenue.map(_revenue => ({
        ..._revenue,
        copyRightSourceType: _revenue.copyRightSourceType || '',
        copyRightSource: _revenue.copyRightSource || ''
      }));
      await updateRevenuePlaylist(id, params);
    } catch (error) {}
  };

  const handleFormChange = revenues => {
    setIsEmpty(revenues.length === 0);
    const sumValue = revenues.reduce(function (a, b) {
      return a + b?.relatedRightRatio;
    }, 0);
    const isSumValid =
      revenues.length === 0 || (sumValue === 100 && revenues.length > 0);
    setCurrentPartners(revenues.map(_revenue => _revenue?.partnerId));
    return setIsRelatedTotalValid(isSumValid);
  };

  const relatedOptions = useMemo(
    () =>
      !!playlist?.contracts?.length
        ? formatOption(playlist?.contracts, 'contractCode')
        : [],
    [playlist?.contracts]
  );

  return (
    <div>
      <Form
        name="revenue-form"
        autoComplete="off"
        form={form}
        onValuesChange={(_, allValues) => handleFormChange(allValues.revenue)}
      >
        <Form.List
          name="revenue"
          rules={[
            {
              validator: async (_, revenue) => {
                if (revenue.length < 1) {
                  return Promise.reject(
                    new Error('Ít nhất 1 phân chia doanh thu')
                  );
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <Row justify="space-between" className="mb-md">
                <Space>
                  <Typography.Title level={3}>Phân tuyển tập</Typography.Title>
                </Space>
                <Space>
                  <Top5Artists add={add} disabledOptions={currentPartners} />
                  <Button
                    type="primary"
                    ghost
                    icon={<PlusCircleOutlined />}
                    onClick={() => handleAddRevenue(add)}
                    size="small"
                  >
                    Thêm phân tuyển tập
                  </Button>
                </Space>
              </Row>
              {isEmpty ? (
                <>
                  <Empty
                    description={<span>Chưa có dữ liệu phân tuyển tập</span>}
                  />
                  <Row justify="end">
                    <Space>
                      {!isRelatedTotalValid && (
                        <Tag icon={<CloseCircleOutlined />} color="error">
                          Tổng tỉ lệ quyền liên quan bằng 100%
                        </Tag>
                      )}
                      {!!errors.length && (
                        <Tag icon={<CloseCircleOutlined />} color="error">
                          {errors}
                        </Tag>
                      )}

                      <Button onClick={onSubmit}>Cập nhật</Button>
                    </Space>
                  </Row>
                </>
              ) : (
                <>
                  <Row gutter={16} style={{ textAlign: 'left' }}>
                    <Col span={8}>
                      <Typography.Text strong>Đối tác</Typography.Text>
                    </Col>

                    <Col span={8}>
                      <Typography.Text strong>
                        Hợp đồng quyền liên quan
                      </Typography.Text>
                    </Col>
                    <Col span={7}>
                      <Typography.Text strong>
                        Tỉ lệ quyền liên quan
                      </Typography.Text>
                    </Col>
                  </Row>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => {
                      return (
                        <Row gutter={16} align="middle" key={key}>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'partnerId']}
                              fieldKey={[fieldKey, 'partnerId']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Chọn đối tác'
                                }
                              ]}
                            >
                              <PartnerAutoComplete
                                defaultOption={
                                  revenuePlaylist?.find(revenue => {
                                    const _revenue =
                                      form.getFieldValue('revenue');
                                    return (
                                      revenue?.partnerId ===
                                      _revenue[index]?.partnerId
                                    );
                                  })?.partner ||
                                  top5Artists.find(artist => {
                                    const _revenue =
                                      form.getFieldValue('revenue');
                                    return (
                                      artist?.id === _revenue[index]?.partnerId
                                    );
                                  })
                                }
                                placeHolder="Đối tác"
                                disabledOptions={currentPartners}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'relatedRightContractId']}
                              fieldKey={[fieldKey, 'relatedRightContractId']}
                            >
                              <Select
                                showSearch
                                filterOption={(input, option) => {
                                  return option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }}
                                options={relatedOptions}
                                listHeight={150}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              {...restField}
                              name={[name, 'relatedRightRatio']}
                              fieldKey={[fieldKey, 'relatedRightRatio']}
                            >
                              <Select
                                options={pertages}
                                showSearch
                                placeHolder="Tỉ lệ quyền liên quan"
                              />
                            </Form.Item>
                          </Col>

                          <Col>
                            <Form.Item {...restField}>
                              <DeleteOutlined
                                style={{ color: 'red' }}
                                onClick={() => remove(name)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    }
                  )}
                  <Row justify="end">
                    <Space>
                      {!isRelatedTotalValid && (
                        <Tag icon={<CloseCircleOutlined />} color="error">
                          Tổng tỉ lệ quyền liên quan bằng 100%
                        </Tag>
                      )}
                      {!!errors.length && (
                        <Tag icon={<CloseCircleOutlined />} color="error">
                          {errors}
                        </Tag>
                      )}

                      {/* {!revenuePlaylist.length && (
                    <Tag icon={<InfoCircleOutlined />} color="warning">
                      Chưa có phân chia doanh thu.
                    </Tag>
                  )} */}

                      <Button onClick={onSubmit}>Cập nhật</Button>
                    </Space>
                  </Row>
                </>
              )}
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default PlaylistRevenueSharing;
