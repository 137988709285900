import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';

export const useMasterData = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* ********************* */
  //* NOTE: get master data */
  //* ********************* */
  masterDatas: [],
  totalPage: 1,
  total: 0,
  getMasterDatas: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get('/masterdata', {
        params: {
          PageSize: PAGE_SIZE,
          ...get().params,
          ...params
        }
      });

      if (data) {
        set({
          masterDatas: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************** */
  //* NOTE: delete master datas  */
  //* ************************** */

  deleteMasterDatas: async params => {
    try {
      const { data } = await API.delete('/masterdata', { data: params });

      if (data) {
        get().getMasterDatas();

        return notification.success({
          message: 'Xoá master data thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************ */
  //* NOTE: create master data */
  //* ************************ */

  createMasterData: async params => {
    try {
      const { data } = await API.post('/masterdata', params);

      if (data) {
        await get().getMasterDatas();
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************************** */
  //* NOTE: get and update master data */
  //* ******************************** */

  masterData: null,
  resetMasterData: () => set({ masterData: null }),
  getMasterData: async masterDataId => {
    try {
      const {
        data: { data }
      } = await API.get(`/masterdata/${masterDataId}`);

      if (data) {
        set({ masterData: data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateMasterData: async params => {
    try {
      const { data } = await API.put(`/masterdata/${params.id}`, params);

      if (data) {
        get().getMasterDatas();

        notification.success({
          message: 'Cập nhật master data thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getMasterDataByGroup: async params => {
    try {
      const {
        data: { data }
      } = await API.get(`/masterdata/search?Group=${params}`);
      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
      return null;
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
