import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { extractPartnersFromSong } from 'utils/other';

const { Text } = Typography;

const SongInfo = ({ song }) => {
  const { songName, doiSoatNamVietCode, partnerSong } = song;
  // eslint-disable-next-line no-unused-vars
  const [_, singers] = extractPartnersFromSong(partnerSong, true);
  const [musicians] = extractPartnersFromSong(partnerSong, true);

  return (
    <div className="mb-md">
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Tác phẩm:</Text>
            <Text strong>{songName}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Mã bài hát:</Text>
            <Text strong>{doiSoatNamVietCode}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Tác giả:</Text>
            <Text strong>
              {
                <Space direction="vertical" size={0}>
                  {musicians.map(({ label, value }) => (
                    <Typography.Text strong key={value}>
                      {label}
                    </Typography.Text>
                  ))}
                </Space>
              }
            </Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Người trình bày:</Text>
            <Text strong>
              {
                <Space direction="vertical" size={0}>
                  {singers.map(({ label, value }) => (
                    <Typography.Text strong key={value}>
                      {label}
                    </Typography.Text>
                  ))}
                </Space>
              }
            </Text>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default SongInfo;
