import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import shallow from 'zustand/shallow';
import queryString from 'query-string';

import Button from 'components/Button';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import OrderBySelect from 'components/OrderBySelect';
import { CommonContainer } from 'components/StyledComponents';

import { useDepartment } from 'stores/useDepartment';
import Table from 'components/Table';

import { columns } from './columns';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE } from 'appConstants';
import DepartmentFormModal from './components/DepartmentFormModal';
import { useHistory, useLocation } from 'react-router-dom';

const Department = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const { departments, getDepartments, deleteDepartments, setParams, total } =
    useDepartment(
      useCallback(
        ({
          departments,
          getDepartments,
          deleteDepartments,
          setParams,
          total
        }) => ({
          departments,
          getDepartments,
          deleteDepartments,
          setParams,
          total
        }),
        []
      ),
      shallow
    );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-phong-ban',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-phong-ban',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    const params = { ...searchParams, page: page - 1, sort };
    setParams(params);
    getDepartments(params);
  }, [getDepartments, page, searchParams, sort, setParams]);

  return (
    <div>
      <Typography.Title>Phòng ban</Typography.Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>{/* <SearchBox /> */}</Col>
          <Col>
            <Space>
              {selectedRows.length !== 0 && (
                <DeleteConfirmPopup
                  title={`Xoá ${selectedRows.length} phòng ban?`}
                  onDelete={() => {
                    deleteDepartments({ ids: selectedRows });
                    setSelectedRows([]);
                  }}
                >
                  <Button danger>Xoá ({selectedRows.length})</Button>
                </DeleteConfirmPopup>
              )}

              {/* <Dropdown
                title="Excel"
                items={[
                  { value: 'import', label: 'Nhập' },
                  { value: 'export', label: 'Xuất' }
                ]}
              /> */}

              <OrderBySelect defaultValue={sort} onChange={setOrderBy} />

              <DepartmentFormModal>
                <Button>Tạo mới</Button>
              </DepartmentFormModal>
            </Space>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={departments.map(item => ({ key: item.id, ...item }))}
          paginationProps={{
            total: total,
            pageSize: PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
          rowSelection={rowSelection}
        />
      </CommonContainer>
    </div>
  );
};

export default Department;
