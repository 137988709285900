import { SearchOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';
import { DEBOUNCE_VALUE } from 'appConstants';
import Select from 'components/Select';
import { useCallback, useState } from 'react';
import { useService } from 'stores/useService';
import { debounce } from 'utils/lodash';
import { extractPartnersFromSong } from 'utils/other';
import shallow from 'zustand/shallow';

const PlaylistSongAutoComplete = ({ onSelect, currentSongs }) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const { searchForSong } = useService(
    useCallback(
      ({ searchForSong }) => ({
        searchForSong
      }),
      []
    ),
    shallow
  );

  const onSearch = async searchText => {
    setFetching(true);
    if (!searchText) {
      setFetching(false);
      return;
    }

    const results = await searchForSong(searchText, true);
    setFetching(false);
    if (!results) {
      return;
    }

    // Remove song exist
    const resultsFilter = currentSongs
      ? results.filter(r => {
          return !currentSongs.some(current => current.songId === r.id);
        })
      : results;
    return setOptions(
      resultsFilter.map(({ id, songName, partnerSong, songTypeName }) => {
        const [musicians, singers] = extractPartnersFromSong(partnerSong, true);

        const musician = musicians.map(({ label }) => label).join(', ');
        const singer = singers.map(({ label }) => label).join(', ');

        return {
          label: songName + ' - ' + singer,
          songName,
          value: id,
          singer,
          musician,
          songTypeName
        };
      })
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const handleSelect = async e => {
    const res = options.find(({ value }) => value === e);
    setOptions(options => options.filter(o => o.value !== e));
    onSelect(res);
  };

  return (
    <>
      <Select
        value={null}
        filterOption={false}
        notFoundContent={
          fetching ? (
            <Spin size="small" />
          ) : (
            <Empty description="Nhập để tìm kiếm gợi ý !" />
          )
        }
        showSearch
        onSearch={debouncedOnSearch}
        options={options}
        placeHolder="Tìm bài hát thêm vào danh sách"
        onSelect={handleSelect}
        style={{ width: '100%' }}
        suffixIcon={<SearchOutlined />}
      />
    </>
  );
};

export default PlaylistSongAutoComplete;
