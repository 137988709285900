import { notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';

export const useReviewDetail = create((set, get) => ({
  appendixReview: null,

  getAppendixReview: async appendixId => {
    try {
      // get().setIsPartnerLoading(true);

      const { data } = await API.get(`/appendix/${appendixId}/details`);

      // get().setIsPartnerLoading(false);

      if (data) {
        set({
          appendixReview: data
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      // get().setIsPartnerLoading(false);

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  changeAppendixStatus: async (id, status) => {
    try {
      const { data } = await API.post(`/appendix/${id}/change-status`, {
        status
      });

      if (data) {
        await get().getAppendixReview(id);

        notification.success({
          message: 'Thay đổi trạng thái thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // Func approve/deny review appendix
  approveAppendixReview: async params => {
    try {
      const { data } = await API.post(`/appendix/${params.id}/confirm`, {
        ...params
      });

      if (data) {
        await get().getAppendixReview(data.appendixId);

        notification.success({
          message: `Đã ${
            data.status === 'approved' ? 'phê duyệt' : 'từ chối duyệt'
          } phụ lục!`
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // send modify demand
  sendModifyDemand: async params => {
    try {
      const { data } = await API.post(
        '/notification/send-modify-demand',
        params
      );
      if (data) {
        notification.success({
          message: 'Đã gửi yêu cầu sửa đổi!'
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
