import moment from 'moment';
export const calculatePercent = (a, b) => ((a / b) * 100).toFixed(2) * 1 || 0;
export const generateFilterParams = arr => {
  let obj = {};

  arr
    .filter(
      ({ value, operator, operatorFrom, operatorTo }) =>
        (value || value === 0) && (operator || (operatorFrom && operatorTo))
    )
    .forEach(({ name, operator, value, operatorFrom, operatorTo }) => {
      // Check is range picker
      if (operator) {
        obj[`${name}[${operator}]`] = value;
      } else {
        obj[`${name}[${operatorFrom}]`] = moment(value[0]).format('YYYY-MM-DD');
        obj[`${name}[${operatorTo}]`] = moment(value[1]).format('YYYY-MM-DD');
      }
    });

  return obj;
};

export const objToArr = obj => {
  return Object.keys(obj).map(key => ({ key, value: obj[key] }));
};

export const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const extractPartnersFromSong = (songs, forSelect) => {
  if (!songs) return [[], []];

  const a = [],
    b = [];

  songs.forEach(item => {
    const thingToPush = forSelect
      ? {
          value: item?.partner?.id,
          label: item?.partner?.artistName || item?.partner?.fullName
        }
      : item?.partnerId;

    if (item?.type === 'musician') {
      a.push(thingToPush);
    } else if (item?.type === 'singer') {
      b.push(thingToPush);
    }
  });

  return [a, b];
};

//--------------------------------------------------------------------------------------//
//                          Generate appendixCode by condition                          //
//--------------------------------------------------------------------------------------//

export const getAppendixCode = appendix => {
  if (!appendix?.index02) {
    return appendix?.appendixCode03;
  }

  if (appendix?.doiSoatId) {
    return appendix?.index01 === appendix?.index02
      ? appendix?.appendixCode02
      : `${appendix?.appendixCode01}-${appendix?.index02}`;
  }

  return `${appendix?.appendixCode01}-02.${appendix?.index02}`;
};

//--------------------------------------------------------------------------------------//
//                      Get label of song with singer information                       //
//--------------------------------------------------------------------------------------//

export const getSongWithSingerLabel = song => {
  const { songName, partnerSong } = song;
  // eslint-disable-next-line no-unused-vars
  const [_, singers] = extractPartnersFromSong(partnerSong, true);
  const singer = singers.map(({ label }) => label).join(', ');
  return songName + ' - ' + singer;
};
