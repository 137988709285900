import AutoComplete from 'components/AutoComplete';
import { useCallback } from 'react';
import { useService } from 'stores/useService';
import { useSongPublication } from 'stores/useSongPublication';
import { get } from 'utils/lodash';
import { getSongWithSingerLabel } from 'utils/other';
import shallow from 'zustand/shallow';

const SongPublicationAutoComplete = props => {
  const { searchForSong } = useService(
    useCallback(
      ({ searchForSong }) => ({
        searchForSong
      }),
      []
    ),
    shallow
  );

  const { songPublication } = useSongPublication(
    useCallback(
      ({ songPublication }) => ({
        songPublication
      }),
      []
    ),
    shallow
  );

  const song = get(songPublication, 'song', null);
  const defaultOption = song ? { value: song.id, label: song.songName } : null;

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm bài hát"
      handleSearch={searchValue => searchForSong(searchValue, true)}
      labelKey="songName"
      valueKey="id"
      style={{ width: '100%' }}
      getLabel={getSongWithSingerLabel}
      {...props}
    />
  );
};

export default SongPublicationAutoComplete;
