import React, { useContext } from 'react';
import Select from 'components/Select';
import { FilterBoxFormContext } from './FilterBoxForm';

import { Form } from 'antd';
import Input from 'components/Input';

const FilterSelect = ({ options, ...props }) => {
  const form = useContext(FilterBoxFormContext);

  const onSelect = value => {
    if (!form) {
      return;
    }

    const found = options.find(({ value: thisValue }) => thisValue === value);

    return form.setFieldsValue({ label: found?.label });
  };

  return (
    <>
      <Select options={options} onSelect={onSelect} {...props} />

      {/* magic 🧙‍♀️🧙‍♀️🧙‍♀️ */}
      <Form.Item name="label" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
    </>
  );
};

export default FilterSelect;
