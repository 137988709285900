import React, { useContext } from 'react';
import { Form } from 'antd';

import Select from 'components/Select';
import { MASTER_DATA_GROUP_LABEL } from 'appConstants';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';

import Input from 'components/Input';

const options = Object.keys(MASTER_DATA_GROUP_LABEL).map(key => ({
  value: key,
  label: MASTER_DATA_GROUP_LABEL[key]
}));

const MasterDataGroupSelect = props => {
  const form = useContext(FilterBoxFormContext);

  const onSelect = value => {
    if (!form) {
      return;
    }

    return form.setFieldsValue({ label: MASTER_DATA_GROUP_LABEL[value] });
  };

  return (
    <>
      <Select options={options} onSelect={onSelect} {...props} />

      {/* magic 🧙‍♀️🧙‍♀️🧙‍♀️ */}
      <Form.Item name="label" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
    </>
  );
};

export default MasterDataGroupSelect;
