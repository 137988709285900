// import { Button } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useCurrentUser } from 'stores/useCurrentUser';
import { usePlaylist } from 'stores/usePlaylist';
import shallow from 'zustand/shallow';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { formatDate } from 'utils/date';

const btnApproveStyle = {
  icon: <CheckOutlined />,
  style: { background: '#009c03', border: 'none' }
};

const btnWaitingStyle = {
  icon: <CloseOutlined />,
  type: 'danger'
};

const ButtonApprove = ({ playlists, selectedRows, onApprove }) => {
  const [isApprovedAll, setIsApprovedAll] = useState();

  const playlistSelected = useMemo(
    () =>
      playlists.filter(playlist => {
        return (
          selectedRows.includes(playlist.id) &&
          !(playlist.totalSong === 0 && playlist.status === 'waiting')
        );
      }),
    [playlists, selectedRows]
  );

  const playlistNeedApprove = useMemo(
    () => playlistSelected.filter(playlist => playlist.status === 'waiting'),
    [playlistSelected]
  );

  const { updatePlaylists } = usePlaylist(
    useCallback(
      ({ updatePlaylists }) => ({
        updatePlaylists
      }),
      []
    ),
    shallow
  );

  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (!!playlistSelected.length) {
      const checkApprovedAll = playlistSelected.every(
        playlist => playlist.status === 'approved'
      );

      setIsApprovedAll(checkApprovedAll);
    }
  }, [playlistSelected]);

  const handleApprove = () => {
    updatePlaylists(
      playlistSelected.map(({ id, views, totalSong }) => ({
        id,
        views: views || 0,
        totalSong,
        status: totalSong === 0 || isApprovedAll ? 'waiting' : 'approved',
        approvedBy: currentUser.id,
        approvedDate: formatDate(moment())
      }))
    );
    onApprove();
  };

  return (
    !!playlistSelected.length && (
      <Button
        onClick={handleApprove}
        {...(isApprovedAll ? btnWaitingStyle : btnApproveStyle)}
      >
        {isApprovedAll
          ? `Bỏ duyệt (${playlistSelected.length})`
          : `Duyệt (${playlistNeedApprove.length})`}
      </Button>
    )
  );
};

export default ButtonApprove;
