import React from 'react';
import { Col, Divider, Row, Space, Typography } from 'antd';

import UserAvatar from 'components/UserAvatar';
import { GENDER_DICTIONARY } from 'appConstants';
import TextContent from 'components/TextContent';

const { Text } = Typography;

const ManagerInfo = ({ manager }) => {
  return (
    <div>
      <Divider orientation="right">
        <Text style={{ color: 'orange' }}>Thông tin quản lý</Text>
      </Divider>
      <Row align="middle">
        <Col span={20}>
          <Row gutter={[32, 16]}>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Họ tên:</Text>
                <TextContent value={manager?.fullName} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Ngày sinh:</Text>
                <TextContent value={manager?.dateOfBirth} isDate />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>CMND/CCCD:</Text>
                <TextContent value={manager?.identityNumber} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Ngày cấp:</Text>
                <TextContent value={manager?.dateOfIdentification} isDate />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Nơi cấp:</Text>
                <TextContent value={manager?.placeOfIdentification} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Mã số thuế:</Text>
                <TextContent value={manager?.taxCode} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Số điện thoại:</Text>
                <TextContent value={manager?.phone} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Giới tính:</Text>
                <Text strong>{GENDER_DICTIONARY[manager?.gender]}</Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Địa chỉ:</Text>
                <TextContent value={manager?.address} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Email:</Text>
                <TextContent value={manager?.email} />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row justify="center">
            <UserAvatar
              fullName={manager?.fullName}
              avatar={manager?.avatar}
              size={100}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ManagerInfo;
