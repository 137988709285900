import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';

export const useUser = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* ******************* */
  //* NOTE: create users  */
  //* ******************* */

  isCreatingUser: false,
  createUser: async params => {
    set({ isCreatingUser: true });
    try {
      const { data } = await API.post('/user', params);

      set({ isCreatingUser: false });

      if (data) {
        await get().getUsers();

        notification.success({
          message: 'Tạo nhân viên thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* **************** */
  //* NOTE: get users  */
  //* **************** */

  users: [],
  totalPage: 1,
  total: 0,
  getUsers: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get('/user', {
        params: {
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        }
      });

      if (data) {
        set({
          users: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data.result;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************** */
  //* NOTE: get and update users */
  //* ************************** */

  user: null,
  resetUser: () => set({ user: null }),
  getUser: async userId => {
    try {
      const { data } = await API.get(`/user/${userId}`);
      if (data) {
        set({ user: data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateUser: async (params, paramsForGet) => {
    try {
      const { data } = await API.put(`/user/${params.id}`, params);

      if (data) {
        get().getUsers(paramsForGet);
        set({
          user: data
          // users: get().users.map(item => (item.id === data.id ? data : item))
        });

        notification.success({
          message: 'Cập nhật nhân viên thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: delete users  */
  //* ******************* */

  deleteUsers: async params => {
    try {
      const {
        data: { data }
      } = await API.delete('/user', { data: params });

      if (data) {
        get().getUsers();
        return notification.success({
          message: 'Xoá nhân viên thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForUsers: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/user/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data && data.length !== 0) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForRole: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/role/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data && data.length !== 0) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForDepartment: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/department/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data && data.length !== 0) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: Change password user */
  //* ******************* */
  changePasswordUser: async (params, callback = f => f) => {
    try {
      const { data } = await API.put(
        `/user/${params.id}/change-password`,
        params
      );
      if (data) {
        notification.success({ message: 'Đổi mật khẩu thành công!' });
        callback();

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
