import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Space, Typography } from 'antd';
import { IMPORT_FILE_TEMPLATE } from 'appConstants';
import Upload from 'components/Upload';
import ImportFileTemplate from 'containers/ImportFileTemplate';
import { useCallback } from 'react';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { getAppendixCode } from 'utils/other';
import shallow from 'zustand/shallow';

const ResourceQTGFormModalContent = ({ form }) => {
  const { addendum } = useAddendumDetail(
    useCallback(
      ({ addendum }) => ({
        addendum
      }),
      []
    ),
    shallow
  );

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChange = info => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} sẵn sàng để import`);
    } else if (info.file.status === 'error') {
      if (info.file.error.status === 404 || info.file.error.status === 400) {
        const errors = info.file.response?.errors?.split(';');
        message.error({
          content: (
            <Space direction="vertical" align="start">
              {errors?.map(error => error.trim())}
            </Space>
          ),
          icon: (
            <Button
              type="link"
              onClick={() => message.destroy()}
              icon={<CloseCircleOutlined />}
            ></Button>
          ),
          duration: 0
        });
      } else {
        message.error(
          <Space direction="vertical" align="start">
            File không hợp lệ.
          </Space>
        );
      }
    }
  };

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Space>
            <Typography.Text>Mã phụ lục:</Typography.Text>
            <Typography.Text strong>
              {getAppendixCode(addendum)}
            </Typography.Text>
          </Space>
        </Col>
        <Col span={12}>
          <Form.Item
            name="file"
            label="File import (.xlsx):"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: 'Vui lòng upload file bài hát!'
              }
            ]}
          >
            <Upload
              endPoint={`/appendix/${addendum?.id}/import-song`}
              showUploadList={{ showRemoveIcon: false }}
              multiple
              className="navi-upload"
              maxCount={1}
              onChange={onChange}
            >
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <ImportFileTemplate file={IMPORT_FILE_TEMPLATE.SONG_APPENDIX} />
        </Col>
      </Row>
    </Form>
  );
};

export default ResourceQTGFormModalContent;
