import { UserOutlined } from '@ant-design/icons';
import { Col, Row, Space, Tag } from 'antd';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, ROLE_CHECK } from 'appConstants';
import Button from 'components/Button';
import DeleteButton from 'components/DeleteButton';
import ExportButton from 'components/ExportButton';
import OrderBySelect from 'components/OrderBySelect';
import Table from 'components/Table';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

const ContractMainContent = ({
  selectedRows,
  setSelectedRows,
  rowSelection,
  onPaginate,
  expandedRow,
  contracts,
  totalPage,
  deleteContracts,
  contractFilterBox: ContractFilterBox,
  page,
  columns,
  partnerId,
  rowExpandable,
  orderBy,
  setOrderBy,
  total,
  getExportContracts,
  isExportLoading,
  showExport,
  getExportContractByIds
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const userSelected = useMemo(
    () => searchParams.UserName,
    [searchParams.UserName]
  );

  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE,
    [searchParams.sort]
  );

  const onDelete = (selectedRows, partnerId) => {
    deleteContracts({ ids: selectedRows }, partnerId);
    setSelectedRows([]);
  };

  // const handleExportContract = async () => {
  //   try {
  //     const { link } = await getExportContracts();
  //     // if (link) window.open(link);
  //   } catch (error) {}
  // };

  return (
    <>
      <Row justify="space-between" className="mb-md">
        <Col>
          {/* <SearchBox /> */}
          <ContractFilterBox />

          <div style={{ display: 'flex' }}>
            {userSelected && (
              <Tag
                color="blue"
                closable
                onClose={() => {
                  delete searchParams['UserId[eq]'];
                  delete searchParams.UserName;

                  return history.push({
                    pathname: '/danh-sach-hop-dong',
                    search: queryString.stringify({
                      ...searchParams,
                      page: 1,
                      sort
                    })
                  });
                }}
                className="tag-user-selected mb-md"
                icon={<UserOutlined />}
              >
                {userSelected}
              </Tag>
            )}
          </div>
        </Col>

        <Col>
          <Space>
            {showExport && (
              <ExportButton
                loading={isExportLoading}
                onExport={getExportContracts}
                onExportByIds={getExportContractByIds}
                selectedItems={selectedRows}
                callback={() => setSelectedRows([])}
                subject="hợp đồng"
              />
            )}

            {selectedRows.length !== 0 && (
              <DeleteButton
                title={`Xoá ${selectedRows.length} hợp đồng?`}
                onDelete={() => onDelete(selectedRows, partnerId)}
                length={selectedRows.length}
                from={ROLE_CHECK.SONG}
              />
            )}

            <OrderBySelect defaultValue={orderBy} onChange={setOrderBy} />
            {!partnerId && (
              <Link to="/chi-tiet-hop-dong/soan-hop-dong">
                <Button>Tạo mới</Button>
              </Link>
            )}
          </Space>
        </Col>
      </Row>
      <Table
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={contracts.map(item => ({
          key: item.id,
          ...item
        }))}
        rowSelection={rowSelection}
        paginationProps={{
          total: total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page
        }}
        expandable={{
          expandedRowRender: expandedRow,
          rowExpandable
        }}
      />
    </>
  );
};

export default ContractMainContent;
