import React, { useCallback, useEffect } from 'react';
import shallow from 'zustand/shallow';
import { Form, Input as AntdInput } from 'antd';

import Input from 'components/Input';

import MasterDataGroupSelect from '../MasterDataGroupSelect';

import { useMasterData } from 'stores/useMasterData';

const MasterDataFormModalContent = ({
  form,
  masterDataId,
  isUpdateMasterData
}) => {
  const { getMasterData, masterData, resetMasterData } = useMasterData(
    useCallback(
      ({ getMasterData, masterData, resetMasterData }) => ({
        getMasterData,
        masterData,
        resetMasterData
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (isUpdateMasterData) {
      getMasterData(masterDataId);
    }
  }, [isUpdateMasterData, getMasterData, masterDataId]);

  useEffect(() => {
    if (isUpdateMasterData && masterData) {
      const { title, code, description, group, color } = masterData;
      form.setFieldsValue({
        title,
        code,
        description,
        group,
        color
      });
    }
  }, [masterData, form, isUpdateMasterData]);

  useEffect(
    () => () => {
      form.resetFields();
      if (masterData) {
        resetMasterData();
      }
    },
    [form, resetMasterData, masterData]
  );

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="code"
        label="Code"
        rules={[{ required: true, message: 'Nhập code!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="title"
        label="Tiêu đề"
        rules={[{ required: true, message: 'Nhập tiêu đề!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="description" label="Chú thích">
        <AntdInput.TextArea />
      </Form.Item>

      <Form.Item
        name="group"
        label="Nhóm"
        rules={[{ required: true, message: 'Nhập nhóm!' }]}
      >
        <MasterDataGroupSelect form={form} />
      </Form.Item>

      <Form.Item name="color" label="Màu sắc">
        <input type="color" />
      </Form.Item>
    </Form>
  );
};

export default MasterDataFormModalContent;
