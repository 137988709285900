import { PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import Button from 'components/Button';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useContract } from 'stores/useContract';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';
import { columns } from './columns';

const Appendix = ({ contractId }) => {
  const { contract, getContract, DeleteAppendixOfContract, setNewestAppendix } =
    useContract(
      useCallback(
        ({
          contract,
          getContract,
          DeleteAppendixOfContract,
          setNewestAppendix
        }) => ({
          contract,
          getContract,
          DeleteAppendixOfContract,
          setNewestAppendix
        }),
        []
      ),
      shallow
    );

  // const handleChangeStatus = appendixId => {
  //   updateAppendixStatus({ appendixId, status: 'deal' });
  // };

  const handleDelete = useCallback(
    async appendixId => {
      const data = await DeleteAppendixOfContract({ ids: [appendixId] });
      if (data) {
        // setNewAppendix(newAppendix =>
        //   newAppendix.filter(a => a.id !== appendixId)
        // );
      }
    },
    [DeleteAppendixOfContract]
  );

  const appendix = get(contract, 'appendix', null);
  appendix?.sort((a, b) => moment(b.createDate) - moment(a.createDate));
  setNewestAppendix(appendix ? appendix[0] : null);

  return (
    <div>
      <div style={{ textAlign: 'end' }}>
        <Link to={`/tao-moi-phu-luc/${contractId}`} target="_blank">
          <Button type="link" icon={<PlusCircleOutlined />}>
            Tạo phụ lục hợp đồng
          </Button>
        </Link>
      </div>
      <div style={{ textAlign: 'end' }} className="mb-md">
        <Button
          type="link"
          icon={<ReloadOutlined />}
          style={{ color: 'green' }}
          onClick={() => getContract(contractId)}
        >
          Refresh ds phụ lục
        </Button>
      </div>
      {appendix && (
        <div className="card-box" style={{ padding: '16px' }}>
          <div className="mb-md">
            <Typography.Text strong>Phụ lục mới</Typography.Text>
            <Table
              className="navi-table"
              columns={columns}
              scroll={{ x: 900 }}
              dataSource={appendix.slice(0, 1)?.map(item => ({
                ...item,
                key: item.id,
                handleDelete
                // handleChangeStatus
              }))}
              pagination={false}
            />
          </div>
          <Typography.Text strong>Phụ lục cũ</Typography.Text>
          <Table
            className="navi-table"
            columns={columns}
            scroll={{ x: 900 }}
            dataSource={appendix.slice(1)?.map(item => ({
              ...item,
              key: item.id,
              handleDelete
              // handleChangeStatus
            }))}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

export default Appendix;
