import React, { useCallback } from 'react';

import shallow from 'zustand/shallow';

import { useSong } from 'stores/useSong';

import AutoComplete from 'components/AutoComplete/AutoComplete';
import { get } from 'utils/lodash';
import { extractPartnersFromSong } from 'utils/other';

const ComposerAutoComplete = props => {
  const { searchForPartner, song, musicianFromMedley } = useSong(
    useCallback(
      ({ searchForPartner, song, musicianFromMedley }) => ({
        searchForPartner,
        song,
        musicianFromMedley
      }),
      []
    ),
    shallow
  );

  const partnerSong = get(song, 'partnerSong', []);

  // eslint-disable-next-line no-unused-vars
  const [defaultOption] = extractPartnersFromSong(partnerSong, true);

  return (
    <AutoComplete
      placeHolder="Tìm tác giả"
      handleSearch={searchForPartner}
      defaultOption={[
        ...defaultOption,
        ...musicianFromMedley.map(({ id, artistName }) => ({
          value: id,
          label: artistName
        }))
      ]}
      labelKey="artistName"
      valueKey="id"
      mode="multiple"
      {...props}
    />
  );
};

export default ComposerAutoComplete;
