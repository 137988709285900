import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Space } from 'antd';
import { IMPORT_FILE_TEMPLATE } from 'appConstants';
import Upload from 'components/Upload';
import ImportFileTemplate from 'containers/ImportFileTemplate';
import React, { useEffect } from 'react';

const ImportSongFormModalContent = ({ form, onImportSuccess = f => f }) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  const onChange = info => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} import thành công !`);
      onImportSuccess();
    } else if (info.file.status === 'error') {
      if (info.file.error.status === 404 || info.file.error.status === 400) {
        const errors = info.file.response?.errors?.split(';');
        message.error({
          content: (
            <Space direction="vertical" align="start">
              {errors?.map(error => error.trim())}
            </Space>
          ),
          icon: (
            <Button
              type="link"
              onClick={() => message.destroy()}
              icon={<CloseCircleOutlined />}
            ></Button>
          ),
          duration: 0
        });
      } else {
        message.error(
          <Space direction="vertical" align="start">
            File không hợp lệ.
          </Space>
        );
      }
    }
  };

  function beforeUpload(file) {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'csv' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      message.error('Bạn chỉ có thể upload file excel!');
    }
    return isExcel;
  }

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="file"
            label="File import (.xlsx):"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: 'Vui lòng upload file bài hát!'
              }
            ]}
          >
            <Upload
              endPoint={`/playlist/import-song`}
              showUploadList={{ showRemoveIcon: false }}
              multiple
              className="navi-upload"
              maxCount={1}
              onChange={onChange}
              beforeUpload={beforeUpload}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <ImportFileTemplate
            file={IMPORT_FILE_TEMPLATE.SONG_PLAYLIST_ALL_LINK}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ImportSongFormModalContent;
