import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Typography
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ReupBackgroundAutoComplete from '../AutoComplete/ReupBackgroundAutoComplete';
import qs from 'query-string';
import ReupEffectAutoComplete from '../AutoComplete/ReupEffectAutoComplete';
import ReupMusicTypeAutoComplete from '../AutoComplete/ReupMusicTypeAutoComplete';
import ReupSpectrumAutoComplete from '../AutoComplete/ReupSpectrumAutoComplete';
import ReupTagAutoComplete from '../AutoComplete/ReupTagAutoComplete';
import ReupHotkeyAutoComplete from '../AutoComplete/ReupHotkeyAutoComplete';
import ReupSongSelect from '../ReupSongSelect';
import { useHistory, useParams } from 'react-router-dom';
import { useReupVideo } from 'stores/useReup/useReupVideo';
import shallow from 'zustand/shallow';
import { getRandomAssets } from 'stores/useReup/getRandomAssetsServices';
import { REUP_TYPE, REUP_VIDEOS_STATUS } from 'appConstants';

const list = [
  {
    single: true,
    type: REUP_TYPE.MT,
    magicKey: 'musicType',
    formName: 'musicTypeId',
    magicPair: ['id', 'title']
  },
  {
    single: true,
    type: REUP_TYPE.EF,
    magicKey: 'effect',
    formName: 'effectId',
    magicPair: ['id', 'title']
  },
  {
    single: true,
    type: REUP_TYPE.ST,
    magicKey: 'spectrum',
    formName: 'spectrumId',
    magicPair: ['id', 'title']
  },
  {
    single: true,
    type: REUP_TYPE.BG,
    magicKey: 'image',
    formName: 'imageId',
    magicPair: ['id', 'title']
  },
  {
    single: false,
    type: REUP_TYPE.SG,
    params: {
      Page: 0,
      Quantity: 20,
      PageSize: 20
    },
    magicKey: 'songs',
    formName: 'songIds',
    magicPair: ['id', 'title']
  },
  {
    single: false,
    type: REUP_TYPE.TG,
    magicKey: 'tags',
    formName: 'tagIds',
    magicPair: ['id', 'title']
  }
];

const _hotkey = {
  single: true,
  type: REUP_TYPE.HK,
  magicKey: 'hotkeys',
  formName: 'hotkeyIds',
  magicPair: ['id', 'title']
};

const ReupVideoInfo = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [defaultOptions, setDevaultOptions] = useState({
    songs: [],
    musicType: {},
    effect: {},
    spectrum: {},
    image: {},
    hotkeys: [],
    tags: []
  });

  const history = useHistory();
  const { createReupVideo, reupVideo, updateReupVideo } = useReupVideo(
    useCallback(
      ({ createReupVideo, reupVideo, updateReupVideo }) => ({
        createReupVideo,
        reupVideo,
        updateReupVideo
      }),
      []
    ),
    shallow
  );

  const onSave = async () => {
    try {
      let _params = await form.validateFields();
      const params = {
        ..._params,
        hotkeyIds: [_params.hotkeyIds]
      };
      if (!!id) {
        updateReupVideo({ ...params, id });
      } else {
        const res = await createReupVideo(params);
        history.push({
          pathname: '/reup/video-detail/' + res.id
        });
      }
    } catch (e) {
      throw e;
    }
  };

  const onRandom = async () => {
    setLoading(true);
    try {
      let formData = {};
      Promise.all(list.map(_ => getRandomAssets(_))).then(async values => {
        const randomSongs =
          values.find(({ type }) => type === REUP_TYPE.SG)?.data || [];
        const hotkeyParams = randomSongs.map(item =>
          item.title.replace('.mp3', '')
        );
        const value = await getRandomAssets({
          ..._hotkey,
          params: {
            songNames: hotkeyParams
          },
          paramsSerializer: params => {
            return qs.stringify(params);
          }
        });

        [...values, value].forEach(
          ({ type, data, defaultOptions, ...rest }) => {
            if (type === REUP_TYPE.HK) {
              formData.title = data?.recommendTitle;
            }
            formData = { ...formData, ...rest };
            setDevaultOptions(prevState => ({
              ...prevState,
              ...defaultOptions
            }));
          }
        );

        form.setFieldsValue(formData);
      });
    } catch (e) {
      throw e;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (reupVideo) {
      form.setFieldsValue({
        title: reupVideo.title,
        musicTypeId: reupVideo.musicTypeId,
        effectId: reupVideo.effectId,
        spectrumId: reupVideo.spectrumId,
        imageId: reupVideo.imageId,
        hotkeyIds: reupVideo.hotkeyVideo[0]?.hotKey?.id,
        tagIds: reupVideo.tagVideo?.map(({ tag }) => tag?.id) || [],
        songIds: reupVideo.songVideo?.map(({ song }) => song?.id) || []
      });

      setDevaultOptions({
        songs: reupVideo?.songVideo?.map(({ song }) => song) || [],
        musicType: reupVideo?.musicType,
        effect: reupVideo?.effect,
        spectrum: reupVideo?.spectrum,
        image: reupVideo?.image,
        hotkeys: reupVideo?.hotkeyVideo[0]?.hotKey || {},
        tags: reupVideo?.tagVideo?.map(({ tag }) => tag) || []
      });
    }
  }, [form, reupVideo]);

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel
        header={<Typography.Text strong>Thông tin video</Typography.Text>}
        key="1"
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form}>
            <Form.Item
              name="title"
              label="Tiêu đề"
              rules={[{ required: true, message: 'Nhập tên tiêu đề!' }]}
            >
              <Input />
            </Form.Item>

            <ReupSongSelect songs={defaultOptions?.songs || []} form={form} />

            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="musicTypeId"
                  label="Thể loại"
                  rules={[{ required: true, message: 'Chọn thể loại!' }]}
                >
                  <ReupMusicTypeAutoComplete
                    reupMusicType={defaultOptions?.musicType}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="effectId"
                  label="Effect"
                  rules={[{ required: true, message: 'Chọn effect!' }]}
                >
                  <ReupEffectAutoComplete reupEffect={defaultOptions?.effect} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="spectrumId"
                  label="Spectrum"
                  rules={[{ required: true, message: 'Chọn spectrum!' }]}
                >
                  <ReupSpectrumAutoComplete
                    reupSpectrum={defaultOptions?.spectrum}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="imageId"
                  label="Background"
                  rules={[{ required: true, message: 'Chọn background!' }]}
                >
                  <ReupBackgroundAutoComplete
                    reupBackground={defaultOptions?.image}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="hotkeyIds"
              label="Hotkey"
              rules={[{ required: true, message: 'Chọn hotkey!' }]}
            >
              <ReupHotkeyAutoComplete reupHotkey={defaultOptions?.hotkeys} />
            </Form.Item>

            <Form.Item
              name="tagIds"
              label="Tag"
              rules={[{ required: true, message: 'Chọn tag!' }]}
            >
              <ReupTagAutoComplete
                mode="multiple"
                reupTag={defaultOptions?.tags}
              />
            </Form.Item>
          </Form>

          <Row justify="end">
            <Col>
              <Space>
                <Tag color={REUP_VIDEOS_STATUS[reupVideo?.status]?.color}>
                  {REUP_VIDEOS_STATUS[reupVideo?.status]?.status}
                </Tag>

                <Divider type="vertical" />
                <Button onClick={onRandom}>Tự động lựa chọn</Button>

                <Button onClick={onSave} type="primary">
                  Lưu
                </Button>
              </Space>
            </Col>
          </Row>
        </Spin>
      </Collapse.Panel>
    </Collapse>
  );
};

export default ReupVideoInfo;
