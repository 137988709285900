import React, { useCallback, useState } from 'react';
import { Modal, Form } from 'antd';
import shallow from 'zustand/shallow';

import { useDepartment } from 'stores/useDepartment';
import Input from 'components/Input';

const DepartmentFormModal = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { createDepartment } = useDepartment(
    useCallback(
      ({ createDepartment }) => ({
        createDepartment
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();

      await createDepartment(params);
      form.resetFields(['name']);

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    form.resetFields(['name']);

    setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        title="Tạo mới phòng ban"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
        destroyOnClose
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Tên phòng ban"
            name="name"
            rules={[{ required: true, message: 'Nhập tên phòng ban!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DepartmentFormModal;
