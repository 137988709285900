import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import { Typography } from 'antd';
import Upload from 'components/Upload';
import { UploadOutlined } from '@ant-design/icons';

const LiquidateFormModalContent = ({ form, contract }) => {
  useEffect(() => {
    if (contract) {
      const { liquidationFile } = contract;
      form.setFieldsValue({
        liquidationFile
      });
    }
  }, [contract, form]);

  useEffect(
    () => () => {
      form.resetFields();
    },
    [form, contract]
  );

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item label="Mã hợp đồng">
        <Typography.Text strong>{contract.contractCode}</Typography.Text>
      </Form.Item>

      <Form.Item label="Loại hợp đồng">
        <Typography.Text strong>{contract.contractType}</Typography.Text>
      </Form.Item>

      <Form.Item
        name="liquidationFile"
        label="Biên bản thanh lý"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Tải file' }]}
      >
        <Upload
          showUploadList={{ showRemoveIcon: false }}
          multiple
          className="navi-upload"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Tải lên</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
};

export default LiquidateFormModalContent;
