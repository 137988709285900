import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space, Typography } from 'antd';
import shallow from 'zustand/shallow';
import queryString from 'query-string';

import Table from 'components/Table';

import OrderBySelect from 'components/OrderBySelect';
import UserFilterBox from './components/UserFilterBox';
import { CommonContainer } from 'components/StyledComponents';

import { columns } from './columns';

import { useUser } from 'stores/useUser';

import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, ROLE_CHECK } from 'appConstants.js';
import CreateButton from 'components/CreateButton';
import DeleteButton from 'components/DeleteButton';
import { useHistory, useLocation } from 'react-router-dom';

const User = () => {
  const { getUsers, users, deleteUsers, setParams, total } = useUser(
    useCallback(
      ({ getUsers, users, deleteUsers, setParams, total }) => ({
        getUsers,
        users,

        deleteUsers,
        setParams,
        total
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-nhan-vien',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-nhan-vien',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  useEffect(() => {
    const paramsForGet = { ...searchParams, page: page - 1, sort };
    setParams(paramsForGet);
    getUsers(paramsForGet);
  }, [getUsers, page, searchParams, sort, setParams]);

  return (
    <div>
      <Typography.Title>Danh sách nhân viên</Typography.Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>
            {/* <SearchBox /> */}
            <UserFilterBox />
          </Col>

          <Col>
            <Space>
              {selectedRows.length !== 0 && (
                <DeleteButton
                  title={`Xoá ${selectedRows.length} nhân viên?`}
                  onDelete={() => {
                    deleteUsers({ ids: selectedRows });
                    setSelectedRows([]);
                  }}
                  length={selectedRows.length}
                  from={ROLE_CHECK.USER}
                />
              )}

              {/* <Dropdown
                title="Excel"
                items={[
                  { value: 'import', label: 'Nhập' },
                  { value: 'export', label: 'Xuất' }
                ]}
              /> */}

              <OrderBySelect defaultValue={sort} onChange={setOrderBy} />

              <CreateButton from={ROLE_CHECK.USER} />
            </Space>
          </Col>
        </Row>
        {/* <UserFilterBox /> */}
        <Table
          columns={columns}
          dataSource={users.map(item => ({ ...item, key: item.id }))}
          paginationProps={{
            total: total,
            pageSize: PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
          rowSelection={rowSelection}
          scroll={{ x: 1500 }}
        />
      </CommonContainer>
    </div>
  );
};

export default User;
