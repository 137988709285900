import { Col, Divider, Row, Space, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

const BankAccount = ({ bankAccount }) => {
  return (
    <div>
      <Divider orientation="left">
        <Text style={{ color: 'red' }}>Thông tin thanh toán</Text>
      </Divider>
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Số tài khoản:</Text>
            <Text strong>{bankAccount?.accountNumber}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Ngân hàng:</Text>
            <Text strong>{bankAccount?.bankName}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Tên chủ tài khoản:</Text>
            <Text strong>{bankAccount?.accountName}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size={0}>
            <Text>Chi nhánh:</Text>
            <Text strong>{bankAccount?.bankBranch}</Text>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default BankAccount;
