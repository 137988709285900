import { Col, Form, Row, Tag } from 'antd';
import { useCallback, useEffect } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import Input from 'components/Input';
import MetaCard from 'components/MetaCard';
import { useCurrentUser } from 'stores/useCurrentUser';
import shallow from 'zustand/shallow';

const PersonalInfo = () => {
  const [form] = Form.useForm();
  const { currentUser, updateProfile } = useCurrentUser(
    useCallback(
      ({ currentUser, updateProfile }) => ({
        currentUser,
        updateProfile
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (currentUser)
      form.setFieldsValue({
        fullName: currentUser.fullName,
        phone: currentUser.phone,
        email: currentUser.email,
        username: currentUser.username,
        department: currentUser?.department?.name,
        role: currentUser?.sysRole?.name
      });
  }, [form, currentUser]);

  const handleUpdateInfo = async values => {
    try {
      const params = {
        fullName: values?.fullName,
        phone: values?.phone
      };
      await updateProfile(params);
    } catch (error) {}
  };

  return (
    <MetaCard title="Thông tin cá nhân">
      <Form layout="vertical" form={form} onFinish={handleUpdateInfo}>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item label="Tên đăng nhập:" name="username">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Email:" name="email">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Phòng ban:" name="department">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Vai trò:" name="role">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Họ tên:"
              name="fullName"
              rules={[{ required: true, message: 'Nhập họ tên!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Số điện thoại:"
              name="phone"
              rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" align="middle">
          <Tag color="processing" icon={<InfoCircleOutlined />}>
            Chỉ có thể thay đổi những thông tin được thao tác.
          </Tag>

          <Button htmlType="submit">Lưu</Button>
        </Row>
      </Form>
    </MetaCard>
  );
};

export default PersonalInfo;
