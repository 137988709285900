import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import 'moment/locale/vi';
import React from 'react';
import { formatDate } from 'utils/date';
import { USER_COMFIRMED_TYPE_VALUE } from 'appConstants';

export const columns = [
  {
    title: 'Người duyệt',
    dataIndex: 'user',
    render: user => <Typography.Text strong>{user? (user.fullName??''):''}</Typography.Text>,
    width: '20%'
  },
  {
    title: 'Thuộc bộ phận',
    dataIndex: 'type',
    key: 'type',
    render: type => (
      <Typography.Text>{USER_COMFIRMED_TYPE_VALUE[type]}</Typography.Text>
    ),
    width: '20%'
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note',
    render: note => <Typography.Text>{note}</Typography.Text>
  },
  {
    title: 'Ngày duyệt',
    dataIndex: 'approveDate',
    render: (_, { createDate, updateDate }) => (
      <Typography.Text>{formatDate(updateDate || createDate)}</Typography.Text>
    ),
    width: '20%'
  },
  {
    title: 'Trạng thái duyệt',
    dataIndex: 'status',
    render: status =>
      status === 'approved' ? (
        <CheckOutlined style={{ color: 'green' }} />
      ) : (
        <CloseOutlined style={{ color: 'red' }} />
      ),
    width: '20%',
    align: 'center'
  }
];
