import { Col, Form, List, Row, Space, Spin, Tag, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import shallow from 'zustand/shallow';

import Input from 'components/Input';
import SingerAutoComplete from '../SingerAutoComplete';

import { useSong } from 'stores/useSong';
import ComposerAutoComplete from '../ComposerAutoComplete';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import Checkbox from 'components/Checkbox';
import { Medley } from 'components/Icons';
import SongTypeDetailSelect from 'containers/SongTypeDetailSelect';
import SongTypeSelect from 'containers/SongTypeSelect';
import { Link } from 'react-router-dom';
import { extractPartnersFromSong } from 'utils/other';
import SongMedley from '../SongMedley';

const SongFormModalContent = ({
  form,
  songId,
  isUpdateSong,
  onUpdateArtist,
  onChangeIsMedley
}) => {
  const {
    getSong,
    song,
    resetSong,
    songOfMedley,
    setSongOfMedley,
    isSongLoading
  } = useSong(
    useCallback(
      ({
        getSong,
        song,
        resetSong,
        songOfMedley,
        setSongOfMedley,
        isSongLoading
      }) => ({
        getSong,
        song,
        resetSong,
        songOfMedley,
        setSongOfMedley,
        isSongLoading
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    if (isUpdateSong) {
      getSong(songId);
    }
  }, [isUpdateSong, getSong, songId]);

  useEffect(() => {
    if (isUpdateSong && song) {
      const { songName, songType, partnerSong, isMedley, songTypeDetail } =
        song;

      const [musicianIds, singerIds] = extractPartnersFromSong(
        partnerSong.filter(({ partner }) => partner)
      );
      form.setFieldsValue({
        songName,
        songType,
        singerIds,
        musicianIds,
        isMedley,
        songTypeDetail
      });
      onChangeIsMedley(isMedley);
    }
  }, [song, form, isUpdateSong, onChangeIsMedley]);

  useEffect(
    () => () => {
      form.resetFields();
      if (song) {
        resetSong();
      }
    },
    [form, resetSong, song]
  );

  useEffect(() => {
    if (isUpdateSong && song) {
      const { songMedley } = song;

      const newSongMedley = songMedley.map(song => {
        const [musicians, singers] = extractPartnersFromSong(
          song.song.partnerSong,
          true
        );

        const musician = musicians.map(({ label }) => label).join(', ');
        const singer = singers.map(({ label }) => label).join(', ');
        return { ...song, song: { ...song.song, singer, musician } };
      });

      setSongOfMedley(newSongMedley);
    }
    return () => {
      setSongOfMedley([]);
    };
  }, [song, isUpdateSong, setSongOfMedley]);

  return (
    <Spin spinning={isSongLoading}>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="songName"
              label="Tên bài hát"
              rules={[{ required: true, message: 'Nhập tên bài hát!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="songType"
              label="Thể loại"
              rules={[{ required: true, message: 'Chọn thể loại!' }]}
            >
              <SongTypeSelect
                valueKey="code"
                onChange={() => form.resetFields(['songTypeDetail'])}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.songType !== currentValues.songType
              }
            >
              {({ getFieldValue }) => {
                const songTypeCode = getFieldValue('songType');
                // form.resetFields(['songTypeDetail']);
                return (
                  <Form.Item name="songTypeDetail" label="Thể loại chi tiết">
                    <SongTypeDetailSelect code={songTypeCode} valueKey="code" />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="singerIds"
              label="Người trình bày"
              rules={[{ required: true, message: 'Chọn người trình bày!' }]}
            >
              <SingerAutoComplete />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="musicianIds"
              label="Tác giả"
              rules={[{ required: true, message: 'Chọn tác giả!' }]}
            >
              <ComposerAutoComplete />
            </Form.Item>
          </Col>
          {isUpdateSong && (
            <>
              <Col span={24}>
                <Form.Item label="Loại hình kinh doanh">
                  <Row gutter={[0, 8]}>
                    {!!song?.businessTypes?.length ? (
                      song?.businessTypes?.map(type => (
                        <Col>
                          <Tag color="geekblue">{type?.name}</Tag>
                        </Col>
                      ))
                    ) : (
                      <Tag icon={<ExclamationCircleOutlined />} color="warning">
                        Chưa có loại hình kinh doanh
                      </Tag>
                    )}
                  </Row>
                </Form.Item>
              </Col>
              <Col span={24} className="mb-md">
                {!!song?.contracts.length && (
                  <List
                    size="small"
                    header={
                      <Typography.Text strong>Hợp đồng đã ký</Typography.Text>
                    }
                    bordered
                    dataSource={[
                      ...(song?.contracts || []),
                      ...(song?.contractForMedleys || [])
                    ]}
                    renderItem={contract => (
                      <List.Item key={contract.id}>
                        <List.Item.Meta
                          title={
                            <Link
                              to={`/chi-tiet-hop-dong/${contract?.id}/soan-hop-dong`}
                            >
                              {contract?.contractCode}
                            </Link>
                          }
                          description={
                            contract?.medley ? (
                              <Space>
                                <Typography.Text>
                                  <Medley
                                    style={{ fontSize: 18, color: '#1DD05D' }}
                                  />
                                  {contract?.medley?.songName}
                                </Typography.Text>
                                <Typography.Text>
                                  #{contract?.doiSoatCode}
                                </Typography.Text>
                              </Space>
                            ) : null
                          }
                        />
                        <div>
                          #
                          {contract?.medley
                            ? contract?.doiSoatCodeOfSongForContract
                            : contract?.doiSoatCode}
                        </div>
                      </List.Item>
                    )}
                    style={{ maxHeight: 200, overflowY: 'auto' }}
                  />
                )}
              </Col>
            </>
          )}

          <Col>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const isMedley = getFieldValue('isMedley');
                return (
                  <Form.Item
                    name="isMedley"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox
                      disabled={!!songOfMedley.length && isMedley}
                      onChange={e => onChangeIsMedley(e.target.checked)}
                    >
                      Bài hát là liên khúc
                    </Checkbox>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isMedley = getFieldValue('isMedley');
            return isMedley ? (
              <SongMedley songId={songId} onUpdateArtist={onUpdateArtist} />
            ) : null;
          }}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default React.memo(SongFormModalContent);
