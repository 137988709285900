import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Row, Space, Spin, Typography } from 'antd';
import Button from 'components/Button';
import { CommonContainer } from 'components/StyledComponents';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSongPublication } from 'stores/useSongPublication';
import { formatDate } from 'utils/date';
import shallow from 'zustand/shallow';
import PublicationDetailForm from './components/PublicationDetailForm';

const { Title } = Typography;

const SongPublicationDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const {
    isLoading,
    getSongPublication,
    songPublication,
    updateSongPublication,
    createSongPublication,
    setIsProviderFacebook
  } = useSongPublication(
    useCallback(
      ({
        isLoading,
        getSongPublication,
        songPublication,
        updateSongPublication,
        createSongPublication,
        setIsProviderFacebook
      }) => ({
        isLoading,
        getSongPublication,
        songPublication,
        updateSongPublication,
        createSongPublication,
        setIsProviderFacebook
      }),
      []
    ),
    shallow
  );

  const isUpdatePublication = useMemo(() => !!id, [id]);
  const notFound = useMemo(
    () => isUpdatePublication && !songPublication,
    [isUpdatePublication, songPublication]
  );

  useEffect(() => {
    if (isUpdatePublication) {
      getSongPublication(id);
    }
  }, [getSongPublication, id, isUpdatePublication]);

  useEffect(() => {
    if (songPublication) {
      const {
        title,
        link,
        code,
        onlineStatus,
        startDate,
        finishDate,
        publishedDate,
        songId,
        publisherId,
        publisher,
        likes,
        views,
        reaches
      } = songPublication;
      setIsProviderFacebook(publisher?.provider?.code === 'FACEBOOK');
      form.setFieldsValue({
        title,
        link,
        publisherId,
        providerId: publisher?.providerId,
        code,
        onlineStatus,
        startDate: startDate && moment(startDate),
        finishDate: finishDate && moment(finishDate),
        publishedDate: publishedDate && moment(publishedDate),
        songId,
        likes,
        views,
        reaches
      });
    }
  }, [form, setIsProviderFacebook, songPublication]);

  useEffect(() => {
    if (!isUpdatePublication) {
      form.resetFields();
    }
  }, [form, isUpdatePublication]);

  const onFinish = async values => {
    const {
      title,
      link,
      code,
      onlineStatus,
      startDate,
      finishDate,
      songId,
      publisherId,
      providerId,
      publishedDate,
      views,
      likes,
      reaches
    } = values;

    const params = {
      id,
      title,
      link,
      code,
      onlineStatus,
      startDate: startDate && formatDate(startDate),
      finishDate: finishDate && formatDate(finishDate),
      publishedDate: publishedDate && formatDate(publishedDate),
      songId,
      publisherId,
      providerId,
      views: views || 0,
      likes: likes || 0,
      reaches: reaches || 0
    };

    if (isUpdatePublication) {
      await updateSongPublication(params);
    } else {
      await createSongPublication(params, id =>
        history.push(`/chi-tiet-link-phat-hanh/${id}`)
      );
    }
  };
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Spin spinning={isLoading}>
        <Row justify="space-between" align="middle">
          <Title>
            {isUpdatePublication
              ? 'Chi tiết bài hát phát hành'
              : 'Tạo bài hát phát hành'}
          </Title>
          <Col>
            <Space>
              {isUpdatePublication && (
                <Link to="/chi-tiet-link-phat-hanh">
                  <Button icon={<PlusOutlined />} type="default">
                    Tạo mới
                  </Button>
                </Link>
              )}
            </Space>
          </Col>
        </Row>

        <CommonContainer>
          <PublicationDetailForm
            form={form}
            isUpdatePublication={isUpdatePublication}
          />
          <Row justify="end">
            <Form.Item>
              <Button htmlType="submit" disabled={notFound}>
                {isUpdatePublication ? 'Cập nhật' : 'Tạo bài hát phát hành'}
              </Button>
            </Form.Item>
          </Row>
        </CommonContainer>
      </Spin>
    </Form>
  );
};

export default SongPublicationDetail;
