import { Form, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSongRelease } from 'stores/useSongRelease';
import shallow from 'zustand/shallow';
import GroupMailFormModalContent from './GroupMailFormModalContent';

const GroupMailFormModal = ({ children, defaultValue }) => {
  const { createMailGroup, updateMailGroup } = useSongRelease(
    useCallback(
      ({ createMailGroup, updateMailGroup }) => ({
        createMailGroup,
        updateMailGroup
      }),
      []
    ),
    shallow
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const isUpdateCompany = useMemo(() => !!defaultValue?.id, [defaultValue?.id]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();
      if (isUpdateCompany) {
        await updateMailGroup({ ...params, id: defaultValue.id });
      } else {
        await createMailGroup(params);
      }

      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isUpdateCompany && defaultValue) {
      const { title, mails } = defaultValue;
      form.setFieldsValue({
        title,
        mails
      });
    }
  }, [isUpdateCompany, defaultValue, form]);

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        title={
          isUpdateCompany
            ? 'Chỉnh sửa danh sách mail'
            : 'Tạo mới danh sách mail'
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        width={800}
        forceRender
      >
        <GroupMailFormModalContent
          defaultMails={defaultValue?.mails}
          form={form}
        />
      </Modal>
    </>
  );
};

export default GroupMailFormModal;
