import { FILTER_TYPE } from 'appConstants';
import moment from 'moment';
import queryString from 'query-string';
import { formatDate } from 'utils/date';

const checkValue = (value, foundOptions) => {
  if (Array.isArray(value)) {
    return foundOptions.map(option => option.artistName).join(', ');
  }
  if (foundOptions) {
    const found = foundOptions.find(
      ({ value: foundValue }) => foundValue === value
    );

    return found?.label;
  }
  return moment(value, 'YYYY-MM-DD', true).isValid()
    ? formatDate(value)
    : value;
};

// Build for new filter - (Song release)
export const genInitValueCustom = async (
  searchParams,
  filterItems,
  getExtendOptions
) => {
  const initRes = [];

  for (const key of Object.keys(searchParams)) {
    const character = key.match(/\[(.*?)\]/);
    const operator = character ? character[1] : '';
    const paramsName = key.replace('[' + operator + ']', '');
    const paramsValue = searchParams[key];

    const foundIndex = initRes.findIndex(({ name }) => name === paramsName);

    const foundOptions = filterItems.find(
      ({ name }) => name === paramsName
    )?.options;

    if (foundIndex !== -1) {
      const temp = initRes[foundIndex];
      initRes[foundIndex] = {
        ...temp,
        tag: temp.tag + ' - ' + checkValue(paramsValue),
        value:
          operator === 'gte' || operator === 'lte'
            ? [moment(temp.value), moment(paramsValue)]
            : temp.value
      };

      continue;
    }

    // Check normal input or autocomplete
    const autoCompleteItem = filterItems.find(
      ({ name, isUsingChild, label }) =>
        name === paramsName && isUsingChild && label
    );

    const thisItem = filterItems.find(({ name }) => name === paramsName);

    const tempTag = async () => {
      const isExtendOptionObj =
        typeof getExtendOptions === 'object' && getExtendOptions !== null;
      const funcGetValue = isExtendOptionObj
        ? getExtendOptions[paramsName]
        : getExtendOptions;

      const label = thisItem.label;

      // Check item has custom label
      if (thisItem.getLabel) {
        const record = await funcGetValue(paramsValue);
        const label = thisItem.getLabel(record);
        return `${thisItem.formTitle}: ${label}`;
      }

      if (thisItem?.isMultiple) {
        const extendOptions = await funcGetValue({ partnerIds: paramsValue });
        const tag = extendOptions
          .map(({ artistName, fullName }) => artistName || fullName)
          .join(', ');
        return `${thisItem.formTitle}: ${tag}`;
      }

      const getLabelFromData = async () => {
        const res = await funcGetValue(paramsValue);
        return Array.isArray(res) ? res[0][label] : res[label];
      };

      const tag =
        autoCompleteItem && getExtendOptions
          ? await getLabelFromData()
          : checkValue(paramsValue, foundOptions);

      return `${thisItem.formTitle}: ${tag}`;
    };

    initRes.push({
      operator,
      name: paramsName,
      value: paramsValue,
      tag: await tempTag()
    });
  }
  return initRes;
};

export const genInitValue = async (
  searchParams,
  filterItems,
  getExtendOptions
) => {
  let arr = [];

  for (const key of Object.keys(searchParams)) {
    const character = key.match(/\[(.*?)\]/);

    // if (!character) {
    //   continue;
    // }
    // Handle case normal filter (?status=param) - have no operator.
    const operator = character ? character[1] : '';
    const paramsName = key.replace('[' + operator + ']', '');
    const paramsValue = searchParams[key];

    //check date range
    const foundIndex = arr.findIndex(({ name }) => name === paramsName);

    if (foundIndex !== -1) {
      const temp = arr[foundIndex];
      arr[foundIndex] = {
        ...temp,
        tag: temp.tag + ' - ' + checkValue(paramsValue)
      };

      continue;
    }

    const foundOptions = filterItems.find(
      ({ name }) => name === paramsName
    )?.options;

    // Autocomple filter by operator
    const specialItem = filterItems.find(
      ({ name, isUsingChild, type }) =>
        name === paramsName && isUsingChild && type === FILTER_TYPE.SELECT
    );

    if (
      // Quằn - Trigger hardcode 😂
      Array.isArray(paramsValue) ||
      paramsName === 'SingerIds' ||
      paramsName === 'MusicianIds'
    ) {
      const data = await getExtendOptions({ partnerIds: paramsValue });
      arr.push({
        operator,
        name: paramsName,
        value: paramsValue,
        tag:
          filterItems.find(({ name }) => name === paramsName)?.formTitle +
          ': ' +
          checkValue([paramsValue], data)
      });
    } else {
      if (specialItem) {
        getExtendOptions(paramsValue).then(data => {
          const foundOptions = [data].map(({ id, code }) => ({
            value: id,
            label: code
          }));
          arr.push({
            operator,
            name: paramsName,
            value: paramsValue,
            tag:
              filterItems.find(({ name }) => name === paramsName)?.formTitle +
              ': ' +
              checkValue(paramsValue, foundOptions)
          });
          return data;
        });
      } else {
        arr.push({
          operator,
          name: paramsName,
          value: paramsValue,
          tag:
            filterItems.find(({ name }) => name === paramsName)?.formTitle +
            ': ' +
            checkValue(paramsValue, foundOptions)
        });
      }
    }
  }

  return arr;
};

export const filterToUrl = (
  { key, value, operator, operatorFrom, operatorTo, otherType },
  searchParams,
  history,
  pathname,
  extraParams
) => {
  const newSearchParams = { ...searchParams };
  const theKey = otherType ? `${key}` : `${key}[${operator}]`;
  let filterParams;

  if (Array.isArray(value) && value[0]?.isValid && value[0].isValid()) {
    filterParams = {
      [`${key}[${operatorFrom}]`]: moment(value[0]).format('YYYY-MM-DD'),
      [`${key}[${operatorTo}]`]: moment(value[1]).format('YYYY-MM-DD')
    };
  } else {
    filterParams =
      (value && operator) || (value && otherType) ? { [theKey]: value } : {};
  }

  Object.keys(newSearchParams).forEach(item => {
    if (item.includes(key)) {
      delete newSearchParams[item];
    }
  });

  history.push({
    pathname,
    search: queryString.stringify({
      ...newSearchParams,
      ...filterParams,
      page: 1,
      ...extraParams
    })
  });
};

export const generateQueryParams = params => {
  const paramsQuery = new URLSearchParams();
  const objectArray = Object.entries(params);
  objectArray.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(val => paramsQuery.append(key, val));
      return;
    }
    paramsQuery.append(key, value);
  });

  return paramsQuery;
};

export const secConvert = secs => {
  function pad(num) {
    return ('0' + num).slice(-2);
  }

  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};
