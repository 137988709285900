import { message } from 'antd';
import AutoComplete from 'components/AutoComplete/AutoComplete';
import React, { useCallback } from 'react';
import { useProjectAppendix } from 'stores/useProjectAppendix';
import { getAppendixCode } from 'utils/other';
import shallow from 'zustand/shallow';

const ProjectAppendixAutoComplete = ({ ...props }) => {
  const { searchForProjectAppendix, addProjectAppendix, projectAppendixes } =
    useProjectAppendix(
      useCallback(
        ({
          searchForProjectAppendix,
          addProjectAppendix,
          projectAppendixes
        }) => ({
          searchForProjectAppendix,
          addProjectAppendix,
          projectAppendixes
        }),
        []
      ),
      shallow
    );

  const handleSelect = value => {
    if (!value) return;

    const isExisted = projectAppendixes.find(
      _appendix => _appendix.id === value
    );
    if (isExisted) {
      message.error('Phụ lục liên kết đã tồn lại !');
      return;
    }
    addProjectAppendix(value);
  };

  return (
    <AutoComplete
      placeHolder={'Tìm kiếm phụ lục liên kết'}
      handleSearch={searchForProjectAppendix}
      valueKey="id"
      getLabel={getAppendixCode}
      onSelect={handleSelect}
      value={null}
      {...props}
    />
  );
};

export default React.memo(ProjectAppendixAutoComplete);
