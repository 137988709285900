import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import React, { useCallback } from 'react';
import { useSongRelease } from 'stores/useSongRelease';
import shallow from 'zustand/shallow';

const SongReleaseDeleteButton = ({ item }) => {
  const { deleteSongsRelease } = useSongRelease(
    useCallback(
      ({ deleteSongsRelease }) => ({
        deleteSongsRelease
      }),
      []
    ),
    shallow
  );

  return (
    <DeleteConfirmPopup
      title={`Xoá ${item.songName}?`}
      onDelete={() => deleteSongsRelease({ ids: [item.id] })}
    >
      <Button type="danger" icon={<DeleteOutlined />} />
    </DeleteConfirmPopup>
  );
};

export default SongReleaseDeleteButton;
