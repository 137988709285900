import React, { useCallback } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const UserAvatar = ({ fullName, avatar, ...props }) => {
  const generateAvatarContent = useCallback(fullName => {
    if (!fullName || typeof fullName !== 'string') {
      return <UserOutlined />;
    }

    const arr = fullName.split(' ');

    if (arr.length === 1) {
      return arr.shift()[0].toUpperCase();
    }

    return (arr.shift()[0] + arr.pop()[0]).toUpperCase();
  }, []);

  return avatar ? (
    <Avatar size={64} {...props} src={avatar} />
  ) : (
    <Avatar size={64} {...props}>
      {generateAvatarContent(fullName)}
    </Avatar>
  );
};

export default UserAvatar;
