import React from 'react';

import { Select } from 'antd';

import { ROLE_LABEL } from 'appConstants';

const PermissionMultiSelect = props => {
  const generateOptions = () =>
    Object.keys(ROLE_LABEL).map(key => (
      <Select.Option key={key} value={key}>
        {ROLE_LABEL[key]}
      </Select.Option>
    ));

  return (
    <Select mode="multiple" allowClear style={{ width: '100%' }} {...props}>
      {generateOptions()}
    </Select>
  );
};

export default PermissionMultiSelect;
