import { Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { reorder } from 'utils/other';
import shallow from 'zustand/shallow';
import SongItemWrapper from './SongItemWrapper';

const SongsWrapper = () => {
  const {
    songAddendum,
    tempSongAddendum,
    tempSortSongAddendum,
    pushTempSortSongAddendum
  } = useAddendumDetail(
    useCallback(
      ({
        songAddendum,
        tempSongAddendum,
        tempSortSongAddendum,
        pushTempSortSongAddendum
      }) => ({
        songAddendum,
        tempSongAddendum,
        tempSortSongAddendum,
        pushTempSortSongAddendum
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    pushTempSortSongAddendum([...songAddendum, ...tempSongAddendum]);
  }, [songAddendum, tempSongAddendum, pushTempSortSongAddendum]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newSongs = reorder(
      tempSortSongAddendum,
      result.source.index,
      result.destination.index
    );

    pushTempSortSongAddendum(newSongs);
  };

  return (
    <div>
      {!!tempSortSongAddendum.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <SongItemWrapper songs={tempSortSongAddendum} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Typography.Text>Không có bài hát nào để sắp xếp.</Typography.Text>
      )}
    </div>
  );
};

export default SongsWrapper;
