import { notification } from 'antd';
import API from 'api';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import create from 'zustand';

export const useDashboard = create((set, get) => ({
  newContracts: null,
  needToCheckContracts: null,
  expiredContracts: null,
  nearExpiredContracts: null,
  payContracts: null,
  checkContracts: null,
  waitingPlaylists: null,
  latestSongs: null,
  statistics: {},

  getNewContracts: async () => {
    try {
      const { data } = await API.get('/dashboard/contract/latest');

      if (data) {
        set({ newContracts: data });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getNeedToCheckContracts: async () => {
    try {
      const { data } = await API.get('/dashboard/contract/need-to-check');

      if (data) {
        set({ needToCheckContracts: data });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getExpiredContracts: async () => {
    try {
      const { data } = await API.get('/dashboard/contract/expired');
      if (data) {
        set({ expiredContracts: data });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getPayContracts: async () => {
    try {
      const { data } = await API.get('/dashboard/contract/pay');
      if (data) {
        let payAppendixs = [];
        for (const contract of data?.data) {
          payAppendixs.push(...contract.appendix);
        }
        set({ payContracts: { data: payAppendixs, total: data.total } });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getNearExpiredContracts: async () => {
    try {
      const { data } = await API.get('/dashboard/contract/near-expired');

      if (data) {
        set({ nearExpiredContracts: data });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getCheckContracts: async () => {
    try {
      const { data } = await API.get('/dashboard/contract/check');

      if (data) {
        let checkAppendixs = [];
        for (const contract of data?.data) {
          checkAppendixs.push(...contract.appendix);
        }
        set({ checkContracts: { data: checkAppendixs, total: data?.total } });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getLatestSongs: async () => {
    try {
      const { data } = await API.get('/dashboard/song/latest');

      if (data) {
        set({ latestSongs: data });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getWaitingPlaylists: async () => {
    try {
      const { data } = await API.get('/dashboard/playlist?status[eq]=waiting');

      if (data) {
        set({ waitingPlaylists: data });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getStatistics: async () => {
    try {
      const data = await API.get('/dashboard/statistics');

      if (data) {
        set({ statistics: data.data });
      }
    } catch (data) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  changeContractStatus: async (id, status, liquidationFile) => {
    try {
      const { data } = await API.post(`/contract/${id}/change-status`, {
        status,
        liquidationFile
      });

      if (data) {
        set(({ expiredContracts }) => ({
          expiredContracts: expiredContracts.filter(
            contract => contract.id !== data.id
          )
        }));
        notification.success({
          message: 'Thay đổi trạng thái thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updatePlaylist: async params => {
    try {
      const { data } = await API.put(`/playlist/${params.id}`, params);

      if (data) {
        set(({ waitingPlaylists }) => ({
          waitingPlaylists: waitingPlaylists.filter(
            waitingPlaylists => waitingPlaylists.id !== data.id
          )
        }));
        notification.success({
          message: 'Cập nhật tuyển tập thành công!'
        });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
