import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { options } from 'utils/propTypes';

const { Option } = Select;

const NaviSelect = React.forwardRef(
  ({ placeHolder, options, handleChange, ...rest }, ref) => {
    return (
      <Select
        placeholder={placeHolder}
        onChange={handleChange}
        ref={ref}
        {...rest}
      >
        {options?.map((option, index) => (
          <Option value={option.value} key={index} disabled={option?.disabled}>
            {option.label}
          </Option>
        ))}
      </Select>
    );
  }
);

NaviSelect.propTypes = {
  placeHolder: PropTypes.string,
  options: options,
  handleChange: PropTypes.func
};

NaviSelect.defaultProps = {
  placeHolder: 'Vui lòng chọn'
};

export default NaviSelect;
