import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';

export const useReupVideo = create((set, get) => ({
  params: {},
  setParams: params => set({ params }),
  //* ********************* */
  //* NOTE: get reupVideos  */
  //* ********************* */
  reupVideos: [],
  totalPage: 1,
  total: 0,
  getReupVideos: async params => {
    try {
      const {
        data: { data, total }
      } = await API.get(`/reup/video`, {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });

      if (data) {
        set({
          reupVideos: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ********************** */
  //* NOTE: delete reupVideos  */
  //* ********************** */

  deleteReupVideos: async params => {
    try {
      const { data } = await API.delete('/reup/video', { data: params });

      if (data) {
        get().getReupVideos();

        return notification.success({
          message: 'Xoá reup video thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************ */
  //* NOTE: create reupVideos  */
  //* ************************ */

  createReupVideo: async params => {
    try {
      const { data } = await API.post('/reup/video', params);

      if (data) {
        await get().getReupVideos();

        notification.success({
          message: 'Tạo reup video thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch (e) {
      notification.error({
        message: e?.errorMessages?.message || DEFAULT_ERROR_MESSAGE
      });

      throw e;
    }
  },

  //* **************************** */
  //* NOTE: get and update reupVideo */
  //* **************************** */

  reupVideo: null,
  setReupVideo: reupVideo => set({ reupVideo }),
  resetReupVideo: () => set({ reupVideo: null }),
  getReupVideo: async id => {
    try {
      const { data } = await API.get(`/reup/video/${id}`);

      if (data) {
        set({ reupVideo: data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  updateReupVideo: async params => {
    try {
      const { data } = await API.put(`/reup/video/${params.id}`, params);

      if (data) {
        get().getReupVideos();

        notification.success({
          message: 'Cập nhật reup video thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForReupVideoType: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata/search', {
        params: { limit: 5, group: 'musicType', freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: upload youtube */
  //* ******************* */

  uploadReupVideo: async id => {
    try {
      const { data } = await API.get(`/reup/video/${id}/upload-youtube`);

      if (data) {
        get().getReupVideos();
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
