import AutoComplete from 'components/AutoComplete/AutoComplete';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useService } from 'stores/useService';
import { getSongWithSingerLabel } from 'utils/other';
import shallow from 'zustand/shallow';

const SongAutocompleteFilter = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { getSong, searchForSong } = useService(
    useCallback(
      ({ getSong, searchForSong }) => ({
        getSong,
        searchForSong
      }),
      []
    ),
    shallow
  );

  const form = useContext(FilterBoxFormContext);

  useEffect(() => {
    (async () => {
      const params = form.getFieldsValue();
      if (params.value) {
        const data = await getSong(params.value);
        setOptions(
          [data].map(({ id, songName }) => ({
            value: id,
            label: songName
          }))
        );
      }
    })();
  }, [form, getSong]);

  return (
    <AutoComplete
      placeHolder={placeHolder}
      handleSearch={searchForSong}
      defaultOption={options}
      labelKey="songName"
      valueKey="id"
      getLabel={getSongWithSingerLabel}
      {...props}
    />
  );
};

export default SongAutocompleteFilter;
