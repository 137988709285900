import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import RoleFormModal from 'pages/Role/components/RoleFormModal';
import UserFormModal from 'pages/User/components/UserFormModal';
import SongFormModal from 'pages/Song/components/SongFormModal';

import { ROLE_CHECK } from 'appConstants';

const UpdateButton = ({ from, name, id }) => {
  switch (true) {
    case from === ROLE_CHECK.ROLE:
      return (
        <RoleFormModal roleId={id}>
          <Typography.Text strong style={{ cursor: 'pointer' }}>
            {name} <EditOutlined />
          </Typography.Text>
        </RoleFormModal>
      );

    case from === ROLE_CHECK.USER:
      return (
        <UserFormModal userId={id}>
          <Typography.Text strong style={{ cursor: 'pointer' }}>
            {name} <EditOutlined />
          </Typography.Text>
        </UserFormModal>
      );

    case from === ROLE_CHECK.SONG:
      return (
        <SongFormModal songId={id}>
          <Typography.Text strong style={{ cursor: 'pointer' }}>
            {name} <EditOutlined />
          </Typography.Text>
        </SongFormModal>
      );

    case from === ROLE_CHECK.PARTNER:
      return (
        <Link to={`/chi-tiet-doi-tac/${id}`}>
          <Typography.Text
            strong
            style={{ width: 190 }}
            ellipsis={{ tooltip: name }}
          >
            {name} <EditOutlined />
          </Typography.Text>
        </Link>
      );

    default:
      return <></>;
  }
};

export default UpdateButton;
