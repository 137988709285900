import { Form, Modal, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useAddendumDetail } from 'stores/useAddendumDetail';
import { useService } from 'stores/useService';
import shallow from 'zustand/shallow';
import OriginSongFormModalContent from './OriginSongFormModalContent';

const OriginSongFormModal = ({ children, record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const {
    tempSongAddendum,
    findAndUpdateSongAddendum,
    findAndUpdateTempSongAddendum
  } = useAddendumDetail(
    useCallback(
      ({
        tempSongAddendum,
        findAndUpdateSongAddendum,
        findAndUpdateTempSongAddendum
      }) => ({
        tempSongAddendum,
        findAndUpdateSongAddendum,
        findAndUpdateTempSongAddendum
      }),
      []
    ),
    shallow
  );

  const { songsBySearch } = useService(
    useCallback(
      ({ songsBySearch }) => ({
        songsBySearch
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleSave = row => {
    if (row.id) {
      findAndUpdateSongAddendum({
        ...row,
        appendixId: id,
        songId: row.songId,
        index: tempSongAddendum.length,
        send: true
      });
    } else {
      findAndUpdateTempSongAddendum({
        ...row,
        appendixId: id,
        index: tempSongAddendum.length,
        send: true
      });
    }
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();
      const { originSongId } = params;
      const songSelected = songsBySearch.find(
        _song => _song.id === originSongId
      );

      handleSave({
        ...record,
        ...params,
        relatedRightSongId: songSelected?.id || '',
        relatedRightSong: songSelected
      });

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        destroyOnClose
        title={
          <Typography.Text>
            Bài hát gốc của bản phối | {record?.songName}
          </Typography.Text>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <OriginSongFormModalContent form={form} record={record} />
      </Modal>
    </>
  );
};

export default OriginSongFormModal;
