import { EditOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import React, { useCallback } from 'react';
import { usePartnerDetail } from 'stores/usePartnerDetail';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';
import CompanyFormModal from './CompanyFormModal';

const { Text } = Typography;

const CompanyDetail = () => {
  const { selectingCompany } = usePartnerDetail(
    useCallback(
      ({ selectingCompany }) => ({
        selectingCompany
      }),
      []
    ),
    shallow
  );

  const name = get(selectingCompany, 'name', null);
  const representationName = get(selectingCompany, 'representationName', null);
  const phone = get(selectingCompany, 'phone', null);
  const email = get(selectingCompany, 'email', null);
  const taxCode = get(selectingCompany, 'taxCode', null);
  const representationIdentityNumber = get(
    selectingCompany,
    'representationIdentityNumber',
    null
  );
  const address = get(selectingCompany, 'address', null);

  if (selectingCompany) {
    return (
      <Row className="deputy-info-detail-wrapper">
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Space>
                <Text strong>Tên công ty:</Text>
                <CompanyFormModal defaultValue={selectingCompany}>
                  <Text strong style={{ cursor: 'pointer' }}>
                    {name} <EditOutlined />
                  </Text>
                </CompanyFormModal>
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Họ tên người đại diện:</Text>
                {representationName}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Số điện thoại:</Text>
                {phone}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Email:</Text>
                {email}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Mã số thuế:</Text>
                {taxCode}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>CMND/CCCD người đại diện:</Text>
                {representationIdentityNumber}
              </Space>
            </Col>
            <Col span={8}>
              <Space>
                <Text strong>Địa chỉ:</Text>
                {address}
              </Space>
            </Col>
          </Row>
        </Col>
        {/* <Col span={4}>
          <Space direction="vertical" align="center">
            <Avatar
              size={96}
              src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSusoQHrrN1QD_DMEYP5yYn1bBI-oj7rnnA7WeK7429w1NyCeYV"
              icon={<UserOutlined />}
            />
            <Text strong>{name}</Text>
          </Space>
        </Col> */}
      </Row>
    );
  }

  return <></>;
};

export default CompanyDetail;
