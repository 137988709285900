import { Button, Card, Space, Tag, Typography } from 'antd';
import { DIGITAL_VALUE, TIKTOK_VALUE } from 'appConstants';
import React, { useCallback, useMemo } from 'react';

import { useSongRelease } from 'stores/useSongRelease';
import { formatDate, formatTime } from 'utils/date';
import shallow from 'zustand/shallow';
import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import GroupMailFormModal from '../GroupMailFormModal';

const ReviewMail = ({ currentSong, dataReview }) => {
  const { mailGroups } = useSongRelease(
    useCallback(
      ({ mailGroups }) => ({
        mailGroups
      }),
      []
    ),
    shallow
  );

  const mailGroup = useMemo(
    () => mailGroups.find(({ id }) => id === dataReview?.mailGroupId),
    [dataReview?.mailGroupId, mailGroups]
  );

  const labelFormMail = useMemo(() => {
    if (currentSong) {
      const { songName, singer } = currentSong;
      const shouldDisplay = dataReview?.songName || songName || null;
      return (
        <Typography.Text>
          Lịch phát hành {shouldDisplay && '| ' + shouldDisplay}{' '}
          {singer && '- ' + singer}
        </Typography.Text>
      );
    }
    return <Typography.Text>Lịch phát hành</Typography.Text>;
  }, [dataReview?.songName, currentSong]);

  return (
    <div>
      <Space size={0}>
        <Typography.Text strong>Review mail</Typography.Text>
      </Space>
      <Card bodyStyle={{ padding: 0 }} title={labelFormMail}>
        <div
          style={{ padding: '12px 24px', borderBottom: '1px solid #f0f0f0' }}
        >
          <Space direction="vertical">
            <div>
              <Typography.Text>Gửi tới:</Typography.Text>
            </div>
            <Space wrap={true}>
              {mailGroup?.mails.map((mail, index) => (
                <Tag key={index}>{mail}</Tag>
              ))}
              <GroupMailFormModal defaultValue={mailGroup}>
                <Button size="small" icon={<EditOutlined />}></Button>
              </GroupMailFormModal>
            </Space>
          </Space>
        </div>
        <div style={{ padding: '12px 24px' }}>
          <Space direction="vertical">
            {dataReview?.link && (
              <a href={dataReview?.link} target="_blank" rel="noreferrer">
                <LinkOutlined /> {dataReview?.link}
              </a>
            )}

            <Typography>{`Bài hát: ${
              dataReview?.songName || currentSong?.songName || ''
            }`}</Typography>
            <Typography>{`Ca sĩ: ${currentSong?.singer || ''}`}</Typography>
            <Typography>{`Sáng tác: ${
              currentSong?.musician || ''
            }`}</Typography>
            <Typography>{`Nhạc số: ${
              DIGITAL_VALUE[dataReview?.digitalMusic] || ''
            }`}</Typography>
            <Typography>{`Tiktok: ${
              TIKTOK_VALUE[dataReview?.tiktok] || ''
            }`}</Typography>
            <Typography>{`Ngày phát hành: ${
              dataReview?.releaseDate ? formatDate(dataReview?.releaseDate) : ''
            }`}</Typography>
            <Typography>{`Giờ phát hành: ${
              dataReview?.releaseTime ? formatTime(dataReview?.releaseTime) : ''
            }`}</Typography>
            <Typography>{`Kênh: ${
              dataReview?.publisherName || dataReview?.channel || ''
            }`}</Typography>
          </Space>
        </div>
      </Card>
    </div>
  );
};

export default ReviewMail;
