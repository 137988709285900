import {
  CloseOutlined,
  CloudSyncOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Row, Space, Tooltip } from 'antd';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { usePublisherStatistic } from 'stores/usePublisherStatistic';
import shallow from 'zustand/shallow';
import { StatisticEditableContext } from './Statistic';

const StatisticAction = ({ record }) => {
  const { id } = useParams();
  const form = useContext(StatisticEditableContext);
  const {
    updatePublisherStatistic,
    publisher,
    getChannelInfo,
    createPublisherStatistic,
    deletePublisherStatistic,
    editingKey,
    setEditingKey,
    setTableData,
    tableData
  } = usePublisherStatistic(
    useCallback(
      ({
        updatePublisherStatistic,
        publisher,
        getChannelInfo,
        createPublisherStatistic,
        deletePublisherStatistic,
        editingKey,
        setEditingKey,
        setTableData,
        tableData
      }) => ({
        updatePublisherStatistic,
        publisher,
        getChannelInfo,
        createPublisherStatistic,
        deletePublisherStatistic,
        editingKey,
        setEditingKey,
        setTableData,
        tableData
      }),
      []
    ),
    shallow
  );
  const isEditing = record => record.key === editingKey;
  const editable = isEditing(record);

  const resetForm = () => {
    setEditingKey('');
    form.resetFields();
  };

  const handleSave = async key => {
    try {
      const row = await form.validateFields();
      const year = moment(row.time).year();
      const month = moment(row.time).month();
      delete row.time;
      const params = { ...row, id, year, month };
      const res = key
        ? await updatePublisherStatistic({
            ...params,
            statisticreportid: key
          })
        : await createPublisherStatistic(params);
      if (res) resetForm();
    } catch (errInfo) {}
  };

  const onCancel = () => {
    setEditingKey('');
    setTableData(tableData.filter(_d => _d.key !== 0));
    form.resetFields();
  };

  const onEdit = record => {
    const { year, month } = record;
    const time = moment({ year, month });
    form.setFieldsValue({
      ...record,
      time
    });
    setEditingKey(record.key);
  };

  const SyncChannelInfo = async () => {
    const data = await getChannelInfo({
      code: publisher.code,
      providerId: publisher.providerId
    });
    form.setFieldsValue({
      views: data?.viewCount,
      subscribers: data?.subscriberCount
    });
  };

  const handleDelete = async key => {
    try {
      deletePublisherStatistic({ id, ids: [key] });
    } catch (error) {}
  };

  return editable ? (
    <Row justify="space-between" align="middle">
      <Space>
        <Button
          icon={<SaveOutlined />}
          onClick={() => handleSave(record.key)}
          type="primary"
        />
        <Button
          icon={<CloseOutlined />}
          onClick={onCancel}
          type="primary"
          danger
        />
      </Space>
      <Tooltip title={`Lấy dữ liệu từ kênh ${publisher.name}`}>
        <Button icon={<CloudSyncOutlined />} onClick={SyncChannelInfo} />
      </Tooltip>
    </Row>
  ) : (
    <Space>
      <Button
        icon={<EditOutlined />}
        disabled={editingKey !== ''}
        onClick={() => onEdit(record)}
        type="primary"
        ghost
      />
      <DeleteConfirmPopup
        title="Xoá thống kê số liệu?"
        onDelete={() => handleDelete(record.key)}
        disabled={editingKey !== ''}
      >
        <Button
          icon={<DeleteOutlined />}
          disabled={editingKey !== ''}
          type="primary"
          danger
          ghost
        />
      </DeleteConfirmPopup>
    </Space>
  );
};

export default StatisticAction;
