import { FILTER_TYPE } from 'appConstants';
import FilterBox from 'components/FilterBox';
import { filterToUrl, genInitValueCustom } from 'components/FilterBox/utils';
import SongAutocompleteFilter from 'containers/SongAutocompleteFilter';
import queryString from 'query-string';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useService } from 'stores/useService';
import { useUser } from 'stores/useUser';
import shallow from 'zustand/shallow';
import UserAutoComplete from './UserAutoComplete';

const filterItems = [
  {
    type: FILTER_TYPE.TEXT,
    formTitle: 'Link',
    name: 'Link'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Nhân viên cập nhật',
    name: 'UpdateBy',
    placeHolder: 'Tìm nhân viên',
    isUsingChild: true,
    label: 'fullName',
    autocompleteComponent: <UserAutoComplete />
  },
  {
    type: FILTER_TYPE.RANGE_PICKER,
    formTitle: 'Thời gian phát hành',
    name: 'PublishDate'
  },
  {
    type: FILTER_TYPE.SELECT,
    formTitle: 'Tuyển tập chứa bài hát',
    name: 'SongId',
    placeHolder: 'Tìm bài hát',
    isUsingChild: true,
    label: 'songName',
    autocompleteComponent: <SongAutocompleteFilter placeHolder="Tìm bài hát" />,
    otherType: true
  }
];

export const data = [
  {
    mainTitle: 'Tuyển tập',
    filterItems
  }
];

const PlaylistFilterBox = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);

  const { searchForUsers } = useUser(
    useCallback(
      ({ searchForUsers }) => ({
        searchForUsers
      }),
      []
    ),
    shallow
  );

  const { getSong } = useService(
    useCallback(
      ({ getSong }) => ({
        getSong
      }),
      []
    ),
    shallow
  );

  delete searchParams.page;
  delete searchParams.sort;

  const setFilter = (
    key,
    { value, operator, operatorFrom, operatorTo },
    otherType
  ) => {
    return filterToUrl(
      { key, value, operator, operatorFrom, operatorTo, otherType },
      searchParams,
      history,
      '/danh-sach-tuyen-tap'
    );
  };

  return (
    <FilterBox
      options={data}
      setFilter={setFilter}
      initValue={genInitValueCustom(searchParams, filterItems, {
        UpdateBy: searchForUsers,
        SongId: getSong
      })}
      getExtendOptions={{
        UpdateBy: searchForUsers,
        SongId: getSong
      }}
    />
  );
};

export default React.memo(PlaylistFilterBox);
