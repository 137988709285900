import React, { useCallback, useEffect } from 'react';
import { useDashboard } from 'stores/useDashboard';
import shallow from 'zustand/shallow';
import DashboardTable from '../DashboardTable';
import { columns } from './columns';

const ExpiredContract = () => {
  const { expiredContracts, getExpiredContracts } = useDashboard(
    useCallback(
      ({ expiredContracts, getExpiredContracts }) => ({
        expiredContracts,
        getExpiredContracts
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    getExpiredContracts();
  }, [getExpiredContracts]);

  return (
    <DashboardTable
      data={expiredContracts?.data?.map(contract => ({
        ...contract,
        key: contract.id
      }))}
      columns={columns}
      title="Hợp đồng hết hạn"
      total={expiredContracts?.total}
    />
  );
};

export default ExpiredContract;
