import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date';
import ChangeStatusButton from './ChangeStatusButton';

const { Text } = Typography;
export const columns = [
  {
    title: 'Mã hợp đồng',
    dataIndex: 'contractCode',
    ellipsis: true,
    render: (contractCode, { id }) => (
      <Link to={`/chi-tiet-hop-dong/${id}/soan-hop-dong`}>
        <Typography.Text
          style={{ width: '100%' }}
          ellipsis={{ tooltip: contractCode }}
          strong
        >
          #{contractCode}
        </Typography.Text>
      </Link>
    )
  },
  {
    title: 'Ngày hết hạn',
    dataIndex: 'finishDate',
    width: '120px',
    render: finishDate => <Text>{finishDate && formatDate(finishDate)}</Text>
  },
  {
    title: 'Thao tác',
    dataIndex: 'status',
    width: '120px',
    render: (_, contract) => <ChangeStatusButton contract={contract} />
  }
];
