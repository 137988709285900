import { Col, Row, Typography } from 'antd';
import ContractStatusTag from 'containers/ContractStatusTag';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date';

const { Text } = Typography;
export const columns = [
  {
    title: 'Mã hợp đồng',
    dataIndex: 'contractCode',
    ellipsis: true,
    render: (contractCode, { status, id }) => (
      <Row>
        <Col span={24}>
          <Link to={`/chi-tiet-hop-dong/${id}/soan-hop-dong`}>
            <Typography.Text
              style={{ width: '100%' }}
              ellipsis={{ tooltip: contractCode }}
              strong
            >
              #{contractCode}
            </Typography.Text>
          </Link>
        </Col>
        <ContractStatusTag value={status} />
      </Row>
    )
  },
  {
    title: 'Đối tác',
    dataIndex: 'partner',
    width: '150px',
    render: partner =>
      partner ? (
        <Text>{partner?.artistName || partner?.fullName}</Text>
      ) : (
        <Text type="danger">Chưa có</Text>
      )
  },
  {
    title: 'khởi tạo',
    dataIndex: 'createDate',
    width: '120px',
    render: createDate => <Text>{formatDate(createDate)}</Text>
  }
];
