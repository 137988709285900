import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { extractPartnersFromSong } from 'utils/other';

const SongOfPlaylist = ({ songs, onDelete, onChangeSongStatus }) => {
  const SongItem = ({ song, index }) => {
    // eslint-disable-next-line no-unused-vars
    const [_, singers] = extractPartnersFromSong(song?.song?.partnerSong, true);
    const singer =
      song?.song?.singer || singers.map(({ label }) => label).join(', ');
    return (
      <Draggable draggableId={song.songId} index={index}>
        {provided => (
          <Row
            gutter={16}
            align="middle"
            ref={provided.innerRef}
            wrap={false}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Col flex="auto">
              <Row
                justify="space-between"
                align="middle"
                className="playlist-item"
                wrap={false}
                gutter={16}
              >
                <Col flex="auto">
                  <Row align="middle" gutter={16} wrap={false}>
                    <Col>
                      <MenuOutlined />
                    </Col>
                    <Col>
                      <Row>
                        <Col span={24}>
                          <Typography.Text>
                            {song.song?.songName}
                          </Typography.Text>
                        </Col>
                        <Col span={24}>
                          <Typography.Text
                            type="secondary"
                            style={{ width: '100%' }}
                            ellipsis={{ tooltip: singer }}
                          >
                            {singer}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col flex={'none'}>{song.song?.songTypeName}</Col>
              </Row>
            </Col>

            <Col>
              <Button
                size="small"
                ghost
                danger
                onClick={() => onDelete(song.songId)}
                icon={<DeleteOutlined />}
              ></Button>
            </Col>
          </Row>
        )}
      </Draggable>
    );
  };

  return (
    !!songs?.length &&
    songs?.map((song, index) => (
      <SongItem song={song} index={index} key={song.songId} />
    ))
  );
};

export default SongOfPlaylist;
