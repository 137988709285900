import { Tag } from 'antd';
import { MEDIA_TYPE, MEDIA_TYPE_VALUE } from 'appConstants';
import React from 'react';
import { FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons';

const MediaTypeTag = ({ mediaType }) => {
  return mediaType ? (
    <Tag color="geekblue" style={{ marginRight: 0 }}>
      {mediaType === MEDIA_TYPE.IMAGE ? (
        <FileImageOutlined />
      ) : (
        <VideoCameraOutlined />
      )}{' '}
      {MEDIA_TYPE_VALUE[mediaType]}
    </Tag>
  ) : null;
};

export default MediaTypeTag;
