import { Col, Form, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { extractPartnersFromSong } from 'utils/other';
import OriginSongAutocomplete from './OriginSongAutocomplete';

const { Text } = Typography;

const OriginSongFormModalContent = ({ form, record }) => {
  const { relatedRightSong } = record;
  const [currentSong, setCurrentSong] = useState(relatedRightSong);

  useEffect(() => {
    if (relatedRightSong) {
      form.setFieldsValue({ originSongId: relatedRightSong?.id });
    }
  }, [form, relatedRightSong]);

  const selectCallback = song => {
    setCurrentSong(song);
  };

  const [musicians, singers] = extractPartnersFromSong(
    currentSong?.partnerSong,
    true
  );

  const musician = musicians.map(({ label }) => label).join(', ');
  const singer = singers.map(({ label }) => label).join(', ');

  const onClear = () => {
    setCurrentSong(null);
  };

  return (
    <Form form={form} layout="vertical">
      <Row>
        <Col span={24}>
          <Form.Item name="originSongId" label="Bài hát gốc">
            <OriginSongAutocomplete
              form={form}
              selectCallback={selectCallback}
              song={relatedRightSong}
              onClear={onClear}
            />
          </Form.Item>
        </Col>
        {currentSong && (
          <>
            <Col span={24}>
              <Text>Thể loại: {currentSong?.songTypeName}</Text>
            </Col>
            <Col span={24}>
              <Text>Ca sĩ: {singer}</Text>
            </Col>
            <Col span={24}>
              <Text>Tác giả: {musician}</Text>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default OriginSongFormModalContent;
