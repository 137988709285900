import { LockOutlined } from '@ant-design/icons';
import { Form, Input as AntdInput, Typography } from 'antd';
import React from 'react';

const ChangePasswordFormModalContent = ({ form, fullName }) => {
  return (
    <Form form={form} layout="vertical">
      <Form.Item>
        <Typography.Text strong>{fullName}</Typography.Text>
      </Form.Item>
      <Form.Item
        name="newPassword"
        label="Mật khẩu mới"
        rules={[{ required: true, message: 'Nhập mật khẩu mới!' }]}
      >
        <AntdInput.Password prefix={<LockOutlined />} />
      </Form.Item>
      <Form.Item
        name="retypeNewPassword"
        label="Nhập lại mật khẩu mới"
        dependencies={['newPassword']}
        rules={[
          { required: true, message: 'Nhập lại mật khẩu mới!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Hai mật khẩu không trùng khớp!')
              );
            }
          })
        ]}
      >
        <AntdInput.Password prefix={<LockOutlined />} />
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordFormModalContent;
