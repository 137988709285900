import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useReupHotkey } from 'stores/useReup/useReupHotkey';

const ReupHotkeyAutoComplete = ({ reupHotkey = [], ...props }) => {
  const { searchForReupHotkey } = useReupHotkey(
    useCallback(
      ({ searchForReupHotkey }) => ({
        searchForReupHotkey
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      reupHotkey
        ? Array.isArray(reupHotkey)
          ? reupHotkey.map(item => ({
              value: item.id,
              label: item.title
            }))
          : { value: reupHotkey.id, label: reupHotkey.title }
        : null,
    [reupHotkey]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm spectrum"
      handleSearch={searchForReupHotkey}
      labelKey="title"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ReupHotkeyAutoComplete;
