import { REUP_TYPE } from 'appConstants';
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

const ReupBackground = React.lazy(() => import('./ReupBackground'));
const ReupEffect = React.lazy(() => import('./ReupEffect'));
const ReupHotkey = React.lazy(() => import('./ReupHotkey'));
const ReupSong = React.lazy(() => import('./ReupSong'));
const ReupSpectrum = React.lazy(() => import('./ReupSpectrum'));
const ReupTag = React.lazy(() => import('./ReupTag'));
const ReupVideo = React.lazy(() => import('./ReupVideo'));
const ReupMusicType = React.lazy(() => import('./ReupMusicType'));

const Reup = () => {
  const { type } = useParams();

  const render = () => {
    switch (type) {
      case REUP_TYPE.BG:
        return <ReupBackground />;
      case REUP_TYPE.EF:
        return <ReupEffect />;
      case REUP_TYPE.HK:
        return <ReupHotkey />;
      case REUP_TYPE.SG:
        return <ReupSong />;
      case REUP_TYPE.ST:
        return <ReupSpectrum />;
      case REUP_TYPE.TG:
        return <ReupTag />;
      case REUP_TYPE.VD:
        return <ReupVideo />;
      case REUP_TYPE.MT:
        return <ReupMusicType />;
      default:
        return <></>;
    }
  };

  return <Suspense fallback={<div>Loading...</div>}>{render()}</Suspense>;
};

export default Reup;
