import { Typography } from 'antd';
import { PAYMENT_STATUS } from 'appConstants';
import React, { useCallback } from 'react';

const PaymentStatusTag = ({ value }) => {
  const generateType = useCallback(() => {
    switch (value) {
      case 'undue':
        return 'warning';

      case 'waiting':
        return 'danger';

      case 'paid':
        return 'success';

      default:
        return '';
    }
  }, [value]);

  return (
    <Typography.Text type={generateType()}>
      {PAYMENT_STATUS[value]}
    </Typography.Text>
  );
};

export default PaymentStatusTag;
