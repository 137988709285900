import { Col, Empty, Row, Typography } from 'antd';
// import { ROLE_LABEL } from 'appConstants';
// import Checkbox from 'components/Checkbox';
import React, { useCallback } from 'react';
import { useCurrentUser } from 'stores/useCurrentUser';

import shallow from 'zustand/shallow';
// import { getPermissionKeys } from 'pages/Role/utils';
import MetaCard from 'components/MetaCard';

const RoleInfo = () => {
  //NOTE: need to be re-do
  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  // const generateContent = () =>
  //   getPermissionKeys(currentUserRole, true).map(key => (
  //     <Col key={key} span={6}>
  //       <Row>
  //         <Col span={12}>
  //           <Typography.Paragraph>{ROLE_LABEL[key]}:</Typography.Paragraph>
  //         </Col>
  //         <Col span={12}>
  //           {<Checkbox disabled checked={currentUserRole[key]} />}
  //         </Col>
  //       </Row>
  //     </Col>
  //   ));

  return (
    <>
      <Typography.Text strong>
        Tên vai trò: {currentUser?.sysRole?.name}
      </Typography.Text>

      <MetaCard title="Quyền của vai trò" style={{ marginTop: '24px' }}>
        <Row align="middle" justify="center">
          <Col>
            <Empty
              description="Đang hoàn thiện"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </Col>
        </Row>
      </MetaCard>
    </>
  );
};

export default RoleInfo;
