import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';
import { calculatePercent } from 'utils/other';
import { generateFileDownload } from 'utils/redirect';
import { useCurrentUser } from './useCurrentUser';

export const useAddendumDetail = create((set, get) => ({
  //NOTE: get addendum
  addendum: null,
  contractUseInAddendumDetail: null,
  disableEditAddendum: false,
  disableEditAndAddPayment: false,
  isExportLoading: false,
  isAppendixLoading: false,
  mapAllRevenueSharingType: (value, code) => {
    const parserSongAddendum = get().songAddendum.map(song => ({
      ...song,
      [code]: value,
      send: true
    }));
    const parserTempSongAddendum = get().tempSongAddendum.map(song => ({
      ...song,
      [code]: value,
      send: true
    }));
    set({
      songAddendum: parserSongAddendum,
      tempSongAddendum: parserTempSongAddendum
    });
  },
  changeAddendumStatus: async (id, status) => {
    try {
      const { data } = await API.post(`/appendix/${id}/change-status`, {
        status
      });

      if (data) {
        await get().getAddendum(id);

        notification.success({
          message: 'Thay đổi trạng thái thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getContractUseInAddendumDetail: async id => {
    try {
      const { data } = await API.get(`/contract/${id}`);
      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getAddendum: async id => {
    set({ isAppendixLoading: true });
    try {
      get().resetPayment();
      get().resetTempPayment();

      const { data } = await API.get(`/appendix/${id}`);
      set({ isAppendixLoading: false });
      if (data) {
        const contractUseInAddendumDetail =
          await get().getContractUseInAddendumDetail(data.contractId);
        await get().getSongAddendum(data.id, contractUseInAddendumDetail);
        const currentUserActions = useCurrentUser.getState().currentUserActions;

        const isAnywayRole = currentUserActions.some(
          action => action.key === 'Permission.Appendix.Update.Anyway'
        );

        set({
          addendum: data,
          contractUseInAddendumDetail,
          // disableEditAndAddPayment: !(
          //   data.status === 'compose' && data.index01 === data.index02
          // ),
          disableEditAndAddPayment: !(
            (data.canAddPayment && data.status === 'compose') ||
            (data.canAddPayment && isAnywayRole)
          ),
          disableEditAndAddAddendumSong:
            data.status !== 'compose' && !isAnywayRole,
          disableEditAddendum: data.status !== 'compose',
          payment: data.payment.map(item => ({
            ...item,
            appendixId: id
          }))
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isAppendixLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateAddendum: async (id, params) => {
    set({ isAppendixLoading: true });
    try {
      const { data } = await API.put(`/appendix/${id}`, params);
      set({ isAppendixLoading: false });
      if (data) {
        await get().getAddendum(id);
        notification.success({
          message: 'Chỉnh sửa phụ lục thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isAppendixLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************* */
  //* NOTE: Update appendix note */
  //* ************* */

  updateAppendixNote: async (id, params) => {
    try {
      const { data } = await API.put(`/appendix/${id}/note`, params);
      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************* */
  //* NOTE: payment */
  //* ************* */
  payment: [],
  resetPayment: () => set({ tempPayment: [] }),
  findAndUpdatePayment: item => {
    const newArr = [...get().payment];
    const indexToReplace = newArr.findIndex(({ id }) => id === item.id);
    newArr[indexToReplace] = item;
    return set({ payment: newArr });
  },
  tempPayment: [],
  pushTempPayment: tempPayment =>
    set({ tempPayment: [...get().tempPayment, tempPayment] }),
  resetTempPayment: () => set({ tempPayment: [] }),
  findAndUpdateTempPayment: item => {
    const newArr = [...get().tempPayment];
    newArr[item.index] = item;
    return set({ tempPayment: newArr });
  },
  paymentToDelete: [],
  resetPaymentToDelete: () => set({ paymentToDelete: [] }),
  prevDeletePayment: async item => {
    set({ diasbleSortSong: true });
    if (item.id) {
      const newArr = get().payment.filter(({ id }) => id !== item.id);
      const itemToPush = get().payment.find(({ id }) => id === item.id);
      set({
        payment: newArr,
        paymentToDelete: [...get().paymentToDelete, itemToPush]
      });
    } else {
      const newArr = get().tempPayment.filter(
        ({ index }) => index !== item.index
      );

      set({ tempPayment: newArr });
    }
  },
  getTotalMoney: () => {
    const isPartnerOrganization =
      get().contractUseInAddendumDetail?.partner?.masterData?.code ===
      'organization';

    const newArr = [...get().payment, ...get().tempPayment];

    return newArr.length === 0
      ? 0
      : newArr
          .map(item =>
            isPartnerOrganization ? item.takeHomeMoney : item.totalMoney
          )
          .reduce((acc, cur) => acc + cur);
  },
  getAdvancedMoney: () => {
    const newArr = [...get().payment, ...get().tempPayment];

    if (newArr.length > 1) {
      const minCordinal = Math.min(...newArr.map(({ ordinal }) => ordinal));
      const index = newArr.findIndex(({ ordinal }) => ordinal === minCordinal);

      return newArr[index]?.totalMoney || 0;
    }

    return 0;
  },
  createPayment: async params => {
    try {
      const { data } = await API.post('/payment', params);
      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updatePayment: async params => {
    try {
      const { data } = await API.put('/payment', params);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  processAllPayment: async () => {
    try {
      const addPaymentPromises = get()
        .tempPayment.filter(params => params.send)
        .map(params =>
          API.post('/payment', {
            ...params,
            percent: calculatePercent(params.totalMoney, get().getTotalMoney())
          })
        );

      const updatePaymentPromises = get()
        .payment.filter(params => params.send)
        .map(params =>
          API.put(`/payment/${params.id}`, {
            ...params,
            percent: calculatePercent(params.totalMoney, get().getTotalMoney())
          })
        );
      const deletePromises = get().paymentToDelete.map(({ id }) =>
        API.delete(`/payment/${id}`)
      );

      const data = await Promise.all([
        ...addPaymentPromises,
        ...updatePaymentPromises,
        ...deletePromises
      ]);

      if (data) {
        notification.success({
          message: 'Chỉnh sửa đợt thanh toán thành công!'
        });

        const addendumId = get().addendum.id;

        await get().updateAddendum(addendumId, {
          totalMoney: get().getTotalMoney(),
          advanceMoney: get().getAdvancedMoney(),
          index01: get().addendum.index01,
          index02: get().addendum.index02
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  calculateTotalMoney: async params => {
    try {
      const { data } = await API.post('/payment/calculate', params);
      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: addendum song */
  //* ******************* */

  songAddendum: [],
  resetSongAddendum: () => set({ songAddendum: [] }),
  disableEditAndAddAddendumSong: false,
  findAndUpdateSongAddendum: async item => {
    set({ diasbleSortSong: true });
    const newArr = [...get().songAddendum];

    const indexToReplace = newArr.findIndex(
      ({ id }) => id === (item?.medleyId ? item?.medleyId : item.id)
    );
    const _item = newArr[indexToReplace];
    const songWithMedley = {
      ..._item,
      songMedley: _item?.songMedley?.map(_song =>
        _song.id === item.id ? item : _song
      )
    };
    newArr[indexToReplace] = item?.medleyId
      ? { ...songWithMedley, send: item?.send }
      : item;
    return set({ songAddendum: newArr });
  },

  tempSongAddendum: [],
  pushTempSongAddendum: tempSongAddendum => {
    set({ diasbleSortSong: true });
    set({ tempSongAddendum: [...get().tempSongAddendum, tempSongAddendum] });
  },
  resetTempSongAddendum: () => set({ tempSongAddendum: [] }),
  tempSortSongAddendum: [],
  pushTempSortSongAddendum: tempSortSongAddendum =>
    set({ tempSortSongAddendum }),

  sortSongAddendum: [],
  pushSortSongAddendum: sortSongAddendum => set({ sortSongAddendum }),
  diasbleSortSong: false,

  orderSongAppendix: async (params, hasOrder = true, callback = f => f) => {
    try {
      const { data } = await API.put(
        `/appendix/${params.appendixId}/song/order`,
        params
      );
      if (data) {
        callback();
        if (hasOrder) {
          await get().getAddendum(params.appendixId);

          notification.success({
            message: 'Sắp xếp bài hát thành công!'
          });
          return data;
        }
        return null;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  findAndUpdateTempSongAddendum: item => {
    set({ diasbleSortSong: true });
    const newArr = [...get().tempSongAddendum];

    const indexToReplace = newArr.findIndex(
      ({ songId }) => songId === item.songId
    );

    newArr[indexToReplace] = item;
    return set({ tempSongAddendum: newArr });
  },
  songAddendumToDelete: [],
  resetSongAddendumToDelete: () => set({ songAddendumToDelete: [] }),
  prevDeleteSongAddendum: async item => {
    set({ diasbleSortSong: true });
    if (item.id) {
      const newArr = get().songAddendum.filter(({ id }) => id !== item.id);
      const itemToPush = get().songAddendum.find(({ id }) => id === item.id);
      set({
        songAddendum: newArr,
        songAddendumToDelete: [...get().songAddendumToDelete, itemToPush]
      });
    } else {
      const newArr = get().tempSongAddendum.filter(
        ({ songId }) => songId !== item.songId
      );

      set({ tempSongAddendum: newArr });
    }
  },
  deleteMultiSongAddendum: async ids => {
    set({ diasbleSortSong: true });
    ids.forEach(id => {
      const isTempSong = get().tempSongAddendum.some(
        ({ songId }) => songId === id
      );
      if (isTempSong) {
        const newArr = get().tempSongAddendum.filter(
          ({ songId }) => songId !== id
        );

        set({ tempSongAddendum: newArr });
      } else {
        const newArr = get().songAddendum.filter(song => song.id !== id);
        const itemToPush = get().songAddendum.find(song => song.id === id);
        set({
          songAddendum: newArr,
          songAddendumToDelete: [...get().songAddendumToDelete, itemToPush]
        });
      }
    });
  },

  isSongAppendixLoading: false,
  processAllSongAddendum: async () => {
    set({ isSongAppendixLoading: true });
    try {
      const putPostPromises = [...get().songAddendum, ...get().tempSongAddendum]
        .filter(params => params.send)
        .map(params => {
          return API.put(
            `/appendix/${params.appendixId}/song/${params.songId}`,
            {
              ...params,
              songBusiness:
                get().contractUseInAddendumDetail.revenueSharing.map(
                  ({ businessType: { code, id } }) => ({
                    value: params[code],
                    businessTypeId: id
                  })
                )
            }
          );
        });

      // Check list song to delete isExist in list to put promise
      const _songAddendumToDelete = await get().songAddendumToDelete.filter(
        _song =>
          !get()
            .tempSongAddendum.map(_temp => _temp.songId)
            .includes(_song.songId)
      );
      const deletePromises = _songAddendumToDelete.map(params => {
        return API.delete(`/appendix/${params.appendixId}/song/${params.id}`);
      });

      const data = await Promise.all([...putPostPromises, ...deletePromises]);
      set({ isSongAppendixLoading: false });

      if (data) {
        notification.success({
          message: 'Chỉnh sửa bài hát phụ lục thành công!'
        });

        const addendumId = get().addendum.id;

        await get().orderSongAppendix(
          {
            appendixId: addendumId,
            songIds: [...get().songAddendum, ...get().tempSongAddendum].map(
              song => song.id || song.songId
            )
          },
          false
        );

        await get().getAddendum(addendumId);
        set({ diasbleSortSong: false });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isSongAppendixLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  //--------------------------------------------------------------------------------------//
  //                              Ge appendix song - index02                              //
  //--------------------------------------------------------------------------------------//

  getSongAddendum: async (id, contract) => {
    set({ isSongAppendixLoading: true });
    get().resetSongAddendum();
    get().resetTempSongAddendum();
    get().resetSongAddendumToDelete();

    try {
      const {
        data: { data }
      } = await API.get(`/appendix/${id}/index02`, {
        params: { PageSize: 10000 }
      });

      set({ isSongAppendixLoading: false });

      const flatSongBusiness = (songBusiness, revenueSharing) => {
        const res = {};

        revenueSharing.forEach(({ businessType }) => {
          const found = songBusiness.find(
            ({ businessType: theOneWithValue }) =>
              theOneWithValue.code === businessType.code
          );

          const value = found ? found?.value : null;
          res[businessType.code] = value;
        });

        return res;
      };

      contract = contract || get().contractUseInAddendumDetail;
      const revenueSharing = contract?.revenueSharing || [];

      if (data) {
        set({
          songAddendum: data.map(({ appendixDetail, ...rest }) => ({
            ...appendixDetail,
            ...flatSongBusiness(appendixDetail.songBusiness, revenueSharing),
            ...rest
          }))
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isSongAppendixLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  searchForAddendumSong: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/song/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  getArtistContract: async songId => {
    try {
      const {
        data: { data }
      } = await API.get(
        `/song/${songId}/musician/contract?contractType[eq]=BQTG&pageSize=100`
      );

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  // send review appendix
  sendReviewInvitation: async params => {
    try {
      const { data } = await API.post(
        '/notification/send-review-invitation',
        params
      );
      if (data) {
        notification.success({
          message: 'Đã gửi lời mời reivew thành công!'
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getExportAppendix: async appendixId => {
    try {
      set({ isExportLoading: true });
      const { data } = await API.get(`/excel/export-appendix/${appendixId}`);

      if (data) {
        generateFileDownload(data?.link);
        set({ isExportLoading: false });
        return data;
      }

      set({ isExportLoading: false });
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
