import { GlobalOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';
import React from 'react';

const PublisherInfo = ({ publisher }) => {
  return (
    <Space>
      <Tag
        color="purple"
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: 16,
          padding: 4
        }}
        icon={<GlobalOutlined />}
      >
        {publisher?.name} - {publisher?.provider?.name}
      </Tag>
      <Tag
        color="geekblue"
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 16,
          padding: 4
        }}
      >
        {publisher?.code}
      </Tag>
    </Space>
  );
};

export default PublisherInfo;
