import React, { useCallback, useMemo, useState } from 'react';
import shallow from 'zustand/shallow';

import { Modal, Form } from 'antd';

import { useUser } from 'stores/useUser';
import UserFormModalContent from './UserFormModalContent';

const UserFormModal = ({ children, userId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { createUser, updateUser } = useUser(
    useCallback(
      ({ createUser, updateUser }) => ({
        createUser,
        updateUser
      }),
      []
    ),
    shallow
  );

  const isUpdateUser = useMemo(() => !!userId, [userId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const params = await form.validateFields();
      if (userId) {
        await updateUser({ id: userId, ...params });
      } else {
        await createUser(params);
      }

      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        destroyOnClose
        title={isUpdateUser ? 'Chỉnh sửa nhân viên' : 'Tạo mới nhân viên'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <UserFormModalContent
          form={form}
          userId={userId}
          isUpdateUser={isUpdateUser}
        />
      </Modal>
    </>
  );
};

export default UserFormModal;
