import { Space, Typography } from 'antd';
import { EDITABLE_CELL_TYPE } from 'appConstants';
import moment from 'moment';
import React from 'react';
import { formatPrice, formatView } from 'utils/currency';
import { formatMonth } from 'utils/date';
import StatisticAction from './components/StatisticAction';

const spaceProps = {
  direction: 'vertical',
  size: null,
  style: { marginRight: '10px' }
};

export const columns = [
  {
    title: 'Thời gian',
    dataIndex: 'time',
    editable: EDITABLE_CELL_TYPE.DATEPICKER,
    width: 200,
    required: true,
    fixed: 'left',
    render: (_, { year, month }) => {
      return (
        <Typography.Text strong>
          {formatMonth(moment({ year, month }))}
        </Typography.Text>
      );
    }
  },
  {
    title: 'Lượt xem',
    dataIndex: 'views',
    required: true,
    editable: EDITABLE_CELL_TYPE.CURRENCY,
    width: 150,
    render: views => <Typography.Text>{formatView(views)}</Typography.Text>
  },
  {
    title: 'Lượt đăng ký',
    dataIndex: 'subscribers',
    required: true,
    editable: EDITABLE_CELL_TYPE.CURRENCY,
    width: 150,
    render: subscribers => (
      <Typography.Text>{formatView(subscribers)}</Typography.Text>
    )
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tiền thực nhận</span>
        <span>(đơn vị: vnđ)</span>
      </Space>
    ),
    labelValid: 'Tiền thực nhận',
    dataIndex: 'netIncome',
    editable: EDITABLE_CELL_TYPE.CURRENCY,
    required: true,
    width: 150,
    render: netIncome => (
      <Typography.Text>{formatPrice(netIncome)}</Typography.Text>
    )
  },
  {
    title: (
      <Space {...spaceProps}>
        <span>Tiền trước thuế</span>
        <span>(đơn vị: vnđ)</span>
      </Space>
    ),
    labelValid: 'Tiền trước thuế',
    dataIndex: 'grossIncome',
    editable: EDITABLE_CELL_TYPE.CURRENCY,
    required: false,
    width: 150,
    render: grossIncome => (
      <Typography.Text>{formatPrice(grossIncome)}</Typography.Text>
    )
  },

  {
    title: 'Ghi chú',
    dataIndex: 'note',
    editable: EDITABLE_CELL_TYPE.TEXT,
    required: false,
    width: 200,
    render: note => (
      <Typography.Paragraph
        style={{ width: 200, margin: 0 }}
        ellipsis={{ tooltip: note, rows: 2 }}
      >
        {note}
      </Typography.Paragraph>
    )
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate =>
      createDate && (
        <Space direction="vertical">
          <Typography.Text>
            {moment(createDate).format('DD/MM/YYYY hh:mm')}
          </Typography.Text>

          <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
        </Space>
      ),
    width: 150
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    render: updateDate =>
      updateDate && (
        <Space direction="vertical">
          <Typography.Text>
            {moment(updateDate).format('DD/MM/YYYY hh:mm')}
          </Typography.Text>

          <Typography.Text>{moment(updateDate).fromNow()}</Typography.Text>
        </Space>
      ),
    width: 150
  },
  {
    title: 'Thao tác',
    dataIndex: 'operation',
    width: 200,
    render: (_, record) => <StatisticAction record={record} />
  }
];
