import { Card, Col, Form, Row } from 'antd';
import Button from 'components/Button';
import Input from 'components/Input';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { usePartnerDetail } from 'stores/usePartnerDetail';
import { get } from 'utils/lodash';
import shallow from 'zustand/shallow';
import BankNameAutoComplete from './BankNameAutoComplete';

// import Select from 'components/Select';

const BankingInfo = ({ isUpdatePartner }) => {
  const { createPartnerBankAccount, partner, updatePartnerBankAccount } =
    usePartnerDetail(
      useCallback(
        ({ createPartnerBankAccount, partner, updatePartnerBankAccount }) => ({
          createPartnerBankAccount,
          partner,
          updatePartnerBankAccount
        }),
        []
      ),
      shallow
    );
  const { id } = useParams();
  const [form] = Form.useForm();

  const haveBankAccount = useMemo(
    () => partner?.bankAccount?.id,
    [partner?.bankAccount?.id]
  );

  const onSave = async () => {
    try {
      const values = await form.validateFields();

      if (haveBankAccount) {
        updatePartnerBankAccount(
          { ...values, note: '_', evidence: '_' },
          id,
          partner?.bankAccount?.id
        );
      } else {
        createPartnerBankAccount(values, id);
      }
    } catch ({ data }) {}
  };

  useEffect(() => {
    if (partner && isUpdatePartner) {
      const { bankAccount } = partner;

      const bankName = get(bankAccount, 'bankName', null);
      const accountNumber = get(bankAccount, 'accountNumber', null);
      const accountName = get(bankAccount, 'accountName', null);
      const bankBranch = get(bankAccount, 'bankBranch', null);

      form.setFieldsValue({
        bankName,
        accountNumber,
        accountName,
        bankBranch
      });
    }
  }, [partner, form, isUpdatePartner]);

  return (
    <Card title="Thông tin thanh toán" className="mb-md">
      <Form form={form}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              name="bankName"
              label="Ngân hàng"
              rules={[{ required: true, message: 'Chọn ngân hàng!' }]}
            >
              <BankNameAutoComplete />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="accountNumber"
              label="Số tài khoản"
              rules={[{ required: true, message: 'Nhập số tài khoản!' }]}
            >
              <Input type="number" style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="accountName"
              label="Tên chủ tài khoản"
              rules={[{ required: true, message: 'Nhập tên chủ tài khoản!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="bankBranch"
              label="Chi nhánh"
              rules={[{ required: true, message: 'Nhập chi nhánh!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" style={{ marginTop: 16 }}>
          <Button type="primary" onClick={onSave}>
            Lưu
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default BankingInfo;
