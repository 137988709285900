import { MenuOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const SongItemWrapper = ({ songs }) => {
  const SongItem = ({ song, index }) => {
    return (
      <Draggable draggableId={song.songId} index={index}>
        {provided => (
          <Row
            gutter={16}
            align="middle"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="sort-song-item"
          >
            <Col>
              <Typography.Text strong>{index + 1}</Typography.Text>
            </Col>
            <Col flex="auto">
              <Row justify="space-between">{song.songName}</Row>
            </Col>
            <Col>
              <MenuOutlined />
            </Col>
          </Row>
        )}
      </Draggable>
    );
  };

  return (
    !!songs?.length &&
    songs?.map((song, index) => (
      <SongItem song={song} index={index} key={song.songId} />
    ))
  );
};

export default SongItemWrapper;
