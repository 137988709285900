import { Input } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const NaviTextArea = ({ children, rows, ...rest }) => {
  return <TextArea rows={rows} {...rest} />;
};

NaviTextArea.propTypes = {
  rows: PropTypes.number
};

NaviTextArea.defaultProps = {
  rows: 4
};

export default NaviTextArea;
