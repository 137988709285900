import { Col, Divider, Row, Space, Typography } from 'antd';
import { GENDER_DICTIONARY } from 'appConstants';
import TextContent from 'components/TextContent';
import UserAvatar from 'components/UserAvatar';
import React from 'react';

const { Text } = Typography;

const PartnerInfo = ({ partner }) => {
  return (
    <div>
      <Divider orientation="left">
        <Text type="success">Thông tin đối tác</Text>
      </Divider>
      <Row align="middle">
        <Col span={20}>
          <Row gutter={[32, 16]}>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Họ tên:</Text>
                <TextContent value={partner?.fullName} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Ngày sinh:</Text>
                <TextContent value={partner?.dateOfBirth} isDate />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Nghệ danh:</Text>
                {/* <Text strong>{partner?.artistName}</Text> */}
                <TextContent value={partner?.artistName} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>CMND/CCCD:</Text>
                <TextContent value={partner?.identityNumber} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Ngày cấp:</Text>
                <TextContent value={partner?.dateOfIdentification} isDate />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Nơi cấp:</Text>
                <TextContent value={partner?.placeOfIdentification} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Mã số thuế:</Text>
                <TextContent value={partner?.taxCode} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Số điện thoại:</Text>
                <TextContent value={partner?.phone} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Giới tính:</Text>
                <Text strong>{GENDER_DICTIONARY[partner?.gender]}</Text>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Địa chỉ:</Text>
                <TextContent value={partner?.address} />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" size={0}>
                <Text>Email:</Text>
                <TextContent value={partner?.email} />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row justify="center">
            <UserAvatar
              fullName={partner?.fullName}
              avatar={partner?.avatar}
              size={100}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PartnerInfo;
