import AutoComplete from 'components/AutoComplete/AutoComplete';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useService } from 'stores/useService';
import shallow from 'zustand/shallow';

const PublisherAutocompleteFilter = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { getPublisher, searchForPublisher } = useService(
    useCallback(
      ({ getPublisher, searchForPublisher }) => ({
        getPublisher,
        searchForPublisher
      }),
      []
    ),
    shallow
  );

  const form = useContext(FilterBoxFormContext);

  useEffect(() => {
    (async () => {
      const params = form.getFieldsValue();
      if (params.value) {
        const data = await getPublisher(params.value);
        setOptions(
          [data].map(({ id, name }) => ({
            value: id,
            label: name
          }))
        );
      }
    })();
  }, [form, getPublisher]);

  return (
    <AutoComplete
      placeHolder="Tìm kênh phát hành"
      handleSearch={searchForPublisher}
      defaultOption={options}
      labelKey="name"
      valueKey="id"
      {...props}
    />
  );
};

export default PublisherAutocompleteFilter;
