import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useReupSpectrum } from 'stores/useReup/useReupSpectrum';

const ReupSpectrumAutoComplete = ({ reupSpectrum, ...props }) => {
  const { searchForReupSpectrum } = useReupSpectrum(
    useCallback(
      ({ searchForReupSpectrum }) => ({
        searchForReupSpectrum
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      reupSpectrum
        ? { value: reupSpectrum.id, label: reupSpectrum.title }
        : null,
    [reupSpectrum]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm spectrum"
      handleSearch={searchForReupSpectrum}
      labelKey="title"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ReupSpectrumAutoComplete;
