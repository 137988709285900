import {
  ClockCircleOutlined,
  EditOutlined,
  EyeOutlined,
  LikeOutlined
} from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import { Facebook, Tiktok, Youtube } from 'components/Icons';
import moment from 'moment';
import ApproveStatusTag from 'pages/PlaylistDetail/components/ApproveStatusTag';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { formatView } from 'utils/currency';
import { formatDate } from 'utils/date';
import queryString from 'query-string';

const { Text } = Typography;

const filterPartner = (partnerSong, type) => {
  return partnerSong.filter(p => p.type === type).map(singer => singer.partner);
};

const SocialIcon = ({ link }) => {
  switch (true) {
    case link.includes('youtube') || link.includes('youtu.be'):
      return <Youtube style={{ fontSize: 16 }} />;
    case link.includes('facebook') || link.includes('fb'):
      return <Facebook style={{ fontSize: 16 }} />;
    case link.includes('tiktok'):
      return <Tiktok style={{ fontSize: 16 }} />;
    default:
      return null;
  }
};

const ChildSongName = ({ song }) => {
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const _songId = searchParams?.SongId;
  return (
    <Typography.Text mark={_songId === song.id}>
      {song?.songName}
    </Typography.Text>
  );
};

export const columns = [
  {
    title: 'Thông tin',
    dataIndex: 'name',
    fixed: 'left',
    render: (name, { id, status, totalSong, link }) => {
      return (
        <Space direction="vertical">
          <Link to={`/chi-tiet-tuyen-tap/${id}`}>
            <Text strong>
              <SocialIcon link={link} /> {name} <EditOutlined />
            </Text>
          </Link>
          <Space>
            <ApproveStatusTag value={status} />
            {totalSong === 0 && status === 'waiting' && (
              <Tag color="error">Chưa đủ điều kiện duyệt</Tag>
            )}
          </Space>
        </Space>
      );
    }
  },
  {
    title: 'Kênh phát hành',
    dataIndex: 'publisher',
    width: 200,
    render: publisher => publisher?.name
  },
  {
    title: 'Phát hành',
    dataIndex: 'publishDate',
    render: publishDate =>
      publishDate && <Typography>{formatDate(publishDate)}</Typography>,
    width: 100
  },
  { title: 'Mã code', dataIndex: 'code' },
  {
    title: 'Phụ trách',
    dataIndex: 'personInCharge',
    width: 200,
    render: (_, { createByUser, updateByUser, approvedBy }) => (
      <Space direction="vertical">
        {createByUser?.fullName && (
          <Text>
            Tạo mới: <Text strong>{createByUser.fullName}</Text>
          </Text>
        )}
        {updateByUser?.fullName && (
          <Text>
            Cập nhật: <Text strong>{updateByUser.fullName}</Text>
          </Text>
        )}
      </Space>
    )
  },

  {
    title: 'Thống kê',
    dataIndex: 'views',
    width: 200,
    render: (views, { scannedDate, likes }) => (
      <Space direction="vertical">
        {(views || views === 0) && (
          <Space>
            <EyeOutlined />
            <Typography.Text>{formatView(views)}</Typography.Text>
          </Space>
        )}
        {(likes || likes === 0) && (
          <Space>
            <LikeOutlined />
            <Typography.Text>{formatView(likes)}</Typography.Text>
          </Space>
        )}

        {scannedDate && (
          <Space>
            <ClockCircleOutlined />
            <Typography.Text style={{ color: 'gray' }}>
              {moment(scannedDate).format('DD/MM/YYYY hh:mm')}
            </Typography.Text>
          </Space>
        )}
      </Space>
    )
  },
  {
    title: 'Phê duyệt',
    dataIndex: 'approved',
    width: 200,
    render: (_, { approver, approvedDate }) => (
      <Space direction="vertical">
        {approver?.fullName && <Text strong>{approver.fullName}</Text>}
        {approvedDate && <Text>{formatDate(approvedDate)}</Text>}
      </Space>
    ),
    defaultHidden: true
  },
  {
    title: 'Số bài hát',
    dataIndex: 'totalSong',
    width: 100,
    defaultHidden: true
  }
  // {
  //   title: 'Thao tác',
  //   width: 100,
  //   render: (_, { id }) => (
  //     <Space direction="vertical">
  //       <ExtractDataButton id={id} />
  //     </Space>
  //   )
  // }
];

export const subColumns = [
  {
    title: 'Tên bài hát',
    dataIndex: 'songName',
    render: (_, song) => <ChildSongName song={song} />
  },
  {
    title: 'Ca sĩ',
    dataIndex: 'singer',
    render: (_, { partnerSong }) => {
      const singers = filterPartner(partnerSong, 'singer');
      return (
        <Space size={16}>
          <Text strong>
            {singers
              .map(singer => singer?.artistName || singer?.fullName)
              .join(', ')}
          </Text>
        </Space>
      );
    }
  },
  {
    title: 'Tác giả',
    dataIndex: 'artist',
    render: (_, { partnerSong }) => {
      const musicians = filterPartner(partnerSong, 'musician');
      return (
        <Space size={16}>
          <Text strong>
            {musicians
              .map(musician => musician?.artistName || musician?.fullName)
              .join(', ')}
          </Text>
        </Space>
      );
    }
  },
  { title: 'Thể loại', dataIndex: 'songTypeName' }
];
