import AutoComplete from 'components/AutoComplete/AutoComplete';
import React, { useCallback, useEffect } from 'react';
import { useContext } from 'react';
import { useProvider } from 'stores/useProvider';
import shallow from 'zustand/shallow';
import { FilterBoxFormContext } from 'components/FilterBox/FilterBoxForm';
import { useState } from 'react';

const ProviderAutoComplete = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { getProvider, searchForProvider } = useProvider(
    useCallback(
      ({ getProvider, searchForProvider }) => ({
        getProvider,
        searchForProvider
      }),
      []
    ),
    shallow
  );

  const form = useContext(FilterBoxFormContext);

  useEffect(() => {
    (async () => {
      const params = form.getFieldsValue();
      if (params.value) {
        const data = await getProvider(params.value);
        setOptions(
          [data].map(({ id, name }) => ({
            value: id,
            label: name
          }))
        );
      }
    })();
  }, [form, getProvider]);

  return (
    <AutoComplete
      placeHolder={placeHolder}
      handleSearch={searchForProvider}
      defaultOption={options}
      labelKey="name"
      valueKey="id"
      {...props}
    />
  );
};

export default ProviderAutoComplete;
