import React from 'react';
import moment from 'moment';
import 'moment/locale/vi';

import {
  TeamOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';

import { BirthdayCake } from 'components/Icons';
import { formatDate } from 'utils/date';
import UserAvatar from 'components/UserAvatar';
import UpdateButton from 'components/UpdateButton';
import { GENDER_DICTIONARY, PARTNER_TYPE, ROLE_CHECK } from 'appConstants';

const representationTypeDictionary = {
  company: 'Công ty',
  manager: 'Quản lý'
};

export const columns = [
  {
    title: 'Ảnh đại diện',
    dataIndex: 'avatar',
    render: (avatar, { artistName }) => (
      <UserAvatar fullName={artistName} avatar={avatar} />
    ),
    fixed: 'left',
    align: 'center',
    width: '150px'
  },
  {
    title: 'Họ tên',
    dataIndex: 'partner-name',
    key: 'partner-name',
    render: (_, { id, fullName, artistName, masterData }) => (
      <Space direction="vertical">
        <UpdateButton
          name={artistName || fullName}
          id={id}
          from={ROLE_CHECK.PARTNER}
        />

        <Typography.Text
          style={{ maxWidth: '200px' }}
          ellipsis={{ tooltip: fullName }}
        >
          {fullName}
        </Typography.Text>
        {masterData?.title && (
          <Tag
            color={
              masterData?.code === PARTNER_TYPE.INDIVIDUAL ? 'cyan' : 'blue'
            }
          >
            {masterData?.title}
          </Tag>
        )}
      </Space>
    ),
    fixed: 'left',
    width: '200px'
  },
  {
    title: 'Thông tin cá nhân',
    dataIndex: 'partner-info',
    key: 'partner-info',
    width: '150px',
    render: (_, { gender, dateOfBirth }) => (
      <Space direction="vertical">
        {dateOfBirth && (
          <Space>
            <BirthdayCake />
            {formatDate(dateOfBirth)}
          </Space>
        )}
        {gender && (
          <Space>
            <TeamOutlined />
            {GENDER_DICTIONARY[gender]}
          </Space>
        )}
      </Space>
    )
  },
  {
    title: 'Thông tin liên hệ',
    dataIndex: 'partner-contact',
    key: 'partner-contact',
    width: '200px',
    render: (_, { address, email, phone }) => (
      <Space direction="vertical">
        {email && (
          <Space>
            <MailOutlined /> {email}
          </Space>
        )}

        {phone && (
          <Space>
            <PhoneOutlined /> {phone}
          </Space>
        )}

        {address && (
          <Space>
            <HomeOutlined />
            <Typography.Text
              style={{ maxWidth: '200px' }}
              ellipsis={{ tooltip: address }}
            >
              {address}
            </Typography.Text>
          </Space>
        )}
      </Space>
    )
  },
  {
    title: 'Người đại diện/Công ty',
    dataIndex: 'partner-represent',
    key: 'partner-represent',
    width: '150px',
    render: (_, { representationType, company, manager }) =>
      representationType && (
        <Space direction="vertical">
          <Space>
            <Typography.Text strong>
              {representationType === 'company'
                ? company?.name
                : manager?.fullName}
            </Typography.Text>
          </Space>

          <Space>
            <InfoCircleOutlined />
            {representationTypeDictionary[representationType]}
          </Space>
        </Space>
      ),
    defaultHidden: true
  },
  {
    title: 'Thông tin thanh toán',
    dataIndex: 'bankAccount',
    width: '150px',
    render: bankAccount => (
      <Space direction="vertical">
        <Space>
          <Typography.Text>{bankAccount?.bankName}</Typography.Text>
        </Space>

        <Space>
          <Typography.Text>{bankAccount?.accountNumber}</Typography.Text>
        </Space>

        <Space>
          <Typography.Text>{bankAccount?.accountName}</Typography.Text>
        </Space>
      </Space>
    )
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    width: '150px',
    render: createDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(createDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
      </Space>
    ),
    defaultHidden: true
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    width: '150px',
    render: updateDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(updateDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(updateDate).fromNow()}</Typography.Text>
      </Space>
    ),
    defaultHidden: true
  }
  // {
  //   width: '200px',
  //   render: () => (
  //     <Button type="primary" size="small">
  //       Tải tất cả hợp đồng
  //     </Button>
  //   ),
  //   align: 'center'
  // }
];
