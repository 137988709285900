import React, { useCallback } from 'react';
import { Tag } from 'antd';
import { getContractTypeValue } from 'utils/parser';
import { CONTRACT_TYPE_VALUE } from 'appConstants';

const ContracTypeTag = ({ value, style }) => {
  const generateColor = useCallback(() => {
    switch (value) {
      case CONTRACT_TYPE_VALUE.DTHT:
        return 'blue';

      case CONTRACT_TYPE_VALUE.BQTG:
        return 'green';

      case CONTRACT_TYPE_VALUE.BQTP:
        return 'red';

      case CONTRACT_TYPE_VALUE.SHTP:
        return 'warning';

      case CONTRACT_TYPE_VALUE.HAPK:
        return 'purple';

      case CONTRACT_TYPE_VALUE.THAM:
        return 'cyan';

      case CONTRACT_TYPE_VALUE.HATA:
        return 'default';

      case CONTRACT_TYPE_VALUE.QUAY:
        return 'magenta';

      case CONTRACT_TYPE_VALUE.CHUP:
        return 'geekblue';

      case CONTRACT_TYPE_VALUE.THUE:
        return 'gold';

      case CONTRACT_TYPE_VALUE.HOTR:
        return 'lime';

      case CONTRACT_TYPE_VALUE.KENH:
        return 'volcano';

      case CONTRACT_TYPE_VALUE.KHAC:
      default:
        return '';
    }
  }, [value]);

  return (
    <Tag
      color={generateColor()}
      style={{
        ...(value === CONTRACT_TYPE_VALUE.HATA
          ? {
              color: '#ffffff',
              background: '#2929298c',
              borderColor: '#333333'
            }
          : {}),
        ...style
      }}
    >
      {getContractTypeValue(value)}
    </Tag>
  );
};

export default ContracTypeTag;
