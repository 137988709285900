import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useReupEffect } from 'stores/useReup/useReupEffect';

const ReupEffectAutoComplete = ({ reupEffect, ...props }) => {
  const { searchForReupEffect } = useReupEffect(
    useCallback(
      ({ searchForReupEffect }) => ({
        searchForReupEffect
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      reupEffect ? { value: reupEffect.id, label: reupEffect.title } : null,
    [reupEffect]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm effect"
      handleSearch={searchForReupEffect}
      labelKey="title"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ReupEffectAutoComplete;
