import React from 'react';
import moment from 'moment';
import 'moment/locale/vi';

import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

import UserAvatar from 'components/UserAvatar';
import UpdateButton from 'components/UpdateButton';
import { ROLE_CHECK } from 'appConstants';
import DeactiveButton from './components/DeactiveButton';
import DeactiveTag from './components/DeactiveTag';
import ChangePasswordFormModal from './components/ChangePasswordFormModal';

export const columns = [
  {
    title: 'Ảnh đại diện',
    dataIndex: 'fullName',
    render: (fullName, { avatar }) => (
      <UserAvatar avatar={avatar} fullName={fullName} />
    ),
    width: 120,
    align: 'center'
  },
  {
    title: 'Họ tên',
    dataIndex: 'user-fullname',
    key: 'user-fullname',
    render: (_, { fullName, username, id, deactive }) => {
      return (
        <Space direction="vertical">
          <Typography.Text style={{ cursor: 'pointer' }}>
            #{username}
          </Typography.Text>

          <UpdateButton name={fullName} id={id} from={ROLE_CHECK.USER} />
          <DeactiveTag deactive={deactive} />
        </Space>
      );
    }
  },
  {
    title: 'Thông tin cá nhân',
    dataIndex: 'user-contact-info',
    key: 'user-contact-info',
    render: (_, { email, phone }) => (
      <Space direction="vertical">
        <Space>
          <MailOutlined /> {email}
        </Space>

        <Space>
          <PhoneOutlined /> {phone}
        </Space>
      </Space>
    )
  },
  {
    title: 'Vai trò',
    dataIndex: 'sysRole',
    render: sysRole => <Typography.Text>{sysRole?.title}</Typography.Text>
  },
  {
    title: 'Phòng ban',
    dataIndex: 'department',
    render: department =>
      department && <Typography.Text>{department?.name}</Typography.Text>
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(createDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
      </Space>
    ),
    defaultHidden: true
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    render: updateDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(updateDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(updateDate).fromNow()}</Typography.Text>
      </Space>
    ),
    defaultHidden: true
  },
  {
    title: 'Thao tác trạng thái',
    dataIndex: 'deactive',
    render: (_, user) => (
      <Space align="center">
        <DeactiveButton user={user} />
        <ChangePasswordFormModal user={user} />
      </Space>
    ),
    align: 'center'
  }
];
