import { Typography } from 'antd';
import { formatPrice } from 'utils/currency';
import PaymentStatusTag from './components/PaymentStatusTag';
export const columns = [
  {
    title: 'Đợt',
    dataIndex: 'batch',
    render: (_, { ordinal }) => (
      <Typography.Text strong>{`Đợt ${ordinal}`}</Typography.Text>
    )
  },
  {
    title: 'Tỉ lệ',
    dataIndex: 'ratio',
    render: (_, { percent }) => (
      <Typography.Text>{`${percent} %`}</Typography.Text>
    )
  },
  {
    title: 'Thực nhận',
    dataIndex: 'takeHomeMoney',
    render: (_, { takeHomeMoney }) => (
      <Typography.Text strong>{formatPrice(takeHomeMoney)}</Typography.Text>
    ),
    editable: true
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'total',
    render: (_, { totalMoney }) => (
      <Typography.Text strong>{formatPrice(totalMoney)}</Typography.Text>
    )
  },
  {
    title: 'Tình trạng',
    dataIndex: 'status',
    render: (_, { status }) => <PaymentStatusTag value={status} />
  }
];
