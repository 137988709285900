import React from 'react';
import moment from 'moment';
import 'moment/locale/vi';

import { EditOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

export const columns = [
  {
    title: 'Phòng ban',
    dataIndex: 'name',
    render: (name, { id }) => (
      <Space direction="vertical">
        <Link to={`/chi-tiet-phong-ban/${id}`}>
          <Typography.Text strong style={{ cursor: 'pointer' }}>
            {name} <EditOutlined />
          </Typography.Text>
        </Link>
      </Space>
    )
  },
  {
    title: 'Thông báo đang theo dõi',
    dataIndex: 'subscribe',
    render: subscribe => (
      <Typography.Text>
        Hiện đang theo dõi {subscribe.length} sự kiện
      </Typography.Text>
    )
  },
  {
    title: 'Khởi tạo',
    dataIndex: 'createDate',
    render: createDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(createDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(createDate).fromNow()}</Typography.Text>
      </Space>
    )
  },
  {
    title: 'Cập nhật',
    dataIndex: 'updateDate',
    render: updateDate => (
      <Space direction="vertical">
        <Typography.Text>
          {moment(updateDate).format('DD/MM/YYYY hh:mm')}
        </Typography.Text>

        <Typography.Text>{moment(updateDate).fromNow()}</Typography.Text>
      </Space>
    )
  }
];
