import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import {
  DEFAULT_ERROR_MESSAGE,
  // ,
  // ORDER_BY_SELECT_VALUE,
  PAGE_SIZE
} from 'appConstants.js';
import { generateFileDownload } from 'utils/redirect';
// import { generateFilterParams } from 'utils/other';

export const usePublisher = create((set, get) => ({
  isPublisherLoading: false,
  isAddingPublisher: false,
  toggleIsAddingPublisher: () =>
    set({ isAddingPublisher: !get().isAddingPublisher }),

  //* ********************* */
  //* NOTE: get publishers  */
  //* ********************* */

  publishers: [],
  totalPage: 1,
  total: 0,
  params: {},
  setParams: params => set({ params }),
  setFilter: (key, value) =>
    set({ filter: { ...get().filter, [key]: value }, page: 1 }),
  getPublishers: async (params, isGetAll = false) => {
    set({ isPublisherLoading: true });
    try {
      const {
        data: { data, total }
      } = await API.get('/publisher', {
        params: {
          PageSize: isGetAll ? 10000 : PAGE_SIZE,
          ...params,
          ...get().params
        }
      });

      set({ isPublisherLoading: false });

      if (data) {
        set({
          publishers: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data.result;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isPublisherLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ************************ */
  //* NOTE: create publishers  */
  //* ************************ */

  createPublisher: async params => {
    set({ isPublisherLoading: true });
    try {
      const { data } = await API.post('/publisher', params);

      set({ isPublisherLoading: false });

      if (data) {
        set({ isAddingPublisher: false });
        await get().getPublishers();

        notification.success({
          message: 'Tạo kênh phát hành thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isPublisherLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********************** */
  //* NOTE: update publishers */
  //* *********************** */

  updatePublisher: async params => {
    set({ isPublisherLoading: true });

    try {
      const { data } = await API.put(`/publisher/${params.id}`, {
        name: params.name,
        code: params.code,
        providerId: params.providerId,
        description: params.description
      });

      set({ isPublisherLoading: false });

      if (data) {
        await get().getPublishers();

        // set({
        //   publishers: get().publishers.map(item =>
        //     item.id === data.id ? data : item
        //   )
        // });

        notification.success({
          message: 'Cập nhật kênh phát hành thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isPublisherLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: delete users  */
  //* ******************* */

  deletePublisher: async id => {
    set({ isPublisherLoading: true });

    try {
      const { data } = await API.delete(`/publisher/${id}`);

      set({ isPublisherLoading: false });

      if (data) {
        await get().getPublishers();

        return notification.success({
          message: 'Xoá kênh phát hành thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isPublisherLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  searchForPublisher: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/publisher/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                  Export list link songPublication from PublisherIds                  //
  //--------------------------------------------------------------------------------------//

  exportSongPublicationByIds: async ids => {
    set({ isExportLoading: true });
    try {
      const { data } = await API.post('/excel/export-all-social-link/bulk', {
        ids
      });
      set({ isExportLoading: false });
      if (data) {
        generateFileDownload(data?.link);
        return data;
      }
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                       Sync statistic data for song publication                       //
  //--------------------------------------------------------------------------------------//

  extractData: async id => {
    try {
      const { data } = await API.get(`/playlist/${id}/sync-statistics`);

      if (data) {
        get().getPlaylists();
      }

      return data;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
