import { MinusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { formatDate } from 'utils/date';

const TextContent = ({ value, isDate = false }) => {
  var isDateValid = value && moment(value).isValid();
  return (
    <Typography.Text strong>
      {isDate && isDateValid ? (
        formatDate(value)
      ) : value ? (
        value
      ) : (
        <MinusOutlined />
      )}
      {/* {value ? isDate ? formatDate(value) : value : <MinusOutlined />} */}
    </Typography.Text>
  );
};

export default TextContent;
