import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import {
  DEFAULT_ERROR_MESSAGE,
  // ORDER_BY_SELECT_VALUE,
  PAGE_SIZE
} from 'appConstants.js';
// import { generateFilterParams } from 'utils/other';

export const useProvider = create((set, get) => ({
  isProviderLoading: false,
  businessAreas: [],
  isAddingProvider: false,
  total: 0,
  params: {},
  setParams: params => set({ params }),
  toggleIsAddingProvider: () =>
    set({ isAddingProvider: !get().isAddingProvider }),
  //* ******************** */
  //* NOTE: get providers  */
  //* ******************** */

  providers: [],
  setFilter: (key, value) =>
    set({ filter: { ...get().filter, [key]: value }, page: 1 }),
  getBusinessAreas: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/business-type?pageSize=100000');

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getProvider: async providerId => {
    try {
      const { data } = await API.get(`/provider/${providerId}`);

      if (data) {
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getProviders: async (params, isGetAll = false) => {
    set({ isProviderLoading: true });

    try {
      const {
        data: { data, total }
      } = await API.get('/provider', {
        params: {
          PageSize: isGetAll ? 10000 : PAGE_SIZE,
          ...params,
          ...get().params
        }
      });

      const businessAreas = await get().getBusinessAreas();

      set({ isProviderLoading: false, businessAreas });

      if (data) {
        set({
          providers: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data.result;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isProviderLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                           Get all providers for selectbox                            //
  //--------------------------------------------------------------------------------------//
  allProviders: [],
  getAllProviders: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/provider', {
        params: {
          PageSize: 10000
        }
      });

      if (data) {
        set({
          allProviders: data
        });
        return data.result;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********************** */
  //* NOTE: create providers  */
  //* *********************** */

  createProvider: async params => {
    set({ isProviderLoading: true });
    try {
      const { data } = await API.post('/provider', params);

      set({ isProviderLoading: false });

      if (data) {
        set({ isAddingProvider: false });
        await get().getProviders();

        notification.success({
          message: 'Tạo nhà cung cấp nội dung thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isProviderLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: get providers */
  //* ******************* */

  updateProvider: async params => {
    set({ isProviderLoading: true });

    try {
      const { data } = await API.put(`/provider/${params.id}`, params);
      set({ isProviderLoading: false });

      if (data) {
        await get().getProviders();

        // set({
        //   providers: get().providers.map(item =>
        //     item.id === data.id ? data : item
        //   )
        // });

        notification.success({
          message: 'Cập nhật nhà cung cấp nội dung thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isProviderLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* *********************** */
  //* NOTE: delete providers  */
  //* *********************** */

  deleteProvider: async id => {
    set({ isProviderLoading: true });

    try {
      const { data } = await API.delete(`/provider/${id}`);
      set({ isProviderLoading: false });

      if (data) {
        await get().getProviders();
        return notification.success({
          message: 'Xoá nhà cung cấp nội dung thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isProviderLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //* ******************* */
  //* NOTE: auto complete */
  //* ******************* */

  searchForProvider: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/provider/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
