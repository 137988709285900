import React, { useCallback } from 'react';
import { notification, Typography } from 'antd';
import shallow from 'zustand/shallow';

import NotificationTypeSelect from 'containers/NotificationTypeSelect';
import { useDepartmentDetail } from 'stores/useDepartmentDetail';

const SelectSubscribeToAdd = props => {
  const { department, tempSubscribe, subscribe, pushToTempSubscribe } =
    useDepartmentDetail(
      useCallback(
        ({ department, tempSubscribe, subscribe, pushToTempSubscribe }) => ({
          department,
          tempSubscribe,
          subscribe,
          pushToTempSubscribe
        }),
        []
      ),
      shallow
    );

  const onSelect = async (receiveNotification, record) => {
    const found = [...subscribe, ...tempSubscribe].find(
      ({ receiveNotification: thisReceiveNotification }) =>
        thisReceiveNotification === receiveNotification
    );

    if (found) {
      return notification.warning({ message: 'Thông báo đã tồn tại!' });
    }

    pushToTempSubscribe({
      // receiveStatus: null,
      receiveNotification,
      objectId: department.id,
      objectType: 'Department',
      index: tempSubscribe.length + 1,
      notificationDetail: {
        code: receiveNotification,
        title: record?.children
      }
    });
  };

  return (
    <>
      <Typography.Text strong>Thêm thông báo:</Typography.Text>{' '}
      <NotificationTypeSelect
        value={null}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        placeHolder="Chọn thông báo"
        onSelect={onSelect}
        style={{ width: 300, marginLeft: 10 }}
        {...props}
      />
    </>
  );
};

export default SelectSubscribeToAdd;
