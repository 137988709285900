import React, { useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import Select from 'components/Select';

import { useDepartmentDetail } from 'stores/useDepartmentDetail';

const NotificationTypeSelect = props => {
  const [options, setOptions] = useState([]);

  const { getAllNotificationType } = useDepartmentDetail(
    useCallback(
      ({ getAllNotificationType }) => ({
        getAllNotificationType
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    const handleGetOption = async () => {
      const res = await getAllNotificationType();

      if (!res) {
        return;
      }

      setOptions(res.map(({ title, code }) => ({ value: code, label: title })));
    };

    handleGetOption();
  }, [getAllNotificationType]);

  return <Select options={options} {...props} />;
};

export default NotificationTypeSelect;
