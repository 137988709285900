import { Form, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { usePlaylistDetail } from 'stores/usePlaylistDetail';
import shallow from 'zustand/shallow';
import ImportSongFormModalContent from './ImportSongFormModalContent';

const ImportSongFormModal = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { importSongs } = usePlaylistDetail(
    useCallback(
      ({ importSongs }) => ({
        importSongs
      }),
      []
    ),
    shallow
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const file = await form.validateFields(['file']);
      const songs = file?.file.shift().response.data;
      importSongs(songs);
      return setIsModalVisible(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    return setIsModalVisible(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: showModal
        })}

      <Modal
        destroyOnClose
        title="Import bài hát"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Huỷ"
        okText="Lưu"
      >
        <ImportSongFormModalContent form={form} />
      </Modal>
    </>
  );
};

export default ImportSongFormModal;
