import { ImportOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table as AntdTable, Typography } from 'antd';
import { ORDER_BY_SELECT_VALUE, PAGE_SIZE, ROLE_CHECK } from 'appConstants';
import DeleteButton from 'components/DeleteButton';
import ExportButton from 'components/ExportButton';
import OrderBySelect from 'components/OrderBySelect';
import { CommonContainer } from 'components/StyledComponents';
import Table from 'components/Table';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useCurrentUser } from 'stores/useCurrentUser';
import { usePlaylist } from 'stores/usePlaylist';
import shallow from 'zustand/shallow';
import { columns, subColumns } from './columns';
import ButtonApprove from './components/ButtonApprove';
import ImportPlaylist from './components/ImportPlaylist';
import PlaylistFilterBox from './components/PlaylistFilterBox';

const { Title } = Typography;

const Playlist = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    getPlaylists,
    playlists,
    deletePlaylists,
    total,
    isExportLoading,
    exportPlaylistByIds
  } = usePlaylist(
    useCallback(
      ({
        getPlaylists,
        playlists,
        deletePlaylists,
        total,
        isExportLoading,
        exportPlaylistByIds
      }) => ({
        getPlaylists,
        playlists,
        deletePlaylists,
        total,
        isExportLoading,
        exportPlaylistByIds
      }),
      []
    ),
    shallow
  );

  const { currentUser } = useCurrentUser(
    useCallback(
      ({ currentUser }) => ({
        currentUser
      }),
      []
    ),
    shallow
  );

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);

  const sort = useMemo(
    () => searchParams.sort || ORDER_BY_SELECT_VALUE.LATEST,
    [searchParams.sort]
  );

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys)
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPlaylists({ ...searchParams, page: page - 1 });
      setLoading(false);
    })();
  }, [getPlaylists, searchParams, page]);

  const expandedRowRender = record => {
    const data = record.playlistDetail.map(({ song }) => ({
      key: song.id,
      ...song
    }));
    return (
      <AntdTable columns={subColumns} dataSource={data} pagination={false} />
    );
  };

  const rowExpandable = record => record.totalSong > 0;

  const onApprove = () => {
    setSelectedRows([]);
  };

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-tuyen-tap',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-tuyen-tap',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  const uploadSuccess = () => {
    history.push({
      pathname: '/danh-sach-tuyen-tap',
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        'UpdateBy[eq]': currentUser?.id,
        sort: ORDER_BY_SELECT_VALUE.RECENTLY_CHANGE
      })
    });
  };

  const playlistToExport = useMemo(
    () =>
      !!selectedRows.length ? selectedRows : playlists.map(_song => _song.id),
    [playlists, selectedRows]
  );

  const isSelected = useMemo(() => !!selectedRows.length, [selectedRows]);

  return (
    <>
      <Title>Danh sách tuyển tập</Title>
      <CommonContainer>
        <Row justify="space-between" className="mb-md">
          <Col>
            <PlaylistFilterBox />
          </Col>
          <Col>
            <Space>
              <ExportButton
                loading={isExportLoading}
                onExportByIds={exportPlaylistByIds}
                selectedItems={playlistToExport}
                callback={() => setSelectedRows([])}
                subject="tuyển tập"
                isSelected={isSelected}
              />
              <ImportPlaylist uploadSuccess={uploadSuccess}>
                <Button
                  icon={<ImportOutlined />}
                  style={{ background: 'green', border: 'none', color: '#fff' }}
                >
                  Import
                </Button>
              </ImportPlaylist>
              {selectedRows.length !== 0 && (
                <ButtonApprove
                  playlists={playlists}
                  selectedRows={selectedRows}
                  onApprove={onApprove}
                />
              )}
              {selectedRows.length !== 0 && (
                <DeleteButton
                  title={`Xoá ${selectedRows.length} tuyển tập?`}
                  onDelete={() => {
                    deletePlaylists({ ids: selectedRows });
                    setSelectedRows([]);
                  }}
                  length={selectedRows.length}
                  from={ROLE_CHECK.PARTNER} //TODO: check role here !
                />
              )}
              <OrderBySelect defaultValue={sort} onChange={setOrderBy} />

              <Link to="/chi-tiet-tuyen-tap">
                <Button type="primary">Tạo mới</Button>
              </Link>
            </Space>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={playlists?.map(playlist => ({
            ...playlist,
            key: playlist.id
          }))}
          expandable={{ expandedRowRender, rowExpandable }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          paginationProps={{
            total: total,
            pageSize: PAGE_SIZE,
            onChange: onPaginate,
            current: page * 1
          }}
          scroll={{ x: 1500 }}
          loading={loading}
        />
      </CommonContainer>
    </>
  );
};

export default Playlist;
