import React from 'react';
import { Tabs, Typography } from 'antd';

import { CommonContainer } from 'components/StyledComponents';
import Publisher from 'containers/Publisher';
import Provider from 'containers/Provider';
import { useHistory, useParams } from 'react-router-dom';

const { TabPane } = Tabs;

const PublishChannel = () => {
  const { id } = useParams();
  const history = useHistory();

  const onChange = e => history.replace('/quan-ly-kenh/' + e);

  return (
    <>
      <Typography.Title>Quản lý kênh</Typography.Title>

      <CommonContainer>
        <Tabs
          type="card"
          activeKey={id}
          onChange={onChange}
          destroyInactiveTabPane={true}
        >
          <TabPane tab="Kênh phát hành" key="kenh-phat-hanh">
            <Publisher />
          </TabPane>

          <TabPane tab="Nhà cung cấp nội dung" key="nha-cung-cap-noi-dung">
            <Provider />
          </TabPane>
        </Tabs>
      </CommonContainer>
    </>
  );
};

export default PublishChannel;
