import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useReupSong } from 'stores/useReup/useReupSong';

const ReupSongAutoComplete = ({ reupSong, ...props }) => {
  const { searchForReupSong } = useReupSong(
    useCallback(
      ({ searchForReupSong }) => ({
        searchForReupSong
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      reupSong
        ? reupSong.map(item => ({
            value: item.id,
            label: item.title
          }))
        : null,
    [reupSong]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm bài hát"
      handleSearch={searchForReupSong}
      labelKey="title"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ReupSongAutoComplete;
