import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PAGE_SIZE } from 'appConstants.js';
import { generateQueryParams } from 'components/FilterBox/utils';
import { generateFileDownload } from 'utils/redirect';

export const useSongPublication = create((set, get) => ({
  isLoading: false,
  isAddingSongPublication: false,
  currentSongId: null,
  toggleIsAddingSongPublication: () =>
    set(({ songPublications, isAddingSongPublication }) => ({
      songPublications: !isAddingSongPublication
        ? [{ add: true, id: 0 }, ...songPublications]
        : songPublications.filter(publication => publication.id !== 0),
      isAddingSongPublication: !isAddingSongPublication
    })),
  song: null,
  songPublication: {},
  songPublications: [],
  songsPublication: [],
  selectProvider: row => {
    set(({ songPublications }) => ({
      songPublications: songPublications.map(publication => {
        return publication.id === row.id ? row : publication;
      })
    }));
  },

  //--------------------------------------------------------------------------------------//
  //                                 Get SongsPublication                                 //
  //--------------------------------------------------------------------------------------//

  getSongsPublication: async params => {
    set({ isLoading: true });
    try {
      const {
        data: { data, total }
      } = await API.get(`/song-publication`, {
        params: generateQueryParams({
          PageSize: PAGE_SIZE,
          ...params,
          ...get().params
        })
      });

      set({ isLoading: false });

      if (data) {
        set({
          songsPublication: data,
          totalPage: Math.ceil(total / PAGE_SIZE),
          total: total
        });
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                  Get Song by SongId                                  //
  //--------------------------------------------------------------------------------------//

  getSong: async songId => {
    set({ isLoading: true });
    try {
      const { data } = await API.get(`/song/${songId}`);
      set({ isLoading: false });

      if (data) {
        set({ song: data });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                            Get SongPublication by SongId                             //
  //--------------------------------------------------------------------------------------//

  getSongPublicationBySongId: async songId => {
    set({ isLoading: true });
    try {
      const {
        data: { data }
      } = await API.get(`/song/${songId}/song-publication`);

      set({ isLoading: false });

      if (data) {
        const parserData = data.map(song => ({
          ...song,
          providerId: song?.publisher?.provider && song.publisher.providerId,
          publisherId: song.publisher && song.publisher.id
        }));
        set({
          songPublications: parserData,
          // song: data[0].song,
          currentSongId: songId
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                              Get SongPublication By Id                               //
  //--------------------------------------------------------------------------------------//

  getSongPublication: async id => {
    set({ isLoading: true });
    try {
      const { data } = await API.get(`/song-publication/${id}`);

      set({ isLoading: false });

      if (data) {
        set({
          songPublication: data
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                Create SongPublication                                //
  //--------------------------------------------------------------------------------------//

  createSongPublication: async (params, callback = f => f) => {
    set({ isLoading: true });
    try {
      const { data } = await API.post('/song-publication', params);

      set({ isLoading: false });

      if (data) {
        notification.success({
          message: 'Tạo link phát hành thành công!'
        });
        callback(data.id);
        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                Update SongPublication                                //
  //--------------------------------------------------------------------------------------//

  updateSongPublication: async params => {
    set({ isLoading: true });

    try {
      const { data } = await API.put(`/song-publication/${params.id}`, params);

      set({ isLoading: false });

      if (data) {
        await get().getSongPublication(params.id);

        notification.success({
          message: 'Cập nhật link phát hành thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                Delete SongPublication                                //
  //--------------------------------------------------------------------------------------//

  deleteSongPublication: async id => {
    set({ isLoading: true });

    try {
      const { data } = await API.delete(`/song-publication/${id}`);

      set({ isLoading: false });

      if (data) {
        await get().getSongsPublication();

        return notification.success({
          message: 'Xoá link phát hành thành công!'
        });
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      set({ isLoading: false });

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  isProviderFacebook: false,
  setIsProviderFacebook: isProviderFacebook => set({ isProviderFacebook }),

  //--------------------------------------------------------------------------------------//
  //                            Export songPublication from ids                           //
  //--------------------------------------------------------------------------------------//
  isExportLoading: false,
  exportSongPublicationByIds: async ids => {
    set({ isExportLoading: true });
    try {
      const { data } = await API.post('/excel/export-song-publications/bulk', {
        ids
      });
      set({ isExportLoading: false });
      if (data) {
        generateFileDownload(data?.link);
        return data;
      }
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
