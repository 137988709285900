import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE } from 'appConstants.js';

export const usePlaylistDetail = create((set, get) => ({
  isLoading: false,
  playlist: {},
  resetPlaylist: () => set({ playlist: {} }),
  providers: [],
  publishers: [],
  publisherIdTemp: null,
  importedSongs: [],
  importSongs: songs => set({ importedSongs: songs }),
  //--------------------------------------------------------------------------------------//
  //                              Get Playlist by PlaylistId                              //
  //--------------------------------------------------------------------------------------//

  getPlaylist: async id => {
    set({ isLoading: true });
    try {
      const { data } = await API.get(`/playlist/${id}`);

      set({ isLoading: false });

      if (data) {
        const dataSort = {
          ...data,
          playlistDetail: data.playlistDetail.sort(
            (firstEl, secondEl) => firstEl.ordinal - secondEl.ordinal
          )
        };
        set({ playlist: dataSort });
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                   Create Playlist                                    //
  //--------------------------------------------------------------------------------------//

  createPlaylist: async (params, callback = f => f) => {
    set({ isLoading: true });
    try {
      const { data } = await API.post('/playlist', params);
      set({ isLoading: false });

      if (data) {
        notification.success({
          message: 'Tạo tuyển tập thành công!'
        });
        callback(data.id);
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                   Update Playlist                                    //
  //--------------------------------------------------------------------------------------//

  updatePlaylist: async params => {
    set({ isLoading: true });
    try {
      const { data } = await API.put(`/playlist/${params.id}`, params);
      set({ isLoading: false });
      if (data) {
        get().getPlaylist(data.id);
        get().getTop5Artists(data.id);
        notification.success({
          message: 'Cập nhật tuyển tập thành công!'
        });
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                  Get all Publisher                                   //
  //--------------------------------------------------------------------------------------//

  getAllPublisher: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/publisher', {
        params: { Page: 0, PageSize: 10000 }
      });

      if (data) {
        set({ publishers: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                   Get all Provider                                   //
  //--------------------------------------------------------------------------------------//

  getAllProvider: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/provider', {
        params: { Page: 0, PageSize: 10000 }
      });

      if (data) {
        set({ providers: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                          Get Revenue Playlist by PlaylistId                          //
  //--------------------------------------------------------------------------------------//
  revenuePlaylist: [],
  getRevenuePlaylist: async id => {
    try {
      const {
        data: { data }
      } = await API.get(`/playlist/${id}/revenue-playlist`);

      if (data) {
        set({ revenuePlaylist: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                               Update Revenue Playlist                                //
  //--------------------------------------------------------------------------------------//

  updateRevenuePlaylist: async (id, params) => {
    set({ isLoading: true });
    try {
      const { data } = await API.put(
        `/playlist/${id}/revenue-playlist`,
        params
      );
      set({ isLoading: false });

      if (data) {
        // get().getPlaylist(data.id);
        notification.success({
          message: 'Cập nhật phân chia tuyển tập thành công!'
        });
        return data;
      }

      return null;
    } catch ({ data }) {
      set({ isLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  //--------------------------------------------------------------------------------------//
  //                           Get top 5 artists by playlist id                           //
  //--------------------------------------------------------------------------------------//
  top5Artists: [],
  getTop5Artists: async playlistId => {
    try {
      const { data } = await API.get(`/playlist/${playlistId}/top-5-artists
      `);

      if (data) {
        set({ top5Artists: data?.data || [] });
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
