import React, { useCallback, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';

import Button from 'components/Button';
import MetaCard from 'components/MetaCard';
import useAuth from 'stores/useAuth';
import shallow from 'zustand/shallow';

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const { changePassword, logout } = useAuth(
    useCallback(
      ({ changePassword, logout }) => ({
        changePassword,
        logout
      }),
      []
    ),
    shallow
  );

  const onFinish = async values => {
    setLoading(true);
    await changePassword(values, logout);
    setLoading(false);
  };

  return (
    <MetaCard title="Đổi mật khẩu" style={{ marginTop: '24px' }}>
      <Form layout="vertical" onFinish={onFinish}>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              label="Mật khẩu"
              name="oldPassword"
              rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Mật khẩu mới"
              name="newPassword"
              rules={[{ required: true, message: 'Nhập mật khẩu mới!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Nhập lại mật khẩu mới"
              name="retypeNewPassword"
              rules={[{ required: true, message: 'Nhập lại mật khẩu mới!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </MetaCard>
  );
};

export default ChangePassword;
