import React, { useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import Select from 'components/Select';
import { useSong } from 'stores/useSong';

const SongTypeDetailSelect = ({ code, valueKey = 'code', ...props }) => {
  const [options, setOptions] = useState([]);
  const { getSongTypeDetail } = useSong(
    useCallback(
      ({ getSongTypeDetail }) => ({
        getSongTypeDetail
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    const handleGetOption = async () => {
      const res = await getSongTypeDetail(code);

      if (!res) return;

      setOptions(
        res.map(({ title, ...other }) => ({
          value: other[valueKey],
          label: title
        }))
      );
    };

    handleGetOption();
  }, [code, getSongTypeDetail, valueKey]);

  return (
    <Select
      showSearch
      allowClear
      options={options}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      {...props}
    />
  );
};

export default React.memo(SongTypeDetailSelect);
