import create from 'zustand';
import API from 'api';
import { notification } from 'antd';

import { DEFAULT_ERROR_MESSAGE, PARTNER_TYPE } from 'appConstants.js';
import { generateFileDownload } from 'utils/redirect';

export const usePartnerDetail = create((set, get) => ({
  //NOTE: get partner
  partner: null,
  isExportLoading: false,
  isSinglePartner: true,
  setIsSinglePartner: isSinglePartner => set({ isSinglePartner }),
  resetUser: () => set({ partner: null }),
  getPartner: async userId => {
    try {
      const { data } = await API.get(`/partner/${userId}`);

      if (data) {
        if (data?.masterData?.code) {
          const isSinglePartner =
            data?.masterData?.code === PARTNER_TYPE.INDIVIDUAL;
          set({ isSinglePartner });
        }

        if (data.representationType === 'manager') {
          set({ partner: data, selectingManager: data.manager });
        } else {
          set({ partner: data, selectingCompany: data.company });
        }

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //NOTE: create partner
  createPartnerBankAccount: async (params, partnerId) => {
    try {
      const { data } = await API.post(
        `/partner/${partnerId}/bank-account`,
        params
      );

      if (data) {
        notification.success({
          message: 'Tạo thông tin ngân hàng thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updatePartnerBankAccount: async (params, partnerId, bankAccId) => {
    try {
      const { data } = await API.put(
        `/partner/${partnerId}/bank-account/${bankAccId}`,
        params
      );

      if (data) {
        notification.success({
          message: 'Chỉnh sửa thông tin ngân hàng thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  createPartner: async (params, callback = f => f, setFieldsError = f => f) => {
    try {
      const { data } = await API.post('/partner', params);
      if (data) {
        callback(data.id);
        notification.success({
          message: 'Tạo đối tác thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      setFieldsError(data?.errors);

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  //NOTE: update partner
  updatePartner: async (params, setFieldsError = f => f) => {
    try {
      const { data } = await API.put(`/partner/${params.id}`, {
        ...params,
        avatar: Array.isArray(params.avatar)
          ? params.avatar[0].url
          : params.avatar,
        delegacyFile: params?.delegacyFile
          ? params?.delegacyFile.map(file => ({
              fileName: file?.name || file.fileName,
              fileLink: file?.url || file.fileLink
            }))
          : null
      });

      if (data) {
        //NOTE: false data
        // set({ partner: data });
        await get().getPartner(data.id);

        notification.success({
          message: 'Chỉnh sửa đối tác thành công!'
        });

        return data;
      }

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });

      return null;
    } catch ({ data }) {
      setFieldsError(data?.errors);

      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  /*** 
    NOTE: representative - manager
  ***/

  selectingManager: null,
  getSelectingManager: async id => {
    try {
      const {
        data: { data }
      } = await API.get(`/manager/${id}`);

      if (data) {
        set({ selectingManager: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  resetSelectingManager: () => set({ selectingManager: null }),
  searchForManager: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/manager/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data && data.length !== 0) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  createManager: async params => {
    try {
      const { data } = await API.post('/manager', params);

      if (data) {
        notification.success({
          message: 'Tạo quản lý thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateManager: async (params, callback = f => f) => {
    try {
      const { data } = await API.put(`/manager/${params.id}`, params);

      if (data) {
        set({ selectingManager: data });
        callback();

        notification.success({
          message: 'Cập nhật quản lý thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  //magic 🧙‍♀️🧙‍♀️🧙‍♀️
  //Thay đổi option của AutoComplete khi edit tên manager,
  //vì label của option là tên của manager
  updateManagerAutoCompleteOptions: f => f,
  setUpdateManagerAutoCompleteOptions: updateManagerAutoCompleteOptions =>
    set({ updateManagerAutoCompleteOptions }),

  /*** 
    NOTE: representative - company
  ***/

  selectingCompany: null,
  getSelectingCompany: async id => {
    try {
      const { data } = await API.get(`/company/${id}`);

      if (data) {
        set({ selectingCompany: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  resetSelectingCompany: () => set({ selectingCompany: null }),
  searchForCompany: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/company/search', {
        params: { limit: 5, freeText: searchValue }
      });

      if (data && data.length !== 0) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  createCompany: async params => {
    try {
      const { data } = await API.post('/company', params);

      if (data) {
        notification.success({
          message: 'Tạo công ty thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  updateCompany: async (params, callback = f => f) => {
    try {
      const { data } = await API.put(`/company/${params.id}`, params);

      if (data) {
        set({ selectingCompany: data });
        callback();

        notification.success({
          message: 'Cập nhật công ty thành công!'
        });

        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  //magic 🧙‍♀️🧙‍♀️🧙‍♀️
  //Thay đổi option của AutoComplete khi edit tên company,
  //vì label của option là tên của company
  updateCompanyrAutoCompleteOptions: f => f,
  setUpdateCompanyrAutoCompleteOptions: updateCompanyrAutoCompleteOptions =>
    set({ updateCompanyrAutoCompleteOptions }),

  /*** 
    NOTE: auto complete
  ***/

  searchForBankName: async searchValue => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata/search', {
        params: { limit: 5, group: 'bankName', freeText: searchValue }
      });

      if (data) {
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },

  //--------------------------------------------------------------------------------------//
  //                                     Partner Type                                     //
  //--------------------------------------------------------------------------------------//

  partnerType: [],
  getAllArtistType: async () => {
    try {
      const {
        data: { data }
      } = await API.get('/masterdata', {
        params: { Page: 0, PageSize: 10000, 'Group[eq]': 'artistType' }
      });

      if (data) {
        set({ partnerType: data });
        return data;
      }

      return null;
    } catch ({ data }) {
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  },
  getExportPartner: async partnerId => {
    try {
      set({ isExportLoading: true });
      const { data } = await API.get(`/excel/export-partner/${partnerId}`);

      if (data) {
        generateFileDownload(data?.link);
        set({ isExportLoading: false });
        return data;
      }

      set({ isExportLoading: false });
    } catch (data) {
      set({ isExportLoading: false });
      notification.error({
        message: data?.errors || DEFAULT_ERROR_MESSAGE
      });
    }
  }
}));
