import Select from 'components/Select';
import { useCallback, useEffect, useState } from 'react';
import { useService } from 'stores/useService';
import shallow from 'zustand/shallow';

const BusinessTypeAutocomplete = ({ placeHolder, ...props }) => {
  const [options, setOptions] = useState([]);
  const { getBusinessAreas } = useService(
    useCallback(
      ({ getBusinessAreas }) => ({
        getBusinessAreas
      }),
      []
    ),
    shallow
  );

  useEffect(() => {
    (async () => {
      const data = await getBusinessAreas();
      setOptions(
        data.map(({ id, name }) => ({
          value: id,
          label: name
        }))
      );
    })();
  }, [getBusinessAreas]);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      placeHolder={placeHolder}
      options={options}
      {...props}
    />
  );
};

export default BusinessTypeAutocomplete;
