import { Form, Row, Typography } from 'antd';
import { CommonContainer } from 'components/StyledComponents';
import React from 'react';
import Statistic from './components/Statistic';

const PublisherStatistic = () => {
  return (
    <Form>
      <Row>
        <Typography.Title>Thống kê kênh phát hành</Typography.Title>
      </Row>
      <CommonContainer>
        <Statistic />
      </CommonContainer>
    </Form>
  );
};

export default PublisherStatistic;
