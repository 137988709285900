import React from 'react';
import { Typography, Row, Col, Space } from 'antd';
import { GENDER_DICTIONARY } from 'appConstants';
import { formatDate } from 'utils/date';
import UserAvatar from 'components/UserAvatar';

const { Text } = Typography;

const PartnerContent = ({ partner }) => {
  return (
    <Row className="p-lg">
      <Col flex="auto">
        <Row>
          <Col flex="1">
            <Text strong>Họ tên: </Text>
            <Text>{partner.fullName}</Text>
          </Col>
          <Col flex="1">
            <Text strong>Giới tính: </Text>
            <Text>{GENDER_DICTIONARY[partner?.gender]}</Text>
          </Col>
          <Col flex="1">
            <Text strong>Số điện thoại: </Text>
            <Text>{partner.phone}</Text>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Text strong>Email: </Text>
            <Text>{partner.email}</Text>
          </Col>
          <Col flex="1">
            <Text strong>Ngày sinh: </Text>
            <Text>
              {partner.dateOfBirth && formatDate(partner.dateOfBirth)}
            </Text>
          </Col>
          <Col flex="1">
            <Text strong>Mã số thuế: </Text>
            <Text>{partner.taxCode}</Text>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Text strong>CMND: </Text>
            <Text>{partner.identityNumber}</Text>
          </Col>
          <Col flex="1">
            <Text strong>Ngày cấp: </Text>
            <Text>
              {' '}
              {partner.dateOfIdentification &&
                formatDate(partner.dateOfIdentification)}
            </Text>
          </Col>
          <Col flex="1">
            <Text strong>Nơi cấp: </Text>
            <Text>{partner.placeOfIdentification}</Text>
          </Col>
        </Row>
        <div>
          <Text strong>Địa chỉ: </Text>
          <Text>{partner.address}</Text>
        </div>
      </Col>
      <Col>
        <Space direction="vertical" align="center">
          <UserAvatar fullName={partner.fullName} avatar={partner.avatar} />
          <Text strong>{partner.artistName}</Text>
        </Space>
      </Col>
    </Row>
  );
};

export default PartnerContent;
