import React, { useCallback, useMemo } from 'react';

import shallow from 'zustand/shallow';

import AutoComplete from 'components/AutoComplete';
import { useReupTag } from 'stores/useReup/useReupTag';

const ReuTagAutoComplete = ({ reupTag, ...props }) => {
  const { searchForReupTag } = useReupTag(
    useCallback(
      ({ searchForReupTag }) => ({
        searchForReupTag
      }),
      []
    ),
    shallow
  );

  const defaultOption = useMemo(
    () =>
      reupTag
        ? reupTag.map(item => ({ value: item.id, label: item.title }))
        : null,
    [reupTag]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      placeHolder="Tìm spectrum"
      handleSearch={searchForReupTag}
      labelKey="title"
      valueKey="id"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ReuTagAutoComplete;
