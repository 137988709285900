import { Checkbox } from 'antd';
import React from 'react';

const NaviCheckbox = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <Checkbox ref={ref} {...rest}>
      {children}
    </Checkbox>
  );
});

export default NaviCheckbox;
